/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionPbxConfiguration } from './subscriptionPbxConfiguration.js';
import { Address } from './address.js';
import { SimType } from './simType.js';


/**
 * Details for subscription of type MOBILE.
 */
export interface SubscriptionDetailsMobile { 
    simType: SimType;
    /**
     * SIM Card number (ICC) of the subscription. SFDC: csord__Subscription.ICCID__c.
     */
    simCardNumber?: string;
    /**
     * SIM Card (IMSI) of the subscription. SFDC: csord__Subscription.IMSI__c.
     */
    imsi?: string;
    /**
     * Whether or not this subscription supports/allows eSIM to be used.
     */
    esimAllowed?: boolean;
    /**
     * eSIM QR Activation code. This must be in the QR format, and thus _must_ begin with \'LPA:\'. This is a mandatory field if simType == ESIM. SFDC: csord__Subscription.Activation_Code__c
     */
    eSimQrActivationCode?: string;
    /**
     * Mobile number (MSISDN) of the subscription. SFDC: csord__Subscription.MSISDN__c.
     */
    mobileNumber?: string;
    /**
     * PUK1 personal unblocking code for the subscription. SFDC: csord__Subscription.PUK1__c.
     */
    puk1?: string;
    /**
     * PUK2 personal unblocking code for the subscription. SFDC: csord__Subscription.PUK2__c.
     */
    puk2?: string;
    /**
     * How the number of the subscription will be listed in the public directory. SFDC: csord__Subscription.Directory_Listing_Publicity__c.
     */
    directoryListing?: SubscriptionDetailsMobile.DirectoryListingEnum;
    /**
     * If directoryListing is CUSTOM then company name for directory listing. SFDC: csord__Service__c.Company_Name_DL__c.
     */
    companyName?: string;
    /**
     * If directoryListing is CUSTOM then recipient name for directory listing. SFDC: csord__Service__c.Recipient_Name_DL__c.
     */
    recipientName?: string;
    directoryAddress?: Address;
    pbxConfiguration?: SubscriptionPbxConfiguration;
    /**
     * The name of the model of the device delivered for mobile connections
     */
    deviceModel?: string;
    /**
     * Serial number of the device
     */
    serialNumber?: string;
}
export namespace SubscriptionDetailsMobile {
    export type DirectoryListingEnum = 'PUBLIC_DIRECTORY' | 'SECRET' | 'CUSTOM' | 'NOT_TO_DIRECTORY';
    export const DirectoryListingEnum = {
        PUBLIC_DIRECTORY: 'PUBLIC_DIRECTORY' as DirectoryListingEnum,
        SECRET: 'SECRET' as DirectoryListingEnum,
        CUSTOM: 'CUSTOM' as DirectoryListingEnum,
        NOT_TO_DIRECTORY: 'NOT_TO_DIRECTORY' as DirectoryListingEnum
    };
}


