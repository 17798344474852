import * as CL from '@design-system/component-library';
import * as React from 'react';
import { checkOutInternetSolutionsMsg, internetSolutionsMsg, t } from '../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';

import './ContactSupportBlock.scss';

// TODO: This whole component is clickable but also contains a button without a specific click handler
// Need to discuss with designers if only button should be clickable or remove button all together
export const ContactSupportBlock = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(paths.INTERNET_FOR_BIG_COMPANY);
  };

  return (
    <a
      className="of-contact-support-container"
      href={paths.INTERNET_FOR_BIG_COMPANY}
      onClick={(event: React.SyntheticEvent) => {
        event.preventDefault();
        handleClick();
      }}
      onKeyPress={(event: React.KeyboardEvent) => onEnterOrSpaceKeyPress(event, handleClick)}
    >
      <div className="of-contact-support-background">
        <img
          alt={t.Z2Y8('Check also our internet subscriptions for bigger companies')}
          src="https://static.elisa.com/v2/image/2tqybbhjs47b/5OXhVGOHXjJ8zzTD51j7MF/netti-toimistolle.webp?w=1920&h=1200&fm=webp"
        />
      </div>
      <div className="of-contact-support-text">
        <div>
          <div>
            <h2 className="ea-h2">{t.LFBC(internetSolutionsMsg)}</h2>
          </div>
          <div>{t.BQS1(checkOutInternetSolutionsMsg)}</div>
          <CL.Button size="l" tabIndex={-1}>
            {t.GJJF('Read more')}
          </CL.Button>
        </div>
      </div>
    </a>
  );
};
