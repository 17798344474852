import { ELISA_YTT } from '../../../common/utils/subscriptionUtils';
import { dsClass } from '../../../common/constants/dsClasses';
import { t } from '../../../common/i18n';
import type { Accordion } from '../../../common/utils/accordionUtils';
import type { Subscription } from '../../../generated/api/subscription';

const isYttInstructionsContentVisible = (subscription: Subscription): boolean => {
  const regex = /^(?:25|35|45) laitteeseen.*/;
  const subscriptionSubType = subscription.subscriptionSubType ?? '';
  const subscriptionUserFriendlyId = subscription.subscriptionUserFriendlyId ?? '';

  return (
    subscriptionSubType.startsWith(ELISA_YTT) &&
    subscriptionUserFriendlyId !== '' &&
    !regex.test(subscriptionUserFriendlyId)
  );
};

const getYttInstructionsAccordionContent = () => (
  <>
    <h5 className="yttSectionHeader">{t.M64T(`Elisa Yritystietoturva's data recovery`)}</h5>
    <div>
      {t.B8KE(
        'You have an Elisa Yritystietoturva subscription, which includes data recovery service if your device or files are damaged. Please read the instructions in the link carefully.'
      )}
      <div className={dsClass.PADDING_BOTTOM_4}>
        <a href="https://yrityksille.elisa.fi/ohje/yritystietoturva-tietojenpalautus" target="_BLANK" rel="noreferrer">
          {t.SMAR('Instructions for data recovery (in Finnish)')}.
        </a>
      </div>
    </div>
    <h5 className="yttSectionHeader">{t.Q9G5('Phone support for security problems')}</h5>
    <p>
      {t.XTU4(
        'You have an Elisa Yritystietoturva subscription, which includes separate telephone support for security problems. Our partner F-Secure is responsible for phone support.'
      )}
    </p>
    <p>
      {t.OXWO(
        'The support line is open from 08.00-17.00. You can get the service in Finnish, Swedish or English. If you wish, the telephone support team can remotely contact your device to resolve the issue.'
      )}
    </p>
    <div>
      <a href="https://yrityksille.elisa.fi/ohje/yritystietoturva-puhelintuki" target="_BLANK" rel="noreferrer">
        {t.N4TR('See the telephone support number and in what situation the support can help (in Finnish)')}.
      </a>
    </div>
  </>
);

export const getYttInstructionsAccordion = (subscription: Subscription): Accordion => ({
  headerName: t.WSA8(`Elisa Yritystietoturva's data recovery and phone support`),
  id: 'domain',
  includedData: getYttInstructionsAccordionContent(),
  displayed: isYttInstructionsContentVisible(subscription),
});
