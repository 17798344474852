import { Contract, SubscriptionType } from '../../generated/api/models.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableTopActions, tableSearch } from '../Table';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { MobileIdDisabled } from '../MobileId/MobileIdDisabled.js';
import { SelectCompanyDialog } from '../Dialogs/SelectCompanyDialog';
import { allServicesMsg, companyMsg, idNumberMsg, orderMsg, statusMsg, t } from '../../common/i18n';
import { getCompanyName, isMultiBiz } from '../../common/utils/accountUtils';
import { getContractStatus } from '../MobileId/MobileIdDetails.js';
import { getPaginatedItems } from './subscriptionListUtils.js';
import { getSubscriptionIcon } from './subscriptionIcons.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { DefaultListSearchParams, TableTopButton } from '../Table';
import type { KeyRow } from '@design-system/component-library';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick';
import type { RealAuthenticatedUserState } from '../../common/types/states';

interface ServiceContractsProps {
  isMobileIdEnabled?: boolean;
  mobileIdContracts?: Contract[];
  offset: number;
  search?: string;
  total?: number;
  itemsPerPage?: number;
}

const getContractRows = (
  mobileIdContracts: Contract[] = [],
  onRowClick: MultiAccountRowClickFunction,
  authenticatedUser?: RealAuthenticatedUserState
): KeyRow[] =>
  mobileIdContracts
    .filter(
      contract =>
        contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED ||
        contract.contractStatus === Contract.ContractStatusEnum.TERMINATED
    )
    .map(contract => ({
      icon: (
        <div
          className={`of-subscription-table__icon-column of-icon--${getSubscriptionIcon(
            SubscriptionType.MOBILE_CERTIFICATE
          )}`}
        ></div>
      ),
      subscriptionName: (
        <Link
          to={`${generatePath(paths.PS_CONTRACT_MOBILE_ID, { contractDisplayId: contract.contractNumber })}?mdmId=${contract.accountMasterId}`}
          onClick={() => {
            onRowClick(
              `${generatePath(paths.PS_CONTRACT_MOBILE_ID, { contractDisplayId: contract.contractNumber })}?mdmId=${contract.accountMasterId}`,
              getCompanyName(authenticatedUser, contract.accountMasterId),
              contract.accountMasterId
            );
          }}
          className="of-subscription-table__text-column"
        >
          {contract.contractName}
        </Link>
      ),
      subscriptionStatus: getContractStatus()[contract.contractStatus],
      subscriptionUserFriendlyId: contract.contractNumber,
      company: getCompanyName(authenticatedUser, contract.accountMasterId),
    }));

export const ServiceContracts = ({
  isMobileIdEnabled,
  mobileIdContracts,
  offset,
  search,
  itemsPerPage,
}: ServiceContractsProps) => {
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { authenticatedUser } = useAuth();
  const onRowClick = useMultiAccountRowClick();
  const [showSelectCompanyDialog, setShowSelectCompanyDialog] = useState(false);

  if (!isMobileIdEnabled) {
    return <MobileIdDisabled />;
  }

  const tableTopButtons: TableTopButton[] = [
    {
      label: t.AQOL(orderMsg),
      onClick: () => {
        if (isMultiBiz(authenticatedUser)) {
          setShowSelectCompanyDialog(true);
        } else {
          navigate(paths.PS_CONTRACT_MOBILE_ID_NEW);
        }
      },
    },
  ];

  const contractColumns = [
    { key: 'icon', label: '' },
    { key: 'subscriptionName', label: t.NX0T(allServicesMsg) },
    { key: 'subscriptionUserFriendlyId', label: t.YTH3(idNumberMsg), sortable: false },
    { key: 'subscriptionStatus', label: t.ASQT(statusMsg), sortable: false },
    { key: 'company', label: t.KJTS(companyMsg), sortable: false },
  ];

  const contractRows = getContractRows(mobileIdContracts, onRowClick, authenticatedUser);
  const rows = search ? tableSearch(search, contractRows) : contractRows;

  return (
    <div className="of-subscription-table">
      {showSelectCompanyDialog && (
        <SelectCompanyDialog
          onCloseDialog={() => setShowSelectCompanyDialog(false)}
          onContinue={selectedMdmId => {
            navigate(`${paths.PS_CONTRACT_MOBILE_ID_NEW}?mdmId=${selectedMdmId}`);
          }}
        />
      )}
      <TableTopActions buttons={tableTopButtons} />
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table
        columns={contractColumns}
        noItemsText={t.XZA8('No services')}
        rows={getPaginatedItems(itemsPerPage, offset, rows)}
      />
      <ListPagination totalItems={rows.length} />
    </div>
  );
};
