import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SimActivation } from '../../../../../components/SimActivation/SimActivation.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { activateSimCardMsg, omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import {
  getSubscriptionBreadcrumbName,
  getSubscriptionStatus,
  getSubscriptionUrlId,
} from '../../../../../common/utils/subscriptionUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { MobileSubPostChangeRequestLoaderResponse } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store.js';

export const MobileSubActivateSimPath = () => {
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as MobileSubPostChangeRequestLoaderResponse;
  const serviceFees = useSelector((state: State) => state?.selfservice?.subscriptions?.voice?.serviceFees, deepEqual);
  const { pendingActionType, pendingActions } = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const pendingSimChanges = pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <SimActivation
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
            {
              name: getSubscriptionBreadcrumbName(subscription),
              path:
                generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) +
                search,
            },
            { name: t.M1E7(activateSimCardMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      disallowBillableSimChanges={false}
      pendingSimChanges={pendingSimChanges}
      pendingSubscriptionChanges={pendingActions}
      onClickOrderSim={() =>
        navigate(
          generatePath(paths.PS_MOBILE_SUBSCRIPTION_ORDER_SIM, {
            subscriptionId: getSubscriptionUrlId(subscription),
          }) + search
        )
      }
      onClickSubscription={() => {
        navigate(
          generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) + search
        );
      }}
      subscription={subscription}
      simChangeServiceFee={
        serviceFees?.find(serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId)?.price
      }
    />
  );
};
