import { LiikkuvaWifiPopOverContent } from './LiikkuvaWifiPopoverContent';
import { ManagedServicePopoverContent } from './ManagedServicePopoverContent';
import { MobiiliturvaPopoverContent } from './MobiiliturvaPopoverContent';
import { MobileVoicemailPopoverContent } from './MobileVoicemailPopoverContent';
import { ReissaajaPopoverContent } from './ReissaajaPopoverContent';
import { ReissunettiOnePopoverContent } from './ReissunettiOnePopoverContent';
import { ReissunettiTwoPopoverContent } from './ReissunettiTwoPopoverContent';
import { SubscriptionAddonCode } from '../../../common/enums';
import { YritysWifiPopoverContent } from './YritysWifiPopoverContent';
import { t } from '../../../common/i18n';
import type { SubscriptionCardServiceProps } from '../../SubscriptionCard/SubscriptionCard';

export const getReissunetti1Service = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: (
    <>
      <span>Reissunetti 1-1,5 {t.C4YQ('GB')} </span>
      <span className="small">* | {t.T56E('Charged separately')}</span>
    </>
  ),
  guid: SubscriptionAddonCode.REISSUNETTI1,
  isIncluded,
  icon: 'roaming',
  popoverContent: <ReissunettiOnePopoverContent />,
});

export const getReissunetti2Service = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: (
    <>
      <span>Reissunetti 2 {t.C4YQ('GB')} * | </span>
      <span className="small">{t.T56E('Charged separately')}</span>
    </>
  ),
  guid: SubscriptionAddonCode.REISSUNETTI2,
  isIncluded,
  icon: 'roaming',
  popoverContent: <ReissunettiTwoPopoverContent />,
});

export const getMobiiliturvaService = (isIncluded: boolean, inPublicStore?: boolean): SubscriptionCardServiceProps => ({
  name: 'Elisa Mobiiliturva',
  guid: SubscriptionAddonCode.MOBIILITURVA,
  isIncluded,
  icon: 'mobiiliturva',
  popoverContent: <MobiiliturvaPopoverContent inPublicStore={inPublicStore} />,
});

export const getReissaajaService = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: (
    <>
      <span>Reissaaja * | </span>
      <span className="small">{t.T56E('Charged separately')}</span>
    </>
  ),
  guid: SubscriptionAddonCode.REISSAAJA,
  isIncluded,
  icon: 'roaming',
  popoverContent: <ReissaajaPopoverContent />,
});

export const getMatkapuhelinvastaajaService = (
  isIncluded: boolean,
  inPublicStore?: boolean
): SubscriptionCardServiceProps => ({
  name: 'Elisa Matkapuhelinvastaaja',
  guid: SubscriptionAddonCode.VOICE_MAIL,
  isIncluded,
  icon: 'voicemail',
  popoverContent: <MobileVoicemailPopoverContent inPublicStore={inPublicStore} />,
});

export const getYritysWifiLiteService = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: 'Elisa YritysWIFI Lite',
  guid: SubscriptionAddonCode.YRITYS_WIFI_LITE,
  isIncluded,
  icon: 'yrityswifilite',
  popoverContent: <YritysWifiPopoverContent />,
});

export const getLiikkuvaWifiService = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: t.I00R('Includes a mobile router'),
  isIncluded,
  icon: 'mobile-broadband',
  popoverContent: <LiikkuvaWifiPopOverContent />,
});

export const getManagedServiceService = (isIncluded: boolean): SubscriptionCardServiceProps => ({
  name: t.XWHX('Managed service'),
  isIncluded,
  icon: 'managed-service',
  popoverContent: <ManagedServicePopoverContent />,
});
