import { AUTHENTICATED } from './authenticatedUserEpic.js';
import { EMPTY, timer } from 'rxjs';
import {
  TypeKeys,
  loadCustomerOrderAdditionalInfoFulfilled,
  loadCustomerOrdersFailed,
  loadCustomerOrdersFulfilled,
  loadEmployeeCustomerOrdersFailed,
  loadEmployeeCustomerOrdersFulfilled,
} from '../actions/index.js';
import { actionToActionState, getMdmIdHeaderFromUrlParams } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, debounce } from 'rxjs/operators';
import {
  getCustomerOrderAdditionalInfoPrivateMethod,
  getCustomerOrdersPrivateMethod,
  getUserCustomerOrdersPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import {
  loadCustomerOrdersUseSearchService,
  loadEmployeeCustomerOrdersUseSearchService,
  updateCustomerOrdersSearchQuery,
} from '../common/elasticsearchUtils.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { CustomerOrderAdditionalInfo, CustomerOrdersResponse } from '../../generated/api/models.js';
import type { ItemsQuery, State } from '../common/store.js';
import type {
  LoadCustomerOrderAdditionalInfoAction,
  LoadCustomerOrdersAction,
  LoadEmployeeCustomerOrdersAction,
  SelfServiceActionTypes,
} from '../actions/index.js';

export const loadCustomerOrdersEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_CUSTOMER_ORDERS)), (action: LoadCustomerOrdersAction) =>
    actionToActionState(action, state$, 'customerOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading customer orders: query missing');
      }
      const loadCustomerOrdersAction = actionAndState.action as LoadCustomerOrdersAction;
      const useSearchService = loadCustomerOrdersUseSearchService(loadCustomerOrdersAction);
      const query = useSearchService
        ? {
            ...actionAndState.state?.query, // order + sort
            limit: loadCustomerOrdersAction.limit,
            offset: loadCustomerOrdersAction.offset || 0,
            search: loadCustomerOrdersAction.search,
            status: loadCustomerOrdersAction.status === 'ALL' ? undefined : loadCustomerOrdersAction.status,
          }
        : actionAndState.state.query;
      const successAction = (res: CustomerOrdersResponse) => {
        if (loadCustomerOrdersAction.displayId && (!res.customerOrders || res.customerOrders.length === 0)) {
          return loadCustomerOrdersFailed(
            `customer order ${loadCustomerOrdersAction.displayId} could not be found`,
            404
          );
        }
        return loadCustomerOrdersFulfilled(res, query, useSearchService, useSearchService && query.offset > 0);
      };

      if (useSearchService) {
        const useSearchServiceMethod = getCustomerOrdersPrivateMethod({
          ...query,
          useSearchService: true,
        });
        return callUiApi({
          epicDependencies,
          failureAction: loadCustomerOrdersFailed,
          method: useSearchServiceMethod,
          headers: getMdmIdHeaderFromUrlParams(),
          state$,
          successAction,
        });
      }
      // TODO: ItemsQuery should not have "sort" and "order": they eventually didn't end up anywhere except invoices
      // so they should be a special case there and not special cased out everywhere else.
      const method = getCustomerOrdersPrivateMethod({
        offset: query.offset,
        limit: query.limit,
        customerOrderDisplayId: loadCustomerOrdersAction.displayId,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadCustomerOrdersFailed,
        method,
        headers: getMdmIdHeaderFromUrlParams(),
        state$,
        successAction,
      });
    })
  );

export const loadEmployeeCustomerOrdersEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS)),
    (action: LoadEmployeeCustomerOrdersAction) => actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    debounce((actionAndState: ActionAndState) =>
      loadEmployeeCustomerOrdersUseSearchService(actionAndState.action) ? timer(500) : EMPTY
    ),
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading customer orders: query missing');
      }
      const loadEmployeeCustomerOrdersAction = actionAndState.action as LoadEmployeeCustomerOrdersAction;

      const useSearchService = loadEmployeeCustomerOrdersUseSearchService(actionAndState.action);

      const query: ItemsQuery = useSearchService
        ? updateCustomerOrdersSearchQuery(actionAndState.state.query, loadEmployeeCustomerOrdersAction.status)
        : actionAndState.state.query;

      const successAction = (res: CustomerOrdersResponse) => {
        if (loadEmployeeCustomerOrdersAction.displayId && (!res.customerOrders || res.customerOrders.length === 0)) {
          return loadEmployeeCustomerOrdersFailed(
            `customer order ${loadEmployeeCustomerOrdersAction.displayId} could not be found`,
            404
          );
        }
        return loadEmployeeCustomerOrdersFulfilled(res, query, useSearchService, useSearchService && query.offset > 0);
      };

      const methodParams = useSearchService
        ? {
            ...query,
            useSearchService: true,
          }
        : { customerOrderDisplayId: loadEmployeeCustomerOrdersAction.displayId };

      return callUiApi({
        epicDependencies,
        failureAction: loadEmployeeCustomerOrdersFailed,
        method: getUserCustomerOrdersPrivateMethod(methodParams),
        state$,
        successAction,
      });
    })
  );

const getCustomerOrderAdditionalInfoEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO)),
    (action: LoadCustomerOrderAdditionalInfoAction) => actionToActionState(action, state$, 'customerOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { customerOrderId } = actionAndState.action as LoadCustomerOrderAdditionalInfoAction;
      const previousStateData = state$.value.selfservice?.customerOrders?.additionalInfo;
      if (!previousStateData || !Object.keys(previousStateData).includes(customerOrderId)) {
        return callUiApi({
          epicDependencies,
          state$,
          method: getCustomerOrderAdditionalInfoPrivateMethod(customerOrderId),
          successAction: (response: CustomerOrderAdditionalInfo) =>
            loadCustomerOrderAdditionalInfoFulfilled(response, customerOrderId),
          failureAction: loadCustomerOrdersFailed,
        });
      }
      return [loadCustomerOrderAdditionalInfoFulfilled(previousStateData[customerOrderId], customerOrderId)];
    })
  );

export const customerOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadCustomerOrdersEpic,
  getCustomerOrderAdditionalInfoEpic,
  loadEmployeeCustomerOrdersEpic
);
