import * as CL from '@design-system/component-library';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { SelectCompanyDialog } from '../Dialogs/SelectCompanyDialog';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getUserHasSeenCompanySelectionNotificationShoppingBasket,
  setUserHasSeenCompanySelectionNotificationShoppingBasket,
} from '../../selfservice/common/localStorageUtils';
import { paths } from '../../common/constants/pathVariables.js';
import { selectCompanyMsg, t } from '../../common/i18n/index.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { HeaderUserAccount, PopoverImperativeRefHandle } from '@design-system/component-library';

export interface ShoppingBasketCompanySelectorProps {
  userAccounts: CL.HeaderUserAccount[];
  showInDialog: boolean;
}

export const ShoppingBasketCompanySelector = ({ userAccounts, showInDialog }: ShoppingBasketCompanySelectorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<PopoverImperativeRefHandle>(null);
  const userHasSeenCompanySelectionNotification = getUserHasSeenCompanySelectionNotificationShoppingBasket();
  const [notificationClosed, setNotificationClosed] = useState(Boolean(userHasSeenCompanySelectionNotification));
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<string>();
  const activeMdmId = userAccounts.find(userAccount => userAccount.active)?.accountMasterId;

  useEffect(() => {
    if (!showInDialog && selectedCompanyOption && selectedCompanyOption) {
      dispatch(setActiveAccount(selectedCompanyOption, true, paths.NEW_SHOPPING_BASKET, selectedCompanyOption));
    }
  }, [dispatch, selectedCompanyOption, showInDialog]);

  const onContinue = () => {
    // Reset state after company has been selected
    navigate(paths.NEW_SHOPPING_BASKET, { replace: true });
  };

  const companySelectorInner = (
    <>
      <CompanySelector
        autoCompleteConfig={userHasSeenCompanySelectionNotification ? undefined : { blurKeepsOptionsOpen: true }}
        labelText={t.JUY2(selectCompanyMsg)}
        className="of-shopping-basket-select-company"
        userAccounts={userAccounts}
        onInputChange={(_e: ChangeEvent | KeyboardEvent | React.MouseEvent, option: Partial<HeaderUserAccount>) => {
          if (option.accountMasterId && option.accountMasterId !== activeMdmId) {
            setSelectedCompanyOption(option.accountMasterId);
          }
        }}
        inputRef={inputRef}
        onOpen={() => {
          if (!notificationClosed) {
            setTimeout(() => {
              popoverRef.current?.openPopover();
              /**
               * Because CL.Popover steals the focus when it's opened
               * move focus back to dropdown after re-render.
               * Issue has been reported to CL: https://github.com/elisa-design-system/component-library/issues/561
               */
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 100);
            }, 500);
          }
        }}
      />
    </>
  );

  const companySelectorWithPopover = notificationClosed ? (
    companySelectorInner
  ) : (
    <CL.Popover
      triggerElement={companySelectorInner}
      ref={popoverRef}
      placement={window.innerWidth < 481 ? 'top' : 'right'}
      onClose={() => {
        setUserHasSeenCompanySelectionNotificationShoppingBasket();
        setNotificationClosed(true);
      }}
    >
      <h5>{t.F9HL('Changing the company updates your cart.')}</h5>
      <p>
        {t.GYOL(
          'If you change the company, the shopping cart updates. This may affect the prices of products in your cart.'
        )}
      </p>
    </CL.Popover>
  );

  return showInDialog ? (
    <div className="of-shopping-basket-select-company-wrapper">
      <SelectCompanyDialog
        onCloseDialog={() => {
          onContinue();
        }}
        onContinue={selectedMdmId => {
          if (selectedMdmId !== activeMdmId) {
            setSelectedCompanyOption(selectedMdmId);
          }
          onContinue();
        }}
      />
    </div>
  ) : (
    <div className={`${dsClass.PADDING_BOTTOM_3}`}>
      <div>
        <div className="of-shopping-basket-select-company-wrapper">{companySelectorWithPopover}</div>
      </div>
    </div>
  );
};
