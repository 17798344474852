import { type Context } from '../OpenFormAnswers.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { has } from '../OpenForm.js';
import { useCallback } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export type OpenFormDispatcher = {
  setAnswer<K extends keyof Context>(value: string | string[] | undefined, key?: K, patch?: Context[K]): void;
  setChoices(value: string | string[] | undefined): void;
  setContext<K extends keyof Context>(key: K, patch?: Context[K]): void;
};

export type UseOpenFormDispatcher = (props: OpenFormQuestion | OpenFormSection) => OpenFormDispatcher;

export const useOpenFormDispatcher = (section: OpenFormSection): UseOpenFormDispatcher => {
  const async = useOpenFormAsync();
  return useCallback<UseOpenFormDispatcher>(
    ({ guid }) => ({
      setAnswer<K extends keyof Context>(value: string | string[] | undefined, key?: K, patch?: Context[K]) {
        this.setChoices(value);
        if (value?.length && key) {
          this.setContext(key, patch);
        }
      },
      setChoices(value: string | string[] | undefined) {
        async.actions.choices({ guid, value });
        if (value?.length && section.pageType === OFPageType.QUESTION_SUBSCRIPTION_TYPE) {
          this.setContext(
            'subscriptionTypes',
            section.questions
              .filter(has(guid))
              .flatMap(q => q.choices.flatMap(c => (value.includes(c.guid) && c.linkedValue) || []))
          );
        }
      },
      setContext<K extends keyof Context>(key: K, patch?: Context[K]) {
        async.actions.context({ guid, key, patch });
      },
    }),
    [section, async]
  );
};
