import * as CL from '@design-system/component-library';
import {
  AccordionsMain,
  TechnicalInformationBroadBand,
  TechnicalInformationMobile,
} from '../../common/utils/accordionUtils.js';
import {
  AdditionalServicesAccordion,
  getAdditionalServicesAccordion,
} from './Accordions/AdditionalServicesAccordion.js';
import { BarringsAccordion, getBarringsAccordion } from './Accordions/BarringsAccordion.js';
import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import { ChangeBillingAccountDialog } from '../ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import {
  ChangeContractOwnerAccordion,
  getChangeContractOwnerAccordion,
} from './Accordions/ChangeContractOwnerAccordion.js';
import { CompositeList } from '../CompositeList';
import {
  Contract,
  SubscriptionAction,
  SubscriptionPbxDetails,
  SubscriptionStatusType,
  SubscriptionType,
} from '../../generated/api/models.js';
import { DialogType, ModelType, SubscriptionCategory } from '../../common/enums.js';
import { Link, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { LinkableAccordion } from '../LinkableAccordion';
import { MobileSubscriptionAccordions } from './MobileSubscriptionAccordions.js';
import {
  ONE_TIME_DEVICE_CHANGE_NOT_ALLOWED,
  getSubscriptionStatus,
  getSubscriptionUrlId,
  hasEsimQrCode,
  isMobileIdAddOn,
  isTellusSubscription,
  simCardChangeAllowed,
} from '../../common/utils/subscriptionUtils.js';
import { PbxAccordions } from './PbxAccordions.js';
import { PbxNumberSettingsAccordionContent } from '../PbxNumberSettingsAccordionContent/PbxNumberSettingsAccordionContent.js';
import { PbxTimeSettingsAccordionContent } from '../PbxTimeSettingsAccordionContent/PbxTimeSettingsAccordionContent.js';
import { PurposeOfUseAccordion, getPurposeOfUseAccordion } from './Accordions/PurposeOfUseAccordion.js';
import { RingUserAccordion } from './Accordions/RingUserAccordion.js';
import { SimCard } from '../SimCard/SimCard.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { SubscriptionDetailsButtonType } from './subscriptionDetailsButtons.js';
import {
  additionalServicesMsg,
  billingAccountChangeCompletedMsg,
  billingAccountChangeFailedMsg,
  changeMsg,
  directoryStatusOfUserAndNumberMsg,
  noAdditionalServicesMsg,
  purposeOfUseMsg,
  settingsMsg,
  simCardMsg,
  t,
  technicalDataMsg,
  transferSubToAnotherBillingAccountMsg,
  userInformationMsg,
} from '../../common/i18n';
import { changeSubscriptionBillingAccount } from '../../common/fetch.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { editSection, showDialog, startNotification } from '../../selfservice/actions';
import { getAdminUserAccordionContent, getDomainAccordionContent } from './subscriptionDetailsSoftwareProduct.js';
import { getDefaultBillingContactId, getDefaultDeliveryMethod } from '../../common/utils/billingAccountUtils.js';
import { getMobilePbxAccordion, getMobilePbxSettingsAccordion } from './Accordions/MobilePbxAccordions.js';
import { getPbxServiceLevels } from '../../common/utils/pbxUtils.js';
import { getPeriodicPriceAsText } from './subscriptionDetailsCommon.js';
import { getSubscriptionTypes } from '../../public/common/util/category.js';
import { getYttInstructionsAccordion } from './Accordions/YttInstructionsAccordion.js';
import { isLiikkuvaWifiSubscription, isOneTimeFeeDevice } from './subscriptionDetailsUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { Accordion } from '../../common/utils/accordionUtils.js';
import type {
  AddOn,
  AddOnRulesResponse,
  BillingAccountHeader,
  BillingAccountsResponse,
  CompanyInfoResponse,
  Contact,
  OnlineModel,
  Subscription,
  SubscriptionDetails as SubscriptionDetailsModel,
  SubscriptionDetailsSoftwareProduct,
} from '../../generated/api/models.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CompanyInfoState, ConfigState } from '../../common/types/states.js';
import type { CompositeListProps } from '../CompositeList';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

import './SubscriptionDetails.scss';

export interface SubscriptionDetailsProps {
  addOnRules?: AddOnRulesResponse;
  addOnRulesMobilePbx?: AddOnRulesResponse;
  billingAccounts?: BillingAccountsResponse;
  breadCrumbs?: JSX.Element;
  category: CategoryKey;
  companyInfo: CompanyInfoResponse;
  contacts?: Contact[];
  pendingSubscriptionActions?: SubscriptionAction[];
  subscription?: Subscription;
  onlineModels?: OnlineModel[];
}

interface AccordionsProps {
  subscription: Subscription;
  details: SubscriptionDetailsModel;
  onClickSubscriptionAddonDetails: (id: string, addOnId: string, category: string) => void;
  subscriptionStatus: SubscriptionStatus;
  pendingSubscriptionActions: SubscriptionAction[];
  category: CategoryKey;
  companyInfo?: CompanyInfoState;
  config: ConfigState;
  onEditSectionIfNoActionsPending: (section?: string) => boolean;
  onShowDialog: (params: DialogParams) => void;
  onClickAttachSubscriptionToVakio: (subscriptionId: string, category: string, isRing?: boolean) => void;
  siteBaseUrl: string;
  addOnRulesAssociations?: AssociationRecord[];
  addOnRulesDependencies?: DependencyRecord[];
  isMobileIdEnabledForCompany?: boolean;
  contacts?: Contact[];
  onlineModels?: OnlineModel[];
  billingAccounts?: BillingAccountsResponse;
}

const isSelfPayMobileIdActive = (isMobileIdEnabledForCompany: boolean, addOnGroup: string): boolean =>
  isMobileIdAddOn(addOnGroup) && !isMobileIdEnabledForCompany;

const getAdditionalServicesAccordionContent = (
  details: SubscriptionDetailsModel,
  onClickSubscriptionAddonDetails: (id: string, addOnId: string, category: string) => void,
  category: string,
  subscription: Subscription,
  isMobileIdEnabledForCompany = false
) => {
  const addOnListProps: CompositeListProps<AddOn> = {
    classes: ['of-subscription-details__add-on-list'],
    columns: [
      {
        columnClasses: ['of-subscription-details__add-on-list__link'],
        columnId: 'addOnProductName',
        ref: 'addOnProductName',
      },
      {
        columnId: 'addOnMonthlyRecurringCharge',
        ref: 'addOnMonthlyRecurringCharge',
        refFormatNumber: getPeriodicPriceAsText,
      },
    ],
    emptyListElement: <span>{t.EIYK(noAdditionalServicesMsg)}</span>,
    getRowId: (addOn: AddOn) => addOn.addOnCode,
    items: details?.selectedAddOns
      ?.filter(addon => addon.display === true)
      ?.filter(addon => !isSelfPayMobileIdActive(isMobileIdEnabledForCompany, addon.addOnGroup)),
    onSelectRow: (addOnCode: string) => {
      onClickSubscriptionAddonDetails(getSubscriptionUrlId(subscription), addOnCode, category);
    },
  };
  return <CompositeList {...addOnListProps} />;
};

const Accordions = ({
  subscription,
  details,
  onClickSubscriptionAddonDetails,
  subscriptionStatus,
  pendingSubscriptionActions,
  category,
  companyInfo,
  config,
  onEditSectionIfNoActionsPending,
  onShowDialog,
  onClickAttachSubscriptionToVakio,
  siteBaseUrl,
  addOnRulesAssociations,
  addOnRulesDependencies,
  isMobileIdEnabledForCompany,
  contacts,
  onlineModels,
  billingAccounts,
}: AccordionsProps) => {
  const resources = useSelector((state: State) => state.resources, deepEqual);
  const { duplicateContactFound, editingSection, errors, saving } = useSelector((state: State) => ({
    duplicateContactFound: state?.selfservice?.pendingSubscriptionActions?.duplicateContactFound,
    editingSection: state?.selfservice?.pendingSubscriptionActions?.editingSection || '',
    errors: state?.selfservice?.pendingSubscriptionActions?.errors,
    saving: state?.selfservice?.pendingSubscriptionActions?.saving,
  }));
  const ringModels = onlineModels
    ? onlineModels.filter(onlineModel => onlineModel.onlineModelCode === ModelType.Ring)
    : undefined;
  const pbxServiceLevels = getPbxServiceLevels(companyInfo, onlineModels);
  const numberRange =
    subscription?.details?.mobile?.pbxConfiguration?.corporateNumberRangeId && resources?.numberRanges
      ? resources.numberRanges[subscription.details.mobile.pbxConfiguration.corporateNumberRangeId]
      : undefined;
  const extensionNumberRange =
    subscription?.details?.mobile?.pbxConfiguration?.extensionRangeId && resources?.numberRanges
      ? resources.numberRanges[subscription.details.mobile.pbxConfiguration.extensionRangeId]
      : undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { search } = useLocation();

  const onClickOrderSim = (subscriptionId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subscriptionId}/tilaa-sim${search}`);
  };
  const onClickSimActivation = (subscriptionId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subscriptionId}/aktivoi-sim-kortti${search}`);
  };

  function specialNumberOnEdit(section: string, isEditable: boolean | undefined) {
    return () => {
      if (isEditable) {
        return onEditSectionIfNoActionsPending(section);
      }
      return onShowDialog({
        subscriptionId: subscription.subscriptionId,
        type: DialogType.SUBSCRIPTION_CHANGE_SPECIAL_NUMBER,
      });
    };
  }

  switch (subscription.subscriptionType) {
    case SubscriptionType.MOBILE:
      return (
        <MobileSubscriptionAccordions
          pbxSolutions={companyInfo?.pbxSolutions || []}
          subscription={subscription}
          siteBaseUrl={siteBaseUrl}
          duplicateContactFound={duplicateContactFound}
          category={category}
          config={config}
          editingSection={editingSection}
          errors={errors}
          onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
          onClickOrderSim={onClickOrderSim}
          onClickSimActivation={onClickSimActivation}
          onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
          onShowDialog={onShowDialog}
          saving={saving}
          subscriptionStatus={subscriptionStatus}
          pendingSubscriptionActions={pendingSubscriptionActions}
          isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
          addOnRulesAssociations={addOnRulesAssociations}
          addOnRulesDependencies={addOnRulesDependencies}
          companyInfo={companyInfo}
          extensionNumberRange={extensionNumberRange}
          numberRange={numberRange}
          ringModels={ringModels}
          contacts={contacts || []}
          billingAccounts={billingAccounts}
          authenticatedUser={authenticatedUser}
        />
      );

    case SubscriptionType.MOBILE_BROADBAND:
      return (
        <div>
          <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
            <SimCard
              pendingSimChanges={
                subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM
              }
              pendingSubscriptionChanges={subscriptionStatus.pendingActions}
              simCardConfig={{
                simCardNumber: subscription.details!.mobile!.simCardNumber,
                simType: subscription.details!.mobile!.simType,
              }}
              sourceIsTellus={isTellusSubscription(subscription)}
              onClickSimActivation={() => onClickSimActivation(getSubscriptionUrlId(subscription), category)}
              onClickOrderSim={() => onClickOrderSim(getSubscriptionUrlId(subscription), category)}
              simCardChangeAllowed={simCardChangeAllowed(subscription)}
              hasEsimQrCode={hasEsimQrCode(subscription)}
            />
          </LinkableAccordion>
          <PurposeOfUseAccordion
            heading={t.U21Y(directoryStatusOfUserAndNumberMsg)}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            contacts={contacts}
            onShowDialog={onShowDialog}
            siteBaseUrl={siteBaseUrl}
            companyInfo={companyInfo}
            duplicateContactFound={duplicateContactFound}
            editingSection={editingSection}
            saving={saving}
          />
          <BarringsAccordion
            addOnAssociations={addOnRulesAssociations}
            addOnDependencies={addOnRulesDependencies}
            details={details}
            forceEditing={editingSection === 'addonsBarrings'}
            onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
            saving={saving}
            subscription={subscription}
          />
          <ChangeContractOwnerAccordion
            subscription={subscription}
            authenticatedUser={authenticatedUser}
            billingAccounts={billingAccounts}
            companyInfo={companyInfo || undefined}
            contacts={contacts}
            subscriptionStatus={subscriptionStatus}
          />
          {!isTellusSubscription(subscription) && (
            <AdditionalServicesAccordion
              category={category}
              subscription={subscription}
              pendingSubscriptionActions={pendingSubscriptionActions}
              addOnRulesAssociations={addOnRulesAssociations}
              addOnRulesDependencies={addOnRulesDependencies}
            />
          )}
          {(details.mobile?.deviceModel || details.mobile?.serialNumber) && (
            <AccordionsMain
              accordions={[
                {
                  headerName: t.L2MX(technicalDataMsg),
                  id: 'technical-information',
                  includedData: TechnicalInformationMobile(details.mobile),
                  displayed: true,
                },
              ]}
            />
          )}
        </div>
      );
    case SubscriptionType.MOBILE_M2M:
      return (
        <div>
          <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
            <SimCard
              pendingSimChanges={
                subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM
              }
              pendingSubscriptionChanges={subscriptionStatus.pendingActions}
              simCardConfig={{
                simCardNumber: subscription.details!.mobile!.simCardNumber,
                simType: subscription.details!.mobile!.simType,
              }}
              sourceIsTellus={isTellusSubscription(subscription)}
              onClickSimActivation={() => onClickSimActivation(subscription.subscriptionDisplayId, category)}
              onClickOrderSim={() => onClickOrderSim(subscription.subscriptionDisplayId, category)}
              simCardChangeAllowed={simCardChangeAllowed(subscription)}
              hasEsimQrCode={hasEsimQrCode(subscription)}
            />
          </LinkableAccordion>
          <ChangeContractOwnerAccordion
            subscription={subscription}
            authenticatedUser={authenticatedUser}
            billingAccounts={billingAccounts}
            companyInfo={companyInfo || undefined}
            contacts={contacts}
            subscriptionStatus={subscriptionStatus}
          />
          <PurposeOfUseAccordion
            heading={t.U21Y(directoryStatusOfUserAndNumberMsg)}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            contacts={contacts}
            onShowDialog={onShowDialog}
            siteBaseUrl={siteBaseUrl}
            companyInfo={companyInfo}
            duplicateContactFound={duplicateContactFound}
            editingSection={editingSection}
            saving={saving}
          />
        </div>
      );
    case SubscriptionType.LANDLINE:
      return (
        <AccordionsMain
          accordions={[
            getPurposeOfUseAccordion({
              heading: t.U21Y(directoryStatusOfUserAndNumberMsg),
              subscription,
              subscriptionStatus,
              contacts,
              onShowDialog,
              siteBaseUrl,
              companyInfo,
              duplicateContactFound,
              editingSection,
              saving,
            }),
            getBarringsAccordion({
              addOnAssociations: addOnRulesAssociations,
              addOnDependencies: addOnRulesDependencies,
              details: details,
              forceEditing: editingSection === 'addonsBarrings',
              onEditSectionIfNoActionsPending,
              saving,
              subscription,
            }),
            getChangeContractOwnerAccordion({
              authenticatedUser,
              companyInfo,
              subscription,
              contacts,
              billingAccounts,
              subscriptionStatus,
            }),
            getAdditionalServicesAccordion({
              category,
              subscription,
              pendingSubscriptionActions,
              addOnRulesAssociations,
              addOnRulesDependencies,
            }),
          ]}
        />
      );

    case SubscriptionType.DEVICE:
      return (
        <AccordionsMain
          accordions={[
            getPurposeOfUseAccordion({
              heading: t.T1WX(userInformationMsg),
              subscription,
              subscriptionStatus,
              contacts,
              onShowDialog,
              siteBaseUrl,
              companyInfo,
              duplicateContactFound,
              editingSection,
              saving,
            }),
            getChangeContractOwnerAccordion({
              authenticatedUser,
              companyInfo,
              subscription,
              contacts,
              billingAccounts,
              subscriptionStatus,
            }),
            {
              headerName: t.LXSR(additionalServicesMsg),
              id: 'additional-services',
              includedData: getAdditionalServicesAccordionContent(
                details,
                onClickSubscriptionAddonDetails,
                category,
                subscription
              ),
              displayed: true,
            },
          ]}
        />
      );

    case SubscriptionType.MOBILE_PBX_LITE:
      return (
        <AccordionsMain
          accordions={[
            getMobilePbxAccordion(t.L2MX(technicalDataMsg), subscription, details.mobilePbx),
            getChangeContractOwnerAccordion({
              authenticatedUser,
              companyInfo,
              subscription,
              contacts,
              billingAccounts,
              subscriptionStatus,
            }),
          ]}
        />
      );

    case SubscriptionType.MOBILE_PBX:
      return (
        <AccordionsMain
          accordions={[
            getMobilePbxSettingsAccordion(t.EJQF(settingsMsg), details.mobilePbx),
            getMobilePbxAccordion(t.LJQ3('Numbering ranges'), subscription, details.mobilePbx),
            getChangeContractOwnerAccordion({
              authenticatedUser,
              companyInfo,
              subscription,
              contacts,
              billingAccounts,
              subscriptionStatus,
            }),
          ]}
        />
      );

    case SubscriptionType.BROADBAND:
    case SubscriptionType.CORPORATE_NETWORK:
    case SubscriptionType.HOUSING_COMPANY_BROADBAND:
      return (
        <AccordionsMain
          accordions={[
            getPurposeOfUseAccordion({
              heading: t.T1WX(userInformationMsg),
              subscription,
              subscriptionStatus,
              contacts,
              onShowDialog,
              siteBaseUrl,
              companyInfo,
              duplicateContactFound,
              editingSection,
              saving,
            }),
            getChangeContractOwnerAccordion({
              authenticatedUser,
              companyInfo,
              subscription,
              contacts,
              billingAccounts,
              subscriptionStatus,
            }),
            {
              headerName: t.L2MX(technicalDataMsg),
              id: 'technical-information',
              includedData: details.broadband ? TechnicalInformationBroadBand(details.broadband) : <></>,
              displayed: true,
            },
          ]}
        />
      );

    case SubscriptionType.SPECIAL_NUMBER:
      // eslint-disable-next-line no-case-declarations
      const isEditable =
        subscription.details!.specialNumber?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU ||
        subscription.details!.specialNumber?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.VAKIO;
      return (
        <div>
          <LinkableAccordion heading={t.SC8P('Time settings')} headingLevel="h3" id="special-number-time-settings">
            <PbxTimeSettingsAccordionContent
              dispatch={dispatch}
              config={config}
              editing={editingSection === 'pbxTimeSettings'}
              errors={errors}
              subscriptionPbxConfiguration={subscription.details!.specialNumber!}
              numberRange={numberRange}
              onCancel={() => onEditSectionIfNoActionsPending()}
              onEdit={specialNumberOnEdit('pbxTimeSettings', isEditable)}
              pbxSolution={subscription}
              saving={Boolean(saving)}
              subscriptionId={subscription.subscriptionId}
            />
          </LinkableAccordion>
          <LinkableAccordion heading={t.AN17('Number settings')} headingLevel="h3" id="special-number-settings">
            <PbxNumberSettingsAccordionContent
              config={config}
              editing={editingSection === 'pbxNumberSettings'}
              errors={errors}
              extensionNumberRange={extensionNumberRange}
              subscriptionPbxConfiguration={subscription.details!.specialNumber!}
              numberRange={numberRange}
              onCancel={() => onEditSectionIfNoActionsPending()}
              onEdit={specialNumberOnEdit('pbxNumberSettings', isEditable)}
              pbxSolution={subscription}
              saving={Boolean(saving)}
              subscriptionId={subscription.subscriptionId}
              subscriptionDisplayId={subscription.subscriptionDisplayId}
            />
          </LinkableAccordion>
          <PurposeOfUseAccordion
            heading={t.Y8KX(purposeOfUseMsg)}
            contacts={contacts}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onShowDialog={onShowDialog}
            siteBaseUrl={siteBaseUrl}
            companyInfo={companyInfo}
            duplicateContactFound={duplicateContactFound}
            editingSection={editingSection}
            saving={saving}
          />
          <ChangeContractOwnerAccordion
            subscription={subscription}
            authenticatedUser={authenticatedUser}
            billingAccounts={billingAccounts}
            companyInfo={companyInfo || undefined}
            contacts={contacts}
            subscriptionStatus={subscriptionStatus}
          />
          <AdditionalServicesAccordion
            category={category}
            subscription={subscription}
            pendingSubscriptionActions={pendingSubscriptionActions}
            addOnRulesAssociations={addOnRulesAssociations}
            addOnRulesDependencies={addOnRulesDependencies}
          />
        </div>
      );

    case SubscriptionType.MOBILE_PBX_END_USER_SERVICE:
      return (
        <div>
          <RingUserAccordion
            subscription={subscription}
            subscriptionPendingStatus={subscriptionStatus.pendingActions}
            onShowDialog={onShowDialog}
          />
          <PbxAccordions
            companyInfo={companyInfo}
            configuredPbxSolution={
              subscription.details!.mobilePbxEndUserService &&
              subscription.details!.mobilePbxEndUserService &&
              (companyInfo?.pbxSolutions || []).find(
                pbxSolution =>
                  pbxSolution.subscriptionId === subscription.details!.mobilePbxEndUserService!.pbxSolutionId
              )
            }
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onShowDialog={onShowDialog}
            errors={errors}
            ringModels={ringModels}
            addOnRulesDependencies={addOnRulesDependencies}
            addOnRulesAssociations={addOnRulesAssociations}
            onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
            editingSection={editingSection}
            contacts={contacts || []}
            pbxServiceLevels={pbxServiceLevels}
          />
          <ChangeContractOwnerAccordion
            subscription={subscription}
            authenticatedUser={authenticatedUser}
            billingAccounts={billingAccounts}
            companyInfo={companyInfo || undefined}
            contacts={contacts}
            subscriptionStatus={subscriptionStatus}
          />
        </div>
      );
    case SubscriptionType.CABLE_TV:
    case SubscriptionType.E_FAX:
    case SubscriptionType.FIREWALL:
    case SubscriptionType.LAN:
    case SubscriptionType.NEGOTIATION:
    case SubscriptionType.SOFTWARE_PRODUCT:
      // eslint-disable-next-line no-case-declarations
      const getUncategorizedAccordions = (softwareProduct: SubscriptionDetailsSoftwareProduct = {}): Accordion[] => [
        {
          headerName: t.E112('Administrator'),
          id: 'admin-user',
          includedData: getAdminUserAccordionContent(softwareProduct, subscription.subscriptionContactName),
          displayed: !!softwareProduct.adminUserEmail,
        },
        getYttInstructionsAccordion(subscription),
        {
          headerName: t.ES5D('Domain name'),
          id: 'domain',
          includedData: getDomainAccordionContent(softwareProduct),
          displayed: !!softwareProduct.domain,
        },
        getChangeContractOwnerAccordion({
          authenticatedUser,
          companyInfo,
          subscription,
          contacts,
          billingAccounts,
          subscriptionStatus,
        }),
      ];
      return <AccordionsMain accordions={getUncategorizedAccordions(details.softwareProduct)} />;

    default:
      throw new Error('Invalid subscriptionType');
  }
};

// Unfortunately there's nothing separating netti lite subscriptions from regular ones in the data model and thus this is needed.
// TODO: if netti lite subscriptions acquire fields that separate them from regular mobilebroandband-type connections, this should be refactored.
const isNettiLiteSubscription = (subscriptionName?: string) =>
  /(netti\s*lite\s*[45]\s*g)/.test(subscriptionName?.toLowerCase() || '');

export const getUpdateButtonType = (subscription: Subscription, isChangeOfferAllowed?: boolean) => {
  const allowedSubscriptionStatuses = [SubscriptionStatusType.ACTIVE, SubscriptionStatusType.SUSPENDED, undefined];
  if (isChangeOfferAllowed && isNettiLiteSubscription(subscription?.subscriptionName)) {
    return SubscriptionDetailsButtonType.CHANGE_NETTI_LITE_SUBSCRIPTION;
  }
  if (isLiikkuvaWifiSubscription(subscription?.subscriptionName)) {
    return SubscriptionDetailsButtonType.UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION;
  }
  if (allowedSubscriptionStatuses.includes(subscription!.subscriptionStatus) && isChangeOfferAllowed) {
    return SubscriptionDetailsButtonType.CHANGE_OFFER;
  }
  return SubscriptionDetailsButtonType.PENDING_ACTIONS;
};

export const SubscriptionDetailsAccordions = ({
  addOnRules,
  addOnRulesMobilePbx,
  category,
  contacts,
  companyInfo,
  pendingSubscriptionActions,
  subscription,
  onlineModels,
  billingAccounts,
}: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const config = useStore<State>().getState().config;
  const { suspendSubscriptionInitiated, terminateSubscriptionInitiated } = useSelector((s: State) => ({
    suspendSubscriptionInitiated: s?.selfservice?.pendingSubscriptionActions?.suspendSubscriptionInitiated || false,
    terminateSubscriptionInitiated: s?.selfservice?.pendingSubscriptionActions?.terminateSubscriptionInitiated || false,
  }));
  const mobileIdContracts = useSelector((s: State) => s.selfservice?.subscriptions?.voice?.mobileIdContracts);
  const { siteBaseUrl } = useContext(SiteContext);
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const onClickSubscriptionAddonDetails = (id: string, addOnId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${id}/${addOnId + search}`);
  };
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const onEditSectionIfNoPendingSubscriptionActions = (section: string) => {
    if (subscription?.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      onShowDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });
      return false;
    }
    if (subscriptionStatus.pendingActions) {
      onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
      return false;
    }
    dispatch(editSection(section));
    return true;
  };
  const onClickAttachSubscriptionToVakio = (subId: string, cat: CategoryKey, isRing: boolean) => {
    if (subscription?.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      onShowDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });
    } else {
      const redirectionPage = isRing ? 'liitä-ring' : 'liitä-vakio';
      navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subId}/${redirectionPage}${search}`);
    }
  };
  const ringServiceLevelName =
    subscription?.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.ringServiceLevelName;
  const associations =
    subscription && ringServiceLevelName
      ? (addOnRulesMobilePbx?.associations[0] as AssociationRecord[])
      : (addOnRules?.associations[0] as AssociationRecord[]);
  const dependencies =
    subscription && ringServiceLevelName
      ? (addOnRulesMobilePbx?.dependencies[0] as DependencyRecord[])
      : (addOnRules?.dependencies[0] as DependencyRecord[]);

  const showSuspendSubscriptionOptions = suspendSubscriptionInitiated || false;
  if (!subscription?.details || showSuspendSubscriptionOptions || terminateSubscriptionInitiated) {
    return null;
  }

  const isMobileIdEnabledForCompany = Boolean(
    mobileIdContracts?.some(contract => contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED)
  );

  return (
    <Accordions
      subscription={subscription}
      details={subscription.details}
      onClickSubscriptionAddonDetails={onClickSubscriptionAddonDetails}
      subscriptionStatus={subscriptionStatus}
      pendingSubscriptionActions={pendingSubscriptionActions || []}
      category={category}
      companyInfo={companyInfo}
      config={config}
      onEditSectionIfNoActionsPending={onEditSectionIfNoPendingSubscriptionActions}
      onShowDialog={onShowDialog}
      onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
      siteBaseUrl={siteBaseUrl}
      addOnRulesAssociations={associations}
      addOnRulesDependencies={dependencies}
      isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
      contacts={contacts}
      onlineModels={onlineModels}
      billingAccounts={billingAccounts}
    />
  );
};

export const SubscriptionDetailsBillingAccount = ({
  billingAccounts,
  subscription,
  pendingSubscriptionActions,
}: Pick<SubscriptionDetailsProps, 'billingAccounts' | 'subscription' | 'pendingSubscriptionActions'>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const isSwitchingBillingAccountAvailable = (sub: Subscription): boolean =>
    !getSubscriptionTypes(SubscriptionCategory.SERVICE).includes(sub.subscriptionType) &&
    !isTellusSubscription(sub) &&
    !isOneTimeFeeDevice(sub);
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const [displayChangeBillingAccountDialog, setDisplayChangeBillingAccountDialog] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const currentPath = pathname + search;
  const mappedBillingAccounts =
    billingAccounts?.searchResults?.map(res => res.result) || ([] as BillingAccountHeader[]);
  const defaultBillingContactId = getDefaultBillingContactId(mappedBillingAccounts);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(mappedBillingAccounts);

  if (!(subscription?.billingAccountDisplayId && subscription.billingAccountId)) {
    return <span>—</span>;
  }

  const handleError = (status: number, message: string) => {
    if (status === 409 && message?.includes(ONE_TIME_DEVICE_CHANGE_NOT_ALLOWED)) {
      onShowDialog({
        body: t.I2EO('Editing details for one-time-fee products is not possible'),
        header: t.RQMS('Editing details is not possible'),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
    } else {
      dispatch(startNotification(t.S3DX(billingAccountChangeFailedMsg), 'error'));
    }
  };

  return (
    <span>
      {displayChangeBillingAccountDialog && (
        <ChangeBillingAccountDialog
          billingAccountId={subscription.billingAccountId}
          changeRequestInProgress={submitInProgress}
          headerText={t.EEYF(transferSubToAnotherBillingAccountMsg)}
          description={t.AIUJ(
            'Please note that, after the change, invoicing will still be based on two invoices for the next payment. Any supplementary services will be transferred with the main product.'
          )}
          detailedView={true}
          searchable={true}
          onBeginCreateBillingAccount={() => {
            const queryParams = new URLSearchParams(search);
            queryParams.append('subscriptionId', subscription.subscriptionId);
            navigate(`${paths.BILLING_ACCOUNTS_CREATE_NEW_BA}?${queryParams}`, {
              state: {
                redirectPath: currentPath,
                hideDisclaimerText: true,
                defaultBillingContactId,
                defaultDeliveryMethod,
              },
            });
          }}
          onCloseDialog={() => {
            setDisplayChangeBillingAccountDialog(false);
          }}
          onSubmit={async (billingAccountId: string, _: string) => {
            if (billingAccountId === subscription?.billingAccountId) {
              setDisplayChangeBillingAccountDialog(false);
            } else {
              try {
                setSubmitInProgress(true);
                await changeSubscriptionBillingAccount(subscription?.subscriptionId, billingAccountId, mdmId);
                dispatch(startNotification(t.CTBU(billingAccountChangeCompletedMsg), 'success'));
              } catch (err) {
                const errRes = await err.json();
                handleError(err.status, errRes[0].message);
              } finally {
                setSubmitInProgress(false);
                setDisplayChangeBillingAccountDialog(false);
                navigate(currentPath, { replace: true });
              }
            }
          }}
        />
      )}
      {subscription.billingAccountDisplayId && (
        <Link
          className="of-subscription-details__to-billing-account"
          to={
            generatePath(paths.BILLING_ACCOUNT, {
              billingAccountId: subscription.billingAccountDisplayId,
            }) + search
          }
        >
          {subscription.billingAccountDisplayId}
        </Link>
      )}
      {isSwitchingBillingAccountAvailable(subscription) && (
        <span>
          <span className="of-vertical-bar"> | </span>
          <CL.Button
            color="light"
            className="of-subscription-details__change-billing-account"
            onClick={() => {
              if (subscriptionStatus.pendingActions) {
                onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
              } else {
                setDisplayChangeBillingAccountDialog(true);
              }
            }}
            type="button"
          >
            {t.SEER(changeMsg)}
          </CL.Button>
        </span>
      )}
    </span>
  );
};
