import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { ContactFieldset } from '../../common/react-hook-form/fieldsets/ContactFieldset';
import { ContactRole, ContactType, UserProfile } from '../../generated/api/models.js';
import { DialogType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { UserRolesAndAdminRights } from '../UserRolesAndAdminRights/UserRolesAndAdminRights.js';
import {
  accessRightsMsg,
  addUserMsg,
  notAbleToProcessContactRequestMsg,
  selectCompanyHeaderMsg,
  t,
} from '../../common/i18n/index.js';
import { createAdminUser, upsertContactUser } from '../../common/fetch.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getPrimaryMdmId, isMultiBiz } from '../../common/utils/accountUtils.js';
import { getUserAccounts } from '../Header/dynamic/headerFunctions.js';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog, updateUserRightsFulfilled, upsertContactFailed } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type {
  AccountContactRelationship,
  Contact,
  ContactCommonFunction,
  ContactPerson,
  ContactStatus,
} from '../../generated/api/models.js';
import type { ConfigState } from '../../common/types/states.js';
import type { ContactFieldsetValues } from '../../common/react-hook-form/fieldsets/ContactFieldset';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FormikValues } from 'formik';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './CreateContactForm.scss';

// A function to generate correct contact payload. If the contactType is common function and also person
// object is sent, then SFDC returns an exception. Same for contactType of Person, commonFunction needs to be
// left out. Also, same happens if the value for either one is undefined.
// todo: Perhaps prune out the undefined person or common function in online-ui-api
const generateContactBasedOnContactType = (
  contact?: Contact,
  contactStatus?: ContactStatus,
  formValues?: FormikValues,
  person?: ContactPerson
): Contact => {
  const { accountContactRelationships, contactId, contactType } = contact!;
  if (contactType === ContactType.COMMON_FUNCTION) {
    const commonFunction = formValues as ContactCommonFunction;
    return { contactId, contactType, contactStatus, commonFunction };
  } else {
    return {
      accountContactRelationships,
      contactId,
      contactStatus,
      contactType,
      person: { ...person, ...(formValues as ContactPerson) },
    };
  }
};

interface ContactFormProps {
  children?: ReactNode;
  onSubmit: (values: ContactFieldsetValues) => void;
  readonly: boolean;
}

const ContactForm = ({ children, onSubmit, readonly }: ContactFormProps) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      costCenter: '',
      employeeNumber: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <h3>{t.AS6H('Personal information')}</h3>
        <ContactFieldset readonly={readonly} />
        {children}
      </form>
    </FormProvider>
  );
};

export interface CreateContactFormProps {
  config: ConfigState;
}

export const CreateContactForm = ({ config }: CreateContactFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const [isNewContactAdmin, setIsNewContactAdmin] = useState(false);
  const [isNewContactApprover, setIsNewContactApprover] = useState(false);
  const [newContactACRs, setNewContactACRs] = useState<AccountContactRelationship[]>([]);

  const { authenticatedUser } = useAuth();
  const { featureFlags } = config;

  const [mdmIdState, setMdmIdState] = useState<string | undefined>(undefined);

  const { submitting } = useSelector(
    (state: State) => ({
      submitting: state.selfservice?.contacts?.saving,
    }),
    deepEqual
  );

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const onChangeAdmin = (isAdmin: boolean) => {
    setIsNewContactAdmin(isAdmin);
  };

  const onChangeApproverRole = (isApprover: boolean) => {
    setIsNewContactApprover(isApprover);
  };

  const onChangeContactACRs = (acrs: AccountContactRelationship[]) => {
    setNewContactACRs(acrs);
  };

  const generateNewContact = (values: FormikValues, isAdmin: boolean, isApprover: boolean) => {
    const contactObj: Contact = {
      contactType: ContactType.PERSON,
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userProfile: UserProfile.EMPLOYEE,
        roles: [ContactRole.ENDUSER_CONTACT],
      },
    };

    if (isAdmin && contactObj.person) {
      contactObj.accountContactRelationships = newContactACRs;
      contactObj.person.userProfile = UserProfile.KEY_USER;
    }

    if (isApprover) {
      contactObj.person!.roles!.push(ContactRole.APPROVER);
    }

    return contactObj;
  };

  const onSubmit = async (values: ContactFieldsetValues) => {
    const contactForUpsert = generateNewContact(values, isNewContactAdmin, isNewContactApprover);
    const updatedContactInformation = generateContactBasedOnContactType(
      contactForUpsert,
      undefined,
      values,
      contactForUpsert.person
    );
    setIsSaving(true);

    const mdmId =
      (isMultiBiz(authenticatedUser) &&
        isFeatureEnabledForUser('consolidatedViews', featureFlags, authenticatedUser?.enabledFeatureFlags) &&
        (mdmIdState || getPrimaryMdmId(authenticatedUser))) ||
      undefined;

    try {
      const res = await (await upsertContactUser(updatedContactInformation, false, false, undefined, mdmId)).json();
      if (isNewContactAdmin) {
        await createAdminUser({ ...updatedContactInformation, contactId: res.contactId }, mdmId);
      }
      dispatch(updateUserRightsFulfilled());
      navigate(paths.COMPANY_INFO_CONTACTS);
    } catch (err) {
      if (err.status === 409) {
        onShowDialog({
          header: t.AYKR('Please contact customer service'),
          body: t.EGFA(notAbleToProcessContactRequestMsg),
          type: DialogType.DUPLICATE_CONTACT,
        });
      } else {
        dispatch(upsertContactFailed('Failed to create contact', 500));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onReset = () => {
    navigate(paths.COMPANY_INFO_CONTACTS);
  };

  return (
    <div className="of-create-new-contact__content">
      {isMultiBiz(authenticatedUser) &&
        isFeatureEnabledForUser('consolidatedViews', featureFlags, authenticatedUser?.enabledFeatureFlags) && (
          <>
            <h3 className={dsClass.MARGIN_TOP_7}>{t.BR9A(selectCompanyHeaderMsg)}</h3>
            <p>{t.A0OI('Select the company for which you want to create a user.')}</p>
            <CompanySelector
              userAccounts={getUserAccounts(authenticatedUser)}
              onInputChange={(_, option) => {
                setMdmIdState(option.accountMasterId!);
              }}
              initialMdmId={getPrimaryMdmId(authenticatedUser)}
            />
          </>
        )}

      <ContactForm readonly={false} onSubmit={onSubmit}>
        {(isFeatureEnabled(config.featureFlags.showUserRightsAccordion) ||
          authenticatedUser?.enabledFeatureFlags?.includes('showUserRightsAccordion')) && (
          <>
            <h3 className="of-create-new-contact__h3-with-separator">{t.Y8LP(accessRightsMsg)}</h3>
            <UserRolesAndAdminRights
              isNewDisconnectedContact={true}
              ignoreAccordion={true}
              onShowDialog={onShowDialog}
              onChangeAdmin={onChangeAdmin}
              onChangeApproverRole={onChangeApproverRole}
              onChangeContactACRs={onChangeContactACRs}
              authenticatedUser={authenticatedUser!}
              mdmId={mdmIdState || getPrimaryMdmId(authenticatedUser)}
            />
          </>
        )}
        <div className="of-create-new-contact__options">
          <ButtonGroupForSubmitAndBack
            onCancel={onReset}
            onSubmit={() => {}}
            submitButtonText={t.V7KF(addUserMsg)}
            submitting={submitting || isSaving}
            submitOnLeft={true}
          />
        </div>
      </ContactForm>
    </div>
  );
};
