import { TableUrlParams } from '../Table/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { DefaultListSearchParams } from '../Table/index.js';

import './SubscriptionTable.scss';

export interface SubscriptionsDeviceMultiFilter {
  commitmentPeriod?: string;
  deviceUser?: string;
  eppFilter?: string;
  expiringFilter?: string;
  paymentType?: string;
  subscriptionSubType?: string;
}

export type SubscriptionDeviceTableSearchParams = DefaultListSearchParams & SubscriptionsDeviceMultiFilter;

export const useMultiFilterUpdate = (
  initialFilter: SubscriptionsDeviceMultiFilter = {}
): [SubscriptionsDeviceMultiFilter, (value?: SubscriptionsDeviceMultiFilter) => void] => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { offset = '0' } = useSearchParams<DefaultListSearchParams>();
  const [selectedFilter, setSelectedFilter] = useState(initialFilter);

  const onFilterUpdated = (filterValue: SubscriptionsDeviceMultiFilter) => {
    if (Number(offset) > 0) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set(TableUrlParams.PAGE, '1');
      queryParams.set(TableUrlParams.OFFSET, '0');
      navigate(`${pathname}?${queryParams}`, { replace: true });
    }
    setSelectedFilter(filterValue);
  };
  return [selectedFilter, onFilterUpdated];
};
