import { CatalogList } from '../../../../../components/CatalogList/CatalogList.js';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { Outlet, useLoaderData } from 'react-router-dom';
import { useAuth } from '../../../AuthProvider.js';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import type { VirtualCatalogResponse } from '../../../../../generated/api/models.js';

export const CompanyInfoCatalogsOutlet = () => {
  return <Outlet />;
};

export const CompanyInfoCatalogsPath = () => {
  const {
    searchResults: virtualCatalogs,
    resultCountAggregations: aggregations,
    total: totalItems,
  } = useLoaderData() as VirtualCatalogResponse;
  const { search, productType, contractPeriod, status } = useSearchParams<{
    search?: string;
    productType?: string;
    contractPeriod?: string;
    status?: string;
  }>();
  const { authenticatedUser } = useAuth();

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CATALOGS}>
      <CatalogList
        searchInput={search}
        totalItems={totalItems}
        virtualCatalogs={virtualCatalogs}
        productTypeFilter={productType}
        contractPeriodFilter={contractPeriod}
        statusFilter={status}
        aggregations={aggregations}
        authenticatedUser={authenticatedUser}
      />
    </CompanyInfoContainer>
  );
};
