import { SubscriptionType } from '../../generated/api/subscriptionType';
import type { Subscription } from '../../generated/api/subscription';

// Even thought this is not optimal, the subscription name is currently the only way to identify Liikkuva Wifi subs
export const isLiikkuvaWifiSubscription = (subscriptionName?: string): boolean =>
  /\bliikkuva\b.*\bwifi\b/i.test(subscriptionName || '');

export const isOneTimeFeeDevice = (subscription: Subscription) =>
  subscription.subscriptionType === SubscriptionType.DEVICE &&
  subscription.details?.contractTerm === 1 &&
  !!subscription.details.oneTimeCharge &&
  subscription.details.oneTimeCharge > 0;
