import { ActionPhase } from '../common/storeUtils.js';
import { AnalyticsEventTypes } from '../common/shopping-cart/shoppingCartInterfaces.js';
import { AuthenticatedUserRole, CommercialProductType, ContactType } from '../../generated/api/models.js';
import { BillingAccountSourceSystem } from '../../common/utils/billingAccountUtils.js';
import { ContactCreationType } from '../../components/ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { EMPTY, of } from 'rxjs';
import { EcommerceEventTypeV4 } from '../../common/analytics.js';
import {
  TypeKeys,
  addCartUpdateEventToAnalytics,
  catalogToValidationRules,
  clearCartItems,
  duplicatContactsCheckFulfilled,
  duplicateContactsCheck,
  duplicateContactsCheckFailed,
  eppSolutionToValidationRules,
  googleEcommerceAddToCart,
  googleEcommerceRemoveFromCart,
  loadBillingAccounts,
  loadCompanyInfo,
  loadDeliveryMethodsFailed,
  loadDeliveryMethodsFulfilled,
  notifyShoppingCartChange,
  postProcessCartItems,
  processCartItems,
} from '../actions/index.js';
import { actionToActionState, isPunchout } from './epicUtils.js';
import { addToShoppingBasket } from '../../components/ShoppingBasket/shoppingBasketFunctions.js';
import {
  addToShoppingCart,
  createCartItem,
  getCartItems,
  getCartItemsWithUpdatedPayment,
  updateCartItem,
} from '../common/shopping-cart/shoppingCartFunctions.js';
import { callGetUiApiWithRetry, callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { centsToEuros } from '../../common/utils/priceUtils.js';
import {
  checkDuplicateContactsPrivateMethod,
  getDeliveryMethodsPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { clearShoppingCart, getShoppingCart, setShoppingCart } from '../common/localStorageUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, delay, filter, mergeMap } from 'rxjs/operators';
import {
  createEcommerceItemFromCommercialProducts,
  createEcommerceItemFromShoppingCartItem,
} from '../common/googleEcommerceEvent.js';
import { findCatalogById } from '../../common/utils/catalogUtils.js';
import { getItemIdentifier } from '../../components/DuplicateContactsCheckDialog/duplicateContactCheckDialogUtils.js';
import { getItemVariant } from '../../common/utils/analyticsUtils.js';
import { getManufacturer } from '../../common/utils/cartProductUtils.js';
import { isEmployeePortal } from '../../common/utils/browserUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddToCartAction,
  ClearCartItemsAction,
  DuplicateContactsCheckAction,
  GetCustomerLevelDiscountedPricesFulfilledAction,
  LoadDeliveryMethodsAction,
  LoadOnlineModelFulfilledAction,
  PostProcessCartItemsAction,
  ProcessAdditionalParametersInCartItemsAction,
  ProcessCartItemsAction,
  SelfServiceActionTypes,
  UpdateCartItemAction,
  UpdateCartItemPaymentAction,
} from '../actions/index.js';
import type { CartItemType, CartUpdateAnalyticsData, Price } from '../common/shopping-cart/shoppingCartInterfaces.js';
import type {
  Contacts,
  ContactsDuplicateCheckRequest,
  DeliveryMethodsResponse,
  DiscountedPrices,
  DiscountedPricesResponse,
  OnlineModel,
} from '../../generated/api/models.js';
import type { EpicDependencies } from './epicUtils.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { State } from '../common/store.js';

const setOrClearShoppingCart = (cartItem?: CartItemType[]) => {
  cartItem ? setShoppingCart(JSON.stringify(cartItem)) : clearShoppingCart();
};

const runValidationsRules = (state$: StateObservable<State>) => {
  const isEmployee =
    isEmployeePortal(window.location.pathname) ||
    state$.value.user?.authenticated?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;

  return isEmployee
    ? catalogToValidationRules(
        findCatalogById(
          state$.value.selfservice?.virtualCatalogs?.items,
          state$.value.selfservice?.virtualCatalogs?.selectedCatalogCode,
          true
        )
      )
    : eppSolutionToValidationRules(state$.value.selfservice?.companyInfo?.eppSolution);
};

const generateUniqueId = (existingItems: CartItemType[]): string => {
  const newId = crypto.randomUUID();
  // Probability is near zero for collision, but handle anyways..
  if (existingItems.find(existingItem => existingItem.id === newId)) {
    return generateUniqueId(existingItems);
  } else {
    return newId;
  }
};

const addToCartEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.ADD_TO_CART).pipe(
    mergeMap((addToCartAction: AddToCartAction) => {
      const {
        cartConfig,
        cartItemContainer: { isPublicSite },
      } = addToCartAction;

      const shoppingCart = JSON.parse(getShoppingCart());

      const uniqueId = generateUniqueId(shoppingCart);
      const cartItem = createCartItem(uniqueId, addToCartAction);

      addToShoppingCart(cartItem, shoppingCart);

      // This is for new the shopping basket, this might be good to remove some time before we go live to keep old & new carts synced
      const useShoppingBasket = isStrictFeatureEnabled(state$.value.config.featureFlags?.shoppingBasket);
      if (useShoppingBasket && !isEmployeePortal(window.location.pathname) && !isPunchout(window.location.pathname)) {
        addToShoppingBasket(addToCartAction.cartItemContainer, Boolean(state$.value.user?.anonymous?.ssoSessionValid));
      }

      if (addToCartAction.onAddToCart) {
        addToCartAction.onAddToCart();
      }

      const { commercialProduct, offer, onlineModel, quantity } = addToCartAction.cartItemContainer;

      let commercialProducts = [commercialProduct];
      if (commercialProduct.productType === CommercialProductType.SALES_PRODUCT) {
        // Subs have the price spread out between two commercialProducts in the same offer...
        commercialProducts = [
          commercialProduct,
          // Keep the 'original' CP as the first one in the array
          ...offer.commercialProducts.filter(
            cp => cp.commercialProductCode !== commercialProduct.commercialProductCode
          ),
        ];
      }
      const ecommerceItem = createEcommerceItemFromCommercialProducts(
        onlineModel.onlineModelCode,
        onlineModel.category,
        onlineModel.onlineModelName,
        getItemVariant(EcommerceEventTypeV4.ADD_TO_CART, commercialProduct.commercialProductName) ??
          commercialProduct.commercialProductName,
        commercialProducts,
        quantity,
        getManufacturer(onlineModel, commercialProduct.commercialProductName)
      );

      return of(
        processCartItems(
          true,
          !isPublicSite,
          JSON.stringify(shoppingCart),
          runValidationsRules(state$),
          cartConfig,
          JSON.stringify(cartItem),
          Date.now()
        ),
        notifyShoppingCartChange(),
        googleEcommerceAddToCart([ecommerceItem])
      ).pipe(delay(1));
    })
  );

const updateProductQuantityEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.UPDATE_CART_ITEM).pipe(
    mergeMap((action: UpdateCartItemAction) => {
      const cartItems: CartItemType[] = updateCartItem(action);
      setOrClearShoppingCart(cartItems);
      if (action.onUpdateCart) {
        action.onUpdateCart();
      }

      const delta = action.newQuantity - action.cartItem.quantity;
      const ecommerceItem = createEcommerceItemFromShoppingCartItem(action.cartItem, Math.abs(delta));

      const isEmployee =
        isEmployeePortal(window.location.pathname) ||
        state$.value.user?.authenticated?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;
      return of(
        processCartItems(false, isEmployee, getShoppingCart(), runValidationsRules(state$)),
        notifyShoppingCartChange(),
        delta > 0 ? googleEcommerceAddToCart([ecommerceItem]) : googleEcommerceRemoveFromCart([ecommerceItem])
      );
    })
  );

const updateProductPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.UPDATE_CART_ITEM_PAYMENT).pipe(
    mergeMap((action: UpdateCartItemPaymentAction) => {
      const cartItems: CartItemType[] = getCartItemsWithUpdatedPayment(getCartItems(), action);
      setOrClearShoppingCart(cartItems);
      const isEmployee =
        isEmployeePortal(window.location.pathname) ||
        state$.value.user?.authenticated?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;
      return of(
        processCartItems(false, isEmployee, getShoppingCart(), runValidationsRules(state$)),
        notifyShoppingCartChange()
      );
    })
  );

const getNewContactsFromCartItemsForDuplicateCheck = (
  cartItems: Array<ShoppingCartItemForCheckout> = []
): Contacts[] => {
  return (
    cartItems.flatMap((item, itemIndex) => {
      return item.purposeOfUseOrContacts
        .map((puc, pucIndex) => ({
          // adding filter after map as to maintain the indexing
          identifier: getItemIdentifier(itemIndex, pucIndex),
          purposeOfUseOrContact: puc,
        }))
        .filter(
          pucWithIdentifier =>
            pucWithIdentifier.purposeOfUseOrContact.contactId === ContactCreationType.CREATE_NEW_CONTACT &&
            pucWithIdentifier.purposeOfUseOrContact.newContact !== undefined
        )
        .map(pucWithIdentifier => ({
          identifier: pucWithIdentifier.identifier,
          contact: {
            contactType: ContactType.PERSON,
            person: pucWithIdentifier.purposeOfUseOrContact.newContact,
          },
        }));
    }) || []
  );
};

export const onProcessAdditionalParametersInCartItemsFulfilledEpic: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (action$: ActionsObservable<SelfServiceActionTypes>, state$: StateObservable<State>) =>
  action$.pipe(
    ofType(TypeKeys.PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS),
    filter(
      (action: ProcessAdditionalParametersInCartItemsAction) =>
        !action.validateOnly &&
        actionToActionState(action, state$, 'deviceCheckout').state!.phase === ActionPhase.SUCCESS
    ),
    mergeMap(() => [
      // NOTE! Loading companyInfo and BA's don't seem to belong here. Processing "additional parameters" doesn't seem to need them.
      //       They are however required to be loaded for later steps during checkout. The checkout should be doing the loading, not
      //       relying on an unrelated redux action to magically load them.
      loadCompanyInfo(),
      loadBillingAccounts(true, BillingAccountSourceSystem.SFDC),
      duplicateContactsCheck(),
    ])
  );

const checkForDuplicateNewContactInCartItemsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.DUPLICATE_CONTACTS_CHECK)), (action: DuplicateContactsCheckAction) =>
    actionToActionState(action, state$, 'deviceCheckout')
  ).pipe(
    concatMap(() => {
      const newContacts = getNewContactsFromCartItemsForDuplicateCheck(state$.value.deviceCheckout?.cartItems);
      if (newContacts.length < 1) {
        return of(duplicatContactsCheckFulfilled());
      }
      const contactsDuplicateCheckRequest: ContactsDuplicateCheckRequest = {
        contacts: newContacts,
      };
      return callUiApi({
        epicDependencies,
        failureAction: duplicateContactsCheckFailed,
        method: checkDuplicateContactsPrivateMethod(),
        payload: contactsDuplicateCheckRequest,
        state$,
        successAction: duplicatContactsCheckFulfilled,
      });
    })
  );

const clearCartItemsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.CLEAR_CART_ITEMS).pipe(
    mergeMap((action: ClearCartItemsAction) => {
      setOrClearShoppingCart();
      if (action.forceReload) {
        if (isInBrowser() && window.location) {
          window.location.reload();
        }
      }
      return EMPTY;
    })
  );

const getCartItemsFromActionOrLocalStorage = (cartItemJsonFromAction?: string): CartItemType[] => {
  const cartItemJson = cartItemJsonFromAction || getShoppingCart();
  return JSON.parse(cartItemJson);
};

interface CartItemsSimplified {
  productId: string;
  guid: string;
  quantity: number;
  productName: string;
}

const getMatchingCurrentCartItem = (
  currentCartItems: CartItemsSimplified[],
  previousCartItem: ShoppingCartItemForCheckout
): CartItemsSimplified => {
  const matchedCartItem = currentCartItems.filter(
    item => item.guid === previousCartItem.offerCode && item.productName === previousCartItem.productName
  );
  return matchedCartItem.length > 0
    ? matchedCartItem[0]
    : {
        productId: previousCartItem.productId,
        guid: previousCartItem.offerCode,
        quantity: 0,
        productName: previousCartItem.productName,
      };
};

const getCartUpdateEventData = (
  currentCartItems: CartItemType[],
  previousCartItems?: Array<ShoppingCartItemForCheckout>
): CartUpdateAnalyticsData[] => {
  const productList = 'tuotesivu';
  const interaction = 'interaction';
  const currentCartItemsSimplified: CartItemsSimplified[] = currentCartItems.map(item => ({
    productId: item.bundle.id,
    guid: item.bundleItems[0].product.guid,
    quantity: item.quantity,
    productName: item.bundleItems[0].product.name,
  }));
  const cartUpdateEventData: CartUpdateAnalyticsData[] = [];
  if (previousCartItems) {
    previousCartItems.map(previousCartItem => {
      const matchingCurrentCartItem = getMatchingCurrentCartItem(currentCartItemsSimplified, previousCartItem);
      const index = currentCartItemsSimplified.indexOf(matchingCurrentCartItem);
      if (index !== -1) {
        currentCartItemsSimplified.splice(index, 1);
      }
      const deltaQuantity = matchingCurrentCartItem.quantity - previousCartItem.quantity;
      if (deltaQuantity !== 0) {
        cartUpdateEventData.push({
          productId: matchingCurrentCartItem.productId,
          onlineModelCode: matchingCurrentCartItem.guid,
          deltaQuantity,
          analyticsEventType:
            deltaQuantity > 0 ? AnalyticsEventTypes.ADD_TO_CART : AnalyticsEventTypes.REMOVE_FROM_CART,
          event: interaction,
          target: productList,
        });
      }
    });
  }
  if (currentCartItemsSimplified.length > 0) {
    currentCartItemsSimplified.map(item =>
      cartUpdateEventData.push({
        productId: item.productId,
        onlineModelCode: item.guid,
        deltaQuantity: item.quantity,
        analyticsEventType: AnalyticsEventTypes.ADD_TO_CART,
        event: interaction,
        target: productList,
      })
    );
  }
  return cartUpdateEventData;
};

const processCartItemsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.PROCESS_CART_ITEMS).pipe(
    mergeMap((action: ProcessCartItemsAction) => {
      const cartUpdateEventData = getCartUpdateEventData(
        getCartItemsFromActionOrLocalStorage(action.cartItemJson),
        state$.value.deviceCheckout?.cartItems
      );

      return of(
        addCartUpdateEventToAnalytics(cartUpdateEventData),
        postProcessCartItems(
          action.cartUpdated,
          action.isEmployee,
          action.cartItemJson,
          action.validationRules,
          action.cartConfig,
          action.latestItemAddedToCart,
          action.latestItemAddedToCartTimestamp
        )
      );
    })
  );

const postProcessCartItemsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.POST_PROCESS_CART_ITEMS).pipe(
    filter((action: PostProcessCartItemsAction) => !action.updateStateOnly),
    mergeMap((action: PostProcessCartItemsAction) => {
      const originalCartItems = getCartItemsFromActionOrLocalStorage(action.cartItemJson);

      // Filter out old cartItems without additionalFields
      const cartItems: CartItemType[] = originalCartItems?.filter(cartItem =>
        cartItem.bundleItems.every(bundleItem => bundleItem.additionalFields !== undefined)
      );

      cartItems.forEach(cartItem => {
        if (cartItem.id === undefined) {
          cartItem.id = generateUniqueId(cartItems);
        }
      });

      setOrClearShoppingCart(cartItems);
      // TODO: remove ff check when Shopping-basket has own checkout
      if (
        !isStrictFeatureEnabled(state$.value.config.featureFlags?.shoppingBasket) &&
        state$.value.deviceCheckout?.cartItems.length !== cartItems.length
      ) {
        return [clearCartItems(true)];
      }
      return EMPTY;
    })
  );

const getUpdatedPriceIfChange = (newPrice?: number | undefined, oldPrice?: number): number | undefined => {
  if (newPrice && oldPrice && oldPrice !== centsToEuros(newPrice)) {
    return centsToEuros(newPrice);
  }
  return;
};

const checkForPriceUpdates = (
  cartItemPrice: Price,
  latestOneTimePrice?: number,
  latestMonthlyRecurringCharge?: number
) => {
  return {
    newOneTimePrice: getUpdatedPriceIfChange(latestOneTimePrice, cartItemPrice.onetime.price),
    newMonthlyRecurringCharge: getUpdatedPriceIfChange(
      latestMonthlyRecurringCharge,
      cartItemPrice?.periodic?.[0]?.price
    ),
  };
};

const updateCartItemPricesWithDiscounts = (discounts: DiscountedPricesResponse) => {
  const cartItems: CartItemType[] = JSON.parse(getShoppingCart());
  if (cartItems.length) {
    let pricesUpdated = false;
    const updatedCartItems = cartItems.map(cartItem => {
      const discountedProduct = discounts.discountedPrices.find(discount => discount.model === cartItem.bundle.guid);
      if (discountedProduct) {
        const cartItemPrice: Price = cartItem.bundleItems[0].price;
        const discountedPrices = discountedProduct.prices.find(
          price => price.commercialProductCode === cartItemPrice.guids[0]
        );
        if (discountedPrices) {
          const { newOneTimePrice, newMonthlyRecurringCharge } = checkForPriceUpdates(
            cartItemPrice,
            discountedPrices.oneTimeCharge,
            discountedPrices.monthlyRecurringCharge
          );
          if (newOneTimePrice) {
            cartItemPrice.onetime.price = newOneTimePrice;
            pricesUpdated = true;
          }
          if (newMonthlyRecurringCharge && cartItemPrice.periodic && cartItemPrice.periodic[0]) {
            cartItemPrice.periodic[0].price = newMonthlyRecurringCharge;
            pricesUpdated = true;
          }
        }
      }
      return cartItem;
    });
    return { cartItems: updatedCartItems, pricesUpdated };
  }
  return { cartItems, pricesUpdated: false };
};

// TODO: Remove this when whole app is reactified.
// When an admin users adds the product from the angular web shop, it may happen that product is added to the cart without discounts,
// because it takes time to load the discounts and revise prices on ui. Doing this will update the cart prices with discounts.
// This can be removed when whole application is reactified, where user will not be allowed to add the product to the cart until
// discounts have be applied.

const updateCartItemPricesWithDiscountsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED).pipe(
    mergeMap((action: GetCustomerLevelDiscountedPricesFulfilledAction) => {
      const { cartItems, pricesUpdated } = updateCartItemPricesWithDiscounts(action.response);
      if (pricesUpdated) {
        const isEmployee = isEmployeePortal(window.location.pathname);
        const updatedCart = JSON.stringify(cartItems);
        setShoppingCart(updatedCart);
        return of(processCartItems(false, isEmployee, updatedCart, runValidationsRules(state$)));
      }
      return EMPTY;
    })
  );

const updateCartItemPricesWithLatestPrices = (onlineModel: OnlineModel, discounts?: DiscountedPrices[]) => {
  const cartItems: CartItemType[] = JSON.parse(getShoppingCart());
  if (cartItems.length) {
    let pricesUpdated = false;
    const updatedCartItems = cartItems.map(cartItem => {
      const isItemInCart = onlineModel.onlineModelCode === cartItem.bundle.guid;
      if (isItemInCart) {
        const cartItemPrice: Price = cartItem.bundleItems[0].price;
        const matchingOffer = onlineModel.offers.find(
          offer => offer.offerCode === cartItem.bundleItems[0].product.guid
        );
        const matchingCommercialProduct = matchingOffer?.commercialProducts?.find(
          cp => cp.commercialProductCode === cartItemPrice.guids[0]
        );
        const isProductDiscounted = discounts
          ?.find(discount => discount.model === cartItem.bundle.guid)
          ?.prices.find(price => price.commercialProductCode === matchingCommercialProduct?.commercialProductCode);
        if (!isProductDiscounted) {
          if (matchingCommercialProduct) {
            const { newOneTimePrice, newMonthlyRecurringCharge } = checkForPriceUpdates(
              cartItemPrice,
              matchingCommercialProduct.oneTimeCharge,
              matchingCommercialProduct.monthlyRecurringCharge
            );
            if (newOneTimePrice) {
              cartItemPrice.onetime.price = newOneTimePrice;
              pricesUpdated = true;
            }
            if (newMonthlyRecurringCharge && cartItemPrice.periodic && cartItemPrice.periodic[0]) {
              cartItemPrice.periodic[0].price = newMonthlyRecurringCharge;
              pricesUpdated = true;
            }
          }
        }
      }
      return cartItem;
    });
    return { cartItems: updatedCartItems, pricesUpdated };
  }
  return { cartItems, pricesUpdated: false };
};

const updateCartItemPricesWithLatestPricesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.LOAD_ONLINE_MODEL_FULFILLED).pipe(
    mergeMap((action: LoadOnlineModelFulfilledAction) => {
      const { cartItems, pricesUpdated } = updateCartItemPricesWithLatestPrices(
        action.onlineModel,
        state$.value.selfservice?.companyInfo?.discountedPrices
      );
      if (pricesUpdated) {
        const isEmployee = isEmployeePortal(window.location.pathname);
        const updatedCart = JSON.stringify(cartItems);
        setShoppingCart(updatedCart);
        return of(processCartItems(false, isEmployee, updatedCart, runValidationsRules(state$)));
      }
      return EMPTY;
    })
  );

export const loadDeliveryMethodEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.GET_DELIVERY_METHODS).pipe(
    concatMap((action: LoadDeliveryMethodsAction) => {
      return callGetUiApiWithRetry(
        {
          epicDependencies,
          state$,
          method: getDeliveryMethodsPrivateMethod({
            isEPPDevicePresent: action.isEppProductPresent,
            isDirectDeliveryProductPresent: action.isDirectDeliveryProductPresent,
          }),
          successAction: (res: DeliveryMethodsResponse) => loadDeliveryMethodsFulfilled(res),
          failureAction: loadDeliveryMethodsFailed,
        },
        undefined,
        2000,
        action$,
        [500]
      );
    })
  );

export const deviceCheckoutEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  addToCartEpic,
  updateProductPaymentEpic,
  updateProductQuantityEpic,
  onProcessAdditionalParametersInCartItemsFulfilledEpic,
  clearCartItemsEpic,
  processCartItemsEpic,
  postProcessCartItemsEpic,
  updateCartItemPricesWithDiscountsEpic,
  updateCartItemPricesWithLatestPricesEpic,
  checkForDuplicateNewContactInCartItemsEpic,
  loadDeliveryMethodEpic
);
