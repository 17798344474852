import { Outlet, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { useEventListener } from '../OpenFormHooks/useEventListener.js';
import { useOpenFormAccount } from '../OpenFormHooks/useOpenFormAccount.js';
import { useOpenFormCanvas } from '../OpenFormProvider.js';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useReload } from '../OpenFormHooks/useReload.js';

export const OpenFormLayoutOutlet = () => {
  const account = useOpenFormAccount();
  const canvas = useOpenFormCanvas();
  const preview = useLocation().pathname.startsWith('/openform/preview/');
  const { goto } = useOpenFormGoto();
  const [reloading, reload] = useReload();

  useEventListener(
    'message',
    useCallback<(event: MessageEvent) => void>(
      event => {
        if (canvas.isEventTrusted(event) && event.data?.action) {
          console.dir(event);
          switch (event.data.action) {
            case canvas.action.billingAccountCreated:
            case canvas.action.contactCreated:
              canvas.postMessage({ action: canvas.action.acknowledgement, target: event.data.action }); // Placeholder
              break;
            case canvas.action.goto:
              goto(event.data);
              break;
          }
        }
      },
      [goto, canvas]
    )
  );

  return reloading ? null : <Outlet context={{ account, preview, reload }} />;
};
