import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { Loading } from '../Loading';
import { SubscriptionCategory } from '../../common/enums';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
  getUpdateButtonType,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionDetailsMobile } from '../SubscriptionDetails/SubscriptionDetailsMobile.js';
import { SubscriptionStatusType } from '../../generated/api/subscriptionStatusType.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionDetailsBroadband } from '../SubscriptionDetails/subscriptionDetailsBroadband.js';
import { getSubscriptionStatus, isTellusSubscription } from '../../common/utils/subscriptionUtils.js';
import { mobileBroadbandMsg, mobileM2MMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables.js';
import {
  resumeSubscription,
  showDialog,
  showTerminateSubscriptionForm,
  terminateSubscriptionAction,
} from '../../selfservice/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

export const SubscriptionBroadband = ({
  addOnRules,
  addOnRulesMobilePbx,
  billingAccounts,
  breadCrumbs,
  category,
  companyInfo,
  contacts,
  subscription,
  pendingSubscriptionActions,
}: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTerminateSubscriptionForm, showSuspendSubscriptionOptions, terminationInProgress } = useSelector(
    (state: State) => ({
      isTerminateSubscriptionForm:
        state?.selfservice?.pendingSubscriptionActions?.terminateSubscriptionInitiated || false,
      showSuspendSubscriptionOptions:
        state?.selfservice?.pendingSubscriptionActions?.suspendSubscriptionInitiated || false,
      terminationInProgress: state?.selfservice?.pendingSubscriptionActions?.terminationInProgress || false,
    })
  );
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const { search } = useLocation();
  const { mdmId } = useSearchParams<{ mdmId: string }>();

  const onClickSubscriptionChangeOfferSelection = (subDisplayId: string, subscriptionType: SubscriptionType) => {
    const redirectPath = (() => {
      switch (subscriptionType) {
        case SubscriptionType.MOBILE_M2M:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE_M2M + search;
        case SubscriptionType.MOBILE_BROADBAND:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE + search;
        default:
          return paths.PS_MOBILE_SUBSCRIPTION_UPDATE + search;
      }
    })();

    navigate(generatePath(redirectPath, { subscriptionId: subDisplayId }));
  };

  const subscriptionId = subscription ? subscription.subscriptionId : '…';
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const isSuspended = subscription && subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED;
  const companyName = companyInfo?.companyName;

  if (!subscription) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading=""
        headingBottom={companyName}
        headingTop={t.VI0P('Broadband')}
        id="subscription-details-missing"
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  if (
    subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND ||
    subscription.subscriptionType === SubscriptionType.MOBILE_M2M
  ) {
    const isLaitenetti = subscription.subscriptionType === SubscriptionType.MOBILE_M2M;

    const buttons = isTellusSubscription(subscription)
      ? [SubscriptionDetailsButtonType.SUPPORT_REQUEST]
      : [
          getUpdateButtonType(
            subscription!,
            getSubscriptionStatus(subscription, pendingSubscriptionActions).isChangeOfferAllowed
          ),
          SubscriptionDetailsButtonType.SUPPORT_REQUEST,
          isSuspended
            ? SubscriptionDetailsButtonType.RESUME_SUBSCRIPTION
            : SubscriptionDetailsButtonType.TEMPORARILY_CLOSE,
          SubscriptionDetailsButtonType.TERMINATE,
        ];

    if (!isTellusSubscription(subscription) && subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND) {
      buttons.push(SubscriptionDetailsButtonType.MOBILE_FIXER);
    }

    const subscriptionDetails = (
      <div className="of-subscription-details__content of-subscription-details__content--mobile">
        <SubscriptionDetailsMobile
          addOnRulesAssociations={addOnRules?.associations[0] as AssociationRecord[]}
          onShowDialog={onShowDialog}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          details={subscription.details}
          showSuspendSubscriptionOptions={showSuspendSubscriptionOptions}
          showTerminateSubscriptionForm={isTerminateSubscriptionForm}
          onTerminateSubscription={(subId, effectiveDate, donateNumber) =>
            dispatch(terminateSubscriptionAction(subId, effectiveDate, donateNumber))
          }
          onResumeSubscription={onResumeSubscription}
          billingAccountSelection={
            <SubscriptionDetailsBillingAccount
              billingAccounts={billingAccounts}
              subscription={subscription}
              pendingSubscriptionActions={pendingSubscriptionActions}
            />
          }
          mobileDetails={subscription.details?.mobile}
          terminationInProgress={terminationInProgress}
          subscriptionCategory={SubscriptionCategory.BROADBAND}
        />
        {!isTerminateSubscriptionForm && !showSuspendSubscriptionOptions && (
          <ButtonGroup
            buttonTypes={buttons}
            companyInfo={companyInfo}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onShowDialog={onShowDialog}
            onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
            onResumeSubscription={onResumeSubscription}
            onClickSubscriptionChangeOfferSelection={onClickSubscriptionChangeOfferSelection}
            mdmId={mdmId}
          />
        )}
        <SubscriptionDetailsAccordions
          addOnRules={addOnRules}
          addOnRulesMobilePbx={addOnRulesMobilePbx}
          category={category}
          contacts={contacts}
          companyInfo={companyInfo}
          pendingSubscriptionActions={pendingSubscriptionActions}
          subscription={subscription}
          billingAccounts={billingAccounts}
        />
      </div>
    );

    const phoneNumber = formatPhoneNumber(subscription.details?.mobile?.mobileNumber ?? '');

    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionPurposeOfUse || '…'}
        headingBottom={`${phoneNumber}${phoneNumber ? ' | ' : ''}${companyName}`}
        headingTop={isLaitenetti ? t.A7CA(mobileM2MMsg) : t.EQKJ(mobileBroadbandMsg)}
        heroPicto={isLaitenetti ? 'mobile-m2m' : 'mobilebb'}
        id={`subscription-details-${subscriptionId}`}
      >
        {subscriptionDetails}
      </DetailsWrapper>
    );
  } else {
    const buttons = isTellusSubscription(subscription)
      ? [SubscriptionDetailsButtonType.SUPPORT_REQUEST]
      : [
          SubscriptionDetailsButtonType.SUPPORT_REQUEST,
          SubscriptionDetailsButtonType.TERMINATE_VIA_DIALOG,
          SubscriptionDetailsButtonType.BROADBAND_FIXER,
        ];

    const subscriptionDetails = (
      <div className="of-subscription-details__content of-subscription-details__content--broadband">
        {getSubscriptionDetailsBroadband(
          onShowDialog,
          subscription,
          subscriptionStatus,
          onResumeSubscription,
          subscription.details,
          <SubscriptionDetailsBillingAccount
            billingAccounts={billingAccounts}
            subscription={subscription}
            pendingSubscriptionActions={pendingSubscriptionActions}
          />
        )}
        <ButtonGroup
          buttonTypes={buttons}
          companyInfo={companyInfo}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
          mdmId={mdmId}
        />
        <SubscriptionDetailsAccordions
          addOnRules={addOnRules}
          addOnRulesMobilePbx={addOnRulesMobilePbx}
          category={category}
          contacts={contacts}
          companyInfo={companyInfo}
          pendingSubscriptionActions={pendingSubscriptionActions}
          subscription={subscription}
        />
      </div>
    );
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading={subscription.subscriptionUserFriendlyId || subscription.subscriptionPurposeOfUse || ''}
        headingBottom={companyName}
        headingTop={t.VI0P('Broadband')}
        heroPicto={!subscription ? undefined : 'fixedbb'}
        id={`subscription-details-${subscriptionId}`}
      >
        {subscriptionDetails}
      </DetailsWrapper>
    );
  }
};
