import * as CL from '@design-system/component-library';
import { Catalog, OnlineModelCategory } from '../../generated/api/models.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { Loading } from '../Loading/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { ProductGrid } from '../ProductGrid/ProductGrid.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { categories } from '../../common/utils/categoryUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath } from 'react-router-dom';
import { getPublishedCatalogs, getSelectedCatalog, toDisplayableProduct } from '../../common/utils/catalogUtils.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { monthMsg, omaElisaForEmployeeMsg, shopMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useRedirectToDeviceStore } from '../../public/site/path/Employee/DeviceStore/EmployeeStore.js';
import { useSelector } from 'react-redux';
import type { Category } from '../../common/types/category.js';
import type { ProductGridItem } from '../ProductGrid/ProductGrid.js';
import type { State } from '../../selfservice/common/store.js';

import './EmployeeDeviceStoreProducts.scss';

interface EmployeeDeviceStoreProductsProps {
  category: Category;
}

const getCategoryLinks = (productCategories: OnlineModelCategory[]) =>
  Object.values(categories)
    .filter(category => productCategories.includes(category.code))
    .sort((c1, c2) => c1.order - c2.order)
    .map(({ displayText, iconClass, urlPath }) => (
      <NavigationMenuItem
        key={urlPath}
        id={urlPath}
        to={generatePath(paths.EMPLOYEE_DEVICE_STORE_PRODUCTS, { category: urlPath })}
        icon={<CL.Icon icon={`${iconClass === 'phone' ? 'smart' : ''}${iconClass}`} size="l" />}
        label={displayText()}
      />
    ));

const getHeroSubtitle = (catalog: Catalog, category: Category) => {
  if (category.code === OnlineModelCategory.ACCESSORIES || catalog.productType === Catalog.ProductTypeEnum.ONETIME) {
    return undefined;
  }
  let subtitle = `${t.V3UP('Device contract period')} ${catalog.contractPeriod} ${t.XXVX(monthMsg)}.`;
  if (catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING) {
    subtitle = subtitle.concat(` ${t.KD2N('Quoted prices VAT')} ` + getVatAsString() + '%');
  }
  return subtitle;
};

export const EmployeeDeviceStoreProducts = ({ category }: EmployeeDeviceStoreProductsProps) => {
  useRedirectToDeviceStore();
  const { discountedPrices, onlineModelHeaders, selectedCatalogCode, virtualCatalogItems } = useSelector(
    (state: State) => ({
      discountedPrices: state.selfservice?.companyInfo?.discountedPrices,
      onlineModelHeaders: state.selfservice?.onlineModelHeaders,
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
    }),
    deepEqual
  );
  const catalogs: Catalog[] = getPublishedCatalogs(virtualCatalogItems) || [];
  const catalog = getSelectedCatalog(catalogs, selectedCatalogCode)!;
  const models = onlineModelHeaders?.items && onlineModelHeaders.items[category.code];

  if (!selectedCatalogCode) {
    return null;
  }
  if (hasSystemError(onlineModelHeaders?.errors)) {
    return <SystemError errors={onlineModelHeaders?.errors} />;
  }
  return (
    <div className="of-employee-device-store-products">
      <HeroHeading
        center={true}
        fullScreen={true}
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.BZGP(shopMsg), path: paths.EMPLOYEE_DEVICE_STORE },
          { name: category.displayText() },
        ]}
        heroHeadingType={HeroHeadingType.BLANK}
        title={category.displayText()}
        subtitle={getHeroSubtitle(catalog, category)}
      />
      {catalog.productCategories.length > 1 && (
        <div className="of-employee-device-store-products__border-bottom">
          <div className="of-employee-device-store-products__categories">
            <NavigationMenu>{getCategoryLinks(catalog.productCategories)}</NavigationMenu>
          </div>
        </div>
      )}
      {models && discountedPrices ? (
        <ProductGrid
          items={models
            .map(model => toDisplayableProduct(model, catalog, discountedPrices!, true, true))
            .filter(product => product)
            .map((product: ProductGridItem) => ({
              ...product,
              pagePath: generatePath(paths.EMPLOYEE_DEVICE_STORE_PRODUCT, {
                category: category.urlPath,
                deviceName: product?.pagePath?.substring(1) || '',
              }),
            }))}
          category={category.code}
          itemLimit={48}
          inEOE={true}
        />
      ) : (
        <Loading big={true} />
      )}
    </div>
  );
};
