import * as CL from '@design-system/component-library';
import { companyPayingForBarringServicesMsg, employeePayingForBarringServicesMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { getOmalaskuBarringsDetailsText, getOmalaskuBarringsTitleText } from './omalaskuUtils';
import type { OmalaskuStatusWithMsisdnResponse } from './omalaskuUtils';

interface OmalaskuBarringsDetailsProps {
  status: OmalaskuStatusWithMsisdnResponse;
}

export const OmalaskuBarringsDetails = ({ status }: OmalaskuBarringsDetailsProps) => {
  const barringTitleText = getOmalaskuBarringsTitleText(status.speechBarrings);
  const barringDetailsText = getOmalaskuBarringsDetailsText(status.speechBarrings);

  return (
    <div>
      <h4
        className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_5}`}
      >{`${t.Z7NS('This subscription has the following barrings:')} ${barringTitleText}`}</h4>
      {barringDetailsText.employeePaying.length > 0 && (
        <div className={dsClass.MARGIN_BOTTOM_5}>
          <div>{t.AHL9(employeePayingForBarringServicesMsg)}</div>
          <CL.List className="of-employee-omalasku__ordering-payable-list" listType="unordered">
            {barringDetailsText.employeePaying.map((item, index) => (
              <li key={`employee-paying${index}`}>{item}</li>
            ))}
          </CL.List>
        </div>
      )}
      {barringDetailsText.companyPaying.length > 0 && (
        <div className={dsClass.MARGIN_BOTTOM_5}>
          <div>{t.Q4GQ(companyPayingForBarringServicesMsg)}</div>
          <CL.List className="of-employee-omalasku__ordering-payable-list" listType="unordered">
            {barringDetailsText.companyPaying.map((item, index) => (
              <li key={`company-paying${index}`}>{item}</li>
            ))}
          </CL.List>
        </div>
      )}
      <CL.Link linkStyle="link" linkHref="https://yrityksille.elisa.fi/ohje/lisamaksulliset-palvelut-ja-estoluokat">
        {t.GJJF('Read more')}
      </CL.Link>
    </div>
  );
};
