import { HeroHeading, HeroHeadingType } from '../../../../../components/HeroHeading';
import { LocalNavi } from '../../../../../components/LocalNavi';
import { ServiceContracts } from '../../../../../components/SubscriptionLists/ServiceContracts.js';
import { contractsMsg, myProductsAndServicesMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getDisplayCategories } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { isFeatureEnabled } from '../../../../../common/utils/featureFlagUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { Contract } from '../../../../../generated/api/contract';
import type { DefaultListSearchParams } from '../../../../../components/Table';
import type { State } from '../../../../../selfservice/common/store.js';

export const ContractsPath = () => {
  const { authenticatedUser } = useAuth();
  const { config } = useSelector((state: State) => ({ config: state.config }), deepEqual);
  const { search, offset = '0' } = useSearchParams<DefaultListSearchParams>();
  const mobileIdContracts = useLoaderData() as Contract[];

  return (
    <div className="of-subscriptions-scene">
      <HeroHeading
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.SW0J(contractsMsg) },
        ]}
        heroHeadingType={HeroHeadingType.SUBSCRIPTIONS}
        title={t.VWNX(myProductsAndServicesMsg)}
      />
      <LocalNavi categories={getDisplayCategories(config, authenticatedUser?.enabledFeatureFlags)} />
      <ServiceContracts
        offset={Number(offset)}
        search={search}
        mobileIdContracts={mobileIdContracts}
        isMobileIdEnabled={isFeatureEnabled(config.featureFlags.mobileId)}
      />
    </div>
  );
};
