import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SimActivation } from '../../../../../components/SimActivation/SimActivation.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { activateSimCardMsg, internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionStatus, getSubscriptionUrlId } from '../../../../../common/utils/subscriptionUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { BroadbandSubPostChangeRequestLoaderResponse } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store.js';

export const BroadbandSubActivateSimPath = () => {
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as BroadbandSubPostChangeRequestLoaderResponse;
  const serviceFees = useSelector(
    (state: State) => state?.selfservice?.subscriptions?.broadband?.serviceFees,
    deepEqual
  );
  const { pendingActionType, pendingActions } = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const pendingSimChanges = pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <SimActivation
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
            {
              name: subscription.subscriptionId,
              path:
                generatePath(paths.PS_BROADBAND_SUBSCRIPTION, {
                  subscriptionId: getSubscriptionUrlId(subscription),
                }) + search,
            },
            { name: t.M1E7(activateSimCardMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.BROADBAND}
      companyInfo={companyInfo}
      disallowBillableSimChanges={false}
      pendingSimChanges={pendingSimChanges}
      pendingSubscriptionChanges={pendingActions}
      onClickOrderSim={() =>
        navigate(
          generatePath(paths.PS_BROADBAND_SUBSCRIPTION_ORDER_SIM, {
            subscriptionId: getSubscriptionUrlId(subscription),
          }) + search
        )
      }
      onClickSubscription={() => {
        navigate(
          generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) + search
        );
      }}
      subscription={subscription}
      simChangeServiceFee={
        serviceFees?.find(serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId)?.price
      }
    />
  );
};
