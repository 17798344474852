import { type Context, OpenFormAnswers } from './OpenFormAnswers.js';
import { type Dispatch } from 'react';
import { type OpenForm } from './OpenFormHooks/useOpenForm.js';
import { type OpenFormList } from './OpenFormHooks/useOpenFormList.js';
import { type OpenFormNotification } from './OpenFormComponents/OpenFormNotifications.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export type OpenFormActions = {
  [K in keyof typeof actions]: (...args: (typeof actions)[K] extends (...args: infer P) => unknown ? P : never) => void;
};

export type OpenFormState = Required<ReturnType<typeof OpenFormReducer.state>>;

type Action = ReturnType<(typeof actions)[keyof typeof actions]>;

const type: unique symbol = Symbol('OpenFormAction');

const actions = {
  choices: (payload: { guid: string; value: string | string[] | undefined }) => ({
    [type]: 'choices' as const,
    payload,
  }),

  confirm: (payload: boolean) => ({
    [type]: 'confirm' as const,
    payload,
  }),

  context: <K extends keyof Context>(payload: { guid: string; key: K; patch?: Context[K] }) => ({
    [type]: 'context' as const,
    payload,
  }),

  form: (payload: { answers: OpenFormAnswers | undefined; form: OpenForm | undefined }) => ({
    [type]: 'form' as const,
    payload,
  }),

  list: (payload: { list?: OpenFormList; pages: Map<string, number> | undefined }) => ({
    [type]: 'list' as const,
    payload,
  }),

  loading: (payload: { id: string; load: string | true | undefined }) => ({
    [type]: 'loading' as const,
    payload,
  }),

  notification: (payload: OpenFormNotification | undefined) => ({
    [type]: 'notification' as const,
    payload,
  }),

  page: (payload: number) => ({
    [type]: 'page' as const,
    payload,
  }),
} as const;

export class OpenFormReducer {
  private constructor(
    readonly answers: OpenFormAnswers = new OpenFormAnswers(),
    readonly confirm: boolean = false,
    readonly disabled: boolean = false,
    readonly form: OpenForm | undefined = undefined,
    readonly list: OpenFormList | undefined = undefined,
    readonly loading: Map<string, string | true> = new Map(),
    readonly notifications: Set<OpenFormNotification> = new Set(),
    readonly pages: Map<string, number> = new Map()
  ) {}

  static actions(dispatch: Dispatch<Action>): OpenFormActions {
    return Object.assign(
      {},
      ...Object.entries(actions).map(([name, action]) => ({ [name]: (payload: never) => dispatch(action(payload)) }))
    );
  }

  static reducer(store?: OpenFormStorage) {
    return (state: OpenFormState, action: Action): OpenFormState => {
      switch (action[type]) {
        case 'choices':
        case 'context':
          return this.#snapshot(this.#prune(this.#reduce(state, action)), store);
        case 'form':
        case 'page':
          return this.#snapshot(this.#reduce(state, action), store);
        case 'confirm':
        case 'loading':
        case 'notification':
          return this.#disabled(this.#reduce(state, action));
        case 'list':
          return this.#reduce(state, action);
      }
    };
  }

  static state() {
    return { ...new OpenFormReducer() } as const;
  }

  static #disabled(state: OpenFormState): OpenFormState {
    const { confirm, loading, notifications } = state;
    const disabled = confirm || typeof loading.values().next().value === 'string' || !!notifications.size;
    return state.disabled !== disabled ? { ...state, disabled } : state;
  }

  static #prune(state: OpenFormState): OpenFormState {
    if (state.form) {
      const { answers, form } = state;
      answers.forEachOf(
        'choices',
        guid => answers.visible(form.questions.find(q => q.guid === guid)) || answers.delete(guid)
      );
    }
    return state;
  }

  static #reduce(state: OpenFormState, action: Action): OpenFormState {
    switch (action[type]) {
      case 'choices': {
        const { guid, value } = action.payload;
        return value?.length
          ? typeof value === 'string'
            ? { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', [value])) }
            : { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', value)) }
          : state.answers.delete(guid)
            ? { ...state, answers: new OpenFormAnswers(state.answers) }
            : state;
      }
      case 'confirm': {
        const confirm = action.payload;
        return state.confirm !== confirm ? { ...state, confirm } : state;
      }
      case 'context': {
        const { guid, key, patch } = action.payload;
        return { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, key, patch)) };
      }
      case 'form': {
        const { answers, form } = action.payload;
        return { ...state, answers: new OpenFormAnswers(answers), form };
      }
      case 'list': {
        const { list = state.list, pages } = action.payload;
        return { ...state, list, pages: new Map(pages) };
      }
      case 'loading': {
        const { id, load } = action.payload;
        return load
          ? { ...state, loading: new Map(state.loading.set(id, load)) }
          : state.loading.delete(id)
            ? { ...state, loading: new Map(state.loading) }
            : state;
      }
      case 'notification': {
        const notification = action.payload;
        return notification
          ? { ...state, notifications: new Set(state.notifications.add(notification)) }
          : state.notifications.delete(state.notifications.values().next().value)
            ? { ...state, notifications: new Set(state.notifications) }
            : state;
      }
      case 'page': {
        const page = action.payload;
        return state.form?.formId && state.pages.get(state.form.formId) !== page
          ? { ...state, pages: new Map(state.pages.set(state.form.formId, page)) }
          : state;
      }
    }
  }

  static #snapshot(state: OpenFormState, store?: OpenFormStorage): OpenFormState {
    if (state.form && store?.storage) {
      const { answers, form, pages } = state;
      const { formId } = form;
      !answers.size
        ? store.removeItem(formId)
        : store.setItem(formId, { answers: Object.fromEntries(answers), page: pages.get(formId) });
    }
    return state;
  }
}
