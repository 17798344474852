import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError, PutSupportCaseResponse, SupportCase } from '../../generated/api/models.js';

export interface AddSupportCaseAction extends CrudAction<TypeKeys.ADD_SUPPORT_CASE> {
  supportCase: SupportCase;
  noSuccessNotification?: boolean;
  showInfoDialog?: boolean;
  successNotificationMsg?: string;
}

export interface AddSupportCaseFulfilledAction extends Action {
  type: TypeKeys.ADD_SUPPORT_CASE_FULFILLED;
  // Disables top level notification. Needed if user is informed about this via some other way in UI
  noSuccessNotification?: boolean;
  // Allows to override default success message
  successNotificationMsg?: string;
  showInfoDialog?: boolean;
  response?: PutSupportCaseResponse;
}

export type AddSupportCaseFailedAction = ErrorAction<TypeKeys.ADD_SUPPORT_CASE_FAILED>;

export interface SetCaseSentStatusAction extends Action {
  type: TypeKeys.SET_CASE_SENT_STATUS;
  supportCaseSent: boolean | undefined;
}

export const addSupportCase = (
  supportCase: SupportCase,
  noSuccessNotification?: boolean,
  showInfoDialog?: boolean,
  successNotificationMsg?: string
): AddSupportCaseAction => ({
  id: generateActionId(),
  noSuccessNotification,
  showInfoDialog,
  successNotificationMsg,
  supportCase,
  type: TypeKeys.ADD_SUPPORT_CASE,
});

export const addSupportCaseFulfilled = (
  response?: PutSupportCaseResponse,
  noSuccessNotification?: boolean,
  showInfoDialog?: boolean,
  successNotificationMsg?: string
): AddSupportCaseFulfilledAction => ({
  noSuccessNotification,
  showInfoDialog,
  successNotificationMsg,
  type: TypeKeys.ADD_SUPPORT_CASE_FULFILLED,
  response,
});

export const addSupportCaseFailed: ErrorActionCreator<TypeKeys.ADD_SUPPORT_CASE_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): AddSupportCaseFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.ADD_SUPPORT_CASE_FAILED,
  };
};

export const setCaseSentStatus = (supportCaseSent: boolean | undefined): SetCaseSentStatusAction => ({
  supportCaseSent,
  type: TypeKeys.SET_CASE_SENT_STATUS,
});

export type SupportCasesActionTypes =
  | AddSupportCaseAction
  | AddSupportCaseFulfilledAction
  | AddSupportCaseFailedAction
  | SetCaseSentStatusAction;
