import { type AuthProps } from '../../public/site/AuthProvider.js';
import { FormProvider } from 'react-hook-form';
import { OpenFormAsync } from './OpenFormAsync.js';
import { OpenFormCanvas } from './OpenFormCanvas.js';
import { OpenFormReducer, type OpenFormState } from './OpenFormReducer.js';
import { OpenFormStorage } from './OpenFormStorage.js';
import { type ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useOpenFormMethods } from './OpenFormHooks/useOpenFormMethods.js';
import { useOpenFormSearchParameters } from './OpenFormHooks/useOpenFormSearchParameters.js';
import { useRefReducer } from './OpenFormHooks/useRefReducer.js';

const OpenFormContextAsync = createContext(OpenFormAsync);
const OpenFormContextCanvas = createContext(OpenFormCanvas);
const OpenFormContextSearchParams = createContext({} as URLSearchParams);
const OpenFormContextState = createContext({} as OpenFormState);

export const useOpenFormAsync = () => useContext(OpenFormContextAsync);
export const useOpenFormCanvas = () => useContext(OpenFormContextCanvas);
export const useOpenFormSearchParams = () => useContext(OpenFormContextSearchParams);
export const useOpenFormState = () => useContext(OpenFormContextState);

export const OpenFormProvider = ({ auth, children }: { auth: AuthProps; children: ReactNode }) => {
  const user = useMemo(() => auth.authenticatedUser, [auth]);
  const storage = useMemo(() => new OpenFormStorage(sessionStorage, user?.activeAccountMasterId), [user]);
  const [state, dispatch] = useRefReducer(OpenFormReducer.reducer(storage), OpenFormReducer.state());

  useEffect(() => {
    OpenFormAsync.actions = OpenFormReducer.actions(dispatch);
    OpenFormAsync.state = state;
    OpenFormAsync.storage = storage;
  }, [user, storage, state, dispatch]);

  return (
    <OpenFormContextState.Provider value={state.current}>
      <OpenFormContextAsync.Provider value={OpenFormAsync}>
        <OpenFormContextSearchParams.Provider value={useOpenFormSearchParameters()}>
          <OpenFormContextCanvas.Provider value={OpenFormCanvas}>
            <FormProvider {...useOpenFormMethods(OpenFormAsync)}>{children}</FormProvider>
          </OpenFormContextCanvas.Provider>
        </OpenFormContextSearchParams.Provider>
      </OpenFormContextAsync.Provider>
    </OpenFormContextState.Provider>
  );
};
