import { loadingPublicHolidaysMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';

export const useOpenFormPublicHolidays = () => {
  const [data, setData] = useState<Date[] | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = async
      .fetchHolidays()
      .settled(({ dates }) => setData(dates.map(timestamp => new Date(timestamp))))
      .failed(text => async.actions.notification({ text, type: 'warning' }))
      .cache()
      .execute(t.JK3C(loadingPublicHolidaysMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
