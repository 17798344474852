import * as CL from '@design-system/component-library';
import { BillingAccountHeaderInfo } from '../AddOrSelectBillingAccountsV2/BillingAccountHeaderInfo.js';
import { BillingAccountInfo } from '../AddOrSelectBillingAccountsV2/BillingAccountInfo.js';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE } from '../../common/utils/billingAccountUtils.js';
import { InProgressCatalogAction } from '../../common/enums.js';
import { VirtualCatalogProductsSummary } from '../CatalogProductsSummary/VirtualCatalogProductSummary.js';
import { WizardActions } from '../WizardActions';
import {
  alvZeroMsg,
  billingAccountMsg,
  cancelMsg,
  catalogCorporateMessageLabelMsg,
  companysShareOfMonthlyFeeMsg,
  contractPeriodMsg,
  damageInsuranceMsg,
  inUseMsg,
  monthMsg,
  nameOfCatalogMsg,
  notInUseMsg,
  productTypeMsg,
  savingTheDataFailedMsg,
  t,
} from '../../common/i18n';
import { createContactsAndBillingAccount } from '../CatalogConfiguration/utils';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getCorporateShareAsText,
  getDamageInsuranceDisplayableItems,
  productTypeDetails,
} from '../../common/utils/catalogUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { startNotification, upsertVirtualCatalog } from '../../selfservice/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { Catalog, OnlineModelCategory, VirtualCatalog } from '../../generated/api/models.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CatalogSummaryLoaderData } from '../../common/loaders';
import type { ReadyFormValues } from '../CatalogConfiguration/CatalogConfigurationForm.js';
import type { State } from '../../selfservice/common/store.js';

export const VirtualCatalogSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { billingAccounts } = useLoaderData() as CatalogSummaryLoaderData;
  const params: ReadyFormValues = location.state;
  const catalog = params.catalog;
  const [loading, setLoading] = useState<'publish' | 'draft' | null>(null);
  const virtualCatalogs = useSelector((state: State) => state.selfservice?.virtualCatalogs, deepEqual);
  const baHeaders = (billingAccounts.searchResults ?? []).map(e => e.result);
  const billingAccount = baHeaders.find(ba => ba.billingAccountId === params.catalog.billingAccountId);
  const inProgressAction = virtualCatalogs?.inProgressAction;

  const createCatalog = (
    cat: Partial<Catalog>,
    pro: Record<string, Array<CatalogProduct>>,
    isPublish: boolean,
    vCat?: VirtualCatalog
  ) => {
    const products = Object.entries(pro || {}).flatMap(([, value]) => value.map(item => item.code));
    const productCategories = Object.entries(pro || {}).flatMap(([key]) => key as OnlineModelCategory);

    dispatch(
      upsertVirtualCatalog(
        {
          ...cat,
          products,
          productCategories,
        },
        isPublish,
        vCat
      )
    );
  };

  const submitCatalog = async (isPublish: boolean) => {
    try {
      const billingAccountId = await createContactsAndBillingAccount(params);
      const cat = { ...params.catalog, billingAccountId };
      createCatalog(cat, params.products!, isPublish, params.virtualCatalog);
    } catch (err) {
      dispatch(startNotification(t.RQA1(savingTheDataFailedMsg), 'error'));
    } finally {
      setLoading(null);
    }
  };

  return (
    <div>
      <CL.Description
        columns={1}
        items={[
          {
            title: t.M6TP(nameOfCatalogMsg),
            description: catalog.name,
          },
          {
            title: t.R6HP(productTypeMsg),
            description: catalog.productType && productTypeDetails()[catalog.productType].displayValue,
          },
          ...(catalog.contractPeriod
            ? [
                {
                  title: t.ULI0(contractPeriodMsg),
                  description: `${catalog.contractPeriod} ${t.XXVX(monthMsg)}`,
                },
              ]
            : []),
          ...(catalog.corporateShare !== undefined
            ? [
                {
                  title: `${t.H8Q4(companysShareOfMonthlyFeeMsg)} (${t.S8TX(alvZeroMsg)})`,
                  description: getCorporateShareAsText(catalog.corporateShare),
                },
              ]
            : []),
          {
            title: t.QSXP(damageInsuranceMsg),
            description:
              catalog.damageInsurance && catalog.damageInsurance.length > 0
                ? getDamageInsuranceDisplayableItems(catalog.damageInsurance)
                : t.MUF5(notInUseMsg),
          },
          {
            title: t.Q03S('Device enrollment program'),
            description: catalog.enrollmentProgramConsent
              ? `${t.V34H(inUseMsg)}, alias: ${catalog.enrollmentProgramAlias || '-'}`
              : t.MUF5(notInUseMsg),
          },
          {
            title: t.C8DA(catalogCorporateMessageLabelMsg),
            description: catalog.corporateMessage ? catalog.corporateMessage : t.MUF5(notInUseMsg),
          },
          {
            title: t.RXBO('The order acceptor needs to select the invoicing agreement when they approve an order'),
            description: catalog.approverMustSelectBillingAccount ? t.V34H(inUseMsg) : t.MUF5(notInUseMsg),
          },
          ...(params.catalog.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && params.billingAccount
            ? [
                {
                  title: t.IFT9(billingAccountMsg),
                  description: <BillingAccountInfo billingAccount={params.billingAccount} />,
                },
              ]
            : []),
          ...(params.catalog.billingAccountId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && billingAccount
            ? [
                {
                  title: billingAccount ? t.IFT9(billingAccountMsg) : '',
                  description: billingAccount ? <BillingAccountHeaderInfo billingAccountHeader={billingAccount} /> : '',
                },
              ]
            : []),
        ]}
      />
      <VirtualCatalogProductsSummary products={params.products!} />

      <div className={dsClass.PADDING_VERTICAL_2}>
        <WizardActions
          isLoading={loading === 'draft' || inProgressAction === InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT}
          onBackClick={() => {
            navigate(paths.COMPANY_INFO_CATALOG_LIST_PRODUCTS, { state: location.state });
          }}
          onForwardClick={() => {
            setLoading('publish');
            submitCatalog(true);
          }}
          forwardButtonText={t.JC06('publish')}
          backButtonText={t.B2V1(cancelMsg)}
          submitting={loading === 'publish'}
        />
      </div>

      <div className={`${dsClass.TEXT_ALIGN_RIGHT} ${dsClass.PADDING_VERTICAL_2}`}>
        <CL.Button
          size="l"
          color="light"
          loading={loading === 'draft' || inProgressAction === InProgressCatalogAction.UPSERT_VIRTUAL_CATALOG_DRAFT}
          disabled={loading === 'publish' || inProgressAction === InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT}
          onClick={() => {
            setLoading('draft');
            submitCatalog(false);
          }}
        >
          {t.TJKD('Save draft')}
        </CL.Button>
        <p className={dsClass.TEXT_S}>
          {t.K6XH('You can save the list as a draft and publish it later.')}
          <br />{' '}
          {t.BZWY('Please note that if you have added a new billing agreement, it will be available immediately.')}
        </p>
      </div>
    </div>
  );
};
