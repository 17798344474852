import {
  EmployeeOrdersBreadcrumb,
  EmployeeOrdersNavi,
  EmployeeOrdersWrapper,
} from '../../../../../components/EmployeeOrdersWrapper/EmployeeOrdersWrapper.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import {
  SubscriptionActionList,
  SubscriptionActionListType,
} from '../../../../../components/SubscriptionActionList/SubscriptionActionList.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getFilteredSubscriptionAction } from '../../../../../common/utils/employeeUtils.js';
import { loadCustomerOrders, loadSubscriptionActions } from '../../../../../selfservice/actions/index.js';
import { omaElisaForEmployeeMsg, orderRequestsMsg, t } from '../../../../../common/i18n/index.js';
import { onLoadBillingAccounts } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const ChangeRequestsPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const subscriptionActions = useSelector((state: State) => state.selfservice?.subscriptionActions);
  const filteredSubscriptionAction = getFilteredSubscriptionAction(subscriptionActions);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.Y29L(orderRequestsMsg)]);

  useEffect(() => {
    onLoadBillingAccounts(dispatch);
    dispatch(loadCustomerOrders());
    dispatch(loadSubscriptionActions());
  }, [dispatch, pathname]);

  return (
    <EmployeeOrdersWrapper>
      <EmployeeOrdersBreadcrumb />
      <EmployeeOrdersNavi />
      <Grid>
        <SubscriptionActionList
          items={filteredSubscriptionAction?.searchResults?.map(subscriptionAction => subscriptionAction.result)}
          onClickSubscriptionAction={(id: string) => {
            navigate(generatePath(paths.EMPLOYEE_CHANGE_REQUEST_ORDER, { subscriptionId: id }));
          }}
          onLoadMoreSubscriptionActions={() => dispatch(loadSubscriptionActions())}
          onSearchSubscriptionActions={newSearchValue => dispatch(loadSubscriptionActions({ search: newSearchValue }))}
          onSortSubscriptionActions={newSort => dispatch(loadSubscriptionActions({ sort: newSort }))}
          search={filteredSubscriptionAction?.search}
          sort={filteredSubscriptionAction?.sort}
          loadMore={
            subscriptionActions?.searchResults?.length !== undefined &&
            subscriptionActions?.total !== undefined &&
            subscriptionActions?.searchResults?.length < subscriptionActions?.total
          }
          type={SubscriptionActionListType.APPROVE_SUBSCRIPTIONS}
          searchResults={filteredSubscriptionAction?.searchResults}
        />
      </Grid>
    </EmployeeOrdersWrapper>
  );
};
