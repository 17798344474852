import { GutterStyle, SelectOneList } from '../../SelectOneList/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';
import type { SelectOneListItemProps } from '../../SelectOneList/index.js';
import type { Subscription } from '../../../generated/api/subscription.js';

export const PbxSolutionSelect = ({
  pbxSolutions,
  isRing,
  onSelect,
  selectedValue,
}: {
  pbxSolutions: Subscription[];
  isRing: boolean;
  onSelect: (value: string) => void;
  selectedValue: string;
}) => {
  if (pbxSolutions.length <= 1) {
    return null;
  }
  const pbxSolutionHeader = isRing ? t.R6GH('Selected Ring service') : t.WA19('Vakio solution:');

  const getOption = (label: string, value: string): SelectOneListItemProps => ({
    element: <div className={`of-attach-pbx-content__selection ${dsClass.FONT_WEIGHT_600}`}>{label}</div>,
    isSelected: selectedValue === value,
    onClick: () => onSelect(value),
  });

  return (
    <div className={dsClass.MARGIN_TOP_4}>
      <div className={dsClass.FONT_SIZE_LARGE}>{pbxSolutionHeader}</div>
      <SelectOneList
        disableIcon={true}
        gutterStyle={GutterStyle.Minimal}
        maxItemsAsideDesktop={4}
        maxItemsAsideLaptop={4}
        maxItemsAsideTablet={3}
        options={pbxSolutions.map(pbxSolution =>
          getOption(pbxSolution.subscriptionUserFriendlyId ?? '', pbxSolution.subscriptionId)
        )}
      />
    </div>
  );
};
