import { type Choices, type SubscriptionTypes } from './OpenFormAnswers.js';
import { type MutableRefObject } from 'react';
import { type OpenFormActions, type OpenFormState } from './OpenFormReducer.js';
import { type OpenFormStorage } from './OpenFormStorage.js';
import {
  fetchAddressSearch,
  fetchBillChannels,
  fetchBillingAccounts,
  fetchHolidays,
  fetchOpenForm,
  fetchOpenFormAccount,
  fetchOpenFormAddress,
  fetchOpenFormList,
  fetchOpenFormProductAvailability,
  fetchOpenFormSummary,
  parameterizedSearchContacts,
  postOpenFormCustomerNeed,
} from '../../common/fetch.js';
import type { FormId, ListId } from '../../common/constants/pathInterfaces.js';
import type { OpenFormCustomerNeedRequest } from '../../generated/api/openFormCustomerNeedRequest.js';
import type { SourceSystem } from '../../generated/api/sourceSystem.js';

export class OpenFormAsync<Resolved = unknown> {
  static readonly cache = new Map<string, Promise<unknown>>();
  static actions: OpenFormActions;
  static storage: OpenFormStorage;
  static #state: MutableRefObject<OpenFormState>;

  private readonly promise: (load?: string | true) => Promise<void | Resolved>;
  private result?: (value?: Resolved) => void | Resolved;
  private error?: (reason?: Response) => void;
  private last?: () => void;

  private awaited?: Resolved | Promise<void | Resolved>;
  private id: string;
  private load?: boolean;
  private save?: boolean;

  private constructor(
    readonly ctrl = new AbortController(),
    async: (_: AbortSignal) => Promise<void | Resolved>
  ) {
    this.id = String(async);
    this.promise = (load?: string | true) => {
      (this.load = !!load) && OpenFormAsync.actions.loading({ id: this.id, load });
      return (this.awaited = async(this.ctrl.signal)
        .then(this.settled().result)
        .catch(this.failed().error)
        .finally(this.done().last));
    };
  }

  static get state(): OpenFormState {
    return this.#state.current;
  }

  static set state(state: MutableRefObject<OpenFormState>) {
    this.#state = state;
  }

  get evict() {
    return () => OpenFormAsync.cache.delete(this.id);
  }

  static fetchAddressSearch(query: { postalCode: string; query: string; filter: boolean }, ctrl?: AbortController) {
    return new this(ctrl, signal => fetchAddressSearch(query, { signal }));
  }

  static fetchBillChannels(ctrl?: AbortController) {
    return new this(ctrl, signal => fetchBillChannels({ signal }));
  }

  static fetchBillingAccounts({ sourceSystem }: { sourceSystem: SourceSystem }, ctrl?: AbortController) {
    return new this(ctrl, signal =>
      Promise.allSettled([
        fetchBillingAccounts({ sourceSystem }, { signal }),
        fetchBillingAccounts({ sourceSystem, useSearchService: true }, { signal }),
      ])
    );
  }

  static fetchContacts(query: { searchTerm: string }, ctrl?: AbortController) {
    return new this(ctrl, signal => parameterizedSearchContacts(query, { signal }));
  }

  static fetchHolidays(ctrl?: AbortController) {
    return new this(ctrl, signal => fetchHolidays({ signal }));
  }

  static fetchOpenForm(query: FormId, ctrl?: AbortController) {
    return new this(ctrl, signal => fetchOpenForm(query, { signal }));
  }

  static fetchOpenFormAccount(ctrl?: AbortController) {
    return new this(ctrl, signal => fetchOpenFormAccount({ signal }));
  }

  static fetchOpenFormList(query: ListId, ctrl?: AbortController) {
    return new this(ctrl, signal => fetchOpenFormList(query, { signal }));
  }

  static fetchOpenFormProductAvailability(
    query: { addressId: string; formId: string; subscriptionTypes: SubscriptionTypes },
    ctrl?: AbortController
  ) {
    return new this(ctrl, signal =>
      Promise.allSettled([
        fetchOpenFormAddress({ addressId: query.addressId }, { signal }),
        fetchOpenFormProductAvailability(query, { signal }),
      ])
    );
  }

  static fetchOpenFormSummary(query: { choices: Choices }, ctrl?: AbortController) {
    return new this(ctrl, signal => fetchOpenFormSummary(query, { signal }));
  }

  static postOpenFormCustomerNeed(query: OpenFormCustomerNeedRequest, ctrl?: AbortController) {
    return new this(ctrl, signal => postOpenFormCustomerNeed(query, { signal }));
  }

  cache(...key: (boolean | null | number | string)[]) {
    (this.id += String(key.length && key)) && (this.save = true);
    return this;
  }

  execute(load?: string | true) {
    this.save
      ? OpenFormAsync.cache.has(this.id)
        ? OpenFormAsync.cache.get(this.id)!.then(this.result)
        : OpenFormAsync.cache.set(this.id, this.promise(load))
      : this.promise(load);
    return this;
  }

  settled(resolved?: (value: Resolved) => void) {
    this.result ??= (value?: Resolved) => {
      resolved && !this.ctrl.signal.aborted && value !== undefined && resolved(value);
      return this.awaited ? (this.awaited = value) : value;
    };
    return this;
  }

  failed(rejected?: (reason: string) => void) {
    this.error ??= (reason?: Response) => {
      rejected && !this.ctrl.signal.aborted && (reason?.text?.().then(rejected) || rejected(String(reason)));
      this.evict();
    };
    return this;
  }

  done(completed?: (value?: Resolved) => void) {
    this.last ??= () => {
      completed?.(this.awaited as Resolved) || delete this.awaited;
      this.load && OpenFormAsync.actions.loading({ id: this.id, load: undefined });
    };
    return this;
  }
}
