import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridBlock, OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import {
  confirmationSMSMsg,
  orderSubmittedForProcessingMsg,
  returnToCustomerViewMsg,
  salesFormsEntryReminderMsg,
  salesFormsMsg,
  t,
} from '../../../common/i18n/index.js';
import { isIframe } from '../OpenFormUtils.js';
import { useOpenFormCanvas } from '../OpenFormProvider.js';

export const OpenFormThankYou = () => {
  const canvas = useOpenFormCanvas();

  return (
    <OpenFormBar backText={t.QF2O(salesFormsMsg)}>
      <OpenFormGridBlock justifyCenterOuter={true}>
        <OpenFormGridCol colWidth={8} className="of-openform__thank-you__container">
          <h2 className="of-openform__thank-you__h2">{t.SOQ1(orderSubmittedForProcessingMsg)}</h2>

          <div className="of-openform__thank-you__paragraph-container">
            <OpenFormGridRow className="of-openform__thank-you__paragraph-row" justifyCenter={false}>
              <OpenFormIcon size="m" icon="check" className="of-openform__thank-you__check" />
              <span>{t.R89M(confirmationSMSMsg)}</span>
            </OpenFormGridRow>
            <OpenFormGridRow className="of-openform__thank-you__paragraph-row" justifyCenter={false}>
              <OpenFormIcon size="m" icon="information" type="regular" className="of-openform__thank-you__check" />
              <span>{t.LDN7(salesFormsEntryReminderMsg)}</span>
            </OpenFormGridRow>
          </div>

          <OpenFormGridRow className="of-openform__thank-you__picture ds-margin-vertical--4"></OpenFormGridRow>

          {!isIframe() ? null : (
            <OpenFormButton
              className="of-openform__view__thank-you--button"
              color="primary"
              text={t.OW3C(returnToCustomerViewMsg)}
              icon={<OpenFormIcon icon="check" />}
              size="l"
              disabled={false}
              onClick={() => canvas.postMessage({ name: 'OpenFormCanvasEvent', action: 'redirectToAccount' })}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridBlock>
    </OpenFormBar>
  );
};
