import * as CL from '@design-system/component-library';
import { Link, generatePath } from 'react-router-dom';
import { SubscriptionType } from '../../generated/api/subscriptionType';
import { dsClass } from '../../common/constants/dsClasses';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { getCategoryBySubscriptionType } from '../../public/common/util/category';
import { getDeviceTypeText } from '../SubscriptionDetails/subscriptionDetailsCommon';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils';
import { isEppDeviceSubscriptionExpiring } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils';
import { myDevicesAndSubscriptionsMsg, servicesNotShownMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useState } from 'react';
import type { EmployeeSubscription } from '../../common/types/subscription';
import type { Subscription } from '../../generated/api/subscription';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction';

export interface SubscriptionRowProps {
  subscription: Subscription;
  endingSoon: boolean;
}

interface DevicesAndSubscriptionsListProps {
  subscriptions?: EmployeeSubscription[];
  subscriptionActions?: SubscriptionAction[];
}

const getSubscriptionTypeText = (subscription: Subscription) => {
  const type = subscription.subscriptionType || SubscriptionType.MOBILE;
  const category = getCategoryBySubscriptionType(type);
  if (category === 'device') {
    return getDeviceTypeText(subscription.subscriptionSubType || '');
  }

  return category;
};

const getSubscriptionPath = (subscription: Subscription): string => {
  const type = subscription.subscriptionType || SubscriptionType.MOBILE;
  const category = getCategoryBySubscriptionType(type);

  const categoryPathMapping: Record<string, string> = {
    device: paths.EMPLOYEE_DEVICE,
    broadband: paths.EMPLOYEE_BROADBAND_SUBSCRIPTION,
    voice: paths.EMPLOYEE_VOICE_SUBSCRIPTION,
  };

  const pathTemplate = categoryPathMapping[category] || paths.EMPLOYEE_SUBSCRIPTIONS;

  return pathTemplate === paths.EMPLOYEE_SUBSCRIPTIONS
    ? generatePath(pathTemplate)
    : generatePath(pathTemplate, { subscriptionId: subscription.subscriptionId });
};

const getSubscriptionDetails = (subscription: Subscription) => {
  if (subscription.subscriptionType === SubscriptionType.DEVICE) {
    return getDeviceTypeText(subscription.subscriptionSubType || '');
  }

  return formatPhoneNumber(subscription.subscriptionUserFriendlyId || '');
};

const RowIcon = ({ subscription }: { subscription: Subscription }) => {
  const typeText = getSubscriptionTypeText(subscription);

  const iconMapping: Record<string, string> = {
    [t.OWF1('Mobile phone')]: 'ea-picto--phone',
    [t.IHS4('Computer')]: 'ea-picto--laptop-filled',
    broadband: 'ea-picto--broadband',
    voice: 'ea-picto--sim',
    subscription: 'ea-picto--sim',
  };

  const rowIcon = iconMapping[typeText] || 'ea-picto--generic-service-filled';

  return <div className={rowIcon} />;
};

export const SubscriptionRow = ({ subscription, endingSoon }: SubscriptionRowProps) => (
  <Link className="of-employee-subscription-row" to={getSubscriptionPath(subscription)} role="button" tabIndex={0}>
    <div className="of-employee-subscription-row-icon">
      <RowIcon subscription={subscription} />
    </div>
    <div className="of-employee-subscription-row-details">
      <span className="of-employee-subscription-row-name">{subscription?.subscriptionName}</span>
      <p className="of-employee-subscription-row-details-text">{getSubscriptionDetails(subscription)}</p>
      {endingSoon && <CL.Badge type="text" color="orange" text={t.TJ9N('Contract expiring')} />}
    </div>
    <div className="ea-icon ea-icon--arrow-right" />
  </Link>
);

export const SubscriptionsList = ({ subscriptions, subscriptionActions }: DevicesAndSubscriptionsListProps) => {
  const MAX_SUBSCRIPTIONS_DISPLAYED = 5;
  const [showAll, setShowAll] = useState(false);

  const subscriptionsCount = subscriptions?.length || 0;

  const subscriptionsToShow = showAll ? subscriptions : subscriptions?.slice(0, MAX_SUBSCRIPTIONS_DISPLAYED);

  return (
    <>
      <h3>{t.YRZJ(myDevicesAndSubscriptionsMsg)}</h3>
      {subscriptionsCount === 0 ? (
        <p>{t.XVCP(servicesNotShownMsg)}</p>
      ) : (
        <>
          {subscriptionsToShow?.map(subscription => (
            <SubscriptionRow
              key={subscription.subscriptionId}
              subscription={subscription}
              endingSoon={
                isEppDeviceSubscriptionExpiring(subscription) &&
                !getSubscriptionStatus(subscription, subscriptionActions).pendingActions
              }
            />
          ))}
          {subscriptionsCount > MAX_SUBSCRIPTIONS_DISPLAYED && (
            <button
              onClick={() => setShowAll(!showAll)}
              className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_LINK}`}
            >
              {showAll ? t.DDHX('Show Less') : t.SF4C('Show All')}
            </button>
          )}
        </>
      )}
    </>
  );
};
