import { InputComponent } from '../components/InputComponent.js';
import { NAME_FORMAT_REGEX, NAME_WITH_SPECIAL_CHARACTERS_FORMAT_REGEX } from '../../utils/nameUtils.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, nameMsg, t } from '../../../common/i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const Name = ({
  name = 'name',
  label = t.VGFI(nameMsg),
  placeholder = t.VGFI(nameMsg),
  required = true,
  disabled = false,
  allowSpecialCharacters = false,
  ...rest
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    const nameFormatRegex = allowSpecialCharacters ? NAME_WITH_SPECIAL_CHARACTERS_FORMAT_REGEX : NAME_FORMAT_REGEX;
    if (required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    } else if (!nameFormatRegex.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      {...rest}
    />
  );
};
