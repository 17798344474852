import { OpenFormAnswers } from '../OpenFormAnswers.js';
import { type OpenFormAsync } from '../OpenFormAsync.js';
import { type UseFormReturn, useForm } from 'react-hook-form';
import { useEffect } from 'react';

export const setValueOptions = { shouldDirty: true, shouldTouch: true, shouldValidate: true } as const;

export const useOpenFormMethods = (async: typeof OpenFormAsync): UseFormReturn => {
  const methods = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

  useEffect(() => {
    const { unsubscribe } = methods.watch((data, { name }) => {
      if (name) {
        const [guid, key] = name.split('.', 2);
        if (!key) {
          async.actions.choices({ guid, value: data[guid] });
        } else if (OpenFormAnswers.isContext(key)) {
          async.actions.context({ guid, key, patch: data[guid]?.[key] });
        }
      }
    });
    return () => unsubscribe();
  }, [async, methods]);

  return methods;
};
