import * as CL from '@design-system/component-library';
import { type ControllerFieldState, type FieldValues, type UseFormStateReturn } from 'react-hook-form';
import { Input } from '../../../components/Input/Input.js';
import { type ReactNode } from 'react';
import { fieldCantBeEmptyMsg, t } from '../../i18n';
import { useController, useFormContext } from 'react-hook-form';

export interface InputComponentProps {
  autocomplete?: string;
  children?: ReactNode;
  className?: string;
  disabled: boolean;
  error?: (fieldState: ControllerFieldState, formState: UseFormStateReturn<FieldValues>) => string | undefined;
  hideLabel?: boolean;
  hint?: string | JSX.Element;
  label?: string;
  maxLength?: number;
  min?: number;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  required: boolean;
  tooltip?: string;
  type: 'text' | 'password' | 'number' | 'time' | 'hidden';
  validate: (value: string, values?: object) => string | undefined;
  wrapperClassName?: string;
  allowSpecialCharacters?: boolean;
}

export type OptionalInputComponentProps = Partial<InputComponentProps>;

const getTextError = (
  fieldState: ControllerFieldState,
  formState: UseFormStateReturn<FieldValues>,
  readonly?: boolean
) => {
  if (readonly) {
    return;
  }
  return formState.isSubmitted || fieldState.isTouched ? fieldState.error?.message : undefined;
};

const Tooltip = ({ tooltipText }: { tooltipText?: string }) => {
  if (!tooltipText) {
    return null;
  }

  return (
    <>
      <style>{`.custom-icon { width: 20px; height: 20px; vertical-align: text-bottom }`}</style>
      <CL.Tooltip
        triggerElement={<CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="m" />}
        placement="top"
        i18n_tooltip_contentText={tooltipText}
      />
    </>
  );
};

export const InputComponent = (props: InputComponentProps) => {
  const { control } = useFormContext();
  const { hint, label, tooltip, validate, ...rest } = props;
  const {
    autocomplete,
    children,
    className,
    disabled,
    error = getTextError,
    maxLength,
    min,
    name,
    placeholder,
    readonly,
    required,
    type,
    wrapperClassName,
  } = rest;
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: { required: { value: required, message: t.VPVR(fieldCantBeEmptyMsg) }, validate },
  });

  return (
    <Input
      autoComplete={autocomplete}
      className={className}
      disabled={disabled}
      maxLength={maxLength}
      min={min}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      placeholder={placeholder}
      readOnly={readonly}
      ref={field.ref}
      required={required}
      type={type}
      value={field.value || ''}
      error={error(fieldState, formState, readonly)}
      hint={hint}
      label={label}
      tooltip={<Tooltip tooltipText={tooltip} />}
      wrapperClassName={wrapperClassName}
    >
      {children}
    </Input>
  );
};
