import * as CL from '@design-system/component-library';
import * as T from './tooltips/index.js';
import { BarringGroup } from './BarringGroup.js';
import { DialogType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { HierarchicalSelector, type HierarchicalSelectorItem } from '../HierarchicalSelector/HierarchicalSelector.js';
import {
  barrings,
  callBarringCodes,
  dataRoamingBarringCodes,
  getAddOnBarringNameByAddOnGuid,
  roamingBarringCodes,
  smsBarringCodes,
} from '../../common/utils/barringUtils.js';
import {
  barringsValidationFailed,
  changeSubscriptionAddons,
  closeDialog,
  showDialog as showDialogAction,
} from '../../selfservice/actions/index.js';
import {
  blockAllDataMsg,
  blockAllUseAbroadMsg,
  blockAllUseOutsideEuMsg,
  blockDataAbroadMsg,
  blockDataOutsideEuMsg,
  callBarringsMsg,
  cancelMsg,
  confirmMsg,
  dataBarringMsg,
  entertainmentBarringMsg,
  generalServiceBarringMsg,
  phoneTrackingMsg,
  premiumRateBarringMsg,
  serviceBarringMsg,
  smsBarringsMsg,
  t,
  useAbroadMsg,
} from '../../common/i18n/index.js';
import {
  createInitialBarringFormValues,
  getSubscriptionAddOnBarrings,
  getTextForActivatingBarring,
  isAvailable,
  isBarredByDefault,
  resolveBarringAvailabilities,
} from './barringAccordionUtil.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getAssociationsAvailability,
  getRequiredDependencyActions,
} from '@onlinefirst/cloudsense-add-on-dependency-engine';
import { isDefined } from '../../common/utils/objectUtils.js';
import { openingFeesAddonCodes } from '../../common/utils/addOnUtils.js';
import { suspendedCommercialProductCodes } from '../../common/utils/commercialProductUtils.js';
import { useDispatch } from 'react-redux';
import type { AddOn } from '../../generated/api/addOn.js';
import type {
  AssociationRecord,
  DependencyActions,
  DependencyRecord,
} from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { BarringGroupAvailability } from './barringAccordionUtil.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionAddOnBarrings } from '../../common/types/barring.js';
import type { SubscriptionDetails } from '../../generated/api/subscriptionDetails.js';

export interface BarringsEditorProps {
  addOnAssociations: AssociationRecord[];
  addOnDependencies: DependencyRecord[];
  barringGroupAvailabilities: BarringGroupAvailability;
  details: SubscriptionDetails;
  subscription: Subscription;
  onEditCompleted: () => void;
}

const Icon = ({ showDialog }: { showDialog: () => void }) => {
  return (
    <div className="of-barrings-content__icon">
      <CL.Icon
        icon="information"
        size="m"
        type="regular"
        // @ts-ignore
        onClick={showDialog}
      />
    </div>
  );
};

export const BarringsEditor = ({
  addOnAssociations,
  addOnDependencies,
  barringGroupAvailabilities,
  details,
  subscription,
  onEditCompleted,
}: BarringsEditorProps) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialogAction(params));
  const closeModal = () => dispatch(closeDialog());
  const availableAddOnCodes =
    addOnAssociations
      ?.filter(association => association.cspmb__price_item__c === subscription.commercialProductId)
      ?.map(association => association.cspmb__add_on_price_item__r.guid__c) ?? [];
  const selectedAddOnCodes = details?.selectedAddOns?.map(addOn => addOn.addOnCode) ?? [];

  const initialValues = createInitialBarringFormValues(availableAddOnCodes, details?.selectedAddOns);
  const methods = useForm<{ [key: string]: boolean }>({ values: initialValues });
  const formValues = methods.watch();
  const barringAvailabilities = resolveBarringAvailabilities(availableAddOnCodes);

  const disableAllDataBarringChanges =
    subscription.commercialProductCode && suspendedCommercialProductCodes.includes(subscription.commercialProductCode);
  const dataRoamingBarring =
    selectedAddOnCodes.find(code => dataRoamingBarringCodes.includes(code)) || barrings.DATA_DENIED.code;
  const roamingBarring =
    selectedAddOnCodes.find(code => roamingBarringCodes.includes(code)) || barrings.ALLOWED_FINLAND.code;

  const subscriptionAddOnBarrings: SubscriptionAddOnBarrings = {
    callBarring: selectedAddOnCodes.find(code => callBarringCodes.includes(code)),
    callBarringAllowedFinland: selectedAddOnCodes.find(code => code === barrings.CALLS_ALLOWED_FINLAND.code),
    dataRoamingBarring,
    roamingBarring,
    dataVartija: selectedAddOnCodes.find(code => code === barrings.DATAVARTIJA.code),
    locatingBarring: selectedAddOnCodes.find(code => code === barrings.DENY_LOCATING.code),
    smsBarring: selectedAddOnCodes.find(code => smsBarringCodes.includes(code)),
  };

  const getSelectedAssociationCodes = (
    newSubscriptionAddOnBarrings: SubscriptionAddOnBarrings,
    subscriptionAddons?: AddOn[]
  ): string[] => {
    const addOnConstant = 'cspmb__add_on_price_item__r';
    const commercialProductIdConstant = 'cspmb__price_item__c';
    const idConstant = 'id';
    const guidConstant = 'guid__c';

    const selectedAddOnBarringCodes = [
      newSubscriptionAddOnBarrings.callBarring,
      newSubscriptionAddOnBarrings.callBarringAllowedFinland,
      newSubscriptionAddOnBarrings.smsBarring,
      newSubscriptionAddOnBarrings.dataRoamingBarring,
      newSubscriptionAddOnBarrings.dataVartija,
      newSubscriptionAddOnBarrings.roamingBarring,
    ];

    const subscriptionAddonCodes = subscriptionAddons
      ? subscriptionAddons
          ?.filter(addon => !openingFeesAddonCodes.includes(addon.addOnCode))
          .map(addon => addon.addOnCode)
      : [];

    const allAddonCodes = subscriptionAddons
      ? selectedAddOnBarringCodes.concat(subscriptionAddonCodes)
      : selectedAddOnBarringCodes;

    return allAddonCodes
      .filter(value => value !== undefined)
      .map((code: string) =>
        addOnAssociations.find(
          association =>
            association[commercialProductIdConstant] === subscription.commercialProductId &&
            association[addOnConstant] !== undefined &&
            association[addOnConstant][guidConstant] === code
        )
      )
      .filter(addonAssociation => addonAssociation !== undefined)
      .map((addonAssociation: AssociationRecord) => addonAssociation[idConstant]);
  };

  // Uses the infamous cloudsense dependency library to validate
  const validateBarringSelections = (
    newSubscriptionAddOnBarrings: SubscriptionAddOnBarrings,
    subscriptionAddons?: AddOn[]
  ): { dependencyActions: DependencyActions; selectedAndExcluded: string[] } => {
    const selectedAddOnBarringCodes = [
      newSubscriptionAddOnBarrings.callBarring,
      newSubscriptionAddOnBarrings.callBarringAllowedFinland,
      newSubscriptionAddOnBarrings.smsBarring,
      newSubscriptionAddOnBarrings.dataRoamingBarring,
      newSubscriptionAddOnBarrings.dataVartija,
      newSubscriptionAddOnBarrings.roamingBarring,
    ].filter(isDefined);

    const selectedAssociationCodes = getSelectedAssociationCodes(newSubscriptionAddOnBarrings, subscriptionAddons);

    const availabilityInfo = getAssociationsAvailability(
      subscription.commercialProductId!,
      addOnAssociations as AssociationRecord[],
      addOnDependencies as DependencyRecord[],
      selectedAssociationCodes
    );

    const dependencyActions = getRequiredDependencyActions(
      subscription.commercialProductId!,
      addOnAssociations as AssociationRecord[],
      addOnDependencies as DependencyRecord[],
      selectedAssociationCodes
    );

    const excludedAssociations = availabilityInfo.filter(availability => Boolean(availability.isExcluded));
    const selectedAndExcluded = selectedAddOnBarringCodes.filter(
      selectedAddOnBarringCode =>
        selectedAddOnBarringCode &&
        excludedAssociations.some(
          excludedAssoc => excludedAssoc.assoc.cspmb__add_on_price_item__r.guid__c === selectedAddOnBarringCode
        )
    );

    return {
      dependencyActions,
      selectedAndExcluded,
    };
  };

  const getMissingAndExcludedAddons = (
    newSubscriptionAddOnBarrings: SubscriptionAddOnBarrings,
    subscriptionAddons?: AddOn[]
  ): {
    missingAddons: AddOn[];
    removedAddons: string[];
  } => {
    const missingAddons: AddOn[] = [];
    const selectedAssociationCodes = getSelectedAssociationCodes(newSubscriptionAddOnBarrings, subscriptionAddons);
    const dependencyActions = getRequiredDependencyActions(
      subscription.commercialProductId!,
      addOnAssociations as AssociationRecord[],
      addOnDependencies as DependencyRecord[],
      selectedAssociationCodes
    );

    if (dependencyActions.dependentAddOnsIdToAdd) {
      const missing = addOnAssociations
        .filter(assoc => dependencyActions.dependentAddOnsIdToAdd.includes(assoc.cspmb__add_on_price_item__c))
        .filter(assoc => assoc.cspmb__price_item__c === subscription.commercialProductId)
        .map(assoc => ({
          addOnCode: assoc.cspmb__add_on_price_item__r.guid__c,
          addOnGroup: assoc.cspmb__group__c,
          addOnId: assoc.cspmb__add_on_price_item__c,
          addOnProductName: assoc.cspmb__add_on_price_item__r.name,
          addOnType: assoc.cspmb__group__c,
        }));

      missingAddons.push(...missing);
    }

    return {
      missingAddons,
      removedAddons: dependencyActions.dependentAddOnsIdToDelete,
    };
  };

  const areBarringSelectionsValid = (validationInfo: {
    dependencyActions: DependencyActions;
    selectedAndExcluded: string[];
  }) => {
    const areThereSelectedExclusions = validationInfo.selectedAndExcluded.length > 0;
    const areThereUnmetDependencies =
      validationInfo.dependencyActions.dependentAddOnsIdToDelete.length < 1 &&
      validationInfo.dependencyActions.dependentAddOnsIdToAdd.length < 1;
    return !areThereSelectedExclusions && areThereUnmetDependencies;
  };

  const saveBarringSettings = (selectedBarrings: SubscriptionAddOnBarrings) => {
    const dependentAddons = getMissingAndExcludedAddons(selectedBarrings, subscription.details?.selectedAddOns);

    const subscriptionAddons = subscription.details?.selectedAddOns ?? [];

    if (dependentAddons) {
      subscriptionAddons.push(
        ...[...dependentAddons.missingAddons].filter(addOn => !dependentAddons.removedAddons.includes(addOn.addOnCode))
      );
    }

    const excludedBarringCodes = addOnAssociations
      .filter(assoc => dependentAddons.removedAddons.includes(assoc.cspmb__add_on_price_item__c))
      .filter(assoc => assoc.cspmb__price_item__c === subscription.commercialProductId)
      .map(assoc => assoc.cspmb__add_on_price_item__r.guid__c);

    // Filter out excluded barring codes from subscription addons
    const filteredSubscriptionAddons = subscriptionAddons.filter(
      addon => !excludedBarringCodes.includes(addon.addOnCode)
    );

    // Filter out excluded barring codes from barrings object
    const filteredBarrings: SubscriptionAddOnBarrings = {};
    Object.keys(selectedBarrings).forEach(<K extends keyof SubscriptionAddOnBarrings>(key: K) => {
      const selectedBarring = selectedBarrings[key];
      if (selectedBarring !== undefined && !excludedBarringCodes.includes(selectedBarring)) {
        filteredBarrings[key] = selectedBarring;
      }
    });

    const barringSelectionsValidationInfo = validateBarringSelections(filteredBarrings, filteredSubscriptionAddons);

    if (areBarringSelectionsValid(barringSelectionsValidationInfo)) {
      onShowDialog({
        body: <div className="of-barrings-content__dialog" />,
        confirmButtonText: t.C7R5('OK, CONTINUE'),
        header: t.P9LC('Please note that it may take several minutes to activate the changes.'),
        hideClosingCross: true,
        onConfirm: () => {
          const barringCodes = Object.values(barrings).map(barring => barring.code);
          const selectedBarringCodes = Object.values(selectedBarrings);
          const subscriptionCurrentBarringCodes =
            details.selectedAddOns && details.selectedAddOns.map(addOn => addOn.addOnCode);

          const barringCodesToAdd = selectedBarringCodes.filter(
            barringCode =>
              barringCode &&
              (!subscriptionCurrentBarringCodes || !subscriptionCurrentBarringCodes.includes(barringCode))
          );

          const barringCodesToRemove = subscriptionCurrentBarringCodes?.filter(
            barringCode => barringCodes.includes(barringCode) && !selectedBarringCodes.includes(barringCode)
          );

          dispatch(changeSubscriptionAddons(subscription, barringCodesToAdd, barringCodesToRemove || []));
          onEditCompleted();
        },
        type: DialogType.GENERIC_CONFIRMATION_DIALOG,
      });
    } else {
      dispatch(barringsValidationFailed(JSON.stringify(barringSelectionsValidationInfo)));
      onShowDialog({
        body: <></>,
        header: t.F8DF('An unexpected error occurred when changing barring services.'),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
    }
    return false;
  };

  const callBarringItemsV2 = [
    {
      afterLabelItem: (
        <Icon
          showDialog={() => {
            onShowDialog({
              body: <T.CallBarringsTooltipBody />,
              header: t.QLJY(callBarringsMsg),
              type: DialogType.GENERIC_INFO_DIALOG,
              wide: true,
            });
          }}
        />
      ),
      label: t.OMJ2(premiumRateBarringMsg),
      name: `${barrings.P4.code}`,
    },
    { label: t.K7TH(entertainmentBarringMsg), name: `${barrings.P3.code}` },
    { label: t.Z44Y(serviceBarringMsg), name: `${barrings.P2.code}` },
    { label: t.U2A0(generalServiceBarringMsg), name: `${barrings.P1.code}` },
  ];

  const additionalCallBarringItems = [
    {
      afterLabelItem: (
        <Icon
          showDialog={() => {
            onShowDialog({
              body: <T.CallAbroadBarringTooltipBody />,
              header: t.QLJY(callBarringsMsg),
              type: DialogType.GENERIC_INFO_DIALOG,
              wide: true,
            });
          }}
        />
      ),
      checked: formValues[barrings.CALLS_ALLOWED_FINLAND.code],
      label: getAddOnBarringNameByAddOnGuid(barrings.CALLS_ALLOWED_FINLAND.code),
      name: `${barrings.CALLS_ALLOWED_FINLAND.code}`,
    },
  ];

  const smsBarringItemsV2 = [
    {
      afterLabelItem: (
        <Icon
          showDialog={() => {
            onShowDialog({
              body: <T.SmsBarringsTooltipBody />,
              header: t.DILB('SMS and mobile payment barrings'),
              type: DialogType.GENERIC_INFO_DIALOG,
              wide: true,
            });
          }}
        />
      ),
      label: t.OMJ2(premiumRateBarringMsg),
      name: `${barrings.T4.code}`,
    },
    { label: t.K7TH(entertainmentBarringMsg), name: `${barrings.T3.code}` },
    { label: t.Z44Y(serviceBarringMsg), name: `${barrings.T2.code}` },
    { label: t.U2A0(generalServiceBarringMsg), name: `${barrings.T1.code}` },
  ];

  const dataRoamingBarringItemsV2 = [
    {
      afterLabelItem: (
        <Icon
          showDialog={() => {
            onShowDialog({
              body: <T.DataRoamingTooltipBody />,
              header: t.CU9Z(dataBarringMsg),
              type: DialogType.GENERIC_INFO_DIALOG,
              wide: true,
            });
          }}
        />
      ),
      label: t.SBCJ(blockDataOutsideEuMsg),
      name: `${barrings.DATA_ALLOWED_EEA.code}`,
      disabled:
        disableAllDataBarringChanges ||
        (isBarredByDefault(barrings.DATA_ALLOWED_EEA.code, barringAvailabilities) &&
          !isAvailable(barrings.DATA_ALLOWED_EEA.code, barringAvailabilities)),
    },
    {
      label: t.L07L(blockDataAbroadMsg),
      name: `${barrings.DATA_ALLOWED_FINLAND.code}`,
      disabled:
        disableAllDataBarringChanges ||
        (isBarredByDefault(barrings.DATA_ALLOWED_FINLAND.code, barringAvailabilities) &&
          !isAvailable(barrings.DATA_ALLOWED_FINLAND.code, barringAvailabilities)),
    },
    {
      label: t.CO7W(blockAllDataMsg),
      name: `${barrings.DATA_DENIED.code}`,
      disabled:
        disableAllDataBarringChanges ||
        (isBarredByDefault(barrings.DATA_DENIED.code, barringAvailabilities) &&
          !isAvailable(barrings.DATA_DENIED.code, barringAvailabilities)),
    },
  ];

  const roamingBarringItemsV2 = [
    {
      label: t.LLHD(blockAllUseOutsideEuMsg),
      name: `${barrings.ALLOWED_EEA.code}`,
      disabled:
        isBarredByDefault(barrings.ALLOWED_EEA.code, barringAvailabilities) &&
        !isAvailable(barrings.ALLOWED_EEA.code, barringAvailabilities),
    },
    {
      label: t.HAWX(blockAllUseAbroadMsg),
      name: `${barrings.ALLOWED_FINLAND.code}`,
      disabled:
        isBarredByDefault(barrings.ALLOWED_FINLAND.code, barringAvailabilities) &&
        !isAvailable(barrings.ALLOWED_FINLAND.code, barringAvailabilities),
    },
  ];

  const dataRoamingBarringAdditionalItems = disableAllDataBarringChanges
    ? undefined
    : [
        {
          afterLabelItem: (
            <Icon
              showDialog={() => {
                onShowDialog({
                  body: <T.DatavartijaTooltipBody />,
                  header: t.CU9Z(dataBarringMsg),
                  type: DialogType.GENERIC_INFO_DIALOG,
                  wide: true,
                });
              }}
            />
          ),
          checked: subscriptionAddOnBarrings.dataVartija === barrings.DATAVARTIJA.code,
          label: getAddOnBarringNameByAddOnGuid(barrings.DATAVARTIJA.code),
          name: `${barrings.DATAVARTIJA.code}`,
        },
      ];

  const locatingBarringItems = [
    {
      label: getAddOnBarringNameByAddOnGuid(barrings.DENY_LOCATING.code),
      name: barrings.DENY_LOCATING.code,
      checked: subscriptionAddOnBarrings.locatingBarring === barrings.DENY_LOCATING.code,
    },
  ];

  const handleRoamingBarringChange = (changedItem: HierarchicalSelectorItem) => {
    if (barrings.ALLOWED_FINLAND.code === changedItem.name && changedItem.checked) {
      // ALLOWED_FINLAND means 'Block all use abroad', then also these need to be selected
      methods.setValue(barrings.DATA_ALLOWED_EEA.code, true);
      methods.setValue(barrings.DATA_ALLOWED_FINLAND.code, true);
    } else if (barrings.ALLOWED_EEA.code === changedItem.name && changedItem.checked) {
      // ALLOWED_EEA means 'Block all use outside the EU and EEA', then also this needs to be checked
      methods.setValue(barrings.DATA_ALLOWED_EEA.code, true);
    }
  };

  const confirmRemoveBarring = ({
    textForActivatingBarring,
    onConfirm,
    onCancel,
  }: {
    textForActivatingBarring: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => {
    onShowDialog({
      body: <div className={dsClass.PADDING_BOTTOM_4} />,
      header: `${t.J8VE('To remove barring, you must allow')} ${textForActivatingBarring}.`,
      onConfirm,
      onCancel,
      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    });
  };

  const handleDataBarringChange = (
    changedItem: HierarchicalSelectorItem,
    previousValues: { [key: string]: boolean }
  ) => {
    if (
      barrings.DATA_ALLOWED_EEA.code === changedItem.name &&
      !changedItem.checked &&
      previousValues[barrings.ALLOWED_EEA.code]
    ) {
      const textForActivatingBarring = getTextForActivatingBarring(barrings.ALLOWED_EVERYWHERE.code);

      return confirmRemoveBarring({
        textForActivatingBarring,
        onConfirm: () => {
          // Turn off the more restrictive barrings
          methods.setValue(barrings.ALLOWED_EEA.code, false);
          methods.setValue(barrings.ALLOWED_FINLAND.code, false);
          closeModal();
        },
        onCancel: () => {
          // Restore the previous values that the hierarchical component toggled
          methods.setValue(barrings.DATA_ALLOWED_EEA.code, previousValues[barrings.DATA_ALLOWED_EEA.code]);
          methods.setValue(barrings.DATA_ALLOWED_FINLAND.code, previousValues[barrings.DATA_ALLOWED_FINLAND.code]);
        },
      });
    }

    if (
      barrings.DATA_ALLOWED_FINLAND.code === changedItem.name &&
      !changedItem.checked &&
      previousValues[barrings.ALLOWED_FINLAND.code]
    ) {
      const textForActivatingBarring = getTextForActivatingBarring(barrings.ALLOWED_EEA.code);

      return confirmRemoveBarring({
        textForActivatingBarring,
        onConfirm: () => {
          // Turn off the more restrictive barrings
          methods.setValue(barrings.ALLOWED_FINLAND.code, false);
          closeModal();
        },
        onCancel: () => {
          // Restore the previous values that the hierarchical component toggled
          methods.setValue(barrings.DATA_ALLOWED_EEA.code, previousValues[barrings.DATA_ALLOWED_EEA.code]);
          methods.setValue(barrings.DATA_ALLOWED_FINLAND.code, previousValues[barrings.DATA_ALLOWED_FINLAND.code]);
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(formData => {
          saveBarringSettings(getSubscriptionAddOnBarrings(formData));
        })}
      >
        <div className="of-barrings-content">
          {barringGroupAvailabilities.areVoiceBarringsAvailable && (
            <BarringGroup id="barring-services-voice">
              <h5>{t.QLJY(callBarringsMsg)}</h5>
              <HierarchicalSelector items={callBarringItemsV2} additionalItems={additionalCallBarringItems} />
            </BarringGroup>
          )}
          {barringGroupAvailabilities.areSmsBarringsAvailable && (
            <BarringGroup id="barring-services-ssm-mms">
              <h5>{t.D2PS(smsBarringsMsg)}</h5>
              <HierarchicalSelector items={smsBarringItemsV2} />
            </BarringGroup>
          )}
          {barringGroupAvailabilities.areDataBarringsAvailable && (
            <BarringGroup id="barring-services-data">
              <h5>{t.CU9Z(dataBarringMsg)}</h5>
              <HierarchicalSelector
                items={dataRoamingBarringItemsV2}
                additionalItems={dataRoamingBarringAdditionalItems}
                onValueChange={handleDataBarringChange}
              />
            </BarringGroup>
          )}
          {barringGroupAvailabilities.areRoamingBarringsAvailable && (
            <BarringGroup id="barring-services-roaming">
              <h5>{t.R805(useAbroadMsg)}</h5>
              <HierarchicalSelector items={roamingBarringItemsV2} onValueChange={handleRoamingBarringChange} />
            </BarringGroup>
          )}
          {barringGroupAvailabilities.areLocatingBarringsAvailable && (
            <BarringGroup id="barring-services-locating">
              <h5>{t.EGQX(phoneTrackingMsg)}</h5>
              <HierarchicalSelector items={locatingBarringItems} />
            </BarringGroup>
          )}
          <div className="of-barrings-content__actions">
            <CL.Button color="primary" type="submit">
              {t.QVYK(confirmMsg)}
            </CL.Button>
            <CL.Button color="light" onClick={onEditCompleted}>
              {t.B2V1(cancelMsg)}
            </CL.Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
