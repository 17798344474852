import { type FieldErrors } from 'react-hook-form';
import { OFPageType } from '../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../generated/api/oFQuestionType.js';
import { OpenFormMap } from './OpenFormMap.js';
import { OpenFormRules } from './OpenFormRules.js';
import { any, has } from './OpenForm.js';
import { getDate, merge } from './OpenFormUtils.js';
import { noSubscriptionsAvailableMsg, t } from '../../common/i18n/index.js';
import type { Address } from '../../generated/api/address.js';
import type { BillingAccount } from '../../generated/api/billingAccount.js';
import type { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import type { OFProductAvailability } from '../../generated/api/oFProductAvailability.js';
import type { OpenFormChoice } from '../../generated/api/openFormChoice.js';
import type { OpenFormCustomerNeedQuestion } from '../../generated/api/openFormCustomerNeedQuestion.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../generated/api/openFormSection.js';
import type { ParameterizedSearchContactResult } from '../../generated/api/parameterizedSearchContactResult.js';

export type AvailabilityAddress = Address & {
  addressId: string;
  addressText: string;
  products: OFProductAvailability[];
};

export type BillingAccountData = {
  accountMasterId?: string;
  billElectronicAddress?: string;
  billElectronicOperator?: string;
  billFormatType?: BillingAccount.BillFormatTypeEnum;
  billLanguage?: 'FI' | 'SV' | 'EN';
  billReceiverEmail?: string;
  billingAccountName?: string;
  billingContactAccountId?: string;
  billingContactEmail?: string;
  billingContactId?: string;
  billingContactName?: string;
  billingContactPhone?: string;
  billingAccountExtensionName?: string;
  customerReference1?: string;
  customerReference2?: string;
  deliveryMethod?: BillingAccountDeliveryMethod;
  payerAddress?: Address;
  payerBusinessId?: string;
  payerName?: string;
  payerNameExtension?: string;
};

export type Choices = string[];

export type Row<
  ChoiceGUID extends string = string,
  HeaderName extends string = string,
  ChoiceValue extends string = string,
> = Record<ChoiceGUID, Record<HeaderName, ChoiceValue>>;

export type SubscriptionTypes = string[];

export type Context = {
  address?: AvailabilityAddress;
  billingAccount?: BillingAccount;
  billingAccountData?: BillingAccountData;
  choices?: Choices;
  contact?: ParameterizedSearchContactResult;
  row?: Row;
  subscriptionTypes?: SubscriptionTypes;
};

export class OpenFormAnswers extends OpenFormMap<Context> {
  readonly visible: <T extends OpenFormChoice | OpenFormQuestion>(target?: T) => boolean;

  constructor(answers?: OpenFormAnswers | [string, Context][]) {
    super(answers);
    this.visible = OpenFormRules.isVisible(this);
  }

  static isContext(key: string): key is keyof Context {
    switch (key) {
      case 'address':
      case 'billingAccount':
      case 'billingAccountData':
      case 'choices':
      case 'contact':
      case 'row':
      case 'subscriptionTypes':
        return true;
      default:
        return false;
    }
  }

  get(target: OpenFormSection | OpenFormQuestion | string | undefined): Context | undefined {
    return target === undefined ? target : typeof target === 'string' ? super.get(target) : super.get(target.guid);
  }

  readonly getAnswers = (question: OpenFormQuestion): OpenFormCustomerNeedQuestion | undefined => {
    const choices = this.get(question)?.choices;
    if (!choices?.length) {
      return undefined;
    }
    switch (question.type) {
      case 'CONTRACT_PERIOD':
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT':
        return { guid: question.guid, answers: choices.map(choice => ({ guid: choice })) };
      case 'ADDITIONAL_INFORMATION_BACKOFFICE':
      case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER':
      case 'BA_SELECTION':
      case 'INSTALLATION_ADDRESS':
      case 'INSTALLATION_CONTACT':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TECHNICAL_CONTACT':
      case 'TIME_OF_DELIVERY':
        return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
      case 'DATE_OF_DELIVERY':
        return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
      case 'FREE_TEXT':
        switch (question.dataType) {
          case 'DATE_FIELD':
            return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
          case 'NUMBER_INTEGER':
            return { guid: question.guid, answers: choices.map(choice => ({ value: Number(choice).toFixed() })) };
          case 'EMAIL':
          case 'PHONE':
          case 'TEXT':
          case 'TIME_FIELD':
            return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
        }
    }
  };

  readonly getAvailable = ({ checkAvailability, choices }: OpenFormQuestion): ((guid: string) => boolean) => {
    if (!checkAvailability) {
      return _ => true;
    }
    const products = super.find(({ address }) => address?.products)?.address?.products;
    if (!products?.some(({ choice, isAvailable }) => isAvailable && choices.some(({ guid }) => choice === guid))) {
      throw new Error(t.OTPK(noSubscriptionsAvailableMsg));
    }
    const available = merge(({ choice, isAvailable }) => ({ [choice]: isAvailable }), ...products);
    return guid => available[guid] === true;
  };

  readonly hasAnswer = (question: OpenFormQuestion) => {
    const choices = this.get(question)?.choices;
    if (!choices?.length) {
      return false;
    }
    switch (question.type) {
      case 'CONTRACT_PERIOD':
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT':
        return choices.every(guid => question.choices.some(has(guid)));
      case 'ADDITIONAL_INFORMATION_BACKOFFICE':
      case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER':
      case 'BA_SELECTION':
      case 'DATE_OF_DELIVERY':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'FREE_TEXT':
      case 'INSTALLATION_ADDRESS':
      case 'INSTALLATION_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TECHNICAL_CONTACT':
      case 'TIME_OF_DELIVERY':
        return choices.every(value => value.length);
    }
  };

  readonly isActive = (section: OpenFormSection) =>
    any(OFPageType.AVAILABILITY_CHECK, OFPageType.ORDER_ENRICHMENT)(section) ||
    section.questions.filter(this.visible).some(q => has(OFQuestionType.FREE_TEXT)(q) || q.choices.some(this.visible));

  readonly isCompleted = (section: OpenFormSection) =>
    section.questions.filter(q => this.visible(q) && q.isMandatory).every(this.hasAnswer);

  readonly isIncomplete = (section: OpenFormSection) =>
    (any(OFPageType.AVAILABILITY_CHECK)(section) && !this.get(section)?.address) ||
    section.questions.filter(this.visible).some(q => q.choices.some(this.visible) && !this.hasAnswer(q));

  readonly nextErrored = (section: OpenFormSection | undefined, errors: FieldErrors) =>
    section?.questions.find(q => this.visible(q) && ((q.isMandatory && !this.hasAnswer(q)) || errors[q.guid]));
}
