// Service names, the same name is used in both languages
export enum MobileBroadbandService {
  LIIKKUVA_WIFI = 'Liikkuva Wifi',
  YRITYSDATA = 'Yritysdata',
}

export enum LiikkuvaWifiOfferCode {
  MBB_1000_M = '19e40786-04a7-e704-f5c5-41c933b7a1de',
  MBB_600_M = 'e212db9f-7c82-a86a-87b5-4c5babc1125e',
  MBB_300_M = '71603324-6b7c-5125-5434-3062a310753f',
}
