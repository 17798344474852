import { ContactType } from '../../../../../generated/api/contactType';
import { MoveContact } from '../../../../../components/MoveContact/MoveContact';
import { generatePath, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isMultiBiz } from '../../../../../common/utils/accountUtils';
import { paths } from '../../../../../common/constants/pathVariables';
import { useAuth } from '../../../AuthProvider';
import { useEffect } from 'react';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { ContactMasterId } from '../../../../../common/constants/pathInterfaces';
import type { MoveContactLoaderData } from '../../../../../common/loaders';

export const CompanyInfoMoveContactPath = () => {
  const { broadbandSubscriptions, contact, deviceSubscriptions, pendingActionsResponse, voiceSubscriptions } =
    useLoaderData() as MoveContactLoaderData;
  const { authenticatedUser } = useAuth();
  const { contactMasterId } = useParams<ContactMasterId>();
  const { search } = useLocation();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      pendingActionsResponse.hasPendingActions ||
      contact.contactType !== ContactType.PERSON ||
      !isMultiBiz(authenticatedUser)
    ) {
      navigate(generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId }) + search, { replace: true });
    }
  }, [
    authenticatedUser,
    contact.contactType,
    contactMasterId,
    navigate,
    pendingActionsResponse.hasPendingActions,
    search,
  ]);

  return (
    <MoveContact
      broadbandSubscriptions={broadbandSubscriptions}
      contact={contact}
      contactMasterId={contactMasterId!}
      deviceSubscriptions={deviceSubscriptions}
      mdmId={mdmId!}
      voiceSubscriptions={voiceSubscriptions}
    />
  );
};
