import { DialogWrapper } from '../../DialogWrapper/index.js';
import { NewInterworksAccountForm } from './NewInterworksAccountForm.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import type { AccountHeader } from '../../../generated/api/accountHeader';
import type { BillChannel } from '../../../generated/api/billChannel.js';
import type { RealAuthenticatedUserState } from '../../../common/types/states';

export interface NewInterworksAccountDialogProps {
  onCloseDialog: () => void;
  billChannels: BillChannel[];
  accountMasterId: string;
}

function getCompanyDetailsByMdmId(mdmId: string, authenticatedUser?: RealAuthenticatedUserState | undefined) {
  if (authenticatedUser?.mdmId === mdmId) {
    return {
      businessId: authenticatedUser.businessId || '',
      companyName: authenticatedUser.companyName,
    };
  }

  const foundCompany: AccountHeader | undefined = (authenticatedUser?.secondaryAccounts || [])?.find(
    secondaryAccount => secondaryAccount.accountMasterId === mdmId
  );

  if (foundCompany) {
    return {
      businessId: foundCompany.businessId,
      companyName: foundCompany.accountName,
    };
  }
  throw new Error(' No company found with given accountMaster Id');
}

export const NewInterworksAccountDialog = ({
  onCloseDialog,
  billChannels,
  accountMasterId,
}: NewInterworksAccountDialogProps) => {
  const { authenticatedUser } = useAuth();
  const { businessId, companyName } = getCompanyDetailsByMdmId(accountMasterId, authenticatedUser);
  return (
    <DialogWrapper onCloseDialog={onCloseDialog} closeable={true} align="top">
      <NewInterworksAccountForm
        businessId={businessId}
        companyName={companyName}
        billChannels={billChannels}
        onCancel={onCloseDialog}
        accountMasterId={accountMasterId}
      />
    </DialogWrapper>
  );
};
