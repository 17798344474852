import { DialogType } from '../../common/enums.js';
import { DirectoryDetails } from '../../generated/api/directoryDetails.js';
import { SubscriptionDetailsMobile } from '../../generated/api/subscriptionDetailsMobile.js';
import { includesVoiceMail } from '../SubscriptionAdditionalService/subscriptionAddOnUtils';
import {
  listedNumberMsg,
  notToDirectoryMsg,
  t,
  unlistedMsg,
  youCanMakeChangesAfterSubActivatedMsg,
} from '../../common/i18n/index.js';
import { showDialog } from '../../selfservice/actions/index.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { Subscription } from '../../generated/api/subscription.js';

const numberPublicityOptions = [
  {
    label: t.ZGKE(unlistedMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.SECRET,
  },
  {
    label: t.KC80(notToDirectoryMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.NOT_TO_DIRECTORY,
  },
  {
    label: t.SCL4(listedNumberMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.CUSTOM,
  },
];

export const getNumberPublicityOptions = (hideNotToDirectory: boolean) =>
  hideNotToDirectory
    ? numberPublicityOptions.filter(i => i.value !== DirectoryDetails.NumberDirectoryEnum.NOT_TO_DIRECTORY)
    : numberPublicityOptions;

export const getCompanyName = (directoryDetails?: DirectoryDetails, companyInfo?: CompanyInfoState | null) =>
  !directoryDetails?.companyName &&
  directoryDetails?.numberDirectory !== DirectoryDetails.NumberDirectoryEnum.CUSTOM &&
  companyInfo
    ? companyInfo.companyName
    : directoryDetails?.companyName;

export const getVoiceDirectoryDetails = (
  purposeOfUseOrContact: PurposeOfUseOrContact,
  companyInfo?: CompanyInfoState | null
) => {
  const voiceDirectoryDetails = purposeOfUseOrContact.directoryListingMobileNumberPublicity?.voiceDirectoryDetails;
  const companyName = getCompanyName(voiceDirectoryDetails, companyInfo);
  return voiceDirectoryDetails
    ? { voiceDirectoryDetails: { ...voiceDirectoryDetails, companyName: companyName } }
    : undefined;
};

export const getPbxDirectoryDetails = (
  purposeOfUseOrContact: PurposeOfUseOrContact,
  companyInfo?: CompanyInfoState | null
) => {
  const pbxDirectoryDetails = purposeOfUseOrContact.directoryListingCorporateNumberPublicity?.pbxDirectoryDetails;
  const companyName = getCompanyName(pbxDirectoryDetails, companyInfo);
  return pbxDirectoryDetails
    ? { pbxDirectoryDetails: { ...pbxDirectoryDetails, companyName: companyName } }
    : undefined;
};

export const changeNumberPrivacyPublicToCustom = (
  numberPrivacy?: DirectoryDetails.NumberDirectoryEnum
): DirectoryDetails.NumberDirectoryEnum | undefined =>
  numberPrivacy === DirectoryDetails.NumberDirectoryEnum.PUBLIC_DIRECTORY
    ? DirectoryDetails.NumberDirectoryEnum.CUSTOM
    : numberPrivacy;

export const subscriptionSuspendedDialog = (subscription: Subscription) =>
  showDialog({
    body: <>{t.J07S(youCanMakeChangesAfterSubActivatedMsg)}</>,
    subscriptionId: subscription.subscriptionId,
    type: DialogType.SUBSCRIPTION_RESUME_FROM_TEMPORARILY_CLOSE,
  });

export const subscriptionActionsPendingDialog = () => showDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });

export const subscriptionInActivationDialog = () =>
  showDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });

export const subscriptionChangeContactDialog = (
  subscription: Subscription,
  resetField: (name: string, options?: Record<string, boolean>) => void
) =>
  showDialog({
    type: DialogType.CHANGE_SUBSCRIPTION_CONTACT,
    subscriptionType: subscription.subscriptionType,
    onConfirm: () => {},
    onCancel: () => {
      resetField(`contactId`);
      resetField(`costCenter`);
      resetField(`employeeNumber`);
      resetField(`numberPrivacyMobile`);
      resetField(`numberPrivacyCorporate`);
    },
    showOmaLaiteLaskuWarning: Boolean(subscription.details?.device?.omaLaiteLasku),
    showVoiceMailWarning: includesVoiceMail(subscription),
  });

export const subscriptionRingSolutionDialog = (siteBaseUrl: string) =>
  showDialog({
    body: (
      <>
        <div>
          {t.LBWF('Changing  user or the purpose is not possible because the subscription has a ring solution')}
        </div>
        <p>
          {t.IWQK('In case you need help please contact')}
          <a href={`${siteBaseUrl}/yhteystiedot`}> {t.AP5F(`Elisa's customer service`)}</a>
        </p>
      </>
    ),
    type: DialogType.GENERIC_INFO_DIALOG,
    header: t.JB5H('Changing user of subscription not allowed'),
  });
