export class OpenFormMap<Value extends object> extends Map<string, Value> {
  constructor(map?: Map<string, Value> | [string, Value][]) {
    super(map);
  }

  assign<K extends keyof Value>(id: string, key: K, patch?: Value[K]) {
    return super.set(id, Object.assign(super.get(id) ?? ({} as Value), { [key]: patch }));
  }

  entriesOf<K extends keyof Value>(key: K, entries: [string, NonNullable<Value[K]>][] = []) {
    for (const [id, value] of super.entries()) {
      if (this.#defined(value[key])) {
        entries.push([id, value[key]]);
      }
    }
    return entries;
  }

  find(callback: (value: Value) => unknown): Value | undefined {
    for (const value of super.values()) {
      if (callback(value)) {
        return value;
      }
    }
    return undefined;
  }

  forEachOf<K extends keyof Value>(key: K, callback: (id: string, value: NonNullable<Value[K]>) => void) {
    for (const [id, value] of super.entries()) {
      if (this.#defined(value[key])) {
        callback(id, value[key]);
      }
    }
  }

  valuesOf<K extends keyof Value>(key: K, values: NonNullable<Value[K]>[] = []) {
    for (const value of super.values()) {
      if (this.#defined(value[key])) {
        values.push(value[key]);
      }
    }
    return values;
  }

  #defined<K extends keyof Value>(value: unknown): value is NonNullable<Value[K]> {
    return value !== null && value !== undefined && !Number.isNaN(value);
  }
}
