import { BreadCrumbsWithTitle } from '../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import type { BreadCrumbList } from '../../components/BreadCrumbs/BreadCrumbs.js';

import './HeroHeading.scss';

export enum HeroHeadingType {
  COMPANY_INFO = 'company-info',
  CUSTOMER_ORDERS = 'customer-orders',
  EMPLOYEE_BROADBANDS = 'employee-broadbands',
  EMPLOYEE_DEVICES = 'employee-devices',
  EMPLOYEE_HOME = 'employee-home',
  EMPLOYEE_ORDERS = 'employee-orders',
  EMPLOYEE_PROFILE = 'employee-profile',
  EMPLOYEE_STORE = 'employee-store',
  EMPLOYEE_SUBSCRIPTIONS = 'employee-subscriptions',
  EMPLOYEE_OMALASKU = 'employee-omalasku',
  INVOICES = 'invoices',
  MESSAGING_CENTER = 'messaging-center',
  SELFSERVICE_HOME = 'selfservice-home',
  SUBSCRIPTIONS = 'subscriptions',
  BLANK = 'blank',
}

export interface HeroHeadingProps {
  center?: boolean;
  fullScreen?: boolean;
  breadCrumbPaths?: BreadCrumbList;
  heroHeadingType: HeroHeadingType;
  subtitle?: string | JSX.Element;
  smallSubtitle?: boolean;
  title: string;
}

export const HeroHeading = ({
  breadCrumbPaths,
  fullScreen,
  heroHeadingType,
  subtitle,
  title,
  center,
  smallSubtitle,
}: HeroHeadingProps) => {
  const homePageHero =
    heroHeadingType === HeroHeadingType.EMPLOYEE_HOME || heroHeadingType === HeroHeadingType.SELFSERVICE_HOME;

  return (
    <div
      className={`of-hero-heading ${homePageHero ? 'of-hero-heading__home' : ''} ${
        fullScreen ? 'of-hero-heading__fullscreen' : ''
      } ${center ? 'of-hero-heading__centered' : ''}`}
    >
      {breadCrumbPaths && breadCrumbPaths.length > 0 && (
        <div className="of-hero-breadcrumbs">
          <BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />
        </div>
      )}
      <div
        className={`of-hero-heading__wrapper of-hero-heading__image of-hero-heading__image--${heroHeadingType.toString()}`}
      >
        <div className="of-hero-heading__content">
          <h1>{title}</h1>
          <p className={`of-hero-heading__subtitle ${smallSubtitle ? 'ds-text--l' : ''}`}>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};
