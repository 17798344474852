import { ActionPhase } from '../common/storeUtils.js';
import { SubscriptionAction } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { mergeArrays, reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SubscriptionActionsState } from '../../common/types/states.js';

export function pendingSubscriptionActionsReducer(
  originalState: (SubscriptionActionsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (SubscriptionActionsState & ActionsHistory) | null {
  if (typeof originalState === 'undefined') {
    return null;
  }

  // Backup because not having AUTH/LOGIN stuff here anymore can cause the app to crash when state is null and spread operator is used
  const state = originalState || ({} as SubscriptionActionsState & ActionsHistory);

  switch (action.type) {
    case TypeKeys.UPSERT_CONTACT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.UPSERT_CONTACT, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
      };
    }
    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX: {
      return {
        ...reduceCrudAction(action, state),
        errors: undefined,
        saving: true,
      };
    }

    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
      };
    }

    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED: {
      if (action.subscriptionAction) {
        return {
          ...state,
          actions: updateActionStatePhase(
            TypeKeys.POLL_FOR_MYSELF_CHANGES,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          items: mergeArrays<SubscriptionAction>(
            'subscriptionActionId',
            'lastModified',
            [action.subscriptionAction],
            state!.items
          ),
          saving: false,
        };
      }
      return state;
    }

    case TypeKeys.CHANGE_OFFER: {
      return {
        ...reduceCrudAction(action, state),
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED:
    case TypeKeys.LOG_IN_FULFILLED:
    case TypeKeys.AUTH_VALIDATE_FULFILLED: {
      return {
        ...state,
        items: mergeArrays<SubscriptionAction>(
          'subscriptionActionId',
          'lastModified',
          state?.items ? state.items : undefined,
          action.authenticationResult.pendingSubscriptionActions
        ),
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO: {
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        editingSection: undefined,
        errors: undefined,
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
        duplicateContactFound: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_WITH_DUPLICATE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
        duplicateContactFound: true,
      };
    }

    case TypeKeys.EDIT_SUBSCRIPTION_SECTION: {
      return {
        ...state,
        editingSection: action.editingSection,
        saving: false,
        duplicateContactFound: false,
      };
    }

    case TypeKeys.TERMINATE_SUBSCRIPTION: {
      return {
        ...reduceCrudAction(action, state),
        saving: false,
        terminationInProgress: true,
      };
    }

    case TypeKeys.TERMINATE_SUBSCRIPTION_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.TERMINATE_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
        terminateSubscriptionInitiated: false,
        terminationInProgress: false,
      };
    }

    case TypeKeys.TERMINATE_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.TERMINATE_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
        terminationInProgress: false,
      };
    }

    case TypeKeys.SHOW_TERMINATE_SUBSCRIPTION_FORM: {
      return {
        ...state,
        saving: false,
        terminateSubscriptionInitiated: true,
      };
    }

    case TypeKeys.CLOSE_TERMINATE_SUBSCRIPTION_FORM: {
      return {
        ...state,
        donorPhoneNumber: false,
        saving: false,
        terminateSubscriptionInitiated: false,
      };
    }

    case TypeKeys.SHOW_SUSPEND_SUBSCRIPTION_OPTIONS: {
      return {
        ...state,
        saving: false,
        suspendSubscriptionInitiated: true,
      };
    }

    case TypeKeys.CLOSE_SUSPEND_SUBSCRIPTION_OPTIONS: {
      return {
        ...state,
        saving: false,
        suspendSubscriptionInitiated: false,
      };
    }

    case TypeKeys.SET_DONOR_PHONE_NUMBER: {
      return {
        ...state,
        donorPhoneNumber: action.donorNumber || false,
        saving: false,
      };
    }

    case TypeKeys.SUSPEND_SUBSCRIPTION: {
      return {
        ...reduceCrudAction(action, state),
        saving: false,
        suspendInProgress: true,
        suspendSubscriptionInitiated: false,
      };
    }

    case TypeKeys.SUSPEND_SUBSCRIPTION_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUSPEND_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
        suspendInProgress: false,
      };
    }

    case TypeKeys.SUSPEND_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUSPEND_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
        suspendInProgress: false,
      };
    }

    case TypeKeys.RESUME_SUBSCRIPTION: {
      return {
        ...reduceCrudAction(action, state),
        saving: false,
      };
    }

    case TypeKeys.RESUME_SUBSCRIPTION_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.RESUME_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.RESUME_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.RESUME_SUBSCRIPTION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION: {
      const errors = action.validationFn(action.pbxConfiguration);
      if (errors) {
        return {
          ...state,
          errors: errors,
          saving: false,
        };
      }
      return {
        ...reduceCrudAction(action, state),
        errors: undefined,
        saving: true,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        editingSection: undefined,
        errors: undefined,
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SIM_CARD:
    case TypeKeys.CHANGE_ADDONS: {
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.CHANGE_SIM_CARD_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_SIM_CARD, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_SIM_CARD_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_SIM_CARD, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        editingSection: undefined,
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.CHANGE_ADDONS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_ADDONS, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_ADDONS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_ADDONS, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        editingSection: undefined,
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    case TypeKeys.POST_EPP_MAINTENANCE:
    case TypeKeys.POST_EPP_REDEEM_TERMINATE: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.POST_EPP_MAINTENANCE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POST_EPP_MAINTENANCE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        saving: false,
      };
    }

    case TypeKeys.POST_EPP_MAINTENANCE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POST_EPP_MAINTENANCE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POST_EPP_REDEEM_TERMINATE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state?.items || [], [
          action.response,
        ]),
        saving: false,
      };
    }

    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POST_EPP_REDEEM_TERMINATE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_OFFER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_OFFER, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        items: mergeArrays<SubscriptionAction>('subscriptionActionId', 'lastModified', state!.items, [
          action.subscriptionAction,
        ]),
        saving: false,
        suspendSubscriptionInitiated: false,
      };
    }

    case TypeKeys.CHANGE_OFFER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_OFFER, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        saving: false,
        suspendSubscriptionInitiated: false,
      };
    }

    case TypeKeys.REVIEW_FULFILLED: {
      if (action.reviewType === 'SUBSCRIPTION_REDEEM') {
        const mergedItems = mergeArrays<SubscriptionAction>(
          'subscriptionActionId',
          'lastModified',
          state?.items ? state.items : undefined,
          action.pendingSubscriptionActions
        );
        return {
          ...state,
          items: mergedItems?.filter(items => {
            if (items.subscriptionId === action.subscriptionId) {
              items.status = action.approved
                ? SubscriptionAction.StatusEnum.PENDING
                : SubscriptionAction.StatusEnum.CANCELLED;
            }
          }),
          saving: false,
        };
      }
      return state;
    }

    case TypeKeys.USE_DUPLICATE_CONTACT: {
      return {
        saving: false,
        editingSection: 'purposeOfUse',
      };
    }

    default:
      return state;
  }
}
