import { ContactRole, CustomerOrderStatus, SubscriptionAction } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../enums.js';
import { paths } from '../constants/pathVariables.js';
import type { ActionsHistory } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState, CustomerOrdersState, SubscriptionActionsState } from '../types/states.js';
import type { ContactPerson, CustomerOrderAdditionalInfo, Subscription } from '../../generated/api/models.js';

const defaultSubscriptionActionsSort = {
  columnId: 'created',
  order: 'desc',
};

export const getOrdererSubscription = (
  additionalInfo?: Record<string, CustomerOrderAdditionalInfo>,
  orderId?: string
): Subscription[] => {
  if (additionalInfo && orderId) {
    return additionalInfo[orderId]?.ordererSubscriptions;
  }
  return [];
};

export const getOrderContactPerson = (
  additionalInfo?: Record<string, CustomerOrderAdditionalInfo>,
  orderId?: string
): ContactPerson | undefined => (orderId ? additionalInfo?.[orderId]?.ordererContact.person : undefined);

export const getSubCategoryFromPath = (path: string) => {
  const pathname = decodeURI(path);
  const isEmployeeSubscriptionsOrVoice =
    pathname.startsWith(paths.EMPLOYEE_SUBSCRIPTIONS) || pathname.startsWith(paths.EMPLOYEE_VOICE_SUBSCRIPTIONS);
  const isBroadband = pathname.startsWith(paths.EMPLOYEE_BROADBAND_SUBSCRIPTIONS);

  if (isEmployeeSubscriptionsOrVoice) {
    return SubscriptionCategory.VOICE;
  } else if (isBroadband) {
    return SubscriptionCategory.BROADBAND;
  } else {
    return SubscriptionCategory.DEVICE;
  }
};

export const getFilteredCustomerOrders = (
  customerOrders: CustomerOrdersState | null | undefined,
  pending: boolean
): CustomerOrdersState | undefined => {
  if (customerOrders) {
    const filterFn = (status?: CustomerOrderStatus) =>
      pending ? status === CustomerOrderStatus.PENDING_APPROVAL : status !== CustomerOrderStatus.PENDING_APPROVAL;
    const items = customerOrders.items?.filter(e => filterFn(e.status));
    const searchResults = customerOrders.searchResults?.filter(e => filterFn(e.result.status));

    return {
      ...customerOrders,
      items,
      searchResults,
      total: items?.length,
    };
  }
  return customerOrders === null ? undefined : customerOrders;
};

export const getFilteredSubscriptionAction = (
  subscriptionActions: SubscriptionActionsState | null | undefined
): SubscriptionActionsState | undefined => {
  if (subscriptionActions) {
    const subscriptionActionItems = subscriptionActions.items?.filter(
      subscriptionAction =>
        subscriptionAction.subscriptionActionType === SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE
    );
    const subscriptionActionSearchResults = subscriptionActions.searchResults?.filter(
      subscriptionAction =>
        subscriptionAction.result.subscriptionActionType ===
        SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE
    );
    return {
      ...subscriptionActions,
      items: subscriptionActionItems,
      searchResults: subscriptionActionSearchResults,
      sort: subscriptionActions.sort || defaultSubscriptionActionsSort,
    };
  }
  return undefined;
};

export const userHasEmptyOrSMEPriceGroup = (segmentPricingGroup?: string) =>
  !segmentPricingGroup || segmentPricingGroup.toLowerCase() === 'sme pricing group';

export const userHasApproverRole = (
  authenticated: (AuthenticatedUserState & ActionsHistory) | null | undefined
): boolean => Boolean(authenticated?.contact?.person?.roles?.includes(ContactRole.APPROVER));
