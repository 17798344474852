import {
  BILLING_ACCOUNT_MAX_LENGTH,
  BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH,
  BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH,
} from '../../common/utils/validationUtils.js';
import { BillingAccount } from '../../generated/api/billingAccount.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/react-hook-form/fields/ContactDropdown.js';
import { Contact } from '../../common/react-hook-form/fields/Contact.js';
import { Email, Name, PhoneNumber, PostalCode, TextInput } from '../../common/react-hook-form/fields/index.js';
import { FORM_GRID_CLEAR, FormGridFieldset } from '../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { InvoiceType } from '../../common/react-hook-form/fields/InvoiceType.js';
import { PostOffice } from '../../common/react-hook-form/fields/PostOffice.js';
import { Reference } from '../../common/react-hook-form/fields/Reference.js';
import { StreetAddress } from '../../common/react-hook-form/fields/StreetAddress.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  firstNameMsg,
  lastNameMsg,
  payerDetailsMsg,
  payerMsg,
  referenceMsg,
  streetAddressMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useFormContext } from 'react-hook-form';
import type { BillChannel } from '../../generated/api/billChannel.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ContactsResponse } from '../../generated/api/contactsResponse.js';

export const billingAccountDefaultValues = (companyInfo?: CompanyInfoState) => ({
  payerName: companyInfo?.companyName || '',
  payerNameExtension: '',
  payerAddress: {
    countryCode: 'FIN',
    line1: companyInfo?.address?.line1 || '',
    postOffice: companyInfo?.address?.postOffice || '',
    postalCode: companyInfo?.address?.postalCode || '',
  },
  billingContactId: '',
  billFormatType: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
  billElectronicOperator: undefined,
  billingAccountName: companyInfo?.companyName || '',
});

interface BillingAccountFieldSetProps {
  billChannels: BillChannel[];
  contacts: ContactsResponse;
  isNewDuplicateContactHandling?: boolean;
}

export const BillingAccountFieldset = (props: BillingAccountFieldSetProps) => {
  const methods = useFormContext();
  const values = methods.watch();

  return (
    <FormGridFieldset>
      <Name name="billingAccount.payerName" label={t.PB6S(payerMsg)} placeholder="" disabled={true} />
      <Name
        name="billingAccount.payerNameExtension"
        label={t.YLAI(payerDetailsMsg)}
        placeholder=""
        allowSpecialCharacters={true}
        maxLength={BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH}
        required={false}
      />
      <StreetAddress name="billingAccount.payerAddress.line1" label={t.DD38(streetAddressMsg)} />
      <PostalCode name="billingAccount.payerAddress.postalCode" placeholder="" />
      <PostOffice name="billingAccount.payerAddress.postOffice" wrapperClassName={dsClass.PADDING_BOTTOM_6} />
      <TextInput type="hidden" name="billingAccount.payerAddress.countryCode" />
      <Contact
        className={FORM_GRID_CLEAR}
        contacts={props.contacts.contacts || []}
        name="billingAccount.billingContactId"
        reference="contact"
      />
      <InvoiceType name="billingAccount.billFormatType" />
      {/* TODO Migrate to use new NewContact.tsx to get better validation for duplicate-contacts
          TODO this involves removing these fields and putting NewContact next to the form
       */}
      {!props.isNewDuplicateContactHandling &&
        values.billingAccount.billingContactId === CREATE_NEW_CONTACT_OPTION_VALUE && (
          <>
            <Name name="contact.firstName" label={t.AIK7(firstNameMsg)} placeholder="" />
            <Name name="contact.lastName" label={t.Y41S(lastNameMsg)} placeholder="" />
            <Email name="contact.email" placeholder="" />
            <PhoneNumber name="contact.phoneNumber" placeholder="" />
          </>
        )}
      <Name
        name="billingAccount.billingAccountName"
        maxLength={BILLING_ACCOUNT_MAX_LENGTH}
        label={t.RH6T(billingAccountNameMsg)}
        placeholder=""
        required={true}
        allowSpecialCharacters={true}
      />
      <Name
        label={t.KUTS(billingAccountExtensionNameMsg)}
        maxLength={BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH}
        name="billingAccount.billingAccountExtensionName"
        placeholder=""
        required={false}
        allowSpecialCharacters={true}
        wrapperClassName={dsClass.PADDING_BOTTOM_6}
      />
      <Reference hint="" name="billingAccount.customerReference1" label={`${t.ZLAU(referenceMsg)} 1`} />
      <Reference hint="" name="billingAccount.customerReference2" label={`${t.ZLAU(referenceMsg)} 2`} />
      <Reference hint="" name="billingAccount.customerReference3" label={`${t.ZLAU(referenceMsg)} 3`} />
      <Reference
        hint=""
        label={`${t.ZLAU(referenceMsg)} 4`}
        name="billingAccount.customerReference4"
        wrapperClassName={dsClass.PADDING_BOTTOM_6}
      />
    </FormGridFieldset>
  );
};
