import { AccountManagerDetailsCard } from '../AccountManagerDetailsCard/AccountManagerDetailsCard.js';
import { ContactChannelsInfoCard } from '../ContactChannelsInfoCard/ContactChannelsInfoCard.js';
import { CustomerOrderStatus } from '../../generated/api/models.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { SelfServiceHomeCustomerOrders } from './SelfServiceHomeCustomerOrders.js';
import { SelfServiceHomeInvoices } from './SelfServiceHomeInvoices.js';
import { SelfServiceHomeNavigation } from './SelfServiceHomeNavigation.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { helloMsg, t, welcomeToManageYourBusinessMsg } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { CustomerOrderHeader, InvoiceHeader } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './SelfServiceHome.scss';

const MAX_ROW_AMOUNT = 5;

export interface SelfServiceHomeProps {
  openInvoices?: InvoiceHeader[];
  customerOrders?: CustomerOrderHeader[];
  pendingRedeemRequestCount?: number;
}

export const SelfServiceHome = ({ openInvoices, customerOrders, pendingRedeemRequestCount }: SelfServiceHomeProps) => {
  const { authenticatedUser } = useAuth();
  const { accountManager, classicSiteUrl } = useSelector(
    (state: State) => ({
      accountManager: state.selfservice?.companyInfo?.accountManager,
      classicSiteUrl: state.config.classicSiteUrl,
    }),
    deepEqual
  );

  useTitle();

  const waitingOrdersAndRedeemRequestsCount = () => {
    const waitingOrders =
      customerOrders?.filter(item => item.status === CustomerOrderStatus.PENDING_APPROVAL)?.length ?? 0;
    const pendingRedeemRequests = pendingRedeemRequestCount || 0;

    return waitingOrders + pendingRedeemRequests;
  };

  const openInvoicesCount = openInvoices?.length || 0;

  return (
    <div className="of-self-service-home">
      <HeroHeading
        heroHeadingType={HeroHeadingType.SELFSERVICE_HOME}
        title={t.WR9Q(helloMsg, authenticatedUser?.firstName || '')}
        subtitle={t.A47Y(welcomeToManageYourBusinessMsg)}
      />
      <SelfServiceHomeNavigation
        waitingOrdersCount={waitingOrdersAndRedeemRequestsCount()}
        openInvoicesCount={openInvoicesCount}
        classicSiteUrl={classicSiteUrl}
      />
      <div className="of-self-service-home__content">
        <SelfServiceHomeInvoices
          className="of-self-service-home__content__block"
          invoices={openInvoices?.slice(0, MAX_ROW_AMOUNT)}
          authenticatedUser={authenticatedUser}
        />
        <SelfServiceHomeCustomerOrders
          className="of-self-service-home__content__block"
          customerOrders={customerOrders?.slice(0, MAX_ROW_AMOUNT)}
          authenticatedUser={authenticatedUser}
        />
        <div className="of-self-service-home__content__block contact-info">
          {accountManager && <AccountManagerDetailsCard accountManager={accountManager} />}
          <ContactChannelsInfoCard />
        </div>
      </div>
    </div>
  );
};
