import * as CL from '@design-system/component-library';
import { OmalaskuBarringStatus } from './OmalaskuBarringStatus';
import { OmalaskuStatusFailedDisclaimer } from './OmalaskuStatusFailedDisclaimer';
import { OmalaskuStatusResponse } from '../../generated/api/omalaskuStatusResponse';
import { OmalaskuSubscriptionBillingAddress } from './OmalaskuSubscriptionBillingAddress';
import { billingAddressTitleMsg, palvelunumerotMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { useOmalasku } from './Omalasku';

export const OmalaskuVoiceSubscriptions = () => {
  const { omalaskuSubsStatuses, msisdns, firstName, lastName } = useOmalasku();

  const address = (omalaskuSubsStatuses || [])
    .filter(subStatus => subStatus.omaLaskuStatus === OmalaskuStatusResponse.OmaLaskuStatusEnum.Active)
    .map(subStatus => subStatus.address)[0];

  const Content = () => {
    if (!omalaskuSubsStatuses) {
      return (
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.PADDING_8} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
          <CL.LoadingSpinner size="xl" />
        </div>
      );
    }
    if (msisdns && msisdns.length === 0) {
      return <p className={dsClass.MARGIN_TOP_4}>{t.BA64(`You don't have subscriptions.`)}</p>;
    }
    if (msisdns && msisdns.length > 0 && omalaskuSubsStatuses.length === 0) {
      return <OmalaskuStatusFailedDisclaimer />;
    }
    return (
      <>
        {omalaskuSubsStatuses.map(status => (
          <OmalaskuBarringStatus key={status.msisdn} status={status} />
        ))}
        {address && (
          <>
            <h5 className={`${dsClass.H5} ${dsClass.MARGIN_TOP_6} ${dsClass.MARGIN_BOTTOM_2}`}>
              {t.PS5T(billingAddressTitleMsg)}
            </h5>
            <OmalaskuSubscriptionBillingAddress firstname={firstName} lastname={lastName} address={address} />
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <h3 className={dsClass.DESCRIPTION_TITLE}>{t.CH87(palvelunumerotMsg)}</h3>
      <p>
        {t.TGFE(
          'With OmaLasku Palvelunumerot -service you can remove service number blocks by paying for calls and messages to service numbers yourself.'
        )}
      </p>
      <Content />
    </div>
  );
};
