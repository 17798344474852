import { UUID_REGEX, getPage, parsePath } from '../OpenFormUtils.js';
import { has } from '../OpenForm.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useOpenFormState } from '../OpenFormProvider.js';
import type { FormId, ListId, Page } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export type Goto = (opts?: Partial<Form & List>) => void;
type Form = { formId: string; page: string | number };
type List = { listId: string };

const toForm = (preview: boolean) => (preview ? paths.OPENFORM_PREVIEW : paths.OPENFORM_VIEW);
const toList = (listId?: string) => (listId ? paths.OPENFORM_LIST : paths.OPENFORM);

export const useOpenFormGoto = (): { goto: Goto; page: number; preview: boolean } => {
  const { preview } = useOutletContext<{ preview: boolean }>() ?? {};
  const { answers, form } = useOpenFormState();
  const params = useParams<Page>();
  const navigate = useNavigate();
  const max = useMemo(() => (!form?.sections.length ? 0 : form.sections.length + 1), [form]);
  const page = useMemo(() => getPage(Number(params.page), { max }), [params.page, max]);
  const step = useCallback<(slice?: OpenFormSection[]) => number>(
    (slice = []) => Math.abs(slice.findIndex((section, idx) => idx && answers.isActive(section) && idx)),
    [answers]
  );
  return {
    goto: useCallback<Goto>(
      (opts = {}) => {
        if ('listId' in opts) {
          return navigate(parsePath<ListId>(toList(opts.listId), opts as List), { replace: true });
        }
        if ((opts.formId ??= form?.formId)) {
          if (typeof opts.page !== 'string') {
            opts.page = getPage(opts.page, { max });
            return navigate(parsePath<FormId & Page>(toForm(preview), opts as Form), { replace: true });
          }
          switch (opts.page) {
            case 'done':
              return navigate(parsePath<FormId>(paths.OPENFORM_VIEW_COMPLETED, opts as Form), { replace: true });
            case 'next':
              opts.page = getPage(page + step(form?.sections.slice(page - 1)), { max });
              return navigate(parsePath<FormId & Page>(toForm(preview), opts as Form), { replace: true });
            case 'prev':
              opts.page = getPage(page - step(form?.sections.slice(0, page).reverse()), { max });
              return navigate(parsePath<FormId & Page>(toForm(preview), opts as Form), { replace: true });
            case 'todo':
              opts.page = getPage(form!.sections.findIndex(answers.isIncomplete) + 1, { max });
              return navigate(parsePath<FormId & Page>(toForm(preview), opts as Form), { replace: true });
          }
          if (UUID_REGEX.test(opts.page)) {
            opts.page = getPage(form!.sections.findIndex(has(opts.page)) + 1, { max });
            return navigate(parsePath<FormId & Page>(toForm(preview), opts as Form), { replace: true });
          }
        }
      },
      [preview, answers, form, navigate, max, page, step]
    ),
    page: page,
    preview: preview,
  };
};
