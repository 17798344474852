import {
  FiveGMobileBroadbandOfferCode,
  FiveGVoiceOfferCode,
  MobileBroadbandFourGOfferCodes,
  ModelType,
  OldVoiceOfferCode,
  YrityspakettiOfferCode,
} from '../../../common/enums.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import {
  elsewhereInEuMsg,
  euDataIncludedMsg,
  formatNumber,
  gBMoMsg,
  noFixedTermMsg,
  safeReissaajaMsg,
  t,
  unlimitedDataInFinlandNordicBalticMsg,
  unlimitedInternetInFinlandNordicBalticMsg,
  wifiCallServiceMsg,
} from '../../../common/i18n/index.js';
import type { CampaignAssociation, Offer } from '../../../generated/api/models.js';
import type { ProductPriceTexts } from '../CampaignProductCard.js';

export interface ProductContent {
  body: JSX.Element;
  title?: string;
  footerText?: string;
  productNameAddon?: string;
  headerContent?: JSX.Element;
  imageUrl?: string;
  offerCodes: string[];
  specsText?: string;
  isDefaultSelected?: boolean;
  offerName?: string;
}

export interface LegacyProductContent {
  dataLimitation: string;
  offerCode: string;
  modelType: ModelType;
  title?: string;
  priceTexts?: ProductPriceTexts;
  openingFee?: number;
  campaignAssociation?: CampaignAssociation;
}

export interface ProductContentWithAddonCode extends ProductContent {
  addOnCode: string;
}

export const productContentMatchesOffer = (
  content: ProductContent | ProductContentWithAddonCode,
  offer: Offer
): boolean => {
  if ('addOnCode' in content) {
    const addonCodes = offer.commercialProducts.flatMap(cp => cp.associatedAddOns?.map(addon => addon.addOnCode));
    return content.offerCodes.includes(offer.offerCode) && addonCodes.includes(content.addOnCode);
  } else {
    return content.offerCodes.includes(offer.offerCode);
  }
};

// These are ordered in the page in the order they appear here.
export const mobileBroadbandFiveGContent = (): ProductContent[] => [
  {
    body: (
      <>
        <p>{t.SDTI(unlimitedDataInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)} <b>73 {t.U54O(gBMoMsg)}</b>
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['7797909c-3f7e-b27d-e159-96124f0cbbbb'],
    title: '1000M',
  },
  {
    body: (
      <>
        <p>{t.SDTI(unlimitedDataInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)} <b>60 {t.U54O(gBMoMsg)}</b>
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['83e3965c-e84a-0c8a-18c1-6d04171951f5'],
    title: '600M',
  },
  {
    body: (
      <>
        <p>{t.SDTI(unlimitedDataInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(53.5)} {t.U54O(gBMoMsg)}
          </b>
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['d57895b4-abe3-6d85-278c-1c771aecce9c'],
    title: '300M',
  },
  {
    body: (
      <>
        <p>{t.SDTI(unlimitedDataInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(45.5)} {t.U54O(gBMoMsg)}
          </b>
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['026acb30-8f07-845b-6eb3-42e8d3bbef22'],
    title: '100M',
  },
];

const delimiter = '      ';

// These are ordered in the page in the order they appear here.
// TODO didn't find any use case for the text fields, can possibly be removed
export const m2mContent = (): ProductContentWithAddonCode[] => [
  {
    body: (
      <>
        <p>{t.A7CI(euDataIncludedMsg, '3')}</p>
        <p>{t.A7CC('For sending images, SMS/voice operated devices, security and alarm systems and heat pumps')}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['9be7de49-3191-b932-127e-811c67ae245f'],
    addOnCode: '06c6d401-3f57-f27f-4726-5b9d0edd84bb',
    specsText: '128 kbit/s ↑' + delimiter + '128 kbit/s ↓',
    title: 'S',
  },
  {
    body: (
      <>
        <p>{t.A7CI(euDataIncludedMsg, '4')}</p>
        <p>
          {t.A7CD(
            'For sending video, images, SMS/voice operated devices, security and alarm systems, heat pumps and GPS devices'
          )}
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['9be7de49-3191-b932-127e-811c67ae245f'],
    addOnCode: '21127eef-7290-1d21-bf10-02d608e96adf',
    specsText: '2 Mbit/s ↑' + delimiter + '256 kbit/s ↓',
    title: 'M',
  },
  {
    body: (
      <>
        <p>{t.A7CI(euDataIncludedMsg, '6')}</p>
        <p>
          {t.A7CE(
            'For sending live video, images, SMS/voice operated devices, security and alarm systems, heat pumps and GPS devices'
          )}
        </p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['9be7de49-3191-b932-127e-811c67ae245f'],
    addOnCode: 'a22f20d3-0ba4-d874-22d6-dbf71627a6d0',
    specsText: '5 Mbit/s ↑' + delimiter + '500 kbit/s ↓',
    title: 'L',
  },
  {
    body: <></>,
    offerCodes: ['b1ff488a-9672-22c0-f9db-bcd7ec64a4ca'],
    addOnCode: '497aa153-9530-0665-abaa-3fd0224d2c00',
  },
];

// These are ordered in the page in the order they appear here.
export const mobileVoiceContent = (): ProductContent[] => [
  {
    body: (
      <>
        <p>{t.SLOI(unlimitedInternetInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(50)} {t.U54O(gBMoMsg)}
          </b>
        </p>
        <p>{t.G51L(safeReissaajaMsg)}</p>
        <p>{t.Y0SU(wifiCallServiceMsg)}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['a1e13276-caf3-7c8b-709c-4026bdc04bed'],
    specsText: t.FT13('1000 Mbit/s • 5,000 min • 100 pcs'),
    title: '1000M',
  },
  {
    body: (
      <>
        <p>{t.SLOI(unlimitedInternetInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(42.5)} {t.U54O(gBMoMsg)}
          </b>
        </p>
        <p>{t.G51L(safeReissaajaMsg)}</p>
        <p>{t.Y0SU(wifiCallServiceMsg)}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['945c1ec9-a53b-8f09-b330-0cf033bf7dba'],
    specsText: t.V2BO('600 Mbit/s • 5,000 min • 100 pcs'),
    title: '600M',
  },
  {
    body: (
      <>
        <p>{t.SLOI(unlimitedInternetInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(37)} {t.U54O(gBMoMsg)}
          </b>
        </p>
        <p>{t.G51L(safeReissaajaMsg)}</p>
        <p>{t.Y0SU(wifiCallServiceMsg)}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['0f6a0350-daf4-854d-f0b9-b532df78eb51'],
    specsText: t.B8A4('300 Mbit/s • 5,000 min • 100 pcs'),
    title: '300M',
  },
  {
    body: (
      <>
        <p>{t.SLOI(unlimitedInternetInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(32.5)} {t.U54O(gBMoMsg)}
          </b>
        </p>
        <p>{t.G51L(safeReissaajaMsg)}</p>
        <p>{t.Y0SU(wifiCallServiceMsg)}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['0dd20ed5-51ce-ff8c-16ac-582599416b0f'],
    specsText: t.S1IM('100 Mbit/s • 5,000 min • 100 pcs'),
    title: '100M',
  },
  {
    body: (
      <>
        <p>{t.SLOI(unlimitedInternetInFinlandNordicBalticMsg)}</p>
        <p>
          {t.W8I6(elsewhereInEuMsg)}{' '}
          <b>
            {formatNumber(30)} {t.U54O(gBMoMsg)}
          </b>
        </p>
        <p>{t.G51L(safeReissaajaMsg)}</p>
        <p>{t.Y0SU(wifiCallServiceMsg)}</p>
      </>
    ),
    footerText: t.XJMB(noFixedTermMsg),
    offerCodes: ['96afd01c-4573-660a-cad0-9a42197d14c2'],
    specsText: t.HT0V('10 Mbit/s • 5,000 min • 100 pcs'),
    title: '10M',
  },
];

const populateDataLimitationMessage = (limit: number): string =>
  `${t.SDTI(unlimitedDataInFinlandNordicBalticMsg)}. ${t.W8I6(elsewhereInEuMsg)}
            ${formatNumber(limit)} ${t.U54O(gBMoMsg)}.`;

export const getLegacyOffersContentBase = (): LegacyProductContent[] => [
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: MobileBroadbandFourGOfferCodes.ONE_FIFTY,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(34),
    offerCode: MobileBroadbandFourGOfferCodes.FIFTY,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(22.5),
    offerCode: MobileBroadbandFourGOfferCodes.TEN,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(73),
    offerCode: OldVoiceOfferCode.VOICE_1000_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(66.5),
    offerCode: OldVoiceOfferCode.VOICE_600_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(53.5),
    offerCode: OldVoiceOfferCode.VOICE_300_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: OldVoiceOfferCode.VOICE_100_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(44),
    offerCode: OldVoiceOfferCode.VOICE_10_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(58.5),
    offerCode: YrityspakettiOfferCode.VOICE_1000_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(53.5),
    offerCode: YrityspakettiOfferCode.VOICE_600_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(53.5),
    offerCode: YrityspakettiOfferCode.VOICE_300_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(53.5),
    offerCode: YrityspakettiOfferCode.VOICE_200_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: YrityspakettiOfferCode.VOICE_100_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: YrityspakettiOfferCode.VOICE_50_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: YrityspakettiOfferCode.VOICE_4G_50_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(35),
    offerCode: YrityspakettiOfferCode.VOICE_10_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(26),
    offerCode: YrityspakettiOfferCode.VOICE_2_M,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(80),
    offerCode: FiveGVoiceOfferCode.Voice1000Mps,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(66.5),
    offerCode: FiveGVoiceOfferCode.Voice600Mbps,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(58.5),
    offerCode: FiveGVoiceOfferCode.Voice300Mbps,
    modelType: ModelType.VoiceSME,
  },
  {
    dataLimitation: populateDataLimitationMessage(73),
    offerCode: FiveGMobileBroadbandOfferCode.MBB_1000_M,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(60),
    offerCode: FiveGMobileBroadbandOfferCode.MBB_600_M,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(53.5),
    offerCode: FiveGMobileBroadbandOfferCode.MBB_300_M,
    modelType: ModelType.MobileBroadband,
  },
  {
    dataLimitation: populateDataLimitationMessage(45.5),
    offerCode: FiveGMobileBroadbandOfferCode.MBB_100_M,
    modelType: ModelType.MobileBroadband,
  },
];

export const commercialProductContent = (): ProductContent[] => [
  ...mobileBroadbandFiveGContent(),
  ...mobileVoiceContent(),
];

export const PopoverContent = ({ children }: React.PropsWithChildren) => (
  <div className={`${dsClass.TEXT_ALIGN_LEFT} ${dsClass.TEXT_S}`}>{children}</div>
);
