import { ActionPhase } from '../common/storeUtils.js';
import { BillingAccountDeliveryMethod, BillingAccountStatus } from '../../generated/api/models.js';
import { CommonErrorType, WizardType } from '../../common/enums.js';
import { ConsumerBA, getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { TypeKeys } from '../actions/index.js';
import { addToActionHistory, updateActionStatePhase } from '../common/index.js';
import {
  buildHeaderOnlyItemsQuery,
  getSortedItems,
  getSortedItemsWithIdObject,
  mergeArrays,
  reduceCrudAction,
} from './reducerUtils.js';
import { fieldCantBeEmptyMsg, t } from '../../common/i18n/index.js';
import { findPersonBillingAccount } from '../../common/utils/stateUtils.js';
import { getErrorsFromUpsertPersonBillingAccount } from '../../components/PersonalBillingDetails/PersonalBillingDetails.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionsHistory, ItemsQuery } from '../common/store.js';
import type { BillingAccount, BillingAccountHeader } from '../../generated/api/models.js';
import type { BillingAccountsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function billingAccountsReducer(
  state: (BillingAccountsState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (BillingAccountsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_BILLING_ACCOUNTS: {
      const query: ItemsQuery | undefined = buildHeaderOnlyItemsQuery(action, state, state?.total, state?.search);

      if (query) {
        return {
          ...state,
          actions: addToActionHistory(state, { phase: ActionPhase.IN_PROGRESS, query, value: action }),
          filter: action.filter,
          search: query.search ?? state?.search,
          saving: state?.saving ?? false,
          loading: true,
        };
      } else {
        return state;
      }
    }

    case TypeKeys.LOAD_BILLING_ACCOUNTS_FULFILLED: {
      return {
        ...state!,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_BILLING_ACCOUNTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: state!.errors,
        invoicesBillingAccounts: state!.invoicesBillingAccounts
          ? sortArray(
              mergeArrays<BillingAccount>(
                'billingAccountId',
                'lastModified',
                state!.invoicesBillingAccounts,
                action.billingAccounts
              ),
              'created',
              'desc'
            )
          : undefined,
        items: sortArray(
          mergeArrays<BillingAccount>('billingAccountId', 'lastModified', state!.items, action.billingAccounts),
          'created',
          'desc'
        ),
        total: action.total,
        searchResults: action.searchResults,
        loading: false,
      };
    }

    case TypeKeys.LOAD_BILLING_ACCOUNTS_FAILED: {
      return {
        ...state!,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_BILLING_ACCOUNTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.EDIT_BILLING_ACCOUNT: {
      return {
        ...state,
        redirectFromUrl: action.redirectFromUrl,
        redirectToUrl: action.redirectToUrl,
        saving: false,
        loading: false,
      };
    }

    // Below action is triggered in one of device checkout and one of fixed broadband steps,
    // and billing account are edited on the next step.
    case TypeKeys.PREPARE_BILLING_ACCOUNT_EDIT:
    case TypeKeys.PROCESS_DELIVERY_DETAILS: {
      return {
        ...state,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT: {
      if (action.billingAccount.billingAccountId) {
        const originalBillingAccount = state!.items!.find(
          (ba: BillingAccount) => ba.billingAccountId === action.billingAccount.billingAccountId
        );
        if (!originalBillingAccount) {
          throw new Error(
            `Could not find the original billing account for id ${action.billingAccount.billingAccountId} from state`
          );
        }
      }
      const errors = getErrorsFromUpsertBillingAccount(action.billingAccount, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors,
          saving: false,
          loading: false,
        };
      } else {
        return {
          ...reduceCrudAction(action, state),
          saving: true,
          loading: false,
        };
      }
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED: {
      const serverErrorOnBillingAccountUpdate =
        action.errors && action.errors.find(error => error.type === CommonErrorType.SYSTEM);
      if (serverErrorOnBillingAccountUpdate) {
        serverErrorOnBillingAccountUpdate.displayText =
          t.DRHV('Oops... an error occurred in the system.') +
          t.A6ZP('If you tried to edit a newly created billing agreement, please wait a while, then try again later');
      }
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPSERT_BILLING_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        editingChanges: undefined,
        errors: action.errors,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED: {
      // When changing subscription BA, we need to keep the spinner running
      const saving = action.wizardParams?.type === WizardType.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT;
      // Actions are in this state, expect when ordering new things, because then we only get this action and not
      // UPSERT_BILLING_ACCOUNT.
      // TODO: leo 10.4.2022 - the type is likely never `WizardType.NEW_VOICE_SUBSCRIPTION`, as it seems the code uses
      //  `WizardType.NEW_MOBILE_BROADBAND` to handle BA creation during new sub ordering, regardless of actual type of
      //  sub being ordered (MBB vs Voice vs M2M).
      const actionsInState = !(action.wizardParams?.type === WizardType.NEW_MOBILE_BROADBAND);
      return {
        ...state,
        actions: actionsInState
          ? updateActionStatePhase(
              TypeKeys.UPSERT_BILLING_ACCOUNT,
              state!,
              ActionPhase.IN_PROGRESS,
              ActionPhase.SUCCESS
            )
          : state!.actions,
        editingChanges: undefined,
        errors: undefined,
        invoicesBillingAccounts: getSortedItems(
          action.billingAccount,
          action.isCreate,
          'billingAccountId',
          state!.invoicesBillingAccounts
        ),
        items: getSortedItems(action.billingAccount, action.isCreate, 'billingAccountId', state!.items),
        searchResults: getSortedItemsWithIdObject(
          { result: action.billingAccount as BillingAccountHeader },
          action.isCreate,
          'result',
          'billingAccountId',
          state!.searchResults
        ),
        saving,
        total: action.isCreate ? state!.total! + 1 : state!.total,
        loading: false,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED: {
      // Need to set saving to false here, as it was kept to true in the above case
      return {
        ...state,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER:
    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER:
    case TypeKeys.UPSERT_VIRTUAL_CATALOG: {
      if (action.newBillingAccount && !action.overridePreviousAction) {
        const errors = getErrorsFromUpsertBillingAccount(
          action.newBillingAccount,
          action.newBillingAccountValidationErrors,
          action.newBillingAccountCommonFunction
        );
        return {
          ...state,
          editingChanges: undefined,
          errors,
          saving: false,
          loading: false,
        };
      }
      if (
        action.type === TypeKeys.UPSERT_VIRTUAL_CATALOG &&
        action.billingAccountRequired &&
        !action.catalog.billingAccountId
      ) {
        return {
          ...state,
          errors: [
            {
              type: CommonErrorType.VALIDATION,
              field: 'selectBillingAccount',
              message: t.VPVR(fieldCantBeEmptyMsg),
            },
          ],
          saving: false,
          loading: false,
        };
      }
      return state;
    }

    case TypeKeys.CANCEL_BILLING_ACCOUNT_EDIT: {
      return {
        ...state,
        editingChanges: undefined,
        errors: undefined,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.UPSERT_CONTACT_FULFILLED: {
      const { params, contact } = action;
      const billingAccountContactIdRef: string | undefined = params ? params.billingAccountContactIdRef : undefined;
      let billingAccountContactTypeRef: string | undefined;
      let billingAccountContactNameRef: string | undefined;
      if (billingAccountContactIdRef === 'billingContactId') {
        billingAccountContactTypeRef = 'billingContactType';
        billingAccountContactNameRef = 'billingContactName';
      } else if (billingAccountContactIdRef === 'billReceiverId') {
        billingAccountContactTypeRef = 'billReceiverType';
        billingAccountContactNameRef = 'billReceiverName';
      }

      if (
        state &&
        state.items &&
        billingAccountContactIdRef &&
        billingAccountContactTypeRef &&
        billingAccountContactNameRef
      ) {
        let contactName: string | undefined;
        if (contact?.person) {
          contactName = contact.person.firstName + ' ' + contact.person.lastName;
        } else if (contact?.commonFunction) {
          contactName = contact.commonFunction.name + ' ' + contact.commonFunction.name;
        }
        const editingChange = {
          [billingAccountContactIdRef]: contact.contactId,
          [billingAccountContactTypeRef!]: contact.contactType.toString(),
          [billingAccountContactNameRef!]: contactName,
        };
        return {
          ...state,
          editingChanges: state.editingChanges ? state.editingChanges.concat(editingChange) : [editingChange],
        };
      }
      return state;
    }

    case TypeKeys.LOAD_INVOICE_DOCUMENTS_FULFILLED:
    case TypeKeys.LOAD_INVOICES_FULFILLED: {
      let newState: BillingAccountsState & ActionsHistory;
      if (!state) {
        newState = { saving: false, loading: false };
      } else {
        newState = { ...state };
      }
      const invoicesBillingAccounts = sortArray(
        mergeArrays<BillingAccount>(
          'billingAccountId',
          'lastModified',
          newState.invoicesBillingAccounts,
          action.billingAccounts
        ),
        'created',
        'desc'
      );
      return {
        ...newState,
        invoicesBillingAccounts,
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED:
    case TypeKeys.LOG_OUT: {
      return null;
    }

    case TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT: {
      const errors = getErrorsFromUpsertPersonBillingAccount(
        action.request.address,
        action.request.email,
        action.validationErrors
      );
      if (errors) {
        return {
          ...state,
          errors,
          saving: false,
          loading: false,
        };
      } else {
        return {
          ...reduceCrudAction(action, state),
          saving: true,
          loading: false,
        };
      }
    }

    case TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED: {
      let billingAccount: BillingAccount;

      if (!action.isCreate) {
        const originalBillingAccount = findPersonBillingAccount(state || undefined);
        if (!originalBillingAccount) {
          throw new Error(`Could not find the original person billing account from state`);
        }
        billingAccount = {
          ...originalBillingAccount,
          payerAddress: action.request.address,
          billReceiverEmail: action.request.email,
          ...action.response,
        };
      } else {
        billingAccount = {
          billingAccountStatus: BillingAccountStatus.OPEN,
          sourceSystem: ConsumerBA,
          billLanguage: 'FI',
          deliveryMethod: BillingAccountDeliveryMethod.EMAIL,
          payerAddress: action.request.address,
          billReceiverEmail: action.request.email,
          billingContactId: action.billingContactId,
          payerName: action.payerName,
          billingContactName: action.payerName,
          ...action.response,
        };
      }
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: getSortedItems(billingAccount, action.isCreate, 'billingAccountId', state!.items),
        errors: undefined,
        saving: false,
        total: action.isCreate ? state!.total! + 1 : state!.total,
        loading: false,
      };
    }

    case TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
        saving: false,
        loading: false,
      };
    }

    case TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS: {
      return {
        ...reduceCrudAction(action, state),
        saving: false,
        loading: true,
      };
    }

    case TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FULLFILLED: {
      if (action.billingAccountId) {
        // Just store the total amount here, subscriptions will be reduced into subscriptionsState
        const newState = state!.billingAccountSubscriptions?.find(ba => ba.billingAccountId === action.billingAccountId)
          ? state!.billingAccountSubscriptions.map(ba => {
              if (ba.billingAccountId === action.billingAccountId) {
                ba.totalSubscriptions = action.totalSubscriptions;
                return ba;
              }
              return ba;
            })
          : (state!.billingAccountSubscriptions || []).concat([
              {
                billingAccountId: action.billingAccountId,
                totalSubscriptions: action.totalSubscriptions,
              },
            ]);

        return {
          ...state,
          actions: updateActionStatePhase(
            TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          errors: undefined,
          saving: false,
          billingAccountSubscriptions: newState,
          loading: false,
        };
      }
      return state;
    }

    case TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
        loading: false,
      };
    }

    default:
      return state;
  }
}
