import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { t } from '../../../../common/i18n/index.js';

export const DnsDomainDisclaimerEnableMfa = () => (
  <CL.Disclaimer
    disclaimerType="info"
    icon={<CL.Icon icon="lock" type="regular" aria-hidden="true" />}
    title={t.ZU8S('Activate two-factor authentication')}
    text={t.FDUX(
      'Access to Elisa DNS management requires two-factor authentication. You can activate two-factor authentication on the Company details and settings page.'
    )}
    visible={true}
  >
    <Link to="/omaelisa/yritystiedot/asetukset">
      <CL.Button color="transparent" size="s">
        {t.COTT('Activate')}
      </CL.Button>
    </Link>
  </CL.Disclaimer>
);
