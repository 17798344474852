import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import { VirtualCatalogDraftOptions } from './VirtualCatalogDraftOptions.js';
import { VirtualCatalogInformation } from './VirtualCatalogInformation';
import { VirtualCatalogManageButtonGroup } from './VirtualCatalogManageButtonGroup.js';
import { VirtualCatalogProductsSummary } from '../CatalogProductsSummary/VirtualCatalogProductSummary.js';
import { VirtualCatalogPublishedDetails } from './VirtualCatalogPublishedDetails.js';
import { deviceListsMsg, listOfDevicesMsg, omaElisaForCompaniesMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { BillingAccountHeader, Catalog, VirtualCatalog } from '../../generated/api/models.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { InProgressCatalogAction } from '../../common/enums.js';

import './VirtualCatalogDetails.scss';

export interface VirtualCatalogDetailsProps {
  virtualCatalog: VirtualCatalog;
  catalog: Catalog;
  products: Record<string, Array<CatalogProduct>>;
  productsPublished?: Record<string, Array<CatalogProduct>>;
  companyName: string;
  onAddMoreProducts: () => void;
  inProgressAction?: InProgressCatalogAction;
  billingAccounts: BillingAccountHeader[];
  replaceCatalogs: boolean;
  accountMasterId: string;
}

export const VirtualCatalogDetails = ({
  catalog,
  virtualCatalog,
  products,
  productsPublished,
  companyName,
  onAddMoreProducts,
  inProgressAction,
  billingAccounts,
  replaceCatalogs,
  accountMasterId,
}: VirtualCatalogDetailsProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const onShowDialog = (params: DialogParams) => navigate(search.toString(), { state: params });
  const [viewPublished, setViewPublished] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  });

  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: catalog.name },
      ]}
    />
  );

  if (viewPublished && virtualCatalog.published && productsPublished) {
    return (
      <VirtualCatalogPublishedDetails
        breadCrumbs={breadCrumbs}
        publishedDate={virtualCatalog.published?.lastModified}
        billingAccount={
          billingAccounts.find(ba => ba.billingAccountId === virtualCatalog.published?.billingAccountId)
            ?.billingAccountDisplayId || '-'
        }
        catalog={virtualCatalog.published}
        products={productsPublished}
        companyName={companyName}
        onClickBack={() => setViewPublished(false)}
        mdmId={accountMasterId}
      />
    );
  } else {
    return (
      <DetailsWrapper
        detailsTop={breadCrumbs}
        id={`catalog-details-${catalog.catalogCode}`}
        classes={[`of-catalog-details`]}
        heading={catalog.name}
        headingBottom={companyName}
        headingTop={t.Z0HF(listOfDevicesMsg)}
        heroPicto="billing-account"
      >
        <VirtualCatalogDraftOptions
          catalog={catalog}
          virtualCatalog={virtualCatalog}
          viewPublished={viewPublished}
          setViewPublished={setViewPublished}
          inProgressAction={inProgressAction}
        />
        <VirtualCatalogInformation
          virtualCatalog={virtualCatalog}
          billingAccounts={billingAccounts}
          catalog={catalog}
          mdmId={accountMasterId}
        />
        <VirtualCatalogManageButtonGroup
          catalog={catalog}
          virtualCatalog={virtualCatalog}
          onShowDialog={onShowDialog}
          onAddMoreProducts={onAddMoreProducts}
          replaceCatalogs={replaceCatalogs}
          accountMasterId={accountMasterId}
        />

        {!products || catalog.productCategories.some(value => !products[value]) ? (
          // load all category products first and then show product summary
          <Loading />
        ) : (
          <VirtualCatalogProductsSummary
            {...{
              products,
            }}
          />
        )}
      </DetailsWrapper>
    );
  }
};
