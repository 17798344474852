import { Dropdown } from '../../../common/react-hook-form/components/Dropdown.js';
import { EOperatorChangeRequestForm } from '../EOperatorChange/EOperatorChangeRequestForm.js';
import { Message, TextInput } from '../../../common/react-hook-form/fields/index.js';
import { SupportCase } from '../../../generated/api/models.js';
import {
  billingEOperatorChangesMsg,
  categoryMsg,
  coverageInterruptionsIncidentsMsg,
  eInvoiceMsg,
  invoicingAndCustomerDetailsMsg,
  myServicesMsg,
  newOrdersAndOfferRequestsMsg,
  openOrdersMsg,
  otherTopicMsg,
  pukCodeMsg,
  t,
  technicalAdviceMsg,
  terminationMsg,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { pushSupportCaseGAEventToDataLayer } from '../../../common/analytics.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import type { ContactSupportFormValues, ContactSupportInfo } from '../index.js';
import type { EOperatorChangeRequestFormValues } from '../EOperatorChange/EOperatorChangeRequestForm.js';

import './SupportCaseContent.scss';

interface ExtraFieldProps {
  placeholder: string;
  label: string;
}

export interface SupportCaseContentProps {
  error?: string;
  extraField?: ExtraFieldProps;
  extraInfo?: JSX.Element;
  selfService: boolean;
  isEditing: boolean;
  isSubmitting?: boolean;
  onEOperatorChangeRequestFormSubmit: (
    formValues: EOperatorChangeRequestFormValues,
    contactValues: ContactSupportInfo
  ) => void;
  onSetEditMode?: (isEditing: boolean) => void;
}

export const SupportCaseContent = ({
  extraInfo,
  extraField,
  selfService,
  isEditing,
  isSubmitting,
  onEOperatorChangeRequestFormSubmit,
  onSetEditMode = () => {},
}: SupportCaseContentProps) => {
  const { getValues, reset } = useFormContext<ContactSupportFormValues & EOperatorChangeRequestFormValues>();
  const values = getValues();
  const { authenticatedUser } = useAuth();
  const [isEOperatorChange, setIsEOperatorChange] = useState(false);
  const features = [
    { label: t.J923(newOrdersAndOfferRequestsMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_OFFER_REQUEST },
    { label: t.NC0D(openOrdersMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_OPEN_ORDERS },
    { label: t.WWSI(invoicingAndCustomerDetailsMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_BILLING },
    { label: t.TZYQ(billingEOperatorChangesMsg), value: SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES },
    { label: t.CG4F(myServicesMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_SERVICES },
    { label: t.V7EA(technicalAdviceMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_TECHNICAL },
    { label: t.DKUZ(coverageInterruptionsIncidentsMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_COVERAGE },
    { label: t.MN35(terminationMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_CANCEL },
    { label: t.K5TL(pukCodeMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_PUK },
    { label: t.CHWD(otherTopicMsg), value: SupportCase.FeatureEnum.CONTACT_INFO_OTHER },
  ];

  const onFeatureSelected = (feature: string) => {
    const eOperatorChangeSelected = feature === SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES;
    setIsEOperatorChange(eOperatorChangeSelected);
    if (eOperatorChangeSelected) {
      reset({
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        businessId: values.businessId,
        businessName: values.businessName,
        attachments: [],
        feature: SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES,
        willChangeTakeEffectImmediately: true,
        actingOnBehalfOfCompany: false,
        allBillingAccountsApply: true,
        deliveryMethod: t.IK1D(eInvoiceMsg),
        billLanguage: authenticatedUser?.preferredLanguage?.toUpperCase(),
        isBillingContactPerson: true,
        payerNameOrAddressChange: false,
      });
    } else {
      onSetEditMode(true);
    }
    pushSupportCaseGAEventToDataLayer('form_category_select', selfService, feature);
  };

  return (
    <div className={`of-support-case-description ${dsClass.MARGIN_TOP_6}`}>
      <h4>{t.TPI4('Your message')}</h4>
      <Dropdown label={t.CV5H(categoryMsg)} name="feature" items={features} onValueChange={onFeatureSelected} />
      {extraInfo}
      {extraField && (
        <div>
          <TextInput name="extraField" label={extraField.label} placeholder={extraField.placeholder} required={false} />
        </div>
      )}
      {isEOperatorChange ? (
        <EOperatorChangeRequestForm
          isAnonymousUser={!selfService}
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          contactValues={values}
          onSubmit={onEOperatorChangeRequestFormSubmit}
          onSetEditMode={onSetEditMode}
        />
      ) : (
        <div className={dsClass.MARGIN_TOP_1}>
          <Message name="description" placeholder={t.YZMF('Write a message')} required={true} />
        </div>
      )}
    </div>
  );
};
