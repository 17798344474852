import * as CL from '@design-system/component-library';
import { Attachments } from '../Attachments/Attachments.js';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { ContactSupportInfoFields } from './ContactSupportInfoFields.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PrefilledContactSupportDetails } from './PrefilledContactSupportDetails.js';
import { ReCaptcha } from '../ReCaptcha/ReCaptcha.js';
import { SupportCase } from '../../generated/api/models.js';
import { SupportCaseContent } from './SupportCaseContent/SupportCaseContent.js';
import {
  companyMsg,
  contactInfoMsg,
  messageVisibilityAcknowledgementMsg,
  submitMsg,
  t,
} from '../../common/i18n/index.js';
import { createSupportCaseAnonymous, createSupportCaseAuthenticated, postFile } from '../../common/fetch.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { featureTranslation } from '../../common/utils/supportCaseUtils.js';
import {
  getEOperatorChangeRequestFormValuesAsSingleString,
  mapContactSupportFormValuesToContactDetails,
} from './contactSupportFormUtils.js';
import { getUserAccounts } from '../Header/dynamic/headerFunctions.js';
import { isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import { useStore } from 'react-redux';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { ContactSupportInfo, SupportCaseContactInfo } from './types.js';
import type { EOperatorChangeRequestFormValues } from './EOperatorChange/EOperatorChangeRequestForm.js';
import type { PostFileResponse } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './ContactSupportForm.scss';

const allowedFileTypes = ['.xls', '.xlsx', '.doc', '.docx', '.txt', '.pdf', '.jpg', '.jpeg', '.png'];

export interface ExtraField {
  label: string;
  placeholder: string;
}

/**
 * Fields the form (and react-hook-form) in this component uses
 */
export type ContactSupportFormValues = ContactSupportInfo & {
  businessId: string;
  businessName: string;
  description: string;
  extraField: string;
  feature?: SupportCase.FeatureEnum;
  attachments: File[];
  messageVisibilityAcknowledgement: boolean;
  reCaptchaResponse?: string;
  accountMasterId: string;
};

export interface ContactSupportFormProps {
  action?: string;
  contactInfo?: SupportCaseContactInfo;
  extraField?: ExtraField;
  extraInfo?: JSX.Element;
  selfService?: boolean;
  sourceUrl: string;
  onSendCaseAuthenticated: (feature: SupportCase.FeatureEnum) => void;
  onSendCaseAnonymous: (feature: SupportCase.FeatureEnum) => void;
}

const postFiles = async (
  setIsSendingFile: (isSending: boolean) => void,
  attachments: File[],
  addStartedUpload: (fileName: string) => void,
  addFinishedUpload: (fileName: string) => void
) => {
  setIsSendingFile(true);
  const postFileResponses = await Promise.all(
    attachments.map(async f => {
      addStartedUpload(f.name);
      const postFileResult = await postFile(f);
      addFinishedUpload(f.name);
      return postFileResult;
    })
  );
  setIsSendingFile(false);
  return postFileResponses;
};

export const ContactSupportForm = (props: ContactSupportFormProps) => {
  const {
    contactInfo,
    extraField,
    extraInfo,
    onSendCaseAuthenticated,
    onSendCaseAnonymous,
    selfService = false,
    sourceUrl,
  } = props;
  const { authenticatedUser } = useAuth();
  // This used to be within fieldValues below that the SupportCaseContent had a callback
  // to modify. That code was removed but this value is still being used in lead/support case
  // data as both acknowledgement and emailAcknowledgementOnly. Keeping this here as-is
  // as it's too confusing to be removed
  const defaultAcknowledgementValue = false;

  const [isEditingEOperatorChange, setIsEditingEOperatorChange] = useState(true);
  const [isSendingFiles, setIsSendingFiles] = useState(false);
  const [uploadFinished, setUploadFinished] = useState([] as string[]);
  const [uploadStarted, setUploadStarted] = useState([] as string[]);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const userAccounts = getUserAccounts(authenticatedUser);
  const methods = useForm<ContactSupportFormValues>({
    mode: 'all',
    defaultValues: {
      attachments: [],
      reCaptchaResponse: '',
      accountMasterId: userAccounts.find(({ active }) => active)?.accountMasterId,
    },
  });
  const { watch, getValues, setValue, handleSubmit } = methods;
  const { feature, messageVisibilityAcknowledgement, reCaptchaResponse } = watch();
  const { featureFlags } = useStore<State>().getState().config;
  const consolidatedViewsEnabled = isFeatureEnabledForUser(
    'consolidatedViews',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );

  const isEOperatorChange = () => feature === SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES;

  const addFinishedUpload = (fileName: string) => {
    setUploadFinished(prevFinished => [...prevFinished, fileName]);
  };
  const addStartedUpload = (fileName: string) => {
    setUploadStarted(prevUploadStarted => [...prevUploadStarted, fileName]);
  };

  const onSubmit = async (formValues: ContactSupportFormValues) => {
    const description = formValues.extraField
      ? `${formValues.description}\n\n${formValues.extraField}`
      : formValues.description;
    const supportCase: SupportCase = {
      feature: formValues.feature!,
      action: featureTranslation(formValues.feature),
      url: sourceUrl,
      description,
      emailAcknowledgementOnly:
        formValues.feature !== SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES
          ? defaultAcknowledgementValue
          : undefined,
    };

    if (selfService) {
      const postFileResponses: PostFileResponse[] =
        formValues.attachments.length > 0
          ? await postFiles(setIsSendingFiles, formValues.attachments, addStartedUpload, addFinishedUpload)
          : [];
      setIsSubmitInProgress(true);
      await createSupportCaseAuthenticated(
        {
          ...supportCase,
          attachmentIds: postFileResponses.map(r => r.id),
        },
        formValues.accountMasterId
      );
      onSendCaseAuthenticated(supportCase.feature);
      setIsSubmitInProgress(false);
    } else {
      setIsSubmitInProgress(true);
      await createSupportCaseAnonymous(
        supportCase,
        mapContactSupportFormValuesToContactDetails(formValues),
        reCaptchaResponse
      );
      onSendCaseAnonymous(supportCase.feature);
      setIsSubmitInProgress(false);
    }
  };

  const onEOperatorChangeRequestFormSubmit = async (
    eOperatorChangeRequestFormValues: EOperatorChangeRequestFormValues,
    contactValues: ContactSupportInfo
  ) => {
    setValue(
      'description',
      getEOperatorChangeRequestFormValuesAsSingleString(eOperatorChangeRequestFormValues, contactValues)
    );
    await onSubmit(getValues());
  };

  const eOperatorChangeInEditMode = isEOperatorChange() && isEditingEOperatorChange;
  const shouldShowReCaptcha = !selfService && !eOperatorChangeInEditMode;

  return (
    <FormProvider {...methods}>
      <div className={`of-contact-support-form ${dsClass.MARGIN_BOTTOM_6}`}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="contact-info">
            <h4>{t.GJS9(contactInfoMsg)}</h4>
            {selfService && consolidatedViewsEnabled && isMultiBiz(authenticatedUser) && (
              <CompanySelector
                autoCompleteConfig={{ isSortable: false }}
                className={dsClass.MARGIN_BOTTOM_4}
                labelText={t.KJTS(companyMsg)}
                onInputChange={(
                  _e: ChangeEvent | KeyboardEvent | MouseEvent,
                  option: Partial<CL.HeaderUserAccount>
                ) => {
                  if (option?.accountMasterId) {
                    setValue('accountMasterId', option.accountMasterId);
                  }
                }}
                userAccounts={userAccounts}
                initialMdmId={mdmId}
              />
            )}
            {
              // But show prefilled contact information if we already know their details
              // NOTE: at the moment it's possible to have both or none of these visible. This boolean logic is based
              // on original code that I didn't dare to challenge
              contactInfo && (
                <PrefilledContactSupportDetails
                  contactInfo={contactInfo}
                  consolidatedViewsEnabled={consolidatedViewsEnabled}
                  authenticatedUser={authenticatedUser}
                />
              )
            }
            {
              // In case we don't know user's contact information, allow them to type it in the form
              !selfService && (
                <ContactSupportInfoFields readonly={!isEditingEOperatorChange} readonlyValues={getValues} />
              )
            }
          </div>
          <SupportCaseContent
            extraInfo={extraInfo}
            extraField={extraField}
            selfService={selfService}
            onEOperatorChangeRequestFormSubmit={onEOperatorChangeRequestFormSubmit}
            onSetEditMode={setIsEditingEOperatorChange}
            isEditing={isEditingEOperatorChange}
            isSubmitting={isSubmitInProgress}
          />
          {!isEOperatorChange() && selfService && (
            <>
              <Attachments
                maxFiles={5}
                maxFileSizeMB={30}
                allowedFileTypes={allowedFileTypes}
                uploadStatus={{ started: uploadStarted, finished: uploadFinished }}
              />
              <div className={dsClass.MARGIN_VERTICAL_4}>
                <Checkbox
                  name="messageVisibilityAcknowledgement"
                  label={t.LNZ8(messageVisibilityAcknowledgementMsg)}
                  required={true}
                />
              </div>
            </>
          )}

          {!selfService && (
            <div className={shouldShowReCaptcha ? '' : dsClass.DISPLAY_NONE}>
              <ReCaptcha name="reCaptchaResponse" required={shouldShowReCaptcha} />
            </div>
          )}

          {!isEOperatorChange() && (
            <CL.Button
              type="submit"
              size="l"
              disabled={selfService && !messageVisibilityAcknowledgement}
              loading={isSubmitInProgress || isSendingFiles}
            >
              {t.FSWU(submitMsg)}
            </CL.Button>
          )}
        </form>
      </div>
    </FormProvider>
  );
};
