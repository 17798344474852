import { type BreadCrumbList, BreadCrumbs } from '../BreadCrumbs';
import { DetailsWrapper } from '../DetailsWrapper';
import { MoveContactForm } from './MoveContactForm';
import { generatePath, useLocation } from 'react-router-dom';
import { getCompanyName } from '../../common/utils/accountUtils';
import { moveContactToAnotherCompanyMsg, omaElisaForCompaniesMsg, t, usersMsg } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useAuth } from '../../public/site/AuthProvider';
import type { Contact } from '../../generated/api/contact';
import type { SubscriptionSearchResponse } from '../../generated/api/subscriptionSearchResponse';

export interface MoveContactProps {
  broadbandSubscriptions: SubscriptionSearchResponse[];
  contact: Contact;
  contactMasterId: string;
  deviceSubscriptions: SubscriptionSearchResponse[];
  mdmId: string;
  voiceSubscriptions: SubscriptionSearchResponse[];
}

export const MoveContact = ({ contact, contactMasterId, mdmId, ...rest }: MoveContactProps) => {
  const { authenticatedUser } = useAuth();
  const { search } = useLocation();
  const contactName = `${contact.person?.firstName} ${contact.person?.lastName}`;

  const breadcrumbPaths = [
    { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
    { name: t.BE4A(usersMsg), path: paths.COMPANY_INFO_CONTACTS },
    { name: contactName, path: generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId }) + search },
    { name: t.XCDT(moveContactToAnotherCompanyMsg) },
  ] as BreadCrumbList;

  return (
    <DetailsWrapper
      classes={['of-contact-details']}
      detailsTop={<BreadCrumbs breadCrumbPaths={breadcrumbPaths} />}
      id={`move-contact-${contact?.contactId}`}
      heading={t.XCDT(moveContactToAnotherCompanyMsg)}
      headingBottom={`${contactName} | ${getCompanyName(authenticatedUser, mdmId)}`}
      heroPicto="cl-user"
    >
      <div className="of-move-contact-form">
        <MoveContactForm contact={contact} contactMasterId={contactMasterId} mdmId={mdmId!} {...rest} />
      </div>
    </DetailsWrapper>
  );
};
