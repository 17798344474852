import * as CL from '@design-system/component-library';
import { OpenFormGridCol } from './OpenFormGrid.js';
import { setValueOptions } from '../OpenFormHooks/useOpenFormMethods.js';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const OpenFormDropdown = ({
  name,
  items,
  ...props
}: { name: string } & Omit<CL.DropdownProps, 'selectedValue'>) => {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (!getValues(name)) {
      setValue(name, items[0]?.value, setValueOptions);
    }
  }, [name, items, getValues, setValue]);

  return (
    <OpenFormGridCol colWidth={6}>
      <CL.Dropdown
        {...props}
        items={items}
        selectedValue={getValues(name)}
        onValueChange={el => {
          setValue(name, el.dataset.value, setValueOptions);
          props.onValueChange?.(el);
        }}
      />
    </OpenFormGridCol>
  );
};
