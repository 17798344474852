import { Link, generatePath } from 'react-router-dom';
import { SubscriptionIcon } from './SubscriptionIcon.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { ReactNode } from 'react';
import type { SubscriptionHeader } from '../../generated/api/models.js';

interface SubscriptionLinkProps {
  path: string;
  subscription: SubscriptionHeader;
  children?: ReactNode;
  search?: string;
  onRowClick?: MultiAccountRowClickFunction;
}
export const SubscriptionLink = ({ children, path, subscription, search, onRowClick }: SubscriptionLinkProps) => {
  if (!subscription.accountMasterId) {
    throw new Error('missing mdmId');
  }
  const { authenticatedUser } = useAuth();
  const effectivePath = `${generatePath(path, { subscriptionId: getSubscriptionUrlId(subscription) })}?mdmId=${subscription.accountMasterId}`;

  return (
    <Link
      to={effectivePath}
      className="of-subscription-table__text-column"
      onClick={
        onRowClick
          ? e => {
              e.preventDefault();
              onRowClick(
                effectivePath,
                getCompanyName(authenticatedUser, subscription.accountMasterId),
                subscription.accountMasterId
              );
            }
          : undefined
      }
    >
      <SubscriptionIcon subscription={subscription} />
      <div>
        {highlightKeyword(subscription.subscriptionName || '', search)}
        {children}
      </div>
    </Link>
  );
};
