import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { AddContactDialogParams } from '../../common/types/dialog.js';
import type { CommonError } from '../../common/types/errors.js';
import type {
  CompanyInfoResponse,
  Contact,
  ContactsResponse,
  GenericError,
  PutContactResponse,
} from '../../generated/api/models.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

/**
 * Contacts.
 */
export interface LoadContactsAction extends DisplayItemsLoadAction<TypeKeys.LOAD_CONTACTS, Contact> {
  loadMore?: boolean;
  forceLoad?: boolean;
}

export interface LoadContactsFulfilledAction {
  type: TypeKeys.LOAD_CONTACTS_FULFILLED;
  contacts?: Contact[];
  total: number;
}

export const loadContacts = (
  displayId?: string,
  sort?: CompositeListSort,
  search?: string,
  loadMore?: boolean,
  forceLoad?: boolean
): LoadContactsAction => {
  return {
    displayId,
    loadMore,
    id: generateActionId(),
    search,
    sort,
    type: TypeKeys.LOAD_CONTACTS,
    forceLoad,
  };
};

export const loadContactsFulfilled = (response: ContactsResponse): LoadContactsFulfilledAction => {
  return {
    contacts: response.contacts,
    total: response.total,
    type: TypeKeys.LOAD_CONTACTS_FULFILLED,
  };
};

export type LoadContactsFailedAction = ErrorAction<TypeKeys.LOAD_CONTACTS_FAILED>;

export const loadContactsFailed: ErrorActionCreator<TypeKeys.LOAD_CONTACTS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadContactsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.LOAD_CONTACTS_FAILED,
  };
};

export interface UpsertContactAction extends CrudAction<TypeKeys.UPSERT_CONTACT> {
  contact: Contact;
  params?: AddContactDialogParams;
  showNotification?: boolean;
  validationErrors?: CommonError[];
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  forceUpsert?: boolean;
  bothNamesChanged?: boolean;
}

export const upsertContact = (
  contact: Contact,
  showNotification?: boolean,
  forceUpsert?: boolean,
  bothNamesChanged?: boolean,
  validationErrors?: CommonError[],
  params?: AddContactDialogParams,
  customerType?: CompanyInfoResponse.CustomerTypeEnum
): UpsertContactAction => {
  return {
    contact,
    id: generateActionId(),
    params,
    customerType,
    showNotification,
    type: TypeKeys.UPSERT_CONTACT,
    validationErrors,
    forceUpsert: forceUpsert,
    bothNamesChanged: bothNamesChanged,
  };
};

export interface UpsertContactFulfilledAction {
  type: TypeKeys.UPSERT_CONTACT_FULFILLED;
  params?: AddContactDialogParams;
  contact: Contact;
  showNotification?: boolean;
}

export const upsertContactFulfilled = (
  { contactId, lastModified }: PutContactResponse,
  contact: Contact,
  params?: AddContactDialogParams,
  showNotification?: boolean
): UpsertContactFulfilledAction => {
  return {
    contact: {
      ...contact,
      contactId,
      lastModified,
    },
    params,
    showNotification,
    type: TypeKeys.UPSERT_CONTACT_FULFILLED,
  };
};

export type UpsertContactFailedAction = ErrorAction<TypeKeys.UPSERT_CONTACT_FAILED>;

export const upsertContactFailed: ErrorActionCreator<TypeKeys.UPSERT_CONTACT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UpsertContactFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.UPSERT_CONTACT_FAILED,
  };
};

export interface UpdateUserRightsFulfilledAction {
  type: TypeKeys.UPDATE_USER_RIGHTS_FULFILLED;
}

export const updateUserRightsFulfilled = (): UpdateUserRightsFulfilledAction => {
  return {
    type: TypeKeys.UPDATE_USER_RIGHTS_FULFILLED,
  };
};

export type UpdateUserRightsFailedAction = ErrorAction<TypeKeys.UPDATE_USER_RIGHTS_FAILED>;

export const updateUserRightsFailed = (): UpdateUserRightsFailedAction => {
  return {
    type: TypeKeys.UPDATE_USER_RIGHTS_FAILED,
  };
};

export interface ResetContactsAction {
  type: TypeKeys.RESET_CONTACTS;
}

export const resetContacts = (): ResetContactsAction => ({
  type: TypeKeys.RESET_CONTACTS,
});

export type ContactsActionTypes =
  | LoadContactsAction
  | LoadContactsFulfilledAction
  | LoadContactsFailedAction
  | UpsertContactAction
  | UpsertContactFulfilledAction
  | UpsertContactFailedAction
  | ResetContactsAction
  | UpdateUserRightsFailedAction
  | UpdateUserRightsFulfilledAction;
