import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog.js';
import { SubscriptionType } from '../../generated/api/models.js';
import { capitalize } from '../../common/utils/stringUtils.js';
import { continueMsg, t } from '../../common/i18n/index.js';
import type { ChangeSubscriptionContactDialogParams } from '../../common/types/dialog.js';

const OmaLaiteLaskuWarning = () => (
  <div>
    <strong>{t.CNY8('This device has Elisa Oma Laitelasku')}</strong>
    <br />
    <span>
      {t.WMWY(
        'You are about to change the user for a device that has Elisa Oma Laitelasku service. The remaining employee share will be invoiced from the company.'
      )}
    </span>
    <p>{t.OPI0('The billing account can be changed when confirming the changes.')}</p>
  </div>
);

const VoiceMailWarning = () => (
  <div>
    <p>
      <span>
        <strong>{t.EEE3('NOTE!').toUpperCase()}</strong>
      </span>
      {` ${t.ULM4(
        `When changing the subscription user, remember to terminate also the user's personal voicemail service. The new voicemail service must be activated for the new user.`
      )}`}
    </p>
  </div>
);

export const ChangeSubscriptionContactDialog = ({
  showOmaLaiteLaskuWarning,
  showVoiceMailWarning,
  subscriptionType,
  onCancel,
  onConfirm,
}: ChangeSubscriptionContactDialogParams) => {
  const subscriptionTypeMsg = subscriptionType === SubscriptionType.DEVICE ? t.ACH1('device') : t.D2UO('subscription');

  return (
    <ConfirmationDialog
      confirmButtonText={t.I62A(continueMsg)}
      header={t.KSXW('Switching user affects {} billing information', subscriptionTypeMsg)}
      body={
        <>
          <p>
            {t.LNHO(
              `{0} reference and the cost center details will be automatically updated according to the new person's information. Please check your {1} billing information if necessary.`,
              capitalize(subscriptionTypeMsg),
              subscriptionTypeMsg
            )}
          </p>
          {showOmaLaiteLaskuWarning && <OmaLaiteLaskuWarning />}
          {showVoiceMailWarning && <VoiceMailWarning />}
        </>
      }
      onCloseDialog={onCancel}
      onConfirm={onConfirm}
    />
  );
};
