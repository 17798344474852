import { AddOnPurpose } from '../../generated/api/addOnPurpose';
import { SalesType } from '../../generated/api/salesType';
import { SupportCase } from '../../generated/api/supportCase';
import { centsToEuros, ensureNonNegativeTotals } from '../../common/utils/priceUtils';
import { createBillingAccount, createSupportCaseAuthenticated } from '../../common/fetch';
import { createRowForDescription, getAddonsText, getContactOrPurposeOfUse } from '../../common/utils/supportCaseUtils';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils';
import { getTotalSums } from '../../common/utils/commercialProductUtils';
import { handleContacts } from './contactUtils';
import { t } from '../../common/i18n';
import type { AddedAddon } from '../../common/types/addon';
import type { BillingAccount } from '../../generated/api/billingAccount';
import type { BillingAccountSearchResponse } from '../../generated/api/billingAccountSearchResponse';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation';
import type { CompanyInfoState } from '../../common/types/states';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct';
import type { Contact } from '../../generated/api/contact';
import type { ContactHeader } from '../../generated/api/contactHeader';
import type { DeliveryAddress } from '../../generated/api/deliveryAddress';
import type { PurposeOfUseOrContact, SimCardConfiguration } from '../../common/types/subscription';

export interface LiikkuvaWifiOrder {
  commercialProductName: string;
  companyInfo: {
    postalAddress?: string;
    postCode?: string;
    postOffice?: string;
    name?: string;
    businessId?: string;
  };
  deliveryAddress: DeliveryAddress;
  purposeOfUseOrContact: PurposeOfUseOrContact;
  monthlyRecurringCharge: number;
  offerName: string;
  oneTimeCharge: number;
  campaignName: string | undefined;
  simCardConfiguration: SimCardConfiguration | undefined;
  addedAddOns: AddedAddon[];
  billingAccountDisplayId?: string;
}

const getCampaignName = (campaignName?: string): string => (campaignName ? `Kampanja: ${campaignName}` : '');

export const getSupportCaseDescription = ({
  offerName,
  addedAddOns,
  billingAccountDisplayId,
  deliveryAddress,
  campaignName,
  oneTimeCharge,
  monthlyRecurringCharge,
  purposeOfUseOrContact,
}: LiikkuvaWifiOrder): string =>
  `
      Valitut tuotteet & saatavuustiedot:
      Valittu saatavilla oleva tuote: ${offerName}
      Kertamaksut: ${centsToEuros(oneTimeCharge)}
      Kuukausimaksut: ${centsToEuros(monthlyRecurringCharge)}
      ${getCampaignName(campaignName)}

      ${getContactOrPurposeOfUse(purposeOfUseOrContact, true)}
      Toimitusosoite:
      ${deliveryAddress.address.line1}
      ${deliveryAddress.address.postalCode} ${deliveryAddress.address.postOffice}

      ${createRowForDescription('Liikkuva Wifi liittymän kustannuspaikka', purposeOfUseOrContact.costCenter)}
      ${createRowForDescription('Liikkuva Wifi liittymän viite', purposeOfUseOrContact.employeeNumber)}

      ${getAddonsText(addedAddOns)}

      Laskutussopimuksen numero: ${billingAccountDisplayId}
      `
    // Remove extra spaces and limit consecutive newlines to two
    .replace(/  +/g, '')
    .replace(/\n{3,}/g, '\n\n');

const createLiikkuvaWifiOrder = (
  configuredCommercialProduct: ConfiguredCommercialProduct,
  companyInfo: CompanyInfoState,
  offerName: string,
  purposeOfUseOrContact: PurposeOfUseOrContact,
  deliveryAddress: DeliveryAddress,
  campaignAssociation?: CampaignAssociation,
  addedAddons?: AddedAddon[],
  billingAccountDisplayId?: string
) => {
  const price = ensureNonNegativeTotals(
    getTotalSums([configuredCommercialProduct], campaignAssociation, SalesType.NEW_SALE)
  );

  const liikkuvaWifiOrder: LiikkuvaWifiOrder = {
    commercialProductName: configuredCommercialProduct.commercialProduct.commercialProductName,
    companyInfo: {
      postalAddress: companyInfo.address?.line1,
      postCode: companyInfo.address?.postalCode,
      postOffice: companyInfo.address?.postOffice,
      name: companyInfo.companyName,
      businessId: companyInfo.businessId,
    },
    deliveryAddress,
    purposeOfUseOrContact,
    monthlyRecurringCharge: price.totalMonthlyRecurringCharge,
    offerName,
    oneTimeCharge: price.totalOneTimeCharge,
    // Only add campaign name if it's from voucher
    campaignName: configuredCommercialProduct.campaignName,
    simCardConfiguration: configuredCommercialProduct.simCardConfiguration,
    addedAddOns: addedAddons || [],
    billingAccountDisplayId,
  };

  return liikkuvaWifiOrder;
};

const getDefaultAddedAddOns = (configuredCommercialProduct: ConfiguredCommercialProduct): AddedAddon[] =>
  configuredCommercialProduct.commercialProduct.addOnAssociations
    ?.filter(
      addOnAssociation =>
        addOnAssociation.addOnPurpose === AddOnPurpose.BY_DEFAULT ||
        configuredCommercialProduct.addedAddOns?.find(addedAddOn => addOnAssociation.addOnCode === addedAddOn.addOnCode)
    )
    .map(addOnAssociation => ({
      addOnAssociationCode: addOnAssociation.addOnAssociationCode,
      addOnCode: addOnAssociation.addOnCode,
      displayName:
        configuredCommercialProduct.commercialProduct?.associatedAddOns?.find(
          addedAddOn => addOnAssociation.addOnCode === addedAddOn.addOnCode
        )?.addOnProductName || '',
    }))
    .filter(ao => ao !== undefined) || [];

const getAddedAddons = (configuredCommercialProduct: ConfiguredCommercialProduct): AddedAddon[] => [
  ...getDefaultAddedAddOns(configuredCommercialProduct),
  ...(configuredCommercialProduct.addedAddOns || []),
];

const createNewBillingAccount = async (newBillingAccount?: BillingAccount, mdmId?: string) => {
  if (!newBillingAccount) {
    throw new Error('No needed data for billing account creation');
  }
  try {
    return await createBillingAccount(newBillingAccount, mdmId);
  } catch (err) {
    throw new Error(t.EBJW('Adding a new billing account failed. Please try again later.'));
  }
};

const getExistingBillingAccountDisplayId = (
  billingAccountSearchResponses: BillingAccountSearchResponse[],
  selectedBaId?: string
) => {
  const baDisplayId = billingAccountSearchResponses?.find(
    baSearchResponse => baSearchResponse.result.billingAccountId === selectedBaId
  )?.result.billingAccountDisplayId;
  if (baDisplayId) {
    return baDisplayId;
  }
  throw new Error(`Billing account ${selectedBaId} could not be found from existing billing accounts`);
};

// Liikkuva WiFi orders are processed like fixed broadband, requiring a support case in Salesforce instead of using
// the online order like other mobile broadband types
export const handleLiikkuvaWifiSupportCase = async (
  configuredOffer: ConfiguredOffer,
  companyInfo: CompanyInfoState,
  deliveryAddress: DeliveryAddress,
  billingAccountSearchResponses: BillingAccountSearchResponse[],
  contacts?: Contact[] | ContactHeader[],
  campaignAssociation?: CampaignAssociation,
  selectedBillingAccountId?: string,
  newBillingAccount?: BillingAccount,
  shouldUseDuplicateContact?: boolean
): Promise<void> => {
  const mdmId = getActiveAccountMasterId() || '';
  // There can be only one
  const { selectedCommercialProducts, offer } = configuredOffer;
  for (const configuredCommercialProduct of selectedCommercialProducts) {
    const addedAddons = getAddedAddons(configuredCommercialProduct);

    const purposeOfUseOrContact = await handleContacts(
      configuredCommercialProduct.purposeOfUseOrContact,
      contacts,
      mdmId,
      shouldUseDuplicateContact
    );

    // If the user has chosen existing billing account, use it. New one has to be created first to Salesforce.
    const billingAccountDisplayId = selectedBillingAccountId
      ? getExistingBillingAccountDisplayId(billingAccountSearchResponses, selectedBillingAccountId)
      : (await createNewBillingAccount(newBillingAccount, mdmId)).billingAccountDisplayId;

    const liikkuvaWifiOrder = createLiikkuvaWifiOrder(
      configuredCommercialProduct,
      companyInfo,
      offer.offerName,
      purposeOfUseOrContact,
      deliveryAddress,
      campaignAssociation,
      addedAddons,
      billingAccountDisplayId
    );
    const supportCaseDescription = getSupportCaseDescription(liikkuvaWifiOrder);
    const supportCase: SupportCase = {
      // This is not fixed broadband, but the product is defined like this somewhere in Salesforce
      feature: SupportCase.FeatureEnum.FIXED_BROADBAND_ORDER,
      action: 'Liikkuva WiFi -tilaus',
      url: window.location.pathname,
      description: supportCaseDescription,
    };

    await createSupportCaseAuthenticated(supportCase, mdmId);
  }
};
