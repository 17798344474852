import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { Icon } from '../Icon/Icon.js';
import {
  contactInfoMsg,
  corporateCustomersMsg,
  leaveMessageMsg,
  supportCaseThankYouInfoMsg,
  supportCaseThankYouMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';

import './NewSupportCasePublicThankYou.scss';

export const NewSupportCasePublicThankYou = () => {
  return (
    <>
      <BreadCrumbs
        breadCrumbPaths={[
          { name: t.KBLX(corporateCustomersMsg), path: '/' },
          { name: t.GJS9(contactInfoMsg), path: paths.CONTACT_INFO },
          { name: t.WVNV(leaveMessageMsg), path: paths.CONTACT },
          { name: t.CBCC('Thank you for the message') },
        ]}
      />
      <div className={`of-support-case-public-thank-you ${dsClass.MARGIN_HORIZONTAL_5}`}>
        <h2 className={dsClass.PADDING_LEFT_6}>
          <Icon color="green-600" icon="check" size="l" /> {t.JUCA(supportCaseThankYouMsg)}
        </h2>
        <p>{t.AFXP(supportCaseThankYouInfoMsg)}</p>
      </div>
    </>
  );
};
