import { DialogWrapper } from '../DialogWrapper';
import {
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  customerServiceNumberPriceMsg,
  dialogSystemErrorHeaderMsg,
  problemWithProcessingOrderMsg,
  returnToOmaElisaMsg,
  t,
} from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useNavigate } from 'react-router-dom';

export const SupportCaseSendingFailureDialog = ({ setShowDialog }: { setShowDialog: (isShown: boolean) => void }) => {
  const navigate = useNavigate();

  return (
    <DialogWrapper
      buttons={[
        {
          color: 'link',
          onClick: () => {
            setShowDialog(false);
            navigate(paths.SELF_SERVICE_HOME);
          },
          text: t.YU2R(returnToOmaElisaMsg),
        },
      ]}
      header={t.V0VJ(dialogSystemErrorHeaderMsg)}
      onCloseDialog={() => {}}
    >
      <div>
        {t.STWG(
          problemWithProcessingOrderMsg,
          customerServiceNumberLabelMsg,
          t.HQWW(customerServiceNumberPriceMsg),
          t.HQWV(customerServiceNumberOpenLongMsg)
        )}
      </div>
    </DialogWrapper>
  );
};
