import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { SubscriptionAction } from '../../generated/api/models.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getFilteredSubscriptionAction } from '../../common/utils/employeeUtils.js';
import { orderRequestsMsg, ordersMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetContacts, resetErrors } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './EmployeeOrdersWrapper.scss';

const isSubscriptionActionPendingForApproval = (subscriptionActions: SubscriptionAction[] | undefined): boolean => {
  const subs = subscriptionActions?.filter(itm => itm.status === SubscriptionAction.StatusEnum.PENDING_APPROVAL);
  return !!(subs && subs.length > 0);
};

export const EmployeeOrdersBreadcrumb = () => {
  return (
    <HeroHeading
      heroHeadingType={HeroHeadingType.EMPLOYEE_ORDERS}
      title={t.Y29L(orderRequestsMsg)}
      fullScreen={true}
      center={true}
    />
  );
};

export const EmployeeOrdersNavi = () => {
  const subscriptionActions = useSelector((state: State) => state.selfservice?.subscriptionActions, deepEqual);
  const filteredSubscriptionAction = getFilteredSubscriptionAction(subscriptionActions);
  return (
    <LocalNavi
      categories={[
        {
          children: t.I9C0(ordersMsg),
          to: paths.EMPLOYEE_PENDING_REVIEW_ORDERS,
        },
        {
          children: t.Z1M1('Redeem requests'),
          to: paths.EMPLOYEE_CHANGE_REQUEST_ORDERS,
          hint: isSubscriptionActionPendingForApproval(filteredSubscriptionAction?.items),
        },
        {
          children: t.B2B9('Archive'),
          to: paths.EMPLOYEE_FINISHED_REVIEW_ORDERS,
        },
      ]}
    />
  );
};

export interface EmployeeOrdersWrapperProps {
  children: ReactNode;
}

export const EmployeeOrdersWrapper = (props: EmployeeOrdersWrapperProps) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contacts, customerOrders, subscriptionActions } = useSelector((state: State) => ({
    contacts: state.selfservice?.contacts,
    customerOrders: state.selfservice?.customerOrders,
    subscriptionActions: state.selfservice?.subscriptionActions,
  }));
  const errors = customerOrders?.errors || contacts?.errors || subscriptionActions?.errors;

  useEffect(() => {
    return () => {
      dispatch(resetContacts());
    };
  }, [dispatch]);

  if (hasSystemError(errors)) {
    return (
      <SystemError
        errors={errors}
        onButtonClick={() => {
          navigate(paths.EMPLOYEE_HOME);
          dispatch(resetErrors());
        }}
      />
    );
  }
  return <div className="of-employee-orders-scene">{children}</div>;
};
