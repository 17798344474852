import * as CL from '@design-system/component-library';
import { EditInClassicButton } from './subscriptionDetailsButtons.js';
import { PbxType, SubscriptionPbxDetails, SubscriptionStatusType } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SubscriptionDetailsMobileTermination } from './SubscriptionDetailsMobileTermination.js';
import { SuspendSubscriptionOptions } from '../SuspendSubscriptionOptions/SuspendSubscriptionOptions.js';
import { TextWithPopover } from '../TextWithPopover/TextWithPopover.js';
import {
  billingAccountMsg,
  durationOfContractMsg,
  formatNumber,
  idNumberMsg,
  listPriceMsg,
  openMsg,
  pukCodeMsg,
  subscriptionStatusMsg,
  subscriptionTypeMsg,
  t,
} from '../../common/i18n';
import {
  changeOffer,
  closeSuspendSubscriptionOptions,
  closeTerminateSubscriptionForm,
  suspendSubscription,
} from '../../selfservice/actions';
import {
  commercialProductIdForHyllyliittyma,
  doActionIfNotPendingSubscription,
  isCommitmentPeriodActive,
  isMobileIdAddOn,
  isTellusSubscription,
} from '../../common/utils/subscriptionUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCommitmentText } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getPeriodicPriceAsTextFromDetails, getSubscriptionStatus } from './subscriptionDetailsCommon.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import type {
  AddOn,
  Subscription,
  SubscriptionDetails,
  SubscriptionDetailsMobile as SubscriptionDetailsMobileTypes,
} from '../../generated/api/models.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { DialogParams } from '../../common/types/dialog.js';
import type { StatusFieldAction } from './subscriptionDetailsCommon.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';
import type { TerminateType } from '../../common/enums.js';

import './subscriptionDetailsMobile.scss';

interface FupPopoverProductType {
  productName: string;
  fupQuota: number;
  category: SubscriptionCategory;
}

const fupPopoverProducts: FupPopoverProductType[] = [
  { productName: 'Elisa Yritysliittymä 5G+ Premium (1000M)', fupQuota: 80, category: SubscriptionCategory.VOICE },
  { productName: 'Elisa Yritysliittymä 5G+ Perus (600M)', fupQuota: 66.5, category: SubscriptionCategory.VOICE },
  { productName: 'Elisa Yritysliittymä 5G+ Perus (300M)', fupQuota: 58.5, category: SubscriptionCategory.VOICE },
  { productName: 'Elisa Yritysliittymä 5G Mini (50M)', fupQuota: 47, category: SubscriptionCategory.VOICE },
  { productName: 'Elisa Yritysliittymä 5G Mini (10M)', fupQuota: 45.5, category: SubscriptionCategory.VOICE },
  { productName: 'Elisa Yritysdata 5G+ (1000M)', fupQuota: 80, category: SubscriptionCategory.BROADBAND },
  { productName: 'Elisa Yritysdata 5G+ (600M)', fupQuota: 66.5, category: SubscriptionCategory.BROADBAND },
  { productName: 'Elisa Yritysdata 5G+ (300M)', fupQuota: 58.5, category: SubscriptionCategory.BROADBAND },
  { productName: 'Elisa Yritysdata 5G+ (100M)', fupQuota: 46.5, category: SubscriptionCategory.BROADBAND },
];

const SubscriptionFupPopOver = ({ category }: { category: string }) => {
  return (
    <TextWithPopover
      className="of-subscription-details__popover"
      textContent={t.QH8P(subscriptionTypeMsg)}
      placement="top"
    >
      <p>
        {t.FK90(
          'You can use the internet connection in Europe at domestic prices up to the reasonable usage limit of your subscription.'
        )}
      </p>
      <p>{t.WP9V('Examples:')}</p>
      <ul>
        {fupPopoverProducts
          .filter(product => product.category === category)
          .map((product, i) => (
            <li key={`example-product-${i}`}>
              {product.productName}, {formatNumber(product.fupQuota)} {t.YAB3('GB/mo.')}
            </li>
          ))}
      </ul>
      <p className={dsClass.FONT_SIZE_SMALL}>
        {t.O854(
          'When you arrive abroad, you will receive a text message about your reasonable use limit. You will also receive a text message when your data usage limit is about to be reached. After this, you pay 1.30 €/Gb (gigabyte) for data transfer.'
        )}
      </p>
    </TextWithPopover>
  );
};

interface SubscriptionDetailsMobileProps {
  onShowDialog: (params: DialogParams) => void;
  subscription: Subscription;
  subscriptionStatus: SubscriptionStatus;
  details?: SubscriptionDetails;
  showSuspendSubscriptionOptions: boolean;
  showTerminateSubscriptionForm: boolean;
  onTerminateSubscription: (
    subscriptionId: string,
    effectiveDate: number,
    donateNumber?: boolean,
    terminateType?: TerminateType
  ) => void;
  onResumeSubscription: (subscriptionId: string) => void;
  billingAccountSelection?: JSX.Element | undefined;
  mobileDetails?: SubscriptionDetailsMobileTypes;
  addOnRulesAssociations?: AssociationRecord[];
  terminationInProgress?: boolean;
  subscriptionCategory: SubscriptionCategory;
}

export const SubscriptionDetailsMobile = (props: SubscriptionDetailsMobileProps) => {
  const {
    onShowDialog,
    subscription,
    subscriptionStatus,
    details,
    showSuspendSubscriptionOptions,
    showTerminateSubscriptionForm,
    onTerminateSubscription,
    onResumeSubscription,
    billingAccountSelection,
    mobileDetails,
    addOnRulesAssociations,
    terminationInProgress = false,
    subscriptionCategory,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeTerminateSubscriptionForm());
    };
  }, [dispatch]);

  const subscriptionType = subscription.subscriptionType as 'MOBILE' | 'MOBILE_BROADBAND';

  useEffect(() => {
    return () => {
      dispatch(closeTerminateSubscriptionForm());
    };
  }, [dispatch]);

  if (showTerminateSubscriptionForm) {
    return (
      <SubscriptionDetailsMobileTermination
        onShowDialog={onShowDialog}
        subscription={subscription}
        onTerminateSubscription={onTerminateSubscription}
        terminateSubscriptionLoading={terminationInProgress}
      />
    );
  } else if (showSuspendSubscriptionOptions) {
    const hyllyliittymaAddonCodes =
      addOnRulesAssociations
        ?.filter(assoc => assoc.cspmb__price_item__r.guid__c === commercialProductIdForHyllyliittyma[subscriptionType])
        .filter(assoc => assoc.cspmb__default_quantity__c === 1)
        .map(assoc => assoc.cspmb__add_on_price_item__r.guid__c) || [];

    const selectedAddons: AddOn[] = subscription?.details?.selectedAddOns || [];
    const currentAddonCodes: string[] = selectedAddons.map(addon => addon.addOnCode);
    const isVakio =
      (subscription.compatiblePbxTypes &&
        subscription.compatiblePbxTypes.includes(PbxType.MOBILE_PBX_LITE) &&
        subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.VAKIO) ||
      false;
    const hasMobiiliVarmenne = selectedAddons.some(({ addOnGroup, display }) => display && isMobileIdAddOn(addOnGroup));

    return (
      <SuspendSubscriptionOptions
        hasMobiiliVarmenne={hasMobiiliVarmenne}
        onConfirmSuspend={() =>
          dispatch(suspendSubscription(subscription.subscriptionId, subscription.subscriptionDisplayId))
        }
        onConfirmHyllyliittyma={() =>
          dispatch(
            changeOffer(
              subscription.subscriptionId,
              subscription.subscriptionDisplayId,
              subscription.subscriptionType,
              commercialProductIdForHyllyliittyma[subscriptionType],
              hyllyliittymaAddonCodes,
              currentAddonCodes,
              []
            )
          )
        }
        onCloseSuspendSubscriptionOptions={() => dispatch(closeSuspendSubscriptionOptions())}
        isVakio={isVakio}
        isFixedTermSubscription={isCommitmentPeriodActive(subscription.details?.commitmentEndDate)}
      />
    );
  } else {
    const linkAction: StatusFieldAction | undefined =
      subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED && !subscriptionStatus.pendingActions
        ? {
            link: () =>
              doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, () =>
                onResumeSubscription(subscription.subscriptionId)
              ),
            text: t.K9K7(openMsg),
          }
        : undefined;

    const puk1: CL.DescriptionItem = {
      title: t.K5TL(pukCodeMsg),
      description: mobileDetails?.puk1 ?? '—',
    };
    const puk2: CL.DescriptionItem = {
      title: t.WHH4('PUK2-code'),
      description: mobileDetails?.puk2 ?? '—',
    };

    const pukItems: Array<CL.DescriptionItem> = mobileDetails ? [puk1, puk2] : [];

    return (
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol
            className={dsClass.PADDING_0}
            colsS={6}
            colsM={6}
            colsL={isTellusSubscription(subscription) ? 9 : 12}
          >
            <CL.Description
              id="subscription-details-mobile-grid"
              className={`${dsClass.MARGIN_0} of-subscription-details__description`}
              items={[
                {
                  title: t.LROP(subscriptionStatusMsg),
                  description: getSubscriptionStatus(subscriptionStatus, linkAction),
                },
                ...pukItems,
                { title: t.YTH3(idNumberMsg), description: subscription.subscriptionDisplayId ?? '—' },
                { title: t.IFT9(billingAccountMsg), description: billingAccountSelection },
                { title: t.CAA9(durationOfContractMsg), description: getCommitmentText(details) },
                {
                  title: <SubscriptionFupPopOver category={subscriptionCategory} />,
                  description: subscription.subscriptionName ?? '—',
                },
                {
                  title:
                    details &&
                    details.monthlyRecurringListCharge &&
                    details.monthlyRecurringCharge &&
                    details.monthlyRecurringListCharge !== details.monthlyRecurringCharge
                      ? t.SYWR('Campaign price')
                      : t.N68W(listPriceMsg),
                  description: getPeriodicPriceAsTextFromDetails(details),
                },
              ]}
            />
          </CL.GridCol>
          {isTellusSubscription(subscription) && (
            <CL.GridCol colsS={6} colsM={6} colsL={3}>
              <EditInClassicButton subscription={subscription} />
            </CL.GridCol>
          )}
        </CL.GridRow>
      </CL.Grid>
    );
  }
};
