import { DetailedSummary } from './DetailedSummary.js';
import { SalesType } from '../../generated/api/models.js';
import { SimpleSummary } from './SimpleSummary.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { ensureNonNegativeTotals } from '../../common/utils/priceUtils';
import { getTaxAmount, getTaxAmountFromPriceWithTax } from '../../common/utils/taxUtils.js';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import { t } from '../../common/i18n/index.js';
import { useState } from 'react';
import classNames from 'classnames';
import type { CampaignAssociation } from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { RowItem } from './OrderSummaryUtils.js';
import type { TotalSumsItem } from '../../common/utils/commercialProductUtils.js';

import './OrderSummary.scss';

export enum OrderSummaryType {
  DETAILS_CLOSED = 'DETAILS_CLOSED',
  DETAILS_ONLY = 'DETAILS_ONLY',
  DETAILS_OPEN = 'DETAILS_OPEN',
}

export interface OrderSummaryProps {
  summaryType: OrderSummaryType;
  campaignAssociation?: CampaignAssociation;
  classes?: string[];
  commercialProducts?: ConfiguredCommercialProduct[];
  rowItem?: RowItem;
  totalSumsItem?: TotalSumsItem;
  deliveryCharges?: number;
  hideZeroDeliveryCharges?: boolean;
  priceIncludesVAT?: boolean;
  campaignDescription?: string;
  salesType?: SalesType;
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const {
    campaignAssociation,
    classes,
    commercialProducts,
    deliveryCharges,
    priceIncludesVAT,
    summaryType,
    totalSumsItem,
    salesType,
  } = props;
  const [showDetails, setShowDetails] = useState(summaryType !== OrderSummaryType.DETAILS_CLOSED);
  const showSummarySection = summaryType !== OrderSummaryType.DETAILS_ONLY;

  const orderContainsBillingPeriodItem = commercialProducts?.some(cp => cp.commercialProduct.billingPeriod);

  if (!commercialProducts && !totalSumsItem) {
    throw new Error('commercialProducts or totalSumsItem have to be passed to OrderSummary');
  }
  const effectiveSalesType = salesType || SalesType.NEW_SALE;

  const totalSums = commercialProducts
    ? ensureNonNegativeTotals(
        getTotalSums(commercialProducts, campaignAssociation, effectiveSalesType, priceIncludesVAT)
      )
    : totalSumsItem!; // We can safely use the non-null assertion operator here since the totalSumsItem variable is verified within the preceding if-clause

  if (deliveryCharges) {
    totalSums.totalOneTimeCharge += deliveryCharges;
    totalSums.totalOneTimeChargeVat += priceIncludesVAT
      ? getTaxAmountFromPriceWithTax(deliveryCharges)
      : getTaxAmount(deliveryCharges);
  }

  return (
    <div className={classNames('of-order-summary', classes)}>
      {showSummarySection ? (
        <>
          <SimpleSummary
            totalSums={totalSums}
            summaryType={summaryType}
            priceIncludesVAT={priceIncludesVAT}
            orderContainsBillingPeriodItem={orderContainsBillingPeriodItem}
          />
          <div className={dsClass.TEXT_ALIGN_END}>
            <button
              type="button"
              className="of-order-summary__details-toggle"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? t.TQNV('Hide details') : t.CUQU('Show details')}
            </button>
          </div>
        </>
      ) : null}
      {showDetails ? <DetailedSummary {...props} salesType={effectiveSalesType} totalSums={totalSums} /> : null}
    </div>
  );
};
