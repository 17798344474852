import { CostCenter } from '../fields/CostCenter';
import { Email, Name, PhoneNumber } from '../fields';
import { FormGridFieldset } from '../FormGridFieldset/FormGridFieldset';
import { Reference } from '../fields/Reference';
import { emailWorkMsg, firstNameMsg, lastNameMsg, mobilePhoneNumberWorkMsg, t } from '../../i18n';

export const MoveContactFieldset = () => (
  <FormGridFieldset>
    <Name name="firstName" label={t.AIK7(firstNameMsg)} readonly={true} />
    <Name name="lastName" label={t.Y41S(lastNameMsg)} readonly={true} />
    <PhoneNumber label={t.V9K9(mobilePhoneNumberWorkMsg)} />
    <Email label={t.JYZO(emailWorkMsg)} />
    <CostCenter />
    <Reference />
  </FormGridFieldset>
);
