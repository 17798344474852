import { GutterStyle, SelectOneList, type SelectOneListItemProps } from '../../SelectOneList/index.js';
import { NumberFetchingError } from '../NumberFetchingError.js';
import { ShowMoreButton } from './ShowMoreButton.js';
import { companyNumberMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fetchNumberRange } from '../../../common/fetch';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberUtils.js';
import { getExtensionNumber, getExtensionRangeId, getSelectedPbxSolution } from '../attachPbxUtils';
import { useFormContext } from 'react-hook-form';
import type { NumberRangeData } from '../../../common/loaders';
import type { Subscription } from '../../../generated/api/subscription';

export const VakioCorporateNumberSelect = ({
  pbxSolutions,
  numberRanges,
}: {
  pbxSolutions: Subscription[];
  numberRanges: NumberRangeData[];
}) => {
  const { setValue, watch } = useFormContext();
  const { corporateNumber, currentNumberRange, pbxSolutionId } = watch();
  const selectedNumber =
    corporateNumber || (currentNumberRange.displayedNumbers ? currentNumberRange.displayedNumbers[0] : undefined);
  const selectedPbxSolution = getSelectedPbxSolution(pbxSolutions, pbxSolutionId);

  const onNumberSelect = (value: string) => {
    const newExtensionRangeId = value ? getExtensionRangeId(selectedPbxSolution, value) : undefined;
    const newExtensionNumber =
      newExtensionRangeId && value && numberRanges
        ? getExtensionNumber(newExtensionRangeId, value, numberRanges)
        : undefined;
    setValue('corporateNumber', value);
    setValue('extensionRangeId', newExtensionRangeId);
    setValue('extensionNumber', newExtensionNumber);
  };

  const onDisplayMoreNumbers = () => {
    currentNumberRange.displayedNumbers = currentNumberRange.allNumbers!.slice(
      0,
      currentNumberRange.displayedNumbers!.length + 4
    );
    setValue('currentNumberRange', currentNumberRange);
  };

  const onRetry = async () => {
    const reloadNumberResponse = await fetchNumberRange(currentNumberRange.rangeId);

    const newExtensionRangeId = reloadNumberResponse.rangeId
      ? getExtensionRangeId(selectedPbxSolution, reloadNumberResponse.rangeId)
      : undefined;
    const newExtensionNumber =
      newExtensionRangeId && reloadNumberResponse.rangeId && numberRanges
        ? getExtensionNumber(newExtensionRangeId, reloadNumberResponse.rangeId, numberRanges)
        : undefined;
    const newDefaultNumber = reloadNumberResponse?.displayedNumbers
      ? reloadNumberResponse?.displayedNumbers[0]
      : undefined;
    setValue('currentNumberRange', reloadNumberResponse);
    setValue('extensionRangeId', newExtensionRangeId);
    setValue('extensionNumber', newExtensionNumber);
    setValue('corporateNumber', newDefaultNumber);
  };

  if (currentNumberRange.errors) {
    return (
      <div id="subscription-company-number" className="of-select-number-list__error">
        <NumberFetchingError errors={currentNumberRange.errors} retryAction={onRetry} />
      </div>
    );
  }

  const getOption = (phoneNumber: string): SelectOneListItemProps => ({
    element: (
      <div className="of-attach-pbx-content__number-range-row of-attach-pbx-content__selection of-select-number-list">{`${formatPhoneNumber(
        phoneNumber,
        true
      )}`}</div>
    ),
    isSelected: selectedNumber === phoneNumber,
    onClick: () => onNumberSelect(phoneNumber),
  });

  const displayedNumbers = (currentNumberRange.displayedNumbers as string[]) || [];
  const displayShowMoreButton =
    currentNumberRange?.allNumbers && currentNumberRange.allNumbers.length > displayedNumbers.length;
  const options = displayedNumbers.map(phoneNumber => getOption(phoneNumber));

  return (
    <div id="subscription-company-number" className={dsClass.MARGIN_TOP_4}>
      <div className={dsClass.FONT_SIZE_LARGE}>{t.ZX32(companyNumberMsg)}:</div>
      <div className={dsClass.FONT_SIZE_SMALL}>
        {t.QTLF(
          'Service price €4/month for business numbers (with prefixes such as 010, 020, etc.) and €6.90/month for other numbers.'
        )}
      </div>
      <SelectOneList
        disableIcon={true}
        gutterStyle={GutterStyle.Minimal}
        maxItemsAsideDesktop={4}
        maxItemsAsideLaptop={4}
        maxItemsAsideTablet={3}
        options={options}
      />
      {displayShowMoreButton && <ShowMoreButton onClick={() => onDisplayMoreNumbers()} />}
    </div>
  );
};
