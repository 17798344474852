import { SupportCase } from '../../generated/api/models.js';
import { TextWithPopover } from '../TextWithPopover/TextWithPopover.js';
import {
  contractEndDateMsg,
  contractPeriodMsg,
  durationOfContractMsg,
  fixedTermEndsMsg,
  monthMsg,
  monthsMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { isCommitmentPeriodActive, isInExtensionPeriod } from '../../common/utils/subscriptionUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { DescriptionItem } from '@design-system/component-library';
import type { Subscription, SubscriptionDetails, SubscriptionHeader } from '../../generated/api/models.js';

// 24hrs in ms
export const DAY_TO_MILLISECONDS = 24 * 60 * 60 * 1000;

// no of days before allowing the device change MACD
export const DAYS_BEFORE_ALLOWING_DEVICE_CHANGE = 14;

// no of days before allowing the device termination for Employees
export const DAYS_BEFORE_ALLOWING_DEVICE_TERMINATION_FOR_EMPLOYEE = 14;

// no of days before showing reminder for enabling of the device change MACD
export const DAYS_BEFORE_EXPIRING_DEVICE_SUBSCRIPTION_REMINDER = 15;

export const isEppDeviceSubscription = (subscription?: Subscription) => {
  return subscription?.details?.device?.eppSubscription || false;
};

export const isEndDateExpiring = (date: number) => {
  return date - Date.now() <= DAYS_BEFORE_EXPIRING_DEVICE_SUBSCRIPTION_REMINDER * DAY_TO_MILLISECONDS;
};

export const isEndDateExpired = (date: number) => {
  return date <= Date.now();
};

// TODO Note to author -> This function should never exist as it's wrong to check existing properties and choose approach based on that
const isSubscriptionType = (subscription?: Subscription | SubscriptionHeader): subscription is Subscription =>
  subscription !== undefined && 'details' in subscription;

// TODO Note to author -> This fn should have own versions for Subscription (OE) and SubscriptionHeader (EPP)
// to check if an existing epp device subscription is about to expire in next 15 days
export const isEppDeviceSubscriptionExpiring = (subscription?: Subscription | SubscriptionHeader): boolean => {
  if (subscription) {
    if (isSubscriptionType(subscription)) {
      return Boolean(
        isEppDeviceSubscription(subscription) && isEndDateExpiring(subscription?.details?.commitmentEndDate || 0)
      );
    }
    return Boolean(subscription?.eppSubscription && isEndDateExpiring(subscription?.commitmentEndDate || 0));
  }
  return false;
};

export const isEppDeviceSubscriptionExpired = (subscription: SubscriptionHeader): boolean => {
  if (subscription.eppSubscription && subscription.contractEndDate) {
    return isEndDateExpired(subscription.contractEndDate);
  }
  return false;
};

export const isDeviceSubscriptionExpired = (subscription: Subscription): boolean => {
  if (subscription.details?.contractEndDate) {
    return isEndDateExpired(subscription.details?.contractEndDate || 0);
  }
  return false;
};

export const isEppDeviceChangeAllowed = ({ details }: Subscription): boolean =>
  details?.device?.eppSubscription === true &&
  details.commitmentEndDate !== undefined &&
  details.commitmentEndDate <= Date.now() + DAYS_BEFORE_ALLOWING_DEVICE_CHANGE * DAY_TO_MILLISECONDS;

export const isDeviceTerminationAllowedForEmployee = (subscription: Subscription) =>
  isInExtensionPeriod(subscription) ||
  (subscription?.details?.commitmentEndDate &&
    subscription?.details?.commitmentEndDate - Date.now() <=
      DAYS_BEFORE_ALLOWING_DEVICE_TERMINATION_FOR_EMPLOYEE * DAY_TO_MILLISECONDS);

export const getEppContractEndDateText = (subscription: Subscription): string | undefined => {
  if (isInExtensionPeriod(subscription)) {
    return t.RCIG(
      'The device is in an extension period. In case the device is not being changed or the contract is not terminated, the device will automatically become company property.'
    );
  } else {
    return t.Z8MP(
      'The device can be changed to a new device two weeks prior to the ending of the contract period. In case the device is not being changed or the contract is not terminated, the device will automatically transfer to a 6 month extension period.'
    );
  }
};

export const getEppPeriodTypeLabelText = (subscription: Subscription): string => {
  const eppDeviceSubscriptionExpired = isDeviceSubscriptionExpired(subscription);

  if (eppDeviceSubscriptionExpired) {
    return t.CL9N('Contract expired');
  } else if (isInExtensionPeriod(subscription)) {
    return t.CUJ5('The device is in an extension period');
  } else {
    return t.EBUW('The device is in a basic contract period');
  }
};

export const extensionPeriodText = (subscription: Subscription) =>
  isInExtensionPeriod(subscription) ? ` + ${t.QYMV('Extension')} 6 ${t.XXVX(monthMsg)}` : '';

const getSubscriptionEndDateText = (subscription: Subscription): string => {
  const commitmentEndDate = subscription.details?.commitmentEndDate;
  if (isInExtensionPeriod(subscription)) {
    return `${t.AWEN('Basic contract period')}: ${formatTimestampToUTCDDMMYYYY(commitmentEndDate)!} | ${t.M41V(
      'Extension period'
    )}: ${formatTimestampToUTCDDMMYYYY(subscription.details?.contractEndDate)!}`;
  }
  return `${t.AWEN('Basic contract period')}: ${formatTimestampToUTCDDMMYYYY(commitmentEndDate)!}`;
};

const getEppDeviceCommitmentFields = (subscription: Subscription): DescriptionItem[] => {
  const eppDeviceSubscriptionExpired = isEppDeviceSubscriptionExpiring(subscription);
  const contractPeriodLabel =
    eppDeviceSubscriptionExpired && !isInExtensionPeriod(subscription) ? (
      getEppPeriodTypeLabelText(subscription)
    ) : (
      <TextWithPopover textContent={getEppPeriodTypeLabelText(subscription)} placement="top">
        <h4>{getEppPeriodTypeLabelText(subscription)}</h4>
        <p>{getEppContractEndDateText(subscription) ?? ''}</p>
      </TextWithPopover>
    );

  return [
    {
      title: t.CAA9(durationOfContractMsg),
      description: subscription.details?.commitmentPeriod
        ? `${subscription.details?.commitmentPeriod} ${t.XXVX(monthMsg)}${extensionPeriodText(subscription)}`
        : undefined,
    },
    {
      title: t.D55M(contractPeriodMsg),
      description: contractPeriodLabel,
    },
    {
      title: t.LHJ4(contractEndDateMsg),
      description: getSubscriptionEndDateText(subscription),
    },
  ];
};

export const getCommitmentText = (details?: SubscriptionDetails): string => {
  if (details && isCommitmentPeriodActive(details.commitmentEndDate)) {
    const formattedEndDate = formatTimestampToUTCDDMMYYYY(details.commitmentEndDate);
    if (details.commitmentPeriod) {
      return `${details.commitmentPeriod} ${t.XXVX(monthsMsg)} (${t
        .VEGK(fixedTermEndsMsg)
        .toLowerCase()} ${formattedEndDate})`;
    } else {
      return `${t.VEGK(fixedTermEndsMsg)} ${formattedEndDate}`;
    }
  }
  return t.IHW3('Open-ended');
};

export const getContractTermText = (contractTerm: number | undefined): string => {
  if (contractTerm) {
    return contractTerm.toString();
  }
  return t.ENOC('Data not available');
};

const getNonEppDeviceCommitmentFields = (subscription: Subscription): DescriptionItem[] => [
  {
    title: t.BKNH(contractPeriodMsg),
    description:
      subscription.details?.contractTerm !== 1 ? (
        <TextWithPopover textContent={getContractTermText(subscription.details?.contractTerm)} placement="top">
          <p>{t.LK2D('You can find more detailed information about the remaining installments on the invoice.')}</p>
        </TextWithPopover>
      ) : (
        getContractTermText(subscription.details?.contractTerm)
      ),
  },
];

export const getDeviceCommitmentFields = (subscription: Subscription): DescriptionItem[] =>
  subscription.details?.device?.eppSubscription
    ? getEppDeviceCommitmentFields(subscription)
    : getNonEppDeviceCommitmentFields(subscription);

export const removeOmaLaiteLaskuSupportCase = (
  subscriptionId: string,
  subscriptionDisplayId: string,
  billingAccountId: string
): SupportCase => {
  return {
    feature: SupportCase.FeatureEnum.SUBSCRIPTIONS,
    action: 'Oma Lasku -palveluun liittyvä toimeksianto',
    url: isInBrowser() ? window.location.href : '',
    description: `Asiakkuuden EPP-pääkäyttäjä on tilannut laitesopimukselle ${subscriptionDisplayId} käyttäjänvaihdon. Laitesopimukselta poistetaan Oma Lasku -osuus ja tämän jälkeen tehdään käyttäjänvaihto. Jatkossa yritys maksaa laitteen kk-maksun kokonaisuudessaan. Pääkäyttäjä on voinut tilata myös laskutussopimuksen vaihdon laitesopimukselle. Jos laskutusopimuksen vaihto on myös tilattu niin uuden laskutusopimuksen tiedot näkyvät tällä tukipyynnöllä. Uuden laskutussopimuksen numero on ${billingAccountId}.`,
    featureItemId: subscriptionId,
  };
};
