import * as CL from '@design-system/component-library';
import { AttachPbxContentConfirmationDialogContent } from '../AttachPbxContentConfirmationDialogContent/AttachPbxContentConfirmationDialogContent.js';
import { ConfirmationModal } from '../Modal/ConfirmationModal';
import { CorporateNumberRangeSelect } from './components/CorporateNumberRangeSelect.js';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberRange } from '../../generated/api/numberRange.js';
import { PbxSolutionSelect } from './components/PbxSolutionSelect.js';
import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { VakioCorporateNumberSelect } from './components/VakioCorporateNumberSelect';
import { cancelMsg, confirmMsg, somethingWentWrongMsg, subscriptionMsg, t } from '../../common/i18n/index.js';
import { changeSubscriptionMobilePbx } from '../../common/fetch';
import { forceInternationalPhoneNumberFormat, formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  getCurrentPbxSolutionNumberRanges,
  getExtensionNumber,
  getExtensionRangeId,
  getSelectedPbxSolution,
  isPbxConfigComplete,
  sortNumberRangesInPbxSolutions,
} from './attachPbxUtils';
import { paths } from '../../common/constants/pathVariables';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { NumberRangeData } from '../../common/loaders';
import type { Subscription } from '../../generated/api/subscription.js';

import './AttachPbxContent.scss';

export interface AttachPbxContentAttrs {
  category: CategoryKey;
  companyInfo?: CompanyInfoState | null;
  numberRanges: NumberRangeData[];
  subscription: Subscription;
  onClickSubscription: (id: string) => void;
}

export interface AttachVakioPbxContentFormValues {
  pbxSolutionId: string;
  corporateNumberRangeId?: string;
  corporateNumber?: string;
  extensionNumber?: string;
  extensionRangeId?: string;
  currentNumberRange?: NumberRangeData;
}

const createCompletePbxConfiguration = (
  configuration: AttachVakioPbxContentFormValues,
  subscription?: Subscription
): SubscriptionPbxConfiguration => ({
  ...configuration,
  busyAction: SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN,
  corporateNumber: forceInternationalPhoneNumberFormat(configuration.corporateNumber)!,
  delayForChainCalls: 20,
  pbxConfigurationDetails: { currentActiveChain: SubscriptionPbxDetails.CurrentActiveChainEnum.WORKING_HOURS },
  subscriptionDisplayId: subscription?.subscriptionDisplayId,
  pbxSolutionId: configuration.pbxSolutionId,
  workingHoursReachabilityChain: subscription?.details?.mobile?.mobileNumber
    ? [subscription.details.mobile.mobileNumber]
    : undefined,
});

const getPbxSolutions = (pbxSolutions: Subscription[]) => {
  const filteredSolutions = pbxSolutions.filter(
    pbxSolution => pbxSolution.subscriptionType === SubscriptionType.MOBILE_PBX_LITE
  );
  return filteredSolutions.length > 0 ? filteredSolutions : pbxSolutions;
};

export const AttachVakioPbxContent = ({
  companyInfo,
  subscription,
  numberRanges,
  onClickSubscription,
}: AttachPbxContentAttrs) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const pbxSolutions = getPbxSolutions(companyInfo?.pbxSolutions ?? []);
  const defaultPbxSolutionId = sortNumberRangesInPbxSolutions(pbxSolutions)[0].subscriptionId;
  const defaultPbxSolution = getSelectedPbxSolution(pbxSolutions, defaultPbxSolutionId);

  const corporateNumberRanges = defaultPbxSolution.details!.mobilePbx!.numberRanges.filter(
    numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
  );
  const defaultCorporateRange = corporateNumberRanges[0].rangeId;
  const defaultCorporateNumbers = numberRanges.find(item => item.rangeId === defaultCorporateRange);
  const defaultCorporateNumber = defaultCorporateNumbers?.displayedNumbers
    ? defaultCorporateNumbers.displayedNumbers[0]
    : undefined;

  const extensionRangeId = defaultCorporateNumber
    ? getExtensionRangeId(defaultPbxSolution, defaultCorporateNumber)
    : undefined;
  const defaultExtensionNumber =
    extensionRangeId && defaultCorporateNumber && numberRanges
      ? getExtensionNumber(extensionRangeId, defaultCorporateNumber, numberRanges)
      : undefined;

  const methods = useForm<AttachVakioPbxContentFormValues>({
    defaultValues: {
      corporateNumber: defaultCorporateNumber,
      corporateNumberRangeId: defaultCorporateRange,
      pbxSolutionId: defaultPbxSolutionId,
      currentNumberRange: numberRanges.find(i => i.rangeId === defaultCorporateRange),
      extensionRangeId: extensionRangeId,
      extensionNumber: defaultExtensionNumber,
    },
  });

  const formValues = methods.watch();
  const pbxConfiguration = createCompletePbxConfiguration(formValues, subscription);
  const selectedPbxSolution = getSelectedPbxSolution(pbxSolutions, formValues.pbxSolutionId);

  const onConfirm = async () => {
    if (isPbxConfigComplete(pbxConfiguration)) {
      setIsSaving(true);
      const payload = {
        ...pbxConfiguration,
        pbxConfigurationDetails: {
          ...pbxConfiguration.pbxConfigurationDetails,
          offWorkReachabilityChain: pbxConfiguration.pbxConfigurationDetails.offWorkReachabilityChain
            ? pbxConfiguration.pbxConfigurationDetails.offWorkReachabilityChain.filter(
                phoneNumber => phoneNumber.length > 0
              )
            : undefined,
        },
        workingHoursReachabilityChain: pbxConfiguration.workingHoursReachabilityChain
          ? pbxConfiguration.workingHoursReachabilityChain.filter(phoneNumber => phoneNumber.length > 0)
          : undefined,
      };
      try {
        await changeSubscriptionMobilePbx(payload, subscription.subscriptionId);
        dispatch(startNotification(t.T29V('Change order successful'), 'success'));
        navigate(
          generatePath(paths.PS_MOBILE_SUBSCRIPTION, {
            subscriptionId: subscription?.subscriptionDisplayId,
          })
        );
      } catch (error) {
        dispatch(startNotification(t.JDTO(somethingWentWrongMsg), 'error'));
        setIsSaving(false);
      }
    }
  };

  const onPbxSolutionSelect = (value: string) => {
    methods.setValue('pbxSolutionId', value);
    const newPbxSolution = getSelectedPbxSolution(pbxSolutions, value);
    const newCorporateNumberRanges = newPbxSolution.details!.mobilePbx!.numberRanges.filter(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
    );
    const newCorporateRange = newCorporateNumberRanges[0].rangeId;
    const newNumberRange = numberRanges.find(i => i.rangeId === newCorporateRange);
    const newCorporateNumber = newNumberRange?.displayedNumbers ? newNumberRange?.displayedNumbers[0] : undefined;
    const newExtensionRangeId = getExtensionRangeId(newPbxSolution, newCorporateNumber);
    const newExtensionNumber =
      newExtensionRangeId && newCorporateNumber && numberRanges
        ? getExtensionNumber(newExtensionRangeId, newCorporateNumber, numberRanges)
        : undefined;

    methods.setValue('corporateNumberRangeId', newCorporateRange);
    methods.setValue('corporateNumber', newCorporateNumber);
    methods.setValue('extensionNumber', newExtensionNumber);
    methods.setValue('extensionRangeId', newExtensionRangeId);
    methods.setValue(
      'currentNumberRange',
      numberRanges.find(i => i.rangeId === newCorporateRange)
    );
  };

  const onNumberRangeSelect = (value: string) => {
    const newNumberRange = numberRanges.find(i => i.rangeId === value);
    const newCorporateNumber = newNumberRange?.displayedNumbers ? newNumberRange?.displayedNumbers[0] : undefined;
    const newExtensionRangeId = getExtensionRangeId(selectedPbxSolution, newCorporateNumber);
    const newExtensionNumber =
      newExtensionRangeId && newCorporateNumber && numberRanges
        ? getExtensionNumber(newExtensionRangeId, newCorporateNumber, numberRanges)
        : undefined;
    methods.setValue('corporateNumberRangeId', value);
    methods.setValue('currentNumberRange', newNumberRange);
    methods.setValue('corporateNumber', newCorporateNumber);
    methods.setValue('extensionRangeId', newExtensionRangeId);
    methods.setValue('extensionNumber', newExtensionNumber);
  };

  return (
    <div className="of-attach-pbx-content">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(() => setShowConfirmationModal(true))}>
          <h3>{t.IM77('Vakio settings of subscription')}</h3>
          <p>
            {t.TETZ(
              `Elisa Puheratkaisu Vakio is an easy way of making mobile phones part of a company's telephone system.`
            )}
          </p>
          <CL.Description
            items={[
              {
                title: t.P674(subscriptionMsg),
                description: formatPhoneNumber(subscription?.details?.mobile?.mobileNumber || ''),
              },
            ]}
          />
          <PbxSolutionSelect
            pbxSolutions={pbxSolutions}
            isRing={false}
            onSelect={onPbxSolutionSelect}
            selectedValue={formValues.pbxSolutionId}
          />

          <CorporateNumberRangeSelect
            selectedValue={formValues.currentNumberRange?.rangeId}
            onSelect={onNumberRangeSelect}
            currentPbxSolutionNumberRanges={getCurrentPbxSolutionNumberRanges(
              pbxSolutions.find(pbxSolution => pbxSolution.subscriptionId === formValues.pbxSolutionId)
            )}
          />

          <VakioCorporateNumberSelect numberRanges={numberRanges} pbxSolutions={pbxSolutions} />

          <div className="of-barrings-content__actions">
            <>
              <CL.Button disabled={!isPbxConfigComplete(formValues)} size="l" color="primary" type="submit">
                {t.QVYK(confirmMsg)}
              </CL.Button>
              <CL.Button size="l" color="link" onClick={() => onClickSubscription(subscription!.subscriptionDisplayId)}>
                {t.B2V1(cancelMsg)}
              </CL.Button>
            </>
          </div>
          {showConfirmationModal && (
            <ConfirmationModal
              heading={t.DQ1Z('Vakio solution for subscription')}
              onConfirm={onConfirm}
              onCancel={() => setShowConfirmationModal(false)}
              isLoading={isSaving}
            >
              <AttachPbxContentConfirmationDialogContent
                numberCategory={formValues.currentNumberRange?.numberCategory}
                pbxConfig={pbxConfiguration}
                voiceSub={subscription!}
              />
            </ConfirmationModal>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
