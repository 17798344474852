import * as CL from '@design-system/component-library';
import { SimCardNumber } from '../SimCardNumber/SimCardNumber.js';
import { SimCardType } from '../SimCardType/SimCardType.js';
import { activateSimCardMsg, simChangePendingMsg, subChangePendingMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { SimCardConfiguration } from '../../common/types/subscription.js';

import './SimCard.scss';

export interface SimCardProps {
  pendingSimChanges: boolean;
  pendingSubscriptionChanges: boolean;
  simCardConfig: SimCardConfiguration;
  sourceIsTellus: boolean;
  onClickOrderSim: () => void;
  onClickSimActivation: () => void;
  simCardChangeAllowed?: boolean;
  actions?: boolean;
  hasEsimQrCode?: boolean;
}

export const SimCard = ({
  pendingSimChanges,
  pendingSubscriptionChanges,
  simCardConfig,
  onClickSimActivation,
  onClickOrderSim,
  sourceIsTellus,
  simCardChangeAllowed,
  actions = true,
  hasEsimQrCode = false,
}: SimCardProps): JSX.Element => {
  if (pendingSimChanges) {
    return (
      <div>
        <p>{t.O7PT(simChangePendingMsg)}</p>
        <div className={dsClass.PADDING_BOTTOM_4} />
      </div>
    );
  } else if (pendingSubscriptionChanges) {
    return (
      <div>
        <p>{t.MQHO(subChangePendingMsg)}</p>
        <div className={dsClass.PADDING_BOTTOM_4} />
      </div>
    );
  }

  const actionButtons = (
    <>
      <CL.Button
        color="light"
        size="s"
        id="activate-sim-card-button"
        className="of-sim-card__button1"
        onClick={() => onClickSimActivation()}
        disabled={!(simCardChangeAllowed === undefined || simCardChangeAllowed || hasEsimQrCode)}
      >
        {t.M1E7(activateSimCardMsg)}
      </CL.Button>
      <CL.Button
        color="light"
        size="s"
        id="order-new-sim-button"
        className="of-sim-card__button2"
        onClick={() => onClickOrderSim()}
        disabled={simCardChangeAllowed === false}
      >
        {t.PMFC('Order new')}
      </CL.Button>
    </>
  );

  return (
    <div className="of-sim-card">
      <CL.Description
        items={[
          ...(sourceIsTellus ? [] : [{ description: <SimCardType simType={simCardConfig.simType} /> }]),
          { description: <SimCardNumber simCardNumber={simCardConfig.simCardNumber} /> },
        ]}
      />

      {actions && !sourceIsTellus && (
        <div className={dsClass.PADDING_BOTTOM_4}>
          <div className="of-sim-card__container">{actionButtons}</div>
        </div>
      )}
    </div>
  );
};
