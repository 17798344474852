import { deepEqual } from '../../common/utils/objectUtils';
import {
  getShoppingBasketFromLocalStorage,
  handleShoppingBasketUpdate,
  parseBasketJson,
} from '../../common/hooks/useShoppingBasket';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket';
import type { CartItemContainer } from '../../components/ProductDetails/ProductDetails';

const generateUniqueId = (existingItems: ShoppingBasketType): string => {
  const newId = crypto.randomUUID();
  // Probability is near zero for collision, but handle anyways..
  if (existingItems.items?.find(existingItem => existingItem.id === newId)) {
    return generateUniqueId(existingItems);
  } else {
    return newId;
  }
};

export const areItemsEqualInBasket = (newBasketItem: BasketItem, existingBasketItem: BasketItem) => {
  if (existingBasketItem.guid !== newBasketItem.guid) {
    return false;
  }

  return (
    deepEqual(existingBasketItem.offer, newBasketItem.offer) &&
    deepEqual(existingBasketItem.offer.addOns, newBasketItem.offer.addOns)
  );
};

export const addToShoppingBasket = (basketItem: CartItemContainer, sendShoppingBasketToApi: boolean) => {
  const shoppingBasketJson: ShoppingBasketType = parseBasketJson(getShoppingBasketFromLocalStorage());

  const newItem: BasketItem = {
    id: generateUniqueId(shoppingBasketJson),
    guid: basketItem.onlineModel.onlineModelCode,
    name: basketItem.offer.offerName,
    quantity: basketItem.quantity,
    offer: {
      guid: basketItem.offer.offerCode,
      addOns: basketItem.selectedAddOnBundles.map(addOn => addOn.addOnCode),
      commercialProductGuid: basketItem.commercialProduct.commercialProductCode || '',
      ...(basketItem.commercialProduct.oneTimeCharge && {
        oneTimePriceInCents: basketItem.commercialProduct.oneTimeCharge,
      }),
      ...(basketItem.commercialProduct.monthlyRecurringCharge && {
        periodicPrice: {
          periodicPriceInCents: basketItem.commercialProduct.monthlyRecurringCharge,
          payments: basketItem.commercialProduct.payments,
          period: basketItem.commercialProduct.billingPeriod || 1, // Billing period in months, usually not available so default to 1
        },
      }),
    },
    imageUrl:
      (basketItem.offer.images && basketItem.offer.images.length > 0 && basketItem.offer.images[0]) ||
      basketItem.onlineModel.listingImage ||
      '',
    pageUrl: basketItem.onlineModel.pagePath,
  };

  const existingBasketItem = shoppingBasketJson.items?.find(existingItem =>
    areItemsEqualInBasket(newItem, existingItem)
  );
  if (existingBasketItem) {
    existingBasketItem.quantity += newItem.quantity;
  } else {
    shoppingBasketJson.items = [...(shoppingBasketJson.items || []), newItem];
  }

  handleShoppingBasketUpdate(JSON.stringify(shoppingBasketJson), sendShoppingBasketToApi);
};
