import { SourceSystem } from '../../../generated/api/sourceSystem.js';
import { loadingBillingAccountsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { BillingAccountHeader } from '../../../generated/api/billingAccountHeader.js';

export const useOpenFormBillingAccounts = () => {
  const [data, setData] = useState<(BillingAccount | BillingAccountHeader)[]>([]);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = async
      .fetchBillingAccounts({ sourceSystem: SourceSystem.MIPA })
      .settled(([accounts, headers]) => {
        switch (true) {
          case accounts.status === 'fulfilled':
            return setData(accounts.value.billingAccounts ?? []);
          case headers.status === 'fulfilled':
            return setData((headers.value.searchResults ?? [])?.map(({ result }) => result));
          case accounts.status === 'rejected':
            return async.actions.notification({ text: String(accounts.reason), type: 'warning' });
          case headers.status === 'rejected':
            return async.actions.notification({ text: String(headers.reason), type: 'warning' });
        }
      })
      .cache(SourceSystem.MIPA)
      .execute(t.RCPW(loadingBillingAccountsMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
