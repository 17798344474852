import type { OmalaskuAddress } from '../../generated/api/omalaskuAddress';

interface OmalaskuSubscriptionBillingAddressProps {
  firstname: string;
  lastname: string;
  address: OmalaskuAddress;
}

export const OmalaskuSubscriptionBillingAddress = ({
  firstname,
  lastname,
  address,
}: OmalaskuSubscriptionBillingAddressProps) => {
  return (
    <div>
      <div>{`${firstname} ${lastname}`}</div>
      <div>{address.streetAddress}</div>
      <div>{`${address.postalCode} ${address.postOffice}`}</div>
    </div>
  );
};
