import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import { InfiniteScrollCompositeList } from '../InfiniteScrollCompositeList/index.js';
import { Subscription, SubscriptionStatusType } from '../../generated/api/models.js';
import { closeSuspendSubscriptionOptions, closeTerminateSubscriptionForm } from '../../selfservice/actions/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCategoryBySubscriptionType } from '../../public/common/util/category.js';
import {
  getRecurringPriceAsText,
  getSubscriptionStatusColor,
  getSubscriptionStatusText,
} from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getSubscriptionIcon } from './subscriptionIcons.js';
import {
  identifierMsg,
  imeiSlashSerialNumberMsg,
  monthlyChargeMsg,
  noProductsMsg,
  oneTimePaymentMsg,
  searchPlaceHolderMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { isSubscriptionShown } from './subscriptionListUtils.js';
import { loadBillingAccountSubscriptions } from '../../selfservice/actions/billingAccountsActions.js';
import { paths } from '../../common/constants/pathVariables.js';
import { sortArrayByProperty } from '../../common/utils/arrayUtils.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { CompositeListColumn, CompositeListSort } from '../CompositeListHeader/index.js';
import type { InfiniteScrollCompositeListProps } from '../InfiniteScrollCompositeList/index.js';

export type BillingAccountSubscriptionListProps = {
  billingAccountId: string;
  items?: Subscription[];
  total?: number;
};

export const BillingAccountSubscriptionList = (props: BillingAccountSubscriptionListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<CompositeListSort>();

  const { billingAccountId, items, total } = props;

  const shownItems = items
    ? items.filter(
        (subscriptionToFilter: Subscription) =>
          subscriptionToFilter.subscriptionStatus &&
          isSubscriptionShown(subscriptionToFilter.subscriptionStatus, undefined, subscriptionToFilter.subscriptionType)
      )
    : undefined;
  const sortedItems = sort && shownItems ? sortArrayByProperty(shownItems, sort.columnId, sort.order) : shownItems;
  const subsStillToShow: boolean = (items && total && items.length < total) || false;
  const emptyListText: string = t.GXAQ(noProductsMsg);

  const isSubscriptionNonClickable = (sub: Subscription): boolean => {
    return (
      !!sub.subscriptionStatus &&
      [SubscriptionStatusType.CREATED, SubscriptionStatusType.IN_ACTIVATION].includes(sub.subscriptionStatus)
    );
  };

  const styleNonClickable = (sub: Subscription): string[] => {
    return isSubscriptionNonClickable(sub) ? ['ea-text', 'ea-text--light-grey'] : [];
  };

  const createSubscriptionNameColumn = (): CompositeListColumn<Subscription> => ({
    columnId: 'subscriptionName',
    ref: 'subscriptionName',
    heading: t.C84H('Product'),
    postfixIcon: (subscription: Subscription) =>
      subscription.sourceSystem !== Subscription.SourceSystemEnum.SFDC && !subscription.migrated
        ? 'northeast-arrow'
        : undefined,
    sortable: true,
    wide: true,
    valueColumnClasses: subscription => styleNonClickable(subscription),
    value: (subscription: Subscription) => {
      return [
        <span key={0} title={subscription.subscriptionName}>
          {subscription.subscriptionName}
        </span>,
      ];
    },
  });

  const getSubscriptionText = (sub: Subscription): string => {
    return (
      sub.subscriptionPurposeOfUse ?? sub.subscriptionContactName ?? sub.subscriptionDisplayId ?? sub.subscriptionId
    );
  };

  const getSubscriptionSmallText = (sub: Subscription): string => {
    if (sub.details?.device?.serialNumber) {
      return t.OE45(imeiSlashSerialNumberMsg) + ': ' + sub.details?.device?.serialNumber;
    }
    if (sub.subscriptionUserFriendlyId) {
      return formatPhoneNumber(sub.subscriptionUserFriendlyId);
    }
    return sub.subscriptionDisplayId;
  };

  const createSubscriptionColumns = (): CompositeListColumn<Subscription>[] => [
    createSubscriptionNameColumn(),
    {
      columnId: 'subscription.subscriptionPurposeOfUse',
      ref: 'subscription.subscriptionPurposeOfUse',
      heading: t.L9QG(identifierMsg),
      value: (subscription: Subscription) => {
        const mainText = getSubscriptionText(subscription);
        const smallText = getSubscriptionSmallText(subscription);
        return [
          <span key="subscriptionIdentifier" title={mainText} className="ea-composite-list-row__cell-value-line">
            {mainText}
          </span>,
          <span key="small" className="ea-composite-list-row__cell-value-line">
            <small title={smallText}>{smallText}</small>
          </span>,
        ];
      },
      valueColumnClasses: subscription => styleNonClickable(subscription),
    },
    {
      columnId: 'monthlyRecurringCharge',
      ref: 'details.monthlyRecurringCharge',
      heading: t.P0PS(monthlyChargeMsg),
      value: (subscription: Subscription) =>
        subscription.details?.monthlyRecurringCharge
          ? getRecurringPriceAsText(subscription.details.monthlyRecurringCharge, subscription.details.billingPeriod)
          : '-',
      valueColumnClasses: subscription => styleNonClickable(subscription),
    },
    {
      columnId: 'oneTimeCharge',
      ref: 'details.oneTimeCharge',
      heading: t.ASEI(oneTimePaymentMsg),
      value: (subscription: Subscription) =>
        subscription.details?.oneTimeCharge && !subscription.details?.monthlyRecurringCharge
          ? formatSum(subscription.details?.oneTimeCharge)!
          : '-',
      valueColumnClasses: subscription => styleNonClickable(subscription),
    },
    {
      columnId: 'subscriptionStatus',
      ref: 'subscriptionStatus',
      heading: t.ASQT(statusMsg),
      // @ts-ignore
      value: (subscription?: Subscription) => getSubscriptionStatusText(subscription?.subscriptionStatus),
      valueLineClasses: (subscription: Subscription) => [
        'ea-disc',
        'ea-disc--small',
        `ea-disc--${
          subscription.subscriptionStatus !== undefined
            ? getSubscriptionStatusColor(subscription.subscriptionStatus)
            : 'transparent'
        }`,
      ],
      sortable: true,
    },
  ];

  const columns: CompositeListColumn<Subscription>[] = createSubscriptionColumns();

  const listProps: InfiniteScrollCompositeListProps<Subscription> = {
    classes: ['of-billing-account-subscription-list'],
    columns,
    emptyListText,
    enableInfiniteScroll: subsStillToShow,
    getRowId: (subscription: Subscription) => subscription.subscriptionId,
    items: sortedItems,
    onInfiniteScrollLoadMore: () =>
      subsStillToShow &&
      dispatch(loadBillingAccountSubscriptions({ billingAccountId, getAllItems: false, offset: items?.length })),
    onSelectRow: (id: string, subscription: Subscription) => {
      dispatch(closeSuspendSubscriptionOptions());
      dispatch(closeTerminateSubscriptionForm());
      navigate(`${paths.PS_HOME}/${CATEGORY_URL[getCategoryBySubscriptionType(subscription.subscriptionType)]}/${id}`);
    },
    onSort: (newSort: CompositeListSort) => {
      if (subsStillToShow) {
        dispatch(
          loadBillingAccountSubscriptions({
            billingAccountId: billingAccountId,
            getAllItems: true,
            offset: items?.length,
          })
        );
      }
      setSort(newSort);
    },
    isReadOnly: (subscription: Subscription) => isSubscriptionNonClickable(subscription),
    rowIcon: (subscription: Subscription) =>
      getSubscriptionIcon(subscription.subscriptionType, subscription.subscriptionSubType),
    search: {
      onChange: (newSearchValue: string) => {
        setSearch(newSearchValue);
        if (subsStillToShow) {
          dispatch(
            loadBillingAccountSubscriptions({
              billingAccountId: billingAccountId,
              getAllItems: true,
              offset: items?.length,
              search: search,
            })
          );
        }
      },
      placeholder: t.GGLB(searchPlaceHolderMsg),
      value: search || '',
      objectKeys: ['subscriptionId'],
    },
    sort,
  };

  return <InfiniteScrollCompositeList {...listProps} />;
};
