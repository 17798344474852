import * as CL from '@design-system/component-library';
import { type ChangeEvent, type FocusEvent, useCallback, useMemo } from 'react';
import { type Context } from '../../OpenFormAnswers.js';
import { Input } from '../../../Input/Input.js';
import { OF } from '../../OpenForm.js';
import { OpenFormCheckbox } from '../../OpenFormComponents/OpenFormCheckbox.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { Table } from '../../OpenFormHooks/useTable.js';
import { merge, onBlurPrice, onChangePrice } from '../../OpenFormUtils.js';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';
import type { OpenFormListColumn } from '../../../../generated/api/openFormListColumn.js';

export const OpenFormQuestionListOfObjects = ({
  dispatcher,
  choices,
  context,
  available,
  disabled,
  required,
  multiselect,
  label,
  headers,
}: {
  dispatcher: OpenFormDispatcher;
  choices: OpenFormChoice[];
  context: Context | undefined;
  available: (guid: string) => boolean;
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  label: string;
  headers: OpenFormListColumn[];
}) => {
  const inactive = useCallback((guid: string) => disabled || !available(guid), [available, disabled]);
  const row = useMemo(() => context?.row, [context?.row]);
  const set = useMemo(() => new Set(context?.choices ?? []), [context?.choices]);
  const sorted = useMemo(
    () =>
      choices
        .map(({ guid, values }) => ({ availability: available(guid), guid, values }))
        .sort(Table.compare('availability', 'desc')),
    [choices, available]
  );

  // If there are both `name` and `description__c` columns, we'll render them in a special way.
  const hasNameAndDescription = useMemo(
    () => headers.some(({ name }) => name === 'name') && headers.some(({ name }) => name === OF.description__c),
    [headers]
  );

  const isNameFieldWithDescription = useCallback<(column?: OpenFormListColumn) => boolean>(
    column => hasNameAndDescription && column?.name === 'name',
    [hasNameAndDescription]
  );

  const getRowHeadersAndValues = useCallback(
    (values: string[]) => {
      const index = headers.findIndex(({ name }) => name === OF.description__c);
      return {
        index: index,
        headers: hasNameAndDescription ? headers.filter(({ name }) => name !== OF.description__c) : headers,
        values: hasNameAndDescription ? values.filter((_, idx) => idx !== index) : values,
      };
    },
    [headers, hasNameAndDescription]
  );

  const rowCells = useCallback<(guid: string, values: string[]) => JSX.Element[]>(
    (guid, values) => {
      const visible = getRowHeadersAndValues(values);
      return visible.values.map((value, idx) => {
        if (visible.headers[idx]?.isReadOnly ?? true) {
          return (
            <div
              key={guid + idx}
              className={classNames({
                ['of-openform__view__list-of-objects-table__cell-disabled']: inactive(guid),
                ['of-openform__view__list-of-objects-table__cell-readonly']: true,
              })}
            >
              <div>{value}</div>
              {isNameFieldWithDescription(visible.headers[idx]) ? <div>{values[visible.index]}</div> : null}
            </div>
          );
        }
        const { name, prefix, suffix } = visible.headers[idx] ?? {};

        const onBlur = (e: FocusEvent<HTMLInputElement>) =>
          dispatcher.setContext('row', { ...row, [guid]: { ...row?.[guid], [name]: onBlurPrice(e.target.value) } });

        const onChange = (e: ChangeEvent<HTMLInputElement>) =>
          dispatcher.setContext('row', { ...row, [guid]: { ...row?.[guid], [name]: onChangePrice(e.target.value) } });

        return (
          <div
            key={guid + name}
            className={classNames({
              ['of-openform__view__list-of-objects-table__cell-disabled']: inactive(guid),
              ['of-openform__view__list-of-objects-table__cell-editable']: true,
            })}
          >
            {!prefix ? null : prefix}
            <Input
              disabled={inactive(guid)}
              required={required}
              value={row?.[guid]?.[name] ?? value}
              size={8}
              type="text"
              autoComplete="off"
              inputMode="decimal"
              maxLength={8}
              onBlur={onBlur}
              onChange={onChange}
              onClick={e => set.has(guid) && e.stopPropagation()}
            />
            {!suffix ? null : suffix}
          </div>
        );
      });
    },
    [dispatcher, required, inactive, row, set, isNameFieldWithDescription, getRowHeadersAndValues]
  );

  const rowClick = useCallback<(guid: string, values: string[]) => void>(
    (guid, values) => {
      switch (true) {
        case inactive(guid): {
          return;
        }
        case set.has(guid): {
          set.delete(guid);
          return dispatcher.setChoices(Array.from(set));
        }
        case !!headers?.length: {
          !multiselect && set.clear();
          set.add(guid);
          return dispatcher.setAnswer(Array.from(set), 'row', {
            ...row,
            [guid]: Object.assign(
              merge((value, idx) => (headers[idx]?.name ? { [headers[idx].name]: value } : {}), ...values),
              row?.[guid]
            ),
          });
        }
      }
    },
    [dispatcher, headers, multiselect, inactive, row, set]
  );

  return !sorted.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9} className={classNames({ ['label--mandatory']: required })}>
        <div className="of-openform__view__list-of-objects-labelarea">
          <label>{label}</label>
        </div>
        <CL.Table
          showHeaders={true}
          valignText="middle"
          className="of-openform__view__list-of-objects-table"
          tableType="bordered"
          hover={true}
          columns={[
            { align: 'center', key: 'checkbox', label: null },
            ...headers
              .filter(({ name }) => !hasNameAndDescription || name !== OF.description__c)
              .map((header, idx) => ({
                align: (!idx ? 'left' : 'right') as 'left' | 'center' | 'right',
                key: String(idx),
                label: (header.label ?? null) as string | null,
              })),
          ]}
          rowClicks={sorted.map(item => () => rowClick(item.guid, item.values))}
          rows={sorted.map(({ availability, guid, values }) =>
            Object.assign(
              merge((cell, idx) => ({ [idx]: cell }), ...rowCells(guid, values)),
              {
                active: set.has(guid),
                availability: availability,
                checkbox: <OpenFormCheckbox checked={set.has(guid)} disabled={inactive(guid)} value={guid} />,
              }
            )
          )}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
