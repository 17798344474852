import { InfiniteScrollCompositeList } from '../InfiniteScrollCompositeList/InfiniteScrollCompositeList.js';
import { contentMsg, noOrdersMsg, orderDateMsg, orderMsg, statusMsg, t, userTextMsg } from '../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCustomerOrderStatusInfo } from '../CustomerOrderDetails/CustomerOrderDetailsUtil.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListColumn, CompositeListSort } from '../CompositeListHeader/CompositeListHeader.js';
import type { CustomerOrder, CustomerOrderHeader, CustomerOrderSearchResponse } from '../../generated/api/models.js';
import type { InfiniteScrollCompositeListProps } from '../InfiniteScrollCompositeList/InfiniteScrollCompositeList.js';

export interface CustomerOrderListAttrs {
  errors?: CommonError[];
  items?: CustomerOrder[];
  search?: string;
  sort?: CompositeListSort;
  total?: number;
  searchResults?: CustomerOrderSearchResponse[];
}

export interface CustomerOrderListRoutes {
  onClickCustomerOrder: (id: string) => void;
}

export interface CustomerOrderListDispatchers {
  onSortCustomerOrders: (newSort: CompositeListSort, status?: string) => void;
  onSearchCustomerOrders: (newSearchValue: string, status?: string) => void;
  onLoadMoreCustomerOrders: (reporting?: boolean, forceLoad?: boolean, status?: string) => void;
}

export type CustomerOrderListProps = CustomerOrderListAttrs & CustomerOrderListDispatchers & CustomerOrderListRoutes;

const customerOrderNonVisibleFields = [
  {
    name: 'customerOrderContactName',
    getTitle: () => t.ZC7D('Client'),
  },
  {
    name: 'deliveryOrderSerialNumbers',
    getTitle: () => t.OE45('IMEI'),
  },
  {
    name: 'deliveryOrderPurposeOfUses',
    getTitle: () => t.ZC7D('Client'),
  },
];

interface CustomerOrderStatusOption {
  displayText: () => string;
  status: string;
}

interface CustomerOrderStatusOptions {
  all: CustomerOrderStatusOption;
  delivered: CustomerOrderStatusOption;
  processing: CustomerOrderStatusOption;
  partiallyDelivered: CustomerOrderStatusOption;
  open: CustomerOrderStatusOption;
  pendingApproval: CustomerOrderStatusOption;
  cancelled: CustomerOrderStatusOption;
}

export const customerOrderStatusOptions: CustomerOrderStatusOptions = {
  all: { displayText: () => t.EY6A('All'), status: 'ALL' },
  delivered: { displayText: () => getCustomerOrderStatusInfo('COMPLETED').text, status: 'COMPLETED' },
  processing: {
    displayText: () => getCustomerOrderStatusInfo('IN_PROGRESS').text,
    status: 'FAILED|IN_PROGRESS|PARTIALLY_FAILED',
  },
  partiallyDelivered: {
    displayText: () => getCustomerOrderStatusInfo('PARTIALLY_COMPLETED').text,
    status: 'PARTIALLY_COMPLETED',
  },
  open: { displayText: () => getCustomerOrderStatusInfo('PENDING').text, status: 'PENDING' },
  pendingApproval: {
    displayText: () => getCustomerOrderStatusInfo('PENDING_APPROVAL').text,
    status: 'PENDING_APPROVAL',
  },
  cancelled: { displayText: () => getCustomerOrderStatusInfo('CANCELLED').text, status: 'CANCELLED' },
};

export const getCustomerOrderColumnsV2 = (
  selectedState?: keyof typeof customerOrderStatusOptions,
  onChangeStatusFilter?: (newStatus: string) => void
): CompositeListColumn<CustomerOrderHeader>[] => [
  {
    columnId: 'customerOrderDisplayId',
    heading: t.C001(orderMsg),
    ref: 'customerOrderDisplayId',
    sortable: true,
  },
  {
    columnId: 'created',
    heading: t.ZTPC(orderDateMsg),
    ref: 'created',
    refFormatNumber: formatTimestampToDDMMYYYY,
    sortable: true,
  },
  {
    columnId: 'deliveryOrderContactNames',
    heading: t.U4MA(userTextMsg),
    multiLine: {
      max: 4,
      maxExceededPlural: ' other users',
      maxExceededSingular: ' other user',
    },
    sortable: false,
    value: (customerOrderHeader: CustomerOrderHeader, searchKeyword?: string) =>
      customerOrderHeader.deliveryOrderContactNames
        ? customerOrderHeader.deliveryOrderContactNames.map(contactName => (
            <>{highlightKeyword(contactName, searchKeyword)}</>
          ))
        : [],
  },
  {
    columnId: 'deliveryOrders',
    heading: t.NBNH(contentMsg),
    multiLine: {
      max: 4,
      maxExceededPlural: t.TA45(' other products'),
      maxExceededSingular: t.QOZF(' other product'),
    },
    sortable: false,
    value: (customerOrderHeader: CustomerOrderHeader, searchKeyword?: string) =>
      customerOrderHeader.deliveryOrders
        ? customerOrderHeader.deliveryOrders.map(deliveryOrder => <>{highlightKeyword(deliveryOrder, searchKeyword)}</>)
        : [],
  },
  selectedState
    ? {
        columnId: 'status',
        headingOptionMenu: {
          onChange: (displayText: string) => {
            const stateOption = Object.keys(customerOrderStatusOptions).find(
              (key: keyof typeof customerOrderStatusOptions) =>
                customerOrderStatusOptions[key].displayText() === displayText
            );
            if (onChangeStatusFilter) {
              onChangeStatusFilter(stateOption || 'all');
            }
          },
          options: Object.keys(customerOrderStatusOptions).map((option: keyof typeof customerOrderStatusOptions) =>
            customerOrderStatusOptions[option].displayText()
          ),
          value: customerOrderStatusOptions[selectedState].displayText(),
        },
        ref: 'status',
        refFormatString: (customerOrderStatus: string) =>
          getCustomerOrderStatusInfo(customerOrderStatus ? customerOrderStatus.toString() : undefined).text,
        sortable: false,
        valueLineClasses: (customerOrderHeader: CustomerOrderHeader) => [
          'ea-disc',
          'ea-disc--small',
          `ea-disc--${
            getCustomerOrderStatusInfo(customerOrderHeader.status ? customerOrderHeader.status.toString() : undefined)
              .color
          }`,
        ],
      }
    : {
        columnId: 'status',
        heading: t.ASQT(statusMsg),
        ref: 'status',
        refFormatString: (customerOrderStatus: string) =>
          getCustomerOrderStatusInfo(customerOrderStatus ? customerOrderStatus.toString() : undefined).text,
        sortable: false,
        valueLineClasses: (customerOrderHeader: CustomerOrderHeader) => [
          'ea-disc',
          'ea-disc--small',
          `ea-disc--${
            getCustomerOrderStatusInfo(customerOrderHeader.status ? customerOrderHeader.status.toString() : undefined)
              .color
          }`,
        ],
      },
];

export const CustomerOrderList = ({
  onClickCustomerOrder,
  onSortCustomerOrders,
  onLoadMoreCustomerOrders,
  onSearchCustomerOrders,
  search,
  sort,
  total,
  searchResults,
}: CustomerOrderListProps): JSX.Element => {
  const [selectedStatus, setSelectedStatus] = useState<keyof typeof customerOrderStatusOptions>('all');
  const onChangeStatusFilter = (newStatus: keyof typeof customerOrderStatusOptions) => {
    setSelectedStatus(newStatus);
    const status: string = customerOrderStatusOptions[newStatus].status;
    onSearchCustomerOrders(search || '', status);
  };

  const getRowId = (customerOrderHeader: CustomerOrderHeader) => customerOrderHeader.customerOrderDisplayId || '';
  const listItems = searchResults?.map(res => res.result);

  const listProps: InfiniteScrollCompositeListProps<CustomerOrder | CustomerOrderHeader> = {
    classes: ['of-customer-order-list'],
    columns: getCustomerOrderColumnsV2(selectedStatus, onChangeStatusFilter),
    emptyListText: t.QFXU(noOrdersMsg),
    enableInfiniteScroll: listItems && total !== undefined ? listItems.length < total : false,
    getRowId: getRowId,
    items: listItems,
    onInfiniteScrollLoadMore: () =>
      onLoadMoreCustomerOrders(false, undefined, customerOrderStatusOptions[selectedStatus].status),
    onSelectRow: onClickCustomerOrder,
    onSort: (newSort: CompositeListSort) =>
      onSortCustomerOrders(newSort, customerOrderStatusOptions[selectedStatus].status),
    rowIcon: 'order-filled',
    search: {
      onChange: (newSearchTerm: string) =>
        onSearchCustomerOrders(newSearchTerm, customerOrderStatusOptions[selectedStatus].status),
      placeholder: t.TBVG('Search order...'),
      value: search || '',
      columnIds: [],
    },
    sort: sort,
    nonVisibleFields: customerOrderNonVisibleFields,
    itemsSearchResultsMatchedFields: searchResults?.map(res => res.matchedFields || []),
  };

  return <InfiniteScrollCompositeList {...listProps} />;
};
