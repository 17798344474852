import { isInBrowser } from '../utils/ssrUtils.js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Up-to-date list of pages where elisa-styleguide-min.css still need to be loaded
const whiteListedPaths = [
  '/omaelisa',
  '/työntekijä',
  '/punchout',
  '/ota-yhteytta',
  '/netin-saatavuus',
  '/rekisteroidy',
  '/kassa',
  '/tunnistaudu',
  '/uusi-ostoskori/kassa',
];
const id = 'styleguide';
const rel = 'stylesheet';
const href = 'https://static.elisa.fi/components/pattern-library/7.31.0/release/css/elisa-styleguide-min.css';

// TODO Remove this after all the ea and RP stuff is deprecated
export const useLegacyStyles = () => {
  const { pathname } = useLocation();
  const path = decodeURI(pathname);
  const isNotApplicable = !isInBrowser();
  const isWhiteListedPath = path !== '/' && whiteListedPaths.some(str => path.startsWith(str));

  useEffect(() => {
    if (isNotApplicable) {
      return;
    }

    const link = document.getElementById(id);
    if (!isWhiteListedPath) {
      link?.parentNode?.removeChild(link);
      return;
    }

    if (!link) {
      const linkEl = document.createElement('link');
      linkEl.id = id;
      linkEl.rel = rel;
      linkEl.href = href;
      document.head.appendChild(linkEl);
    }
  }, [isNotApplicable, isWhiteListedPath]);

  return isWhiteListedPath;
};
