import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { SubscriptionLayout } from '../../../../../components/SubscriptionLayout/SubscriptionLayout.js';
import { SubscriptionVoice } from '../../../../../components/Subscription/SubscriptionVoice.js';
import { combinePendingActions, getSubscriptionBreadcrumbName } from '../../../../../common/utils/subscriptionUtils';
import { deepEqual } from '../../../../../common/utils/objectUtils';
import { omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { MobileSubscriptionLoaderResponse } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store';

export const MobileSubPath = () => {
  const {
    addOnRules,
    addOnRulesMobilePbx,
    billingAccounts,
    companyInfo,
    contacts,
    pendingSubscriptionActions,
    subscription,
    onlineModels,
  } = useLoaderData() as MobileSubscriptionLoaderResponse;
  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
        { name: getSubscriptionBreadcrumbName(subscription) },
      ]}
    />
  );
  const pendingSubscriptionActionsFromRedux = useSelector(
    (s: State) => s?.selfservice?.pendingSubscriptionActions,
    deepEqual
  );

  return (
    <SubscriptionLayout>
      <SubscriptionVoice
        addOnRules={addOnRules}
        addOnRulesMobilePbx={addOnRulesMobilePbx}
        billingAccounts={billingAccounts}
        breadCrumbs={breadCrumbs}
        category={SubscriptionCategory.VOICE}
        companyInfo={companyInfo}
        contacts={contacts}
        pendingSubscriptionActions={combinePendingActions(
          subscription.subscriptionId,
          pendingSubscriptionActions,
          pendingSubscriptionActionsFromRedux
        )}
        subscription={subscription}
        onlineModels={onlineModels}
      />
    </SubscriptionLayout>
  );
};
