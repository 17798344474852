import * as CL from '@design-system/component-library';
import { OpenFormIcon } from './OpenFormIcon.js';
import { useCallback, useEffect } from 'react';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useOutletContext } from 'react-router-dom';

export type OpenFormNotification = {
  hide?: () => void;
  icon?: string;
  show?: () => void;
  text: string;
  timer?: number;
  type?: CL.NotificationProps['type'];
};

export const OpenFormNotice = (props: OpenFormNotification) => {
  const async = useOpenFormAsync();
  useEffect(() => async.actions.notification(props), []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export const OpenFormNotifications = () => {
  const { reload } = useOutletContext<{ reload?: () => void }>() ?? {};
  const { notifications } = useOpenFormState();
  const { goto, preview } = useOpenFormGoto();
  const async = useOpenFormAsync();

  const clear = useCallback(() => {
    async.cache.clear();
    async.state.form?.formId && async.storage.removeItem(async.state.form.formId);
    goto(preview ? { page: 1 } : { listId: async.state.list?.listId });
  }, [async, goto, preview]);

  const notification = notifications.values().next().value as OpenFormNotification | undefined;
  if (!notification) {
    return null;
  }
  const { hide, show, text, timer, type = 'info', icon = type } = notification;
  return (
    <CL.Notification
      className="of-openform__title__content__notifications"
      icon={icon && <OpenFormIcon icon={icon} size="m" />}
      key={String([text, type, icon])}
      text={text}
      timer={timer}
      type={type}
      visible={true}
      hasCloseButton={true}
      onNotificationShow={show}
      onNotificationHide={() =>
        (type === 'error' ? (hide ?? clear) : type === 'warning' ? (hide ?? reload) : hide)?.() ||
        async.actions.notification(undefined)
      }
    />
  );
};
