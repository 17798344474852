import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGrid, OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormViewSection } from './OpenFormViewSection.js';
import { OpenFormViewSummary } from './OpenFormViewSummary.js';
import { clearFormMsg, salesFormsMsg, t } from '../../../common/i18n/index.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenForm } from '../OpenFormHooks/useOpenForm.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import type { FormId, Page } from '../../../common/constants/pathInterfaces.js';

export const OpenFormViewLayout = () => {
  const { answers, disabled } = useOpenFormState();
  const { goto, page, preview } = useOpenFormGoto();
  const { formState, reset } = useFormContext();
  const async = useOpenFormAsync();
  const params = useParams<FormId & Page>();
  const form = useOpenForm(params as FormId);
  const breadcrumbs = useMemo(() => (form?.sections ?? []).map(({ guid }) => guid).concat('summary'), [form]);

  useEffect(() => {
    if (form?.formId) {
      async.actions.page(page);
      params.page !== String(page) && goto({ page });
    }
  }, [async, params, form, goto, page]);

  const clear = useCallback(
    (callback = () => goto({ page: 1 })) => {
      async.cache.clear();
      async.actions.form({ answers: undefined, form });
      reset({});
      callback();
    },
    [goto, reset, async, form]
  );

  return (
    <OpenFormBar
      backText={preview ? undefined : t.QF2O(salesFormsMsg)}
      clear={answers.size ? clear : undefined}
      clearText={t.JQ1V(clearFormMsg)}
    >
      {!form ? null : (
        <OpenFormGrid className="of-openform__view">
          <OpenFormGridRow className="of-openform__view__breadcrumbs">
            <OpenFormGridCol className="of-openform__view__breadcrumbs__grid" colWidth={9}>
              {breadcrumbs.map((guid, idx) => (
                <OpenFormButton
                  className={classNames({ ['breadcrumb--incomplete']: idx >= page })}
                  disabled={disabled}
                  color="primary"
                  size="l"
                  text={idx + 1}
                  key={guid}
                />
              ))}
            </OpenFormGridCol>
          </OpenFormGridRow>
          {page > form.sections.length ? (
            <OpenFormViewSummary form={form} answers={answers} disabled={disabled} clear={clear} goto={goto} />
          ) : (
            <OpenFormViewSection
              form={form}
              answers={answers}
              disabled={disabled}
              errors={formState.errors}
              page={page}
              goto={goto}
            />
          )}
        </OpenFormGrid>
      )}
    </OpenFormBar>
  );
};
