import { SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM } from './TableSubscriptionTypeFilter';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { fixedBroadbandsMsg, mobileBroadbandsMsg, subscriptionTypeMsg, t } from '../../common/i18n/index.js';
import type { BroadbandSubscriptionTypeAggregations } from '../../generated/api/broadbandSubscriptionTypeAggregations';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';

const MOBILE_BROADBAND_SUBSCRIPTION_TYPES = [SubscriptionType.MOBILE_BROADBAND, SubscriptionType.MOBILE_M2M];
const FIXED_BROADBAND_SUBSCRIPTION_TYPES = [
  SubscriptionType.BROADBAND,
  SubscriptionType.CORPORATE_NETWORK,
  SubscriptionType.HOUSING_COMPANY_BROADBAND,
];

const getSubTypesAsStrings = (subscriptionTypes: SubscriptionType[]) => subscriptionTypes.map(type => String(type));

// Subscription type filtering checkbox should be checked, if subscription types in the URL matches at least one of the
// values in the hard coded list (e.g. MOBILE_BROADBAND_SUBSCRIPTION_TYPES)
const shouldCheckFilteringSelection = (enabledSubTypes: string[], subscriptionTypes: SubscriptionType[]) =>
  enabledSubTypes.some(subtype => getSubTypesAsStrings(subscriptionTypes).includes(subtype));

export const getBroadbandSubscriptionTypeFilterGroup: (
  subscriptionType?: string,
  aggregations?: BroadbandSubscriptionTypeAggregations
) => SearchFilterGroup = (subscriptionType = '', aggregations) => {
  const enabledSubTypes = subscriptionType.split(',');
  return {
    label: () => t.QH8P(subscriptionTypeMsg),
    value: SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM,
    items: [
      {
        label: () => t.G0EZ(mobileBroadbandsMsg),
        checked: shouldCheckFilteringSelection(enabledSubTypes, MOBILE_BROADBAND_SUBSCRIPTION_TYPES),
        value: getSubTypesAsStrings(MOBILE_BROADBAND_SUBSCRIPTION_TYPES).toString(),
        itemsCount: aggregations?.mobile,
      },
      {
        label: () => t.YW61(fixedBroadbandsMsg),
        checked: shouldCheckFilteringSelection(enabledSubTypes, FIXED_BROADBAND_SUBSCRIPTION_TYPES),
        value: getSubTypesAsStrings(FIXED_BROADBAND_SUBSCRIPTION_TYPES).toString(),
        itemsCount: aggregations?.fixed,
      },
    ],
  };
};
