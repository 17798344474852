import { type OpenFormStorage } from '../OpenFormStorage.js';
import { getPage } from '../OpenFormUtils.js';
import { loadingFormListMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { ListId } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormListItem } from '../../../generated/api/openFormListItem.js';
import type { OpenFormListResponse } from '../../../generated/api/openFormListResponse.js';

export class OpenFormList {
  constructor(private readonly response: OpenFormListResponse) {}

  get forms(): OpenFormListItem[] {
    return this.response.forms;
  }

  get listId(): string {
    return this.response.listId;
  }

  pages(store: OpenFormStorage): Map<string, number> {
    return new Map(this.response.forms.map(({ id }) => [id, getPage(store.getItem(id)?.page)]));
  }
}

export const useOpenFormList = ({ listId }: ListId) => {
  const [data, setData] = useState<OpenFormList | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = async
      .fetchOpenFormList({ listId })
      .settled(response => {
        const list = new OpenFormList(response);
        setData(list);
        async.actions.list({ list, pages: list.pages(async.storage) });
      })
      .failed(text => async.actions.notification({ text, type: 'error' }))
      .cache(listId)
      .execute(t.ZN5B(loadingFormListMsg));

    return () => {
      ctrl.abort();
      async.cache.clear();
      async.actions.list({ pages: undefined });
    };
  }, [listId, async]);

  return data;
};
