import { EppDeviceChangeSummary } from './EppDeviceChangeSummary.js';
import { getDeviceChanges } from './eppDeviceChangeUtil.js';
import type { CustomerOrder } from '../../../generated/api/customerOrder.js';
import type { Subscription } from '../../../generated/api/subscription.js';

export interface EppDeviceChangeSummariesProps {
  customerOrder: CustomerOrder;
  deviceSubscriptions?: Subscription[];
}

export const EppDeviceChangeSummaries = ({ customerOrder, deviceSubscriptions }: EppDeviceChangeSummariesProps) => {
  const newProducts = customerOrder.deliveryOrders?.filter(d => !!d.replacedSubscriptionDisplayId);
  const deviceChanges = getDeviceChanges(newProducts, deviceSubscriptions);

  return (
    <>
      {deviceChanges.map(deviceChange => (
        <EppDeviceChangeSummary
          key={deviceChange.currentEppProduct?.subscriptionId}
          newEppProduct={deviceChange.newEppProduct}
          currentEppProduct={deviceChange.currentEppProduct!}
        />
      ))}
    </>
  );
};
