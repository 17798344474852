import * as CL from '@design-system/component-library';
import { type OpenFormAccount } from '../OpenFormHooks/useOpenFormAccount.js';
import { OpenFormButton } from './OpenFormButton.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { useMemo } from 'react';
import { useOpenFormState } from '../OpenFormProvider.js';
import { useOutletContext } from 'react-router-dom';

export const OpenFormLoadingIndicator = ({ title }: { title?: string }) => {
  const { account, reload } = useOutletContext<{ account?: OpenFormAccount; reload?: () => void }>() ?? {};
  const { disabled, form, loading } = useOpenFormState();
  const hostname = useMemo(() => window.location.hostname, []);
  const icon = useMemo(() => <OpenFormIcon icon="update" />, []);
  const load = title || (loading?.values().next().value as string | true | undefined);
  return (
    <div className="of-openform__title__content__center">
      {load === undefined ? (
        <div className="of-openform__title__content__center__logo">
          <CL.Logo aria-disabled={disabled} />
          {hostname && !hostname.endsWith('yrityksille.elisa.fi') && <pre>{hostname}</pre>}
        </div>
      ) : (
        <div className="of-openform__title__content__center__load">
          {load !== true && <span className="of-openform__title__content__center__load__text">{load}</span>}
          <CL.LoadingSpinner className="of-openform__title__content__center__load__spinner" size="l" />
        </div>
      )}
      <div className="of-openform__title__content__center__name" aria-disabled={disabled}>
        {!account ? null : !form ? (
          <>
            <span className="of-openform__title__content__center__name__account">
              {account.header.accountName}
              <OpenFormButton color="link" size="s" disabled={disabled || !reload} icon={icon} onClick={reload} />
            </span>
            <span className="of-openform__title__content__center__name__form">
              <>&nbsp;</>
            </span>
          </>
        ) : (
          <>
            <span className="of-openform__title__content__center__name__account">{account.header.accountName}</span>
            <span className="of-openform__title__content__center__name__form">
              {form.name}
              <OpenFormButton color="link" size="s" disabled={disabled || !reload} icon={icon} onClick={reload} />
            </span>
          </>
        )}
      </div>
    </div>
  );
};
