import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { billingAccountMsg, noSelectionMsg, selectMsg, t } from '../../i18n/index.js';
import { getBillingAccountListItems } from '../../../components/AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import type { BasicAutoCompleteItem } from '../../../components/AutoComplete/AutoComplete.js';
import type { BillingAccountSearchResponse } from '../../../generated/api/billingAccountSearchResponse.js';

interface BillingAccountProps {
  billingAccounts: Array<BillingAccountSearchResponse>;
  name: string;
  enableAddNewBa?: boolean;
  required?: boolean;
  hideLabel?: boolean;
}

export const BillingAccount = ({
  name,
  billingAccounts,
  enableAddNewBa = true,
  required = true,
  hideLabel = false,
}: BillingAccountProps) => {
  const formattedBas = getBillingAccountListItems(enableAddNewBa, billingAccounts, '').map(x => ({
    ...x,
    id: x.value,
  }));
  const methods = useFormContext();
  const { control, setValue, setError, watch } = methods;
  const reset = watch('key') || 0;
  const rules = { required: { value: required, message: t.GMD4('The billing account must be chosen') } };
  const { field, fieldState, formState } = useController({ name, control, rules });

  // If not required, anyway check the formState for an error
  const error = required
    ? getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error)
    : formState.errors.billingAccountId?.message?.toString();

  const keys = name.split('.');
  const defaultValues = formState.defaultValues;
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  // defaultValue is the default selected value of the component if the reset changes the defaultValue is rendered according the value of the input
  const defaultValue = formattedBas.find(i => (reset === 0 ? i.value === value : i.value === field.value));

  return (
    <AutoComplete<BasicAutoCompleteItem>
      className="large-min-height"
      inputRef={field.ref}
      options={formattedBas}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayHtml={i => i.html}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      config={{
        isSortable: false,
        isClearable: false,
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={!hideLabel ? t.IFT9(billingAccountMsg) : undefined}
      placeholder={t.QRYV(selectMsg)}
      error={error}
      key={`${name}-autocomplete${reset}`}
    />
  );
};
