import { AddOnVisibilityOperationType } from '../../common/enums.js';
import { OrderDeliveryOptions } from '../OrderDeliveryOptions/OrderDeliveryOptions.js';
import { OrderSubscriptionConfiguration } from '../OrderSubscriptionConfiguration/OrderSubscriptionConfiguration.js';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getNonEditableAddOns } from '../SubscriptionAdditionalService/subscriptionAddOnUtils';
import { loadContacts } from '../../selfservice/actions/contactsActions.js';
import { onSubmitOrder } from '../../common/utils/dispatcherUtils.js';
import { useAddOnVisibility } from '../../common/hooks/useAddOnVisibility';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type {
  AddOn,
  BillChannel,
  BillingAccountsResponse,
  CompanyInfoResponse,
  ContactsResponse,
  OnlineModel,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { ModelType } from '../../common/enums.js';
import type { State } from '../../selfservice/common/store.js';

type OrderSubscriptionProps = {
  modelType: ModelType;
};

export interface OrderSubscriptionStateParams {
  addOns?: AddOn[];
  selectedBaId?: string;
  selectedOffer?: ConfiguredOffer;
  showTaxes?: boolean;
}

export interface OrderSubscriptionConfigProps {
  companyInfo: CompanyInfoResponse;
  onlineModels: OnlineModel[];
  contacts: ContactsResponse;
  holidays: Date[];
}

export interface OrderSubscriptionDeliveryOptionsProps {
  companyInfo: CompanyInfoResponse;
  contacts?: ContactsResponse;
  billingAccounts: BillingAccountsResponse;
  billChannels: BillChannel[];
}

export const useOrderSubscriptionOutletContext = () => useOutletContext<OrderSubscriptionProps>();

export const OrderSubscriptionLayout = ({ modelType }: OrderSubscriptionProps) => {
  return <Outlet context={{ modelType } satisfies OrderSubscriptionProps} />;
};

export const OrderSubscriptionConfig = ({
  contacts,
  holidays,
  companyInfo,
  onlineModels,
}: OrderSubscriptionConfigProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const { modelType } = useOrderSubscriptionOutletContext();
  const { authenticatedUser } = useAuth();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const companyName = getCompanyName(authenticatedUser, mdmId) || authenticatedUser?.companyName || '';
  const { numberRanges, phoneNumbers, phoneNumbersLoading, validatedPhoneNumbers } = useSelector((s: State) => {
    const selfservice = s?.selfservice;
    const resources = s?.resources;
    return {
      // USED BY ALL-----------------------------------------------------------

      // Used in OrderSubscriptionConfiguration for scrolling to
      // OrderSubscriptionConfigurationFields errors
      // (i.e. both use it)
      validationErrors: selfservice?.onlineOrders?.errors || [],

      // ONLY USED BY Voice (i.e. MobileSubNewPath)-----------------------------

      // OrderSubscriptionConfigurationFields AttachRing------------------------
      numberRanges: resources?.numberRanges,

      // (NumberOwnerRHF radio label)
      phoneNumbers: resources?.numbers || [],
      phoneNumbersLoading: resources?.numbersLoading || false,
      // (Contains validation errors for phone number(s))
      validatedPhoneNumbers: resources?.validatedPhoneNumbers || [],
    };
  }, deepEqual);

  const stateParams = { ...state } as OrderSubscriptionStateParams;
  const selectedCommercialProduct = stateParams.selectedOffer?.selectedCommercialProducts[0]; // There is only 1
  const { addOnVisibilities } = useAddOnVisibility(
    selectedCommercialProduct?.commercialProduct.commercialProductCode || '',
    AddOnVisibilityOperationType.NEW_SALES,
    undefined,
    mdmId
  );

  // Addons that cannot be edited but are included in the subscription (e.g. Reissunetti). These should always be shown
  // in order summary.
  const nonEditableAddons: AddedAddon[] = getNonEditableAddOns(
    addOnVisibilities || [],
    selectedCommercialProduct?.addedAddOns || []
  );

  // TODO what creates onlineOrders.errors, is it really the
  // shabby purposeOfUseOrContact error thing?
  return (
    <OrderSubscriptionConfiguration
      dispatch={dispatch}
      location={location}
      navigate={navigate}
      search={search}
      orderModelType={modelType}
      holidays={holidays}
      companyName={companyName}
      contacts={contacts.searchResults?.map(sr => sr.result) ?? []}
      phoneNumbers={phoneNumbers}
      phoneNumbersLoading={phoneNumbersLoading}
      validatedPhoneNumbers={validatedPhoneNumbers}
      orderStateParams={stateParams}
      companyInfo={companyInfo}
      onlineModels={onlineModels}
      numberRanges={numberRanges}
      nonEditableAddedAddons={nonEditableAddons}
    />
  );
};

export const OrderSubscriptionDeliveryOptions = ({
  companyInfo,
  billingAccounts,
  billChannels,
}: OrderSubscriptionDeliveryOptionsProps) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { authenticatedUser } = useAuth();
  const { onlineOrders, useDuplicateContacts, contactsFromRedux } = useSelector((s: State) => {
    const selfservice = s?.selfservice;
    return {
      onlineOrders: selfservice?.onlineOrders,
      useDuplicateContacts: selfservice?.subscriptionActions?.useDuplicateContact,
      contactsFromRedux: selfservice?.contacts,
    };
  }, deepEqual);

  // This is needed for the case of creating a new contact for the selected billing account.
  // The contact is created via the formik/ContactDropdown which will open a ADD_CONTACT dialog from the selfServiceDialogs.ts
  // The dialog will upsert the contact infromation. The created contact is then selected by default on the ContactDropdown in the
  // AddOrSelectBillingAccount/CreateBillingAccount billing account form.
  // In order to get rid of this dispatch the billing account form should be refactored so that the newly created contact is saved
  // into the component state instead of the redux state.
  useEffect(() => {
    if (!contactsFromRedux) {
      dispatch(loadContacts());
    }
  }, [contactsFromRedux, dispatch]);

  const selectedOffer = state?.selectedOffer as ConfiguredOffer;
  if (selectedOffer == null) {
    return null;
  }

  return (
    <OrderDeliveryOptions
      companyInfo={companyInfo || undefined}
      contacts={contactsFromRedux?.items}
      billingAccounts={billingAccounts}
      billChannels={billChannels}
      onSubmitOrder={onSubmitOrder(dispatch)}
      onlineOrdersErrors={onlineOrders?.errors}
      saving={!!onlineOrders && onlineOrders.saving}
      user={authenticatedUser?.contact}
      orderStateParams={{ ...state } as OrderSubscriptionStateParams}
      useDuplicateContact={useDuplicateContacts}
    />
  );
};
