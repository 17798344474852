import { AiChatMessage } from '../../generated/api/aiChatMessage.js';
import { Ellipsis } from '../Ellipsis/Ellipsis.js';
import { Form, Link, useLoaderData, useNavigation, useNavigationType } from 'react-router-dom';
import { Grid } from '../Grid/Grid.js';
import { Paragraphs, TypingEffect } from './TypingEffect.js';
import { PublicPagesSearchInputGroup } from '../PublicPagesSearchForm/PublicPagesSearchForm.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { paths } from '../../common/constants/pathVariables';
import { pushChatMessageEventToDataLayer, pushChatStartEventToDataLayer } from '../../common/analytics.js';
import { removeAiChatSessionId } from '../../selfservice/common/localStorageUtils';
import { t } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useScrollToBottom } from '../../common/hooks/useScrollToBottom.js';
import type { AiChatResponse } from '../../generated/api/aiChatResponse.js';

import './AiChat.scss';

interface AiChatMessageProps {
  isProcessed: boolean;
  messages: string[];
  setIsTypingFinished: () => void;
}

interface AiChatMessagesProps {
  count: number;
  message: AiChatMessage;
  setIsTypingFinished: () => void;
}

const createUserMessage = (content = '') => ({ content, role: AiChatMessage.RoleEnum.user, createdAt: Date.now() });

export const AiChatMsg = ({ isProcessed, messages, setIsTypingFinished }: AiChatMessageProps) => {
  return (
    <>
      {isProcessed ? (
        <TypingEffect messages={messages} setIsTypingFinished={setIsTypingFinished} />
      ) : (
        <Paragraphs messages={messages} />
      )}
    </>
  );
};

const AiChatMessages = ({ count, message, setIsTypingFinished }: AiChatMessagesProps) => {
  const containerRef = useScrollToBottom(count);
  const response = useLoaderData() as AiChatResponse;
  const messages = response.messages;
  const { state } = useNavigation();
  const isLoading = state === 'submitting' || state === 'loading';
  const navigationType = useNavigationType();

  useEffect(() => {
    if (!isInBrowser() && !response.sessionId) {
      return;
    }
    if (navigationType === 'POP' && response.messages.length === 0) {
      removeAiChatSessionId();
    }
    if (navigationType === 'REPLACE') {
      if (response.messages.length === 2) {
        pushChatStartEventToDataLayer(response);
      }
      pushChatMessageEventToDataLayer(response);
    }
  }, [response, navigationType]);

  return (
    <div className="messages" ref={containerRef}>
      {[...messages, ...(isLoading ? [message] : [])]
        .filter(msg => msg.content)
        .map((msg, index) => {
          const paragraphs = msg.content
            .split('\n')
            .map(p => p.trim())
            .filter(Boolean);
          const isProcessed =
            !isLoading &&
            Boolean(message.content) &&
            index === messages.length - 1 &&
            msg.role === AiChatMessage.RoleEnum.assistant;
          return (
            <div className={`message ${msg.role}-message`} key={msg.createdAt}>
              <AiChatMsg isProcessed={isProcessed} messages={paragraphs} setIsTypingFinished={setIsTypingFinished} />
            </div>
          );
        })}
      {isLoading && (
        <div className="message loading-message">
          <p>
            <Ellipsis />
          </p>
        </div>
      )}
    </div>
  );
};

export const AiChat = () => {
  const [message, setMessage] = useState(createUserMessage());
  const [query, setQuery] = useState('');
  const [count, setCount] = useState(0);
  const [isTypingFinished, setIsTypingFinished] = useState(true);

  return (
    <Grid>
      <div className={`${dsClass.TEXT_ALIGN_CENTER} ${dsClass.MARGIN_VERTICAL_4}`}>
        <h1>{t.W77L('Instructions and tips for companies')}</h1>
        <p style={{ fontSize: '19px' }}>
          {t.PFQL(`Hello! I'm Elisa's AI intern, ready to find answers for your questions.`)}
        </p>
        <p>
          {t.MO41(
            `I get my information from Elisa's instructions for corporate customers. I might not be able to reply to all of your questions, but I am constantly evolving. Sometimes rephrasing your question helps me give you a better answer.`
          )}
        </p>
      </div>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <div className="ai-chat">
          <Form
            method="post"
            onSubmit={() => {
              setQuery('');
              setCount(c => c + 1);
              setMessage(createUserMessage(query));
              setIsTypingFinished(false);
            }}
          >
            <PublicPagesSearchInputGroup
              handleClear={() => setQuery('')}
              onChange={e => setQuery(e.target.value)}
              isSubmitDisabled={!isTypingFinished}
              value={query}
            />
            <div className={dsClass.MARGIN_VERTICAL_2}>
              <Link
                to={paths.INSTRUCTIONS}
                replace={true}
                onClick={() => {
                  removeAiChatSessionId();
                  setIsTypingFinished(true);
                }}
              >
                {t.RW1L('Start a new topic')}
              </Link>
            </div>
          </Form>
          <AiChatMessages count={count} message={message} setIsTypingFinished={() => setIsTypingFinished(true)} />
        </div>
      </div>
    </Grid>
  );
};
