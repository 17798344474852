import {
  SubscriptionAddOnGroup,
  SubscriptionAdditionalServiceStatusEnum,
  SubscriptionAddonCode,
} from '../../common/enums.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AddedAddon } from '../../common/types/addon';
import type { Subscription } from '../../generated/api/subscription';

export interface AddOnExternalLinkProps {
  addOnCode?: string;
  addOnGroup?: string;
  addOnLink: string;
}

export const addOnDefaultExternalLink = 'https://yrityksille.elisa.fi/ohjeet/lisapalvelut';
export const addOnExternalLinks: AddOnExternalLinkProps[] = [
  {
    addOnCode: SubscriptionAddonCode.VOICE_MAIL,
    addOnLink: 'https://yrityksille.elisa.fi/ohje/elisa-matkapuhelinvastaaja',
  },
  {
    addOnCode: SubscriptionAddonCode.MOBIILITURVA,
    addOnLink: 'https://yrityksille.elisa.fi/mobiiliturva',
  },
  {
    addOnGroup: SubscriptionAddOnGroup.PUBLIC_IP,
    addOnLink: 'https://yrityksille.elisa.fi/ohje/Julkinen-IP-mobiililaajakaistalle',
  },
  {
    addOnGroup: SubscriptionAddOnGroup.FIXED_PUBLIC_IP,
    addOnLink: 'https://yrityksille.elisa.fi/ohje/Julkinen-IP-mobiililaajakaistalle',
  },
];

export const showAddonAsLink = (
  additionalServicePath?: string,
  isEmployee?: boolean,
  serviceStatus?: SubscriptionAdditionalServiceStatusEnum
): boolean =>
  Boolean(serviceStatus !== SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE && additionalServicePath && !isEmployee);

export const shouldNotShowMobileId = (
  isEmployee: boolean,
  isMobileIdEnabledForCompany: boolean,
  isMobileIdEnabledForEoeUser: boolean
) => {
  return (!isEmployee && !isMobileIdEnabledForCompany) || (isEmployee && !isMobileIdEnabledForEoeUser);
};

export const getAddonLinkUrl = (addOnVisibility: AddOnVisibility): string | undefined => {
  if (!addOnVisibility.selfServiceEditable) {
    return undefined;
  }

  return (
    addOnExternalLinks.find(
      addOn =>
        addOn.addOnCode === addOnVisibility.addOnGuidCode || addOn.addOnGroup === addOnVisibility.addOn?.addOnGroup
    )?.addOnLink || addOnDefaultExternalLink
  );
};

export enum AdditionalInformationCategory {
  FIXED_BROADBAND = 'FIXED_BROADBAND',
  ALL = 'ALL',
}

export const getInformationCategoryFromPath = (path: string): AdditionalInformationCategory => {
  const pathname = decodeURI(path);
  if (pathname.startsWith(paths.ANONYMOUS_FIXED_BROADBAND_ORDER)) {
    return AdditionalInformationCategory.FIXED_BROADBAND;
  }
  return AdditionalInformationCategory.ALL;
};

interface AddOnAdditionalInformationType {
  addOnCode?: string;
  addOnGroup?: string;
  information: string;
  categories: AdditionalInformationCategory[];
}

export const getAddOnAdditionalInformationMap = (): AddOnAdditionalInformationType[] => [
  {
    addOnGroup: SubscriptionAddOnGroup.PUBLIC_IP,
    information: t.CCRE(
      'All Netti Lite 4G/5G subscriptions are included with a secure, non-public IP address by default. If you need a public IP address, can you order it as an additional service.'
    ),
    categories: [AdditionalInformationCategory.FIXED_BROADBAND],
  },
];

export const includesVoiceMail = (subscription: Subscription): boolean =>
  Boolean(subscription.details?.selectedAddOns?.some(addon => addon.addOnCode === SubscriptionAddonCode.VOICE_MAIL));

export const mergeAddedAddons = (array1: AddedAddon[], array2: AddedAddon[]): AddedAddon[] =>
  array2.reduce(
    (acc, addon) => {
      if (!acc.find(item => item.addOnCode === addon.addOnCode)) {
        acc.push(addon);
      }
      return acc;
    },
    [...array1]
  );

export const getNonEditableAddOns = (addOnVisibilities: AddOnVisibility[], addOns: AddedAddon[]): AddedAddon[] => {
  const visibilityGuids = new Set(addOnVisibilities.map(({ addOnGuidCode }) => addOnGuidCode));
  return addOns.filter(({ addOnCode }) => !visibilityGuids.has(addOnCode));
};
