import { AttachVakio } from '../../../../../components/AttachVakio/AttachVakio';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptionStatus, getSubscriptionUrlId } from '../../../../../common/utils/subscriptionUtils';
import { paths } from '../../../../../common/constants/pathVariables';
import { useEffect } from 'react';
import type { MobileSubAttachVakioLoaderData } from '../../../../../common/loaders';

export const MobileSubAttachVakioPath = () => {
  const { companyInfo, pendingSubscriptionActions, subscription, numberRanges } =
    useLoaderData() as MobileSubAttachVakioLoaderData;
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (subscriptionStatus.pendingActions) {
      navigate(
        generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) + search,
        {
          replace: true,
        }
      );
    }
  }, [navigate, search, subscription, subscriptionStatus.pendingActions]);

  return (
    <AttachVakio
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      subscription={subscription}
      numberRanges={numberRanges}
    />
  );
};
