import { SelfServiceHome } from '../../../../components/SelfServiceHome/SelfServiceHome.js';
import { SelfServiceLogin } from '../../../../components/SelfServiceLoginScene/SelfServiceLogin.js';
import { fetchCustomerOrders, fetchInvoices, fetchSubscriptionActions } from '../../../../common/fetch.js';
import { isSearchAllAccounts, setIsSearchAllAccounts } from '../../../../selfservice/common/localStorageUtils.js';
import { useAuth } from '../../AuthProvider.js';
import { useEffect, useState } from 'react';
import type { CustomerOrderHeader } from '../../../../generated/api/customerOrderHeader.js';
import type { CustomerOrdersResponse } from '../../../../generated/api/customerOrdersResponse.js';
import type { InvoiceHeader } from '../../../../generated/api/invoiceHeader.js';
import type { InvoicesResponse } from '../../../../generated/api/invoicesResponse.js';
import type { SubscriptionAction } from '../../../../generated/api/subscriptionAction';
import type { SubscriptionActionsResponse } from '../../../../generated/api/subscriptionActionsResponse';

const SelfServiceAuthenticatedPath = () => {
  const [openInvoices, setOpenInvoices] = useState<InvoiceHeader[] | undefined>([]);
  const [customerOrders, setCustomerOrders] = useState<CustomerOrderHeader[] | undefined>([]);
  const [pendingRedeemRequests, setPendingRedeemRequests] = useState<SubscriptionAction[] | undefined>([]);

  if (!isSearchAllAccounts()) {
    setIsSearchAllAccounts(true);
  }

  useEffect(() => {
    async function fetchData(): Promise<{
      openInvoicesResponse: InvoicesResponse;
      customerOrdersResponse: CustomerOrdersResponse;
      subscriptionActions: SubscriptionActionsResponse;
    }> {
      return {
        openInvoicesResponse: await fetchInvoices({ useSearchService: true, openInvoicesOnly: true }),
        customerOrdersResponse: await fetchCustomerOrders({ useSearchService: true }),
        subscriptionActions: await fetchSubscriptionActions({}),
      };
    }
    fetchData().then(data => {
      setOpenInvoices(
        data.openInvoicesResponse?.searchResults?.map(res => res.result).filter(invoice => invoice.balance > 0)
      );
      setCustomerOrders(data.customerOrdersResponse?.searchResults?.map(res => res.result));
      setPendingRedeemRequests(
        data.subscriptionActions?.subscriptionActions?.filter(res => res.status === 'PENDING_APPROVAL')
      );
    });
  }, []);

  return (
    <SelfServiceHome
      openInvoices={openInvoices}
      customerOrders={customerOrders}
      pendingRedeemRequestCount={pendingRedeemRequests?.length}
    />
  );
};

export const SelfServiceHomePath = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <SelfServiceAuthenticatedPath />;
  }
  return <SelfServiceLogin />;
};
