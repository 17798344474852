import { OFPageType } from '../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../generated/api/oFQuestionType.js';
import { UUID_REGEX } from './OpenFormUtils.js';
import type { OpenFormChoice } from '../../generated/api/openFormChoice.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../generated/api/openFormSection.js';

import './OpenForm.scss';

/* eslint-disable @typescript-eslint/naming-convention */
export const OF = {
  Billing_Account_Data__c: 'Billing_Account_Data__c',
  Billing_Account__c: 'Billing_Account__c',
  Contract_period__c: 'Contract_period__c',
  Desired_Delivery_Date__c: 'Desired_Delivery_Date__c',
  Desired_Delivery_Time__c: 'Desired_Delivery_Time__c',
  Installation_Address__c: 'Installation_Address__c',
  Installation_Contact__c: 'Installation_Contact__c',
  Ordering_Contact__c: 'Ordering_Contact__c',
  Product_line__c: 'Product_line__c',
  Technical_Contact__c: 'Technical_Contact__c',
  description__c: 'description__c',
  one_time_charge__c: 'one_time_charge__c',
  recurring_charge__c: 'recurring_charge__c',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

const check =
  (target: OpenFormChoice | OpenFormQuestion | OpenFormSection) =>
  (value: (typeof OF)[keyof typeof OF] | OFQuestionType | OFPageType | string) => {
    switch (true) {
      case value in OF:
        return value === (target as OpenFormQuestion).fieldApiName;
      case value in OFQuestionType:
        return value === (target as OpenFormQuestion).type;
      case value in OFPageType:
        return value === (target as OpenFormSection).pageType;
      case UUID_REGEX.test(value):
        return value === target.guid;
      default:
        throw new Error(`${JSON.stringify(value)} is not {OF,OFQuestionType,OFPageType,UUID}`);
    }
  };

type Match = {
  (...values: (typeof OF)[keyof typeof OF][]): (target?: OpenFormQuestion) => boolean;
  (...values: OFQuestionType[]): (target?: OpenFormQuestion) => boolean;
  (...values: OFPageType[]): (target?: OpenFormSection) => boolean;
  (...values: string[]): (target?: OpenFormChoice | OpenFormQuestion | OpenFormSection) => boolean;
};

export const any: Match = (...values) =>
  !values.length ? _ => false : target => (!target?.guid ? false : values.some(check(target)));

export const has: Match = (...values) =>
  !values.length ? _ => false : target => (!target?.guid ? false : values.every(check(target)));

export const not: Match = (...values) =>
  !values.length ? _ => false : target => (!target?.guid ? false : !values.some(check(target)));
