import { BillingAccountDropDown } from '../../common/formik/Fields/Dropdown/BillingAccountDropdown.js';
import { ProvidedInput } from '../../common/formik/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { notMandatoryMsg, t } from '../../common/i18n/index.js';

interface CustomerOrderBillingInfoParams {
  isInternalCustomer: boolean;
  approverMustSelectBillingAccount?: boolean;
}

export const CustomerOrderBillingInfo = ({
  isInternalCustomer,
  approverMustSelectBillingAccount,
}: CustomerOrderBillingInfoParams) => {
  return (
    <div className="of-customer-order-details__business-account">
      <h3 className={dsClass.MARGIN_BOTTOM_2}>{t.W1ZW('Billing information for the products')}</h3>
      <div className="of-customer-order-details__business-account__grid">
        <div className="of-customer-order-details__business-account__field">
          <ProvidedInput
            name="costCenter"
            label={`${t.WH9D('Cost Center')}${!isInternalCustomer ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
            maxLength={40}
            required={isInternalCustomer}
          />
        </div>
        <div className="of-customer-order-details__business-account__field">
          <ProvidedInput
            name="employeeNumber"
            label={`${t.WGGQ('e.g. employee number')}${!isInternalCustomer ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
            required={false}
            maxLength={80}
          />
        </div>
        <div className="of-customer-order-details__business-account__select">
          <BillingAccountDropDown approverMustSelectBillingAccount={approverMustSelectBillingAccount} />
        </div>
      </div>
    </div>
  );
};
