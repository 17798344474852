/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentHeader { 
    /**
     * Internal, unique id of the document. SFDC: Invoice.Id
     */
    invoiceDocumentId: string;
    /**
     * Customer MDM id. SFDC: Customer__c.MDM_Id__c
     */
    accountMasterId: string;
    /**
     * Internal, unique id of the billing account. SFDC: Billing_Account__c.Id
     */
    billingAccountId: string;
    /**
     * Billing account id shown to the user. SFDC: Billing_Account__r.Billing_Account_Number__c
     */
    billingAccountDisplayId?: string;
    /**
     * Contract party from the invoice. SFDC: Contract_Party__r.MDM_Id__c
     */
    contractPartyId?: string;
    /**
     * Document number. SFDC: Invoice.ABP_Invoice_Number__c
     */
    documentDisplayId: string;
    /**
     * Type of document. SFDC: Invoice.Document_Type__c
     */
    documentType: DocumentHeader.DocumentTypeEnum;
    /**
     * Source system. SFDC: Invoice.Source_System__c
     */
    sourceSystem: DocumentHeader.SourceSystemEnum;
    /**
     * Unix timestamp in milliseconds of creation. SFDC: Invoice.CreatedDate
     */
    created: number;
    /**
     * Unix timestamp in milliseconds of last modification. SFDC: Invoice.LastModifiedDate
     */
    lastModified: number;
    /**
     * Sum in cents. SFDC: Invoice.Invoice_Amount__c
     */
    sum?: number;
    /**
     * Date of document. SFDC: Invoice.Invoice_Date__c
     */
    documentDate?: number;
}
export namespace DocumentHeader {
    export type DocumentTypeEnum = 'CREDIT_NOTE' | 'FA_STATEMENT' | 'PAYMENT_ARRANGEMENT' | 'REMINDER_LETTER' | 'TERMINATION_LETTER';
    export const DocumentTypeEnum = {
        CREDIT_NOTE: 'CREDIT_NOTE' as DocumentTypeEnum,
        FA_STATEMENT: 'FA_STATEMENT' as DocumentTypeEnum,
        PAYMENT_ARRANGEMENT: 'PAYMENT_ARRANGEMENT' as DocumentTypeEnum,
        REMINDER_LETTER: 'REMINDER_LETTER' as DocumentTypeEnum,
        TERMINATION_LETTER: 'TERMINATION_LETTER' as DocumentTypeEnum
    };
    export type SourceSystemEnum = 'SFDC' | 'TELLUS' | 'MIPA';
    export const SourceSystemEnum = {
        SFDC: 'SFDC' as SourceSystemEnum,
        TELLUS: 'TELLUS' as SourceSystemEnum,
        MIPA: 'MIPA' as SourceSystemEnum
    };
}


