// CartItemType to Save in LocalStorage -- Includes lots of unused stuff present in localstorage
import type { AddOnPurpose, CommercialProductSubType } from '../../../generated/api/models.js';
import type { AdditionalField } from './shoppingCartFunctions.js';
import type { ProductType } from './shoppingCartEnums.js';

export type CartItemType = {
  /**
   * Randomly generated UUID to identify specific shopping cart item.
   */
  id: string;
  quantity: number;
  bundle: Bundle;
  bundleItems: [BundleItemsEntity];
};

export interface Bundle {
  id: string; // Probably onlineModelDisplayId
  name: string; // Probably onlineModelName
  category: string;
  manufacturer: string;
  description: string;
  tags?: string[];
  listingImage: string;
  images?: string[];
  created: string;
  modified: string;
  guid: string; // Probably onlineModelCode
  cmsPageId: string;
  productItems?: Product[];
  addOnResource?: AddOnResourceEntity[];
}

export interface Onetime {
  price: number;
}

export interface PeriodicEntity {
  price: number;
  payments: number;
  period: number;
  priceSubType?: CommercialProductSubType;
  billingPeriod?: number;
}

export interface PropertiesEntity {
  name: string;
  value?: string;
  section?: string;
  type?: string;
  required?: boolean;
  order?: number;
}

export interface AddOnResourceEntity {
  onlineModelDisplayId: string;
  onlineModelCode: string;
  offers?: OffersEntity[];
  addOns?: AddOnsEntity[];
}

export interface OffersEntity {
  offerCode: string;
  commercialProducts?: CommercialProductsEntity[];
}

export interface CommercialProductsEntity {
  commercialProductCode: string;
  addOnAssociations?: AddOnAssociationsEntity[];
}

export interface AddOnAssociationsEntity {
  display: boolean;
  addOnPurpose: string;
  addOnCode: string;
  addOnAssociationId: string;
  addOnAssociationCode: string;
}

export interface AddOnsEntity {
  lastModified: number;
  display?: null;
  created: number;
  addOnType: string;
  addOnPurpose?: null;
  addOnProductName: string;
  addOnOneTimeCharge?: null;
  addOnMonthlyRecurringCharge: number;
  addOnId: string;
  addOnGroup: string;
  addOnCode: string;
}

export interface BundleItemsEntity {
  product: Product;
  price: Price;
  addOnBundle: AddOnBundle;
  productPath?: string;
  additionalFields: AdditionalField[];
  productType?: ProductType;
}

export interface Product {
  id: string; // Offer.materialId
  name: string;
  guid: string; // offerCode
  categoryGuid: string; // onlineModelCode
  category: string; // onlineModelDisplayId
  properties?: PropertiesEntity[];
  priceOptions?: string[][];
  images: string[];
  stockAvailability: number;
  inactive: boolean;
}

export interface Price {
  onetime: Onetime;
  guids: string[];
  periodic?: PeriodicEntity[];
}

export interface AddOnBundle {
  selectedAddons: string[];
  addOnCounts: Record<string, number>;
  availableAddOns: AvailableAddOnsEntity[];
}

export interface AvailableAddOnsEntity {
  lastModified?: number;
  display: boolean;
  created?: number;
  addOnType: string;
  addOnPurpose: AddOnPurpose;
  addOnProductName: string;
  addOnOneTimeCharge?: number;
  addOnMonthlyRecurringCharge?: number;
  addOnId?: string;
  addOnGroup: string;
  addOnCode: string;
  addOnAssociationId: string;
  addOnAssociationCode: string;
}

export enum AnalyticsEventTypes {
  ADD_TO_CART = 'addToCart',
  REMOVE_FROM_CART = 'removeFromCart',
}

export interface CartUpdateAnalyticsData {
  productId: string;
  onlineModelCode: string;
  deltaQuantity: number;
  analyticsEventType: AnalyticsEventTypes;
  event: string;
  target: string;
}
