import { type AddressSearchMatch, type AddressSearchResult } from '../AddressSearch/AddressSearch.js';
import { format, parse } from 'date-fns';
import { generatePath } from 'react-router-dom';
import type { Address } from '../../generated/api/address.js';

// UUID "hex-and-dash" string format: https://www.ietf.org/rfc/rfc9562.html#name-uuid-format
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const concat = <T>(...input: (T | undefined)[]): T[] => input.filter((n): n is T => Boolean(n) || n === 0);

export const getAddressSearchResult = (match?: AddressSearchMatch): AddressSearchResult | undefined =>
  match ? { postalCode: match.postalCode, match } : undefined;

export const getAddressText = (addr: Address | undefined) =>
  addr ? concat(addr.line1, addr.line2, addr.postalCode, addr.postOffice).join(' ') : undefined;

// Choices are stored in `dd.MM.yyyy` format in storage, but sent to salesforce in `yyyy-MM-dd` format
export const getDate = (date: string | undefined) =>
  date ? format(parse(date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd') : undefined;

export const getPage = (page?: number | null, { min = 1, max = Number.MAX_SAFE_INTEGER } = {}) =>
  Number.isSafeInteger(page) ? Math.min(max, Math.max(min, page!)) : min;

export const getValue = <T>(input?: T | T[]) =>
  !input ? input : Array.isArray(input) ? String(input) : typeof input === 'object' ? undefined : input;

export const isIframe = () => {
  try {
    return !!window.parent.location.href && window.parent !== window.self;
  } catch {
    return true;
  }
};

export const merge: {
  <
    Field extends string | number | symbol,
    Input,
    Merge extends Record<Field, unknown> extends Record<Field, infer U> ? U : never,
  >(
    callback: (value: Input, index: number, array: Input[]) => Merge,
    ...input: Input[]
  ): Merge;
} = (callback, ...input) => Object.assign({}, ...input.map(callback));

export const onBlurPrice = (value: string) => Math.max(0, Math.min(Number(value), 99999.99)).toFixed(2);

export const onChangePrice = (value: string) => value.replace('-', '').replace(',', '.');

export const parsePath = <P>(path: string, params?: Record<keyof P, unknown>) => generatePath(path, params);
