import { ServiceLevelAndAddons } from '../../AttachRing/ServiceLevelAndAddons.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getOpeningFeeAddons } from '../../../common/utils/ringUtils.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { AttachPbxContentFormValues } from '../AttachRingPbxContent';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { ServiceLevel, ServiceLevelAddon } from '../../AttachRing/ServiceLevelAndAddonsInterfaces.js';

export const ServiceLevelFields = ({
  serviceLevels,
  addons,
  onChange,
  associations,
  dependencies,
  ringModels,
}: {
  serviceLevels?: ServiceLevel[];
  addons?: ServiceLevelAddon[];
  onChange: (field: keyof AttachPbxContentFormValues, value: ServiceLevel[] | ServiceLevelAddon[]) => void;
  associations?: AssociationRecord[];
  dependencies?: DependencyRecord[];
  ringModels?: OnlineModel[];
}) => {
  if (!serviceLevels || !addons) {
    return null;
  }

  return (
    <div className={dsClass.MARGIN_TOP_4}>
      <ServiceLevelAndAddons
        associations={associations}
        dependencies={dependencies}
        ringModels={ringModels}
        inputServiceLevels={serviceLevels}
        inputAddons={addons}
        openingFeeAddons={getOpeningFeeAddons(addons, dependencies, associations)}
        onChange={onChange}
        updateComponentValues={(serviceLevelParams: {
          pbxServiceLevels: ServiceLevel[];
          pbxServiceLevelAddons: ServiceLevelAddon[];
        }) => {
          if (serviceLevelParams?.pbxServiceLevels && serviceLevelParams?.pbxServiceLevelAddons) {
            onChange('pbxServiceLevels', serviceLevelParams.pbxServiceLevels);
            onChange('pbxServiceLevelAddons', serviceLevelParams.pbxServiceLevelAddons);
          }
        }}
      />
    </div>
  );
};
