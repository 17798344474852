export class OpenFormStorage implements Storage {
  readonly storage?: Storage;
  readonly #namespace: string;

  constructor(storage?: Storage, ...namespace: (string | undefined)[]) {
    this.storage = storage;
    this.#namespace = namespace.filter(Boolean).join(':');
  }

  get length() {
    return this.storage?.length ?? 0;
  }

  clear() {
    this.storage?.clear();
  }

  getItem(key: string) {
    try {
      return this.storage ? JSON.parse(this.storage.getItem(this.namespace(key))!) : null;
    } catch {
      return null;
    }
  }

  hasItem(key: string) {
    return !!(this.storage && this.namespace(key) in this.storage);
  }

  key(index: number) {
    try {
      return this.storage ? JSON.parse(this.storage.key(index)!) : null;
    } catch {
      return null;
    }
  }

  namespace(key?: string) {
    return [this.#namespace, key].filter(Boolean).join(':');
  }

  removeItem(key: string) {
    this.storage?.removeItem(this.namespace(key));
  }

  setItem<T>(key: string, value: T) {
    try {
      this.storage?.setItem(this.namespace(key), JSON.stringify(value));
    } catch {
      return;
    }
  }
}
