import { downloadSpeed, fixedInternetMsg, maxMbpsMsg, t, uploadSpeedMsg } from '../../../common/i18n/index.js';
import { getMobiiliturvaService, getYritysWifiLiteService } from './ServiceContentCreator';
import type { ProductContent } from './CommercialProductContent.js';
import type {
  SubscriptionCardOfferSpecification,
  SubscriptionCardServiceProps,
} from '../../SubscriptionCard/SubscriptionCard';

const maximumDownloadAndUploadMsg = 'Download speed max. {0}M, upload speed max. {1}M';
const recommendedForUsersAndDevicesMsg = 'Recommended for {0} users ({1} devices)';
const largeDataTransfersAnd8KMoviesMsg = 'Large data transfers and 8K movies';
const forAllInternetUsersMsg = 'For all internet users';
const streaming4KMoviesAndSeriesMsg = 'Streaming 4K movies and series';
const fourKVideosAndCloudServicesMsg = '4K videos and cloud services';
const fourKVideoCallsAndCloudServicesMsg = '4K video calls and cloud services';
const remoteWorkVideoCallsAndCloudServicesMsg = 'Remote work, video calls and cloud services';
const transferLargeFilesAndShareTheInternetForMultiplePeopleMsg =
  'Transfer large files and share the Internet for multiple people';
const eightKMoviesAndSeriesWithoutInterruptionsMsg = '8K movies and series without interruptions';
const forAllInternetUsersAndDevicesInYourHomeMsg = 'For all internet users and devices in your home';
const transferLargeFilesQuicklyMsg = 'Transfer large files quickly';
const watch4KMoviesAndSeriesMsg = 'Watch 4K movies and series';
const forRegularWorkAndLeisureMsg = 'For regular work and leisure';
const videoCallsAndSeamlessCloudServicesMsg = 'Video calls and seamless cloud services';
const emailsAndLightSurfingMsg = 'Emails and light surfing';
const fastDataTransfer8KMoviesWithoutInterruptionsMsg = 'Fast data transfer, 8K movies without interruptions';
const fastDataTransfer4KVideosAndCloudServicesMsg = 'Fast data transfer, 4K videos and cloud services';
const smoothVideoCallsAndCloudServicesMsg = 'Smooth video calls and cloud services';
const forDemandingWorkAndStreamingServicesMsg = 'For demanding work and active use of streaming services';
const forOccasionalWorkAndInternetUseMsg = 'For occasional work and Internet use';
const forRegularWorkAndLeisureEntertainmentMsg = 'For regular work and leisure entertainment';
const forSmoothWorkCloudAndEntertainmentMsg = 'For smooth work, cloud services and leisure entertainment';

export enum OfferType {
  MOBILE_BROADBAND = 'MOBILE_BROADBAND',
  FIXED_BROADBAND = 'FIXED_BROADBAND',
}

export enum NetworkType {
  FOUR_G = 'FOUR_G',
  FIVE_G = 'FIVE_G',
  FIVE_G_PLUS = 'FIVE_G_PLUS',
  FIBRE = 'FIBRE',
  G_FAST = 'G_FAST',
  CABLE = 'CABLE',
  VDSL = 'VDSL',
  ALL = 'ALL',
}

interface FixedBroadbandProductContent {
  offerCodes: string[];
  body: JSX.Element;
}

export interface NettiLiteOfferMapping {
  offerCodes: string[];
  offerType: OfferType;
  title: string;
  slogan: string;
  cardBullets: string[];
  checkoutBullets?: string[];
  services?: SubscriptionCardServiceProps[];
  networkType?: NetworkType;
  specifications?: SubscriptionCardOfferSpecification[];
}

export const nettiLiteOfferSpecification = (): NettiLiteOfferMapping[] => [
  {
    offerCodes: ['cdab6b05-8450-ef4e-1726-9f8250785717', 'c2e416bd-0352-e32c-e2d0-b779e06740fe'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '1000M',
    slogan: t.FT2S('Super-fast internet connection for daily work or a small office'),
    cardBullets: [
      t.WBNM(transferLargeFilesAndShareTheInternetForMultiplePeopleMsg),
      t.AJAM(eightKMoviesAndSeriesWithoutInterruptionsMsg),
      t.QSG9(forAllInternetUsersAndDevicesInYourHomeMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '100'),
    ],
    specifications: [
      { name: t.WBNM(transferLargeFilesAndShareTheInternetForMultiplePeopleMsg) },
      { name: t.AJAM(eightKMoviesAndSeriesWithoutInterruptionsMsg) },
      { name: t.QSG9(forAllInternetUsersAndDevicesInYourHomeMsg) },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '1000') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
    ],
    networkType: NetworkType.FIVE_G_PLUS,
    services: [getMobiiliturvaService(false), getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['4deb1c4b-335e-c74c-206c-1ccae803484f', '39f931cb-8bca-b1ac-68c3-e8329a7775f1'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.RBCF(transferLargeFilesQuicklyMsg),
      t.R1D7(watch4KMoviesAndSeriesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '75'),
    ],
    specifications: [
      { name: t.RBCF(transferLargeFilesQuicklyMsg) },
      { name: t.R1D7(watch4KMoviesAndSeriesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '600') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '75') } },
    ],
    networkType: NetworkType.FIVE_G_PLUS,
    services: [getMobiiliturvaService(false), getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['8e756f2c-dddd-ee8e-e601-e44e335d595c', '263da02c-c7d9-b845-abb7-9d2b65f7db40'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '300M',
    slogan: t.VB8M('For regular work and actively surfing the Internet during free time'),
    cardBullets: [
      t.RBCF(transferLargeFilesQuicklyMsg),
      t.H4ZY(fourKVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '50'),
    ],
    specifications: [
      { name: t.RBCF(transferLargeFilesQuicklyMsg) },
      { name: t.H4ZY(fourKVideoCallsAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '300') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.FIVE_G_PLUS,
    services: [getMobiiliturvaService(false), getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['d0e1ab37-217c-c80b-8f45-b26252f69306', 'cf5a923d-c011-8a4a-7580-dc9b3e875a35'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '100M',
    slogan: t.GF1O('A good basic connection for work and surfing the Internet during free time'),
    cardBullets: [
      t.FF2X(forRegularWorkAndLeisureMsg),
      t.MV0P(videoCallsAndSeamlessCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
    specifications: [
      { name: t.FF2X(forRegularWorkAndLeisureMsg) },
      { name: t.MV0P(videoCallsAndSeamlessCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.FIVE_G_PLUS,
    services: [getMobiiliturvaService(false), getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['ab864cfc-776f-302d-d857-fcd423a447f5', 'cca43200-1f5c-8500-ffb6-a3aac8cc80c5'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '100M',
    slogan: t.IBNP('A light basic connection for work and surfing the net during free time'),
    cardBullets: [
      t.FF2X(forRegularWorkAndLeisureMsg),
      t.MV0P(videoCallsAndSeamlessCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
    specifications: [
      { name: t.FF2X(forRegularWorkAndLeisureMsg) },
      { name: t.MV0P(videoCallsAndSeamlessCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.FOUR_G,
    services: [getMobiiliturvaService(false)],
  },
  {
    offerCodes: [
      '6204463d-9823-2975-795a-d515651e7bbc',
      '2ce10b29-e4de-6b9b-963e-89d9393f4f34',
      '7cc6c1a9-cb03-a47c-e60f-cb5b5be51d93',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.FF2X(forRegularWorkAndLeisureMsg),
    cardBullets: [
      t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '25'),
    ],
    specifications: [
      { name: t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '25') } },
    ],
    networkType: NetworkType.VDSL,
  },
  {
    offerCodes: [
      'f50f3123-ee3b-d085-2b93-a6eed9b5744b',
      '7b649a0e-bb1b-d23a-8680-1d8c55747078',
      '9538107c-9b1b-3cd4-a712-cedf69a5ca0f',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '15'),
    ],
    specifications: [
      { name: t.IGOI(emailsAndLightSurfingMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '15') } },
    ],
    networkType: NetworkType.VDSL,
  },
  {
    offerCodes: [
      '64d83c9d-2c32-aceb-8b35-273e232c2237',
      '30e6768d-c506-d922-63aa-8a4fff12c8f5',
      '52856bbd-b67c-8dac-512d-2f507cabf228',
      'f38dfece-e0bc-e243-4e50-9f8c60a9d847',
      '359bd67b-bb02-5fb8-9b51-18ee9c7b4f4c',
      '401bc3d1-4937-2e6f-976a-5de50456b0cf',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.DIPB('Super-fast internet for daily work and leisure entertainment'),
    cardBullets: [
      t.GO7L(largeDataTransfersAnd8KMoviesMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '1000'),
    ],
    specifications: [
      { name: t.GO7L(largeDataTransfersAnd8KMoviesMsg) },
      { name: t.JWZQ(forAllInternetUsersMsg) },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '1000') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '1000') } },
    ],
    networkType: NetworkType.FIBRE,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: [
      '38743dc2-8776-1c45-4600-599c9a0bf375',
      '343c1fb8-0d3e-1deb-9850-f1fbceeb2003',
      '186bbba2-e3b9-8f74-1a7e-44167ba7e7ef',
      '38cff28e-528d-4786-9010-30a35e70af0c',
      '5ca7d843-b0c1-e7ce-c2a2-5f03208e78fd',
      'f7a2fcac-2b9b-178e-e577-25920f410020',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.ONM3(streaming4KMoviesAndSeriesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '600'),
    ],
    specifications: [
      { name: t.ONM3(streaming4KMoviesAndSeriesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '600') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '600') } },
    ],
    networkType: NetworkType.FIBRE,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: [
      '0b253f15-0bbc-65dd-9372-425bff0c8f92',
      '40cbd3a8-7a93-6966-8989-d63cb8e6e825',
      'ef6e6140-a78a-fa8a-1342-fd2a36c07ca2',
      '9e351211-e771-5a6c-2a84-408a6d569775',
      '21ec14cd-1e90-ba02-8971-0b26181d5bad',
      '2630dbfc-da37-9e1a-9e4c-70bd557df2a8',
      'dccd1d26-96eb-06ed-a012-51d4f85c525e',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.GAJ8('For regular remote work and actively surfing the Internet during free time'),
    cardBullets: [
      t.MH00(fourKVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '300'),
    ],
    specifications: [
      { name: t.MH00(fourKVideosAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '300') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '300') } },
    ],
    networkType: NetworkType.FIBRE,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: [
      '60297f14-722b-1cc8-b66a-71762163c944',
      '1f388945-0431-42c2-9b93-6be218a86d27',
      '01f78dbe-7eb9-0754-6bc9-3895241aa09f',
      'd8ebab34-a25e-e0c8-c76c-c905611dd585',
      'cc76339b-4412-9931-fd43-fffde6fe73b2',
      '154715bc-7a96-c33a-00c9-821bad175a5b',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '150M',
    slogan: t.FF2X(forRegularWorkAndLeisureMsg),
    cardBullets: [
      t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '150', '150'),
    ],
    specifications: [
      { name: t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '150') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '150') } },
    ],
    networkType: NetworkType.FIBRE,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: [
      '8de637ec-4f06-865f-3ff0-05ceb246b103',
      '6c1006de-dfa0-80fc-f0a1-5d319017595a',
      'dfd6f21d-d7e5-9336-c576-ac1cd41ee151',
      '0eaf4279-d569-d131-ad97-d21431fb079a',
      '4902c2dc-9da5-3112-f272-7d25c580f8da',
      'f976ba6f-277e-a342-d0fe-df7c61e22fe2',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '50'),
    ],
    specifications: [
      { name: t.IGOI(emailsAndLightSurfingMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.FIBRE,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['38508241-a78e-3c20-cb02-27bae0965e90'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.IEFA('Superfast Internet for demanding work and cloud services'),
    cardBullets: [
      t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '50'),
    ],
    specifications: [
      { name: t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg) },
      { name: t.JWZQ(forAllInternetUsersMsg) },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '1000') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.CABLE,
  },
  {
    offerCodes: ['4f5d6fcb-4143-a607-3fab-00dd7efe69e6'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '50'),
    ],
    specifications: [
      { name: t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '600') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.CABLE,
  },
  {
    offerCodes: ['d302bfc6-0b42-7545-bc19-dba61663a12e'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.Y821(forSmoothWorkCloudAndEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '50'),
    ],
    specifications: [
      { name: t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '300') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.CABLE,
  },
  {
    offerCodes: ['bef3ac3d-d6e1-84df-98c9-34607cf233e4'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.W4JJ(forRegularWorkAndLeisureEntertainmentMsg),
    cardBullets: [
      t.NXC5(smoothVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
    specifications: [
      { name: t.NXC5(smoothVideoCallsAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.CABLE,
  },
  {
    offerCodes: ['b10959ad-6256-e305-8e6e-30f4a6651b11', '352cf1b6-8005-6252-1c69-7cd63a6c6d5a'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '15'),
    ],
    specifications: [
      { name: t.IGOI(emailsAndLightSurfingMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '15') } },
    ],
    networkType: NetworkType.CABLE,
  },
  {
    offerCodes: ['485a15d0-16d7-1bca-8477-079e5799070c'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '100'),
    ],
    specifications: [
      { name: t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg) },
      { name: t.JWZQ(forAllInternetUsersMsg) },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '1000') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
    ],
    networkType: NetworkType.G_FAST,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['1cbaf60b-d154-1fc6-f7e9-501d81ba6d70'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y821(forSmoothWorkCloudAndEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '100'),
    ],
    specifications: [
      { name: t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '600') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
    ],
    networkType: NetworkType.G_FAST,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['d19f7685-464e-b826-90b1-9228badc0503'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.W4JJ(forRegularWorkAndLeisureEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '100'),
    ],
    specifications: [
      { name: t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '300') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
    ],
    networkType: NetworkType.G_FAST,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['37796d80-fde0-e46b-b233-89dffbed8714'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.NXC5(smoothVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
    specifications: [
      { name: t.NXC5(smoothVideoCallsAndCloudServicesMsg) },
      { name: t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9') },
      { name: t.R4O4(downloadSpeed), value: { ['all']: t.IJNT(maxMbpsMsg, '100') } },
      { name: t.CQQ7(uploadSpeedMsg), value: { ['all']: t.IJNT(maxMbpsMsg, '50') } },
    ],
    networkType: NetworkType.G_FAST,
    services: [getYritysWifiLiteService(false)],
  },
  {
    offerCodes: ['d7cdba1d-f338-824d-5029-cd0e5223bf4f'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '10M',
    slogan: 'This is a test dummy 5G nettilite product',
    cardBullets: [],
  },
  {
    offerCodes: ['80bcca00-0a01-15b8-2c7d-8bf07340041e'],
    offerType: OfferType.FIXED_BROADBAND,
    title: 'Test',
    slogan: 'Devint test product',
    cardBullets: [],
  },
];

export const nettiLiteFixedBroadbandContent = (): ProductContent[] =>
  nettiLiteOfferSpecification()
    .filter(nettiLite => nettiLite.offerType === OfferType.FIXED_BROADBAND)
    .map(nettiLite => ({
      offerCodes: nettiLite.offerCodes,
      offerName: t.JEHN(fixedInternetMsg),
      title: nettiLite.title,
      body: <>{nettiLite.slogan}</>,
    }));

export const nettiLiteMobileBroadbandContent = (): ProductContent[] =>
  nettiLiteOfferSpecification()
    .filter(nettiLite => nettiLite.offerType === OfferType.MOBILE_BROADBAND)
    .map(nettiLite => ({
      offerCodes: nettiLite.offerCodes,
      offerName: t.JEHN(fixedInternetMsg),
      title: nettiLite.title,
      body: <>{nettiLite.slogan}</>,
    }));

export const resolveBullets = (offerCode: string): string[] =>
  nettiLiteOfferSpecification()
    .filter(nettilite => !nettilite.specifications)
    .find(nettiLite => nettiLite.offerCodes.includes(offerCode))?.cardBullets || [];

export const resolveServices = (offerCode: string): SubscriptionCardServiceProps[] =>
  nettiLiteOfferSpecification().find(nettiLite => nettiLite.offerCodes.includes(offerCode))?.services || [];

export const resolveSpecifications = (offerCode: string): SubscriptionCardOfferSpecification[] =>
  nettiLiteOfferSpecification().find(nettiLite => nettiLite.offerCodes.includes(offerCode))?.specifications || [];

export const formatNettiliteOfferCardName = (offerCode: string, name: string) =>
  name.substring(0, name.lastIndexOf(' '));

export const formatNettiliteOfferCheckoutName = (name?: string) =>
  name?.includes('M/') ? name.substring(0, name?.lastIndexOf('M/')).concat(' M') : name;

export const defaultFixedBroadbandProductContent: FixedBroadbandProductContent = {
  offerCodes: [],
  body: <></>,
};

export const fixedBroadbandProductContent = (): FixedBroadbandProductContent[] =>
  nettiLiteOfferSpecification().map(nettiLite => {
    const bullets = nettiLite.checkoutBullets || nettiLite.cardBullets || [];
    return {
      offerCodes: nettiLite.offerCodes,
      body: (
        <ul className="ea-article-ul">
          {bullets.map((bullet, i) => (
            <li key={`bullet-${i}`}>{bullet}</li>
          ))}
        </ul>
      ),
    };
  });
