import { paths } from '../constants/pathVariables.js';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateBack = (fallback = paths.SELF_SERVICE_HOME, to = -1) => {
  const { key } = useLocation();
  const navigate = useNavigate();

  return () => {
    if (key === 'default') {
      navigate(fallback); // If `key` is default, then this is the first page rendered by RRD.
    } else {
      navigate(to); // There must be something in history, thus we may navigate back with `-1`.
    }
  };
};
