import { type OpenFormAnswers } from './OpenFormAnswers.js';
import type { OpenFormChoice } from '../../generated/api/openFormChoice.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';

export class OpenFormRules {
  private static readonly PRODUCT_LINE_RULE = 'Product_Line' as const;

  static isVisible(answers: OpenFormAnswers) {
    return <T extends OpenFormChoice | OpenFormQuestion>(target?: T): target is T => {
      switch (target?.rulesType) {
        case 'NONE':
          return true;
        case 'VISIBILITY_CONDITIONS':
          return this.parseRules(target.rules).some(this.ruleMatches(answers));
        case 'HIDING_CONDITIONS':
          return !this.parseRules(target.rules).some(this.ruleMatches(answers));
        default:
          return false;
      }
    };
  }

  private static parseRules(rules: string[]) {
    return rules
      .map(value =>
        value.startsWith(this.PRODUCT_LINE_RULE)
          ? { type: 'productLine', value: value.split(':')[1] ?? '' }
          : { type: 'guid', value }
      )
      .filter(rule => Boolean(rule.value.length));
  }

  private static ruleMatches(answers: OpenFormAnswers) {
    const choices = answers.valuesOf('choices').flat();
    const subscriptionTypes = answers.valuesOf('subscriptionTypes').flat();
    return (rule: { type: 'guid' | 'productLine'; value: string }) => {
      switch (rule.type) {
        case 'guid':
          return choices.includes(rule.value);
        case 'productLine':
          return subscriptionTypes.includes(rule.value);
      }
    };
  }
}
