import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { generatePath, useLoaderData, useLocation } from 'react-router-dom';
import { getSubscriptionBreadcrumbName, getSubscriptionUrlId } from '../../../../../common/utils/subscriptionUtils.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables.js';
import type { SubscriptionUpdateLoaderData } from '../../../../../common/loaders';

export const BroadbandSubUpdatePath = () => {
  const { search } = useLocation();
  const { addOnRules, companyInfo, onlineModels, subscription } = useLoaderData() as SubscriptionUpdateLoaderData;
  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };

  return (
    <>
      <BreadCrumbsWithTitle
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
          {
            name: getSubscriptionBreadcrumbName(subscription),
            path:
              generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) +
              search,
          },
          { name: t.IBZN('Mobile broadband update') },
        ]}
      />
      <OrderSubscriptionSelection
        addOnRules={addOnRules}
        isChangeOffer={true}
        onlineModels={onlineModels}
        onlineModelType={ModelType.MobileBroadband}
        subscription={subscription}
        toggles={uiOptions?.toggles}
      />
    </>
  );
};
