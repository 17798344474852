import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs';
import { ContactDetailsForm } from './ContactDetailsForm.js';
import { DetailsWrapper } from '../DetailsWrapper';
import {
  changeRequestInProcessHeaderMsg,
  omaElisaForCompaniesMsg,
  t,
  userNameMsg,
  userTextMsg,
  usersMsg,
} from '../../common/i18n';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type {
  BillingAccountSearchResponse,
  Contact,
  MoveContactPendingActionsResponse,
  SubscriptionSearchResponse,
} from '../../generated/api/models.js';
import type { BreadCrumbList } from '../BreadCrumbs';
import type { State } from '../../selfservice/common/store.js';

import './ContactDetails.scss';

export interface ContactDetailsProps {
  contact: Contact;
  contactMasterId: string;
  deviceSubscriptions: SubscriptionSearchResponse[];
  voiceSubscriptions: SubscriptionSearchResponse[];
  broadbandSubscriptions: SubscriptionSearchResponse[];
  serviceSubscriptions: SubscriptionSearchResponse[];
  billingAccounts: BillingAccountSearchResponse[];
  pendingActionsResponse: MoveContactPendingActionsResponse;
  mdmId: string;
}

const getDisplayName = (contact?: Contact) => {
  if (contact?.person) {
    return `${contact.person.firstName} ${contact.person.lastName}`;
  } else if (contact?.commonFunction) {
    return contact?.commonFunction.name;
  } else {
    return '...';
  }
};

const getHeadingTop = (contact: Contact | undefined) => {
  switch (contact?.contactType) {
    case 'PERSON':
      return t.LUZM(userTextMsg);
    case 'LIMITED_INFORMATION_PERSON':
      return t.J23G(userNameMsg);
    case 'COMMON_FUNCTION':
      return t.G8FE('Common function');
    default:
      return '...';
  }
};

export const ContactDetails = ({ contact, mdmId, pendingActionsResponse, ...rest }: ContactDetailsProps) => {
  const { config } = useSelector(
    (state: State) => ({
      config: state.config,
    }),
    deepEqual
  );
  const { authenticatedUser } = useAuth();
  const displayName = getDisplayName(contact);
  const headingTop = getHeadingTop(contact);
  const backLinkTitle = t.BE4A(usersMsg);
  const detailsWrapperId = `contact-details-${contact?.contactId}`;
  const companyName = getCompanyName(authenticatedUser, mdmId);

  const breadCrumbPaths: BreadCrumbList =
    contact?.person || contact?.commonFunction
      ? [
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: backLinkTitle, path: paths.COMPANY_INFO_CONTACTS },
          { name: displayName },
        ]
      : [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: backLinkTitle }];
  return (
    <DetailsWrapper
      classes={['of-contact-details']}
      detailsTop={<BreadCrumbs breadCrumbPaths={breadCrumbPaths} />}
      id={detailsWrapperId}
      heading={displayName}
      headingBottom={companyName}
      heroPicto="cl-user"
      styledHeadingTop={<span className={`${dsClass.TEXT_LEAD} ${dsClass.COLOR_BLUE_800}`}>{headingTop}</span>}
      styledHeading={<h1 className={`of-contact-details__h1 ${dsClass.COLOR_BLUE_800}`}>{displayName}</h1>}
    >
      {pendingActionsResponse.hasPendingActions && (
        <CL.Disclaimer
          className={dsClass.MARGIN_BOTTOM_6}
          disclaimerType="warning"
          icon={<CL.Icon icon="notification" />}
          title={t.I9KT(changeRequestInProcessHeaderMsg)}
          text={
            pendingActionsResponse.isNewContact
              ? t.S5RO(
                  'The contact has been transferred to company {}. Note that the related subscriptions will become visible on this page when the pending change request has been processed.',
                  companyName
                )
              : t.UM14(
                  'The user has been transferred to another company. Note that the user information will be removed from {} once the transfer has been processed.',
                  companyName
                )
          }
        />
      )}
      <ContactDetailsForm
        config={config}
        contact={contact}
        mdmId={mdmId}
        pendingActionsResponse={pendingActionsResponse}
        {...rest}
      />
    </DetailsWrapper>
  );
};
