import { DialogWrapper } from '../../components/DialogWrapper';
import { closeMsg, t } from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import type { ModalProps } from '../../components/Modal/Modal';

export interface GenericInfoDialogProps {
  hideButtons?: boolean;
  header: string;
  onCloseDialog: () => void;
  size?: ModalProps['size'];
  body: JSX.Element;
}

export const GenericInfoDialog = ({ hideButtons, header, onCloseDialog, body, size = 'm' }: GenericInfoDialogProps) => {
  return (
    <DialogWrapper
      buttons={hideButtons ? [] : [{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
      closeable
      header={header}
      onCloseDialog={onCloseDialog}
      size={size}
    >
      <div className={dsClass.PADDING_TOP_4}>{body}</div>
    </DialogWrapper>
  );
};
