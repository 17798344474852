import { type BillingAccountData } from '../OpenFormAnswers.js';
import { concat } from '../OpenFormUtils.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../../common/i18n/index.js';
import type { Address } from '../../../generated/api/address.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { ParameterizedSearchContactResult } from '../../../generated/api/parameterizedSearchContactResult.js';

export const OpenFormBillingAccount = ({ ba }: { ba: BillingAccount | BillingAccountData }) => (
  <>
    <div>{ba.billingAccountName || ba.payerName}</div>
    <div>{ba.payerNameExtension}</div>
    <div>{ba.billingAccountExtensionName}</div>
  </>
);

export const OpenFormBillingInvoiceElectronic = ({ ba }: { ba: BillingAccount | BillingAccountData }) => (
  <>
    <div>
      {ba.billElectronicOperator} {ba.billElectronicAddress}
    </div>
    <div>{t.IK1D(eInvoiceMsg)}</div>
  </>
);

export const OpenFormBillingInvoiceEmail = ({ ba }: { ba: BillingAccount | BillingAccountData }) => (
  <>
    <div>{ba.billReceiverEmail || (ba as BillingAccountData).billingContactEmail}</div>
    <div>{t.NNV9(emailInvoiceMsg)}</div>
  </>
);

export const OpenFormBillingInvoicePaper = ({ addr }: { addr?: Address }) => (
  <>
    {!addr ? null : (
      <>
        <div>{concat(addr.line1, addr.line2).join(' ')}</div>
        <div>
          {addr.postalCode} {addr.postOffice}
        </div>
      </>
    )}
    <div>{t.TER4(paperInvoiceMsg)}</div>
  </>
);

export const OpenFormContact = ({
  ba,
  co,
}: {
  ba?: BillingAccount | BillingAccountData;
  co?: ParameterizedSearchContactResult;
}) =>
  co ? (
    <>
      <div>
        {co.FirstName} {co.LastName}
      </div>
      <div>{co.Email}</div>
      <div>{co.MobilePhone}</div>
    </>
  ) : ba ? (
    <>
      <div>{ba.billingContactName}</div>
      <div>{(ba as BillingAccountData).billingContactEmail}</div>
      <div>{ba.billingContactPhone}</div>
    </>
  ) : null;
