import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store';

export type MultiAccountRowClickFunction = (to: string, companyName: string, accountMasterId?: string) => void;

// TODO: Remove this hook after all list views have mdmId in place and consolidated views are enabled in PROD
export const useMultiAccountRowClick = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featureFlags = useSelector((state: State) => state.config.featureFlags);
  const { authenticatedUser } = useAuth();
  const isConsolidatedViewsEnabled = isFeatureEnabledForUser(
    'consolidatedViews',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );

  const onRowClick: MultiAccountRowClickFunction = (to: string, companyName: string, accountMasterId?: string) => {
    if (accountMasterId && accountMasterId !== getActiveAccountMasterId() && !isConsolidatedViewsEnabled) {
      dispatch(setActiveAccount(accountMasterId, false, to, companyName));
    } else {
      navigate(to);
    }
  };

  return onRowClick;
};
