/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Primary role for authenticated user of UI API. Note that this is different from roles of SFDC community user, but maps in part to UserProfile of SFDC, but this enumeration handles also non-SFDC related authentications, for e.g. content authors.
 */
export type AuthenticatedUserRole = 'KEY_USER' | 'EMPLOYEE' | 'ADMIN' | 'PUNCHOUT_USER' | 'CUSTOMER_ADMIN' | 'CUSTOMER_EMPLOYEE' | 'ELISA_ADMIN';

export const AuthenticatedUserRole = {
    KEY_USER: 'KEY_USER' as AuthenticatedUserRole,
    EMPLOYEE: 'EMPLOYEE' as AuthenticatedUserRole,
    ADMIN: 'ADMIN' as AuthenticatedUserRole,
    PUNCHOUT_USER: 'PUNCHOUT_USER' as AuthenticatedUserRole,
    CUSTOMER_ADMIN: 'CUSTOMER_ADMIN' as AuthenticatedUserRole,
    CUSTOMER_EMPLOYEE: 'CUSTOMER_EMPLOYEE' as AuthenticatedUserRole,
    ELISA_ADMIN: 'ELISA_ADMIN' as AuthenticatedUserRole
};

