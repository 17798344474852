import * as CL from '@design-system/component-library';
import { CheckboxWithError } from '../../common/react-hook-form/fields/CheckboxWithError';
import { FORM_GRID_FULL_WIDTH, FormGridFieldset } from '../../common/react-hook-form/FormGridFieldset/FormGridFieldset';
import { FormProvider, useForm } from 'react-hook-form';
import { Name, PostalCode } from '../../common/react-hook-form/fields';
import { OmalaskuSubscriptionBillingAddress } from './OmalaskuSubscriptionBillingAddress';
import { OrderForbiddenModal } from './OmalaskuOrderingForbiddenModal';
import { POSTAL_CODE_MAX_LENGTH } from '../../common/utils/validationUtils';
import { PostOffice } from '../../common/react-hook-form/fields/PostOffice';
import { StreetAddress } from '../../common/react-hook-form/fields/StreetAddress';
import { activateOmalasku } from '../../common/fetch';
import {
  billingAddressMsg,
  billingAddressTitleMsg,
  firstNameMsg,
  iAcceptTAndCOmalasku,
  lastNameMsg,
  postOfficeMsg,
  postalCodeMsg,
  streetAddressMsg,
  t,
  termsAndConditionsMsg,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { omalaskuOrderFailedToSubmit, omalaskuOrderSubmitted } from '../../selfservice/actions';
import { paths } from '../../common/constants/pathVariables';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useOmalasku } from './Omalasku';
import { useState } from 'react';
import type { Msisdn } from '../../common/constants/pathInterfaces';
import type { OmalaskuActivationRequest } from '../../generated/api/omalaskuActivationRequest';
import type { OmalaskuAddress } from '../../generated/api/omalaskuAddress';

const OMALASKU_USER_FIRSTNAME_FIELD_NAME = 'firstname';
const OMALASKU_USER_LASTNAME_FIELD_NAME = 'lastname';
const OMALASKU_USER_POSTAL_ADDRESS_FIELD_NAME = 'streetAddress';
const OMALASKU_USER_POSTAL_CODE_FIELD_NAME = 'postalCode';
const OMALASKU_USER_POST_OFFICE_FIELD_NAME = 'postOffice';
const OMALASKU_USER_ACCEPT_T_AND_C = 'tAndC';

interface OmalaskuOrderFormValues {
  [OMALASKU_USER_FIRSTNAME_FIELD_NAME]: string;
  [OMALASKU_USER_LASTNAME_FIELD_NAME]: string;
  [OMALASKU_USER_POSTAL_ADDRESS_FIELD_NAME]: string;
  [OMALASKU_USER_POSTAL_CODE_FIELD_NAME]: string;
  [OMALASKU_USER_POST_OFFICE_FIELD_NAME]: string;
  [OMALASKU_USER_ACCEPT_T_AND_C]: boolean;
}

const OmalaskuOrderFieldsSet = () => {
  return (
    <FormGridFieldset>
      <div className={FORM_GRID_FULL_WIDTH}>
        <p>{t.D689(billingAddressMsg)}</p>
      </div>
      <Name name={OMALASKU_USER_FIRSTNAME_FIELD_NAME} label={t.AIK7(firstNameMsg)} disabled={true} />
      <Name name={OMALASKU_USER_LASTNAME_FIELD_NAME} label={t.Y41S(lastNameMsg)} disabled={true} />
      <div className={FORM_GRID_FULL_WIDTH}>
        <StreetAddress
          name={OMALASKU_USER_POSTAL_ADDRESS_FIELD_NAME}
          label={t.DD38(streetAddressMsg)}
          required={true}
        />
      </div>
      <PostalCode
        allowPoBox={true}
        label={t.RUAW(postalCodeMsg)}
        maxLength={POSTAL_CODE_MAX_LENGTH}
        name={OMALASKU_USER_POSTAL_CODE_FIELD_NAME}
        required={true}
        placeholder=""
      />
      <PostOffice label={t.Z7S5(postOfficeMsg)} name={OMALASKU_USER_POST_OFFICE_FIELD_NAME} required={true} />
    </FormGridFieldset>
  );
};

interface OmalaskuOrderFormProps {
  address?: OmalaskuAddress;
}

export const OmalaskuOrderForm = ({ address }: OmalaskuOrderFormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [showForbiddenModal, setShowForbiddenModal] = useState(false);
  const { msisdn } = useParams<Msisdn>();
  const { resetOmalaskuStatus, firstName, lastName } = useOmalasku();
  const navigate = useNavigate();
  // For showing the notification on top of page which still  uses redux, should be removed once notification logic is out of redux
  const dispatch = useDispatch();
  const orderFormDefaultValues = {
    [OMALASKU_USER_FIRSTNAME_FIELD_NAME]: firstName,
    [OMALASKU_USER_LASTNAME_FIELD_NAME]: lastName,
    [OMALASKU_USER_POSTAL_ADDRESS_FIELD_NAME]: address?.streetAddress,
    [OMALASKU_USER_POSTAL_CODE_FIELD_NAME]: address?.postalCode,
    [OMALASKU_USER_POST_OFFICE_FIELD_NAME]: address?.postOffice,
  };
  const methods = useForm<OmalaskuOrderFormValues>({
    mode: 'all',
    defaultValues: orderFormDefaultValues,
  });

  const activateOmaLaskuOnSubmit = async () => {
    setSubmitting(true);
    const { firstname, lastname, streetAddress, postalCode, postOffice } = methods.getValues();
    const request: OmalaskuActivationRequest = {
      firstName: firstname,
      lastName: lastname,
      address: {
        streetAddress: streetAddress,
        postalCode: postalCode,
        postOffice: postOffice,
      },
    };
    activateOmalasku(msisdn!, request)
      .then(res => {
        if (res) {
          resetOmalaskuStatus();
          dispatch(omalaskuOrderSubmitted());
          navigate(paths.EMPLOYEE_OMALASKU);
        } else {
          setShowForbiddenModal(true);
        }
      })
      .catch(() => {
        dispatch(omalaskuOrderFailedToSubmit());
      })
      .finally(() => setSubmitting(false));
  };

  const cancelOrderingOmalasku = () => {
    navigate(paths.EMPLOYEE_OMALASKU);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(activateOmaLaskuOnSubmit)} noValidate>
          <h3 className={dsClass.H3}>{t.PS5T(billingAddressTitleMsg)}</h3>
          {address ? (
            <div className={dsClass.MARGIN_BOTTOM_4}>
              <OmalaskuSubscriptionBillingAddress firstname={firstName} lastname={lastName} address={address} />
            </div>
          ) : (
            <OmalaskuOrderFieldsSet />
          )}
          <div className={dsClass.MARGIN_BOTTOM_7}>
            <CL.Link
              className={dsClass.MARGIN_BOTTOM_2}
              linkTarget="_blank"
              linkHref="https://static.elisa.com/v2/image/2tqybbhjs47b/7yH2Y4RC39Hq5Wnjf4vsCt/Elisa-OmaLasku-sopimusehdot.pdf"
            >
              {t.JNTB('Read the terms and conditions of OmaLasku')}
            </CL.Link>
            <CheckboxWithError
              name={OMALASKU_USER_ACCEPT_T_AND_C}
              label={t.CRW1(iAcceptTAndCOmalasku, t.BCPB(termsAndConditionsMsg).toLowerCase())}
              errorText={t.MOR3('Accept the terms and conditions')}
            />
          </div>
          <div className={dsClass.MARGIN_TOP_4}>
            <CL.Button type="submit" size="m" loading={submitting}>
              {t.XFA9('Activate')}
            </CL.Button>
            <CL.Button color="link" size="m" onClick={cancelOrderingOmalasku}>
              {t.B2V1('Cancel')}
            </CL.Button>
          </div>
        </form>
      </FormProvider>
      {showForbiddenModal && <OrderForbiddenModal onClose={() => setShowForbiddenModal(false)} />}
    </>
  );
};
