import { NotificationText } from './NotificationText';
import { Picture } from '../Picture/Picture.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getProductImageUrl } from './shoppingBasketUtils.js';
import { t } from '../../common/i18n/index.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';

export const getProductImage = (
  imageUrl: string,
  productName: string,
  offer?: Offer,
  model?: OnlineModel
): JSX.Element => {
  return offer && model ? (
    <Picture
      alt={offer.offerName}
      offerWidthAlternatives={[128]}
      renderedImageSize={{ onPhone: '64px' }}
      src={getProductImageUrl(imageUrl, offer, model)}
    />
  ) : (
    <Picture alt={productName} offerWidthAlternatives={[128]} renderedImageSize={{ onPhone: '64px' }} src={imageUrl} />
  );
};

export interface ShoppingBasketEntryProps {
  id: string;
  productImage: JSX.Element;
  productName: JSX.Element;
  paymentField: JSX.Element;
  quantityField: JSX.Element;
  price: JSX.Element;
  disclaimerFields: string[];
  showContractPriceWillBeConfirmedMsg: boolean;
  addOns: JSX.Element;
}

/**
 * This component is doing closely the same as in Design System (v. 9.1.0)
 * file ShoppingCart.tsx --> function ShoppingCartEntry(). At least the dom structure and style classes should be same.
 * That's how this is looking same than DS version.
 *
 * This is because new Shopping Basket needs to customise ShoppingCartEntries and addons that DS Shopping cart shows.
 * Originated from here: https://atlas.elisa.fi/jira/browse/OFI-53793
 * TODO: these modifications could be contributed to DS side at some point
 */

export const ShoppingBasketEntry = ({
  id,
  productImage,
  productName,
  paymentField,
  quantityField,
  price,
  disclaimerFields,
  showContractPriceWillBeConfirmedMsg,
  addOns,
}: ShoppingBasketEntryProps) => {
  const eppNotificationRow = (
    <div className={`${dsClass.SHOPPINGCART_PRODUCT_INFO} of-notification-row ${dsClass.MARGIN_BOTTOM_1}`}>
      <div className={dsClass.SHOPPINGCART_PRODUCT_IMAGE} />
      <div className={dsClass.SHOPPINGCART_PRODUCT_DETAILS} />
      <NotificationText
        className={dsClass.PADDING_2}
        message={t.PKFX('The EPP contract price will be confirmed when you log in')}
      />
      <div className={dsClass.SHOPPINGCART_PRODUCT_QUANTITY} />
    </div>
  );

  return (
    <li id={id} className={dsClass.SHOPPINGCART_PRODUCT}>
      <div className={dsClass.SHOPPINGCART_PRODUCT_INFO}>
        <div aria-hidden="true" className={dsClass.SHOPPINGCART_PRODUCT_IMAGE}>
          {productImage}
        </div>
        <div className={dsClass.SHOPPINGCART_PRODUCT_DETAILS}>
          <div className={dsClass.SHOPPINGCART_PRODUCT_NAME_AND_DESCRIPTION}>
            <h3 className={dsClass.SHOPPINGCART_PRODUCT_NAME}>{productName}</h3>
            <div className={dsClass.SHOPPINGCART_PRODUCT_DESCRIPTION} />
          </div>
          {paymentField}
          <div className={dsClass.SHOPPINGCART_PRODUCT_QUANTITY}>{quantityField}</div>
          <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_AND_DISCLAIMER}>
            <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE}>{price}</div>
            <div className={dsClass.SHOPPINGCART_PRODUCT_DISCLAIMER}>
              {disclaimerFields.map((row, rowIndex) => (
                <div key={rowIndex}>{row}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showContractPriceWillBeConfirmedMsg && eppNotificationRow}
      {addOns}
    </li>
  );
};
