import * as CL from '@design-system/component-library';
import { useController, useFormContext } from 'react-hook-form';

export interface CheckboxWithErrorProps {
  name: string;
  label: string;
  errorText: string;
}

export const CheckboxWithError = (props: CheckboxWithErrorProps) => {
  const { control } = useFormContext();
  const { field, fieldState, formState } = useController({
    name: props.name,
    control,
    rules: { required: { value: true, message: props.errorText } },
  });
  const error = formState.isSubmitted || fieldState.isTouched ? fieldState.error?.message : undefined;

  return (
    <CL.Checkbox.Group i18n_checkboxgroup_errorMessage={error}>
      <CL.Checkbox
        name={field.name}
        label={props.label}
        onChange={field.onChange}
        inputRef={field.ref}
        value={field.value}
      />
    </CL.Checkbox.Group>
  );
};
