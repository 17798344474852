import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading';
import { OmalaskuVoiceSubscriptions } from './OmalaskuVoiceSubscriptions';
import { dsClass } from '../../common/constants/dsClasses';
import { omaLaskuServicesMsg, t } from '../../common/i18n';

import './OmalaskuSelfService.scss';

export const OmalaskuSelfService = () => {
  return (
    <div className="of-employee-omalasku">
      <HeroHeading
        center={true}
        fullScreen={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_OMALASKU}
        title={t.FJOL(omaLaskuServicesMsg)}
      />
      <div className={`${dsClass.MARGIN_TOP_8} ${dsClass.MARGIN_HORIZONTAL_3}`}>
        <Grid>
          <OmalaskuVoiceSubscriptions />
          <hr className="of-employee-omalasku__separator" />
        </Grid>
      </div>
    </div>
  );
};
