import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { employeeOmaElisaMsg, loginMsg, t } from '../../common/i18n/index.js';

import './EmployeeLoginPage.scss';

interface EmployeeLoginPageProps {
  initiateLogin: () => void;
  isAuxiliarySimLogin?: boolean;
}

export const EmployeeLoginPage = ({ initiateLogin, isAuxiliarySimLogin }: EmployeeLoginPageProps) => (
  <div className={`${dsClass.BACKGROUND_COLOR_WHITE} ${dsClass.PADDING_5} of-employee-login`}>
    <h1 className={`${dsClass.H1} ${dsClass.TEXT_ALIGN_CENTER} of-employee-login__header`}>
      {isAuxiliarySimLogin
        ? t.QQJX('Add Elisa KelloSIM to your subscription by login to My OmaElisa')
        : t.UBBK(employeeOmaElisaMsg)}
    </h1>
    <CL.Grid className="of-employee-login__grid">
      <CL.GridRow justifyCenter={true} className="of-employee-login__grid__row0">
        <CL.GridCol colWidthM={6}>
          <CL.Button
            size="l"
            type="button"
            onClick={initiateLogin}
            className="of-employee-login__grid__row0__button-tablet-and-below"
          >
            {t.Z16I(loginMsg)}
          </CL.Button>
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow className="of-employee-login__grid__row1" justifyCenter={true}>
        <CL.GridCol colWidthL={6} colWidthM={6}>
          <svg className="of-employee-login__grid__row1__picto"></svg>
        </CL.GridCol>
        <CL.GridCol colWidthL={6} colWidthM={6}>
          <div className={dsClass.PADDING_LEFT_1}>
            <div className="of-employee-login__grid__row1__text">
              <p>{t.VLOS('Login has been updated.')}</p>
              <p>{t.ABTG('Welcome to Employee OmaElisa!')}</p>
            </div>
            <CL.Button
              size="l"
              type="button"
              onClick={initiateLogin}
              className="of-employee-login__grid__row1__button-laptop-and-above"
            >
              {t.Z16I(loginMsg)}
            </CL.Button>
          </div>
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow className="of-employee-login__grid__row2">
        <CL.GridCol id="row2Col1" colWidthL={6} colWidthM={6} className="of-employee-login__grid__row2__col">
          <div className="of-employee-login__grid__row2__text">
            <h3>{t.G16D('What is Employee OmaElisa?')}</h3>
            <div>
              {t.U7F2(
                `It’s a service for managing the phone and internet subscriptions and devices provided by your employer. In Employee OmaElisa, you can order devices, check the PUK code for your subscription, and follow your data usage in real time, among other things.`
              )}
            </div>
            <CL.Link
              id="readMoreEmployeeOmaElisa"
              linkStyle="link-button"
              buttonColor="light"
              linkHref="/info-omaelisa-tyontekijalle"
              linkTarget="_blank"
              buttonBlock={false}
              className="of-employee-login__grid__row2__link"
            >
              {t.GJJF('Read more')}
            </CL.Link>
          </div>
        </CL.GridCol>
        <CL.GridCol id="row2Col2" colWidthL={6} colWidthM={6} className="of-employee-login__grid__row2__col">
          <div className="of-employee-login__grid__row2__text">
            <h3>{t.KZ4H(`What can I do in Employee OmaElisa?`)}</h3>
            <div>{t.Q1I0('If you have an Elisa business phone subscription, you can:')}</div>
            <ul>
              <li>{t.HIM5('check the PUK code to unlock your phone')}</li>
              <li>
                {t.JAQQ(
                  'track the recent use of your phone subscriptions, including detailed information on phone calls and text messages'
                )}
              </li>
              <li>{t.B7GD('check your subscription’s roaming limits for calls and data usage')}</li>
              <li>{t.LBBW('send a support request about a broken device')}</li>
              <li>
                {t.TC0J(
                  'resolve issues with your phone subscription or internet connection with our troubleshooting tool'
                )}
              </li>
            </ul>
          </div>
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow className="of-employee-login__grid__row3">
        <CL.GridCol colWidthM={6}>
          <div>
            <CL.Link
              linkStyle="link"
              linkHref="https://verkkoasiointi.elisa.fi/corporate-employee-selfservice/tunnistautuminen"
            >
              {t.SUZL('Create an ID or reset a forgotten password')}
            </CL.Link>
          </div>
        </CL.GridCol>
      </CL.GridRow>
      <h4 className={dsClass.H4}>{t.BJ8A('Other self service channels')}</h4>
      <CL.GridRow className="of-employee-login__grid__row4">
        <CL.GridCol colWidthL={12}>
          <div className="of-employee-login__grid__row4__links">
            <CL.Link linkStyle="link" className="of-employee-login__grid__row4__link" linkHref="/omaelisa">
              <svg className="of-employee-login__grid__row4__link__home-office"></svg>
              <span>{t.M6SM('OmaElisa for companies')}</span>
            </CL.Link>
            <CL.Link
              linkStyle="link"
              className="of-employee-login__grid__row4__link"
              linkHref="https://verkkoasiointi.elisa.fi/kirjautuminen"
            >
              <svg className="of-employee-login__grid__row4__link__self-service"></svg>
              <span>{t.NI69('OmaElisa for consumer')}</span>
            </CL.Link>
            <CL.Link
              linkStyle="link"
              className="of-employee-login__grid__row4__link"
              linkHref="https://verkkoasiointi.elisa.fi/kirjautuminen"
            >
              <svg className="of-employee-login__grid__row4__link__saunalahti-logo"></svg>
              <span>{t.F8VB('Saunalahti business customer')}</span>
            </CL.Link>
          </div>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  </div>
);
