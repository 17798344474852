import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromSupportCaseDialog } from '../../components/SupportCaseDialog/SupportCaseDialog.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SupportCasesState } from '../../common/types/states.js';

export function supportCaseReducer(
  state: ActionsHistory | undefined | null,
  action: SelfServiceActionTypes
): (SupportCasesState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.ADD_SUPPORT_CASE: {
      if (getErrorsFromSupportCaseDialog(action.supportCase)) {
        return state;
      } else {
        return reduceCrudAction(action, { ...state, ...{ isSubmitting: true, supportCaseSent: false } });
      }
    }

    case TypeKeys.ADD_SUPPORT_CASE_FULFILLED: {
      return {
        ...(state || {}),
        supportCaseSent: true,
        supportCaseFailed: false,
        actions: updateActionStatePhase(
          TypeKeys.ADD_SUPPORT_CASE,
          state || {},
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.ADD_SUPPORT_CASE_FAILED: {
      return {
        ...state,
        supportCaseSent: false,
        supportCaseFailed: true,
        actions: updateActionStatePhase(TypeKeys.ADD_SUPPORT_CASE, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
      };
    }

    case TypeKeys.SET_CASE_SENT_STATUS: {
      return {
        ...state,
        supportCaseSent: action.supportCaseSent,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
