export type OpenFormCanvasAction = (typeof OpenFormCanvas.action)[keyof typeof OpenFormCanvas.action];

/* eslint-disable curly */
export class OpenFormCanvas {
  static readonly action = {
    acknowledgement: 'acknowledgement',
    billingAccountCreated: 'billingAccountCreated',
    contactCreated: 'contactCreated',
    goto: 'goto',
    redirectToAccount: 'redirectToAccount',
  } as const;

  static #origin?: string;
  static {
    typeof window !== 'undefined' && this.#setup();
  }

  static get #targetOrigin(): string | undefined {
    return this.#origin;
  }

  static set #targetOrigin(origin: string | undefined) {
    this.#trustedOrigins.some(s => origin?.endsWith(s)) && (this.#origin = origin);
  }

  static get #top(): Window {
    let target: Window;
    for (target = window.top ?? window.parent; target.self !== target.parent; target = target.parent);
    return target;
  }

  static get #trustedOrigins() {
    return ['.salesforce.com', '.force.com'].concat(window.origin);
  }

  static isEventTrusted(event?: Event | MessageEvent): boolean {
    return !!(event?.isTrusted && 'origin' in event && this.#trustedOrigins.some(s => event.origin?.endsWith(s)));
  }

  static postMessage<T extends Record<'action', OpenFormCanvasAction>>(
    message: T,
    targetOrigin = this.#targetOrigin,
    target = this.#top
  ) {
    for (const frame of [target].filter(_ => targetOrigin === window.origin).concat(this.#frames(target))) {
      frame.postMessage(message, { targetOrigin });
    }
  }

  static #frames(target: Window): Window[] {
    const frames = target.self !== window.self ? [target] : [];
    for (let i = 0; i < target.frames.length; frames.push(...this.#frames(target.frames[i++])));
    return frames;
  }

  static #readCookie(cookie: string): string | undefined {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith(cookie + '='))
      ?.split('=')[1];
  }

  static #setup(cookie = 'CanvasRequestOrigin') {
    try {
      this.#targetOrigin = new URL(this.#readCookie(cookie) || window.origin).origin;
    } catch (error) {
      this.#targetOrigin = window.origin;
      console.warn(error);
    }
  }
}
