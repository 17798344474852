import { AttachRingPbxContent } from '../AttachPbxContent/AttachRingPbxContent';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CATEGORY_TEXTS } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { EmptyOrError } from '../EmptyOrError';
import { Loading } from '../Loading';
import { ModelType } from '../../common/enums.js';
import { NumberRange, PbxType, SubscriptionType } from '../../generated/api/models.js';
import { generatePath, useLocation } from 'react-router-dom';
import { getRingServiceLevelsFromOnlineModel } from './attachRingDataConverter.js';
import { getSubscriptionBreadcrumbName, getSubscriptionUrlId } from '../../common/utils/subscriptionUtils.js';
import { omaElisaForCompaniesMsg, returnMsg, subscriptionNotCompatibleWithVakioMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type {
  AttachRingPbxContentAttrs,
  AttachRingPbxContentDispatchers,
  AttachRingPbxContentRoutes,
  RingSettingsConfiguration,
} from '../AttachPbxContent/AttachRingPbxContent';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { DeliveryAddress, OnlineModel, SubscriptionAction } from '../../generated/api/models.js';
import type { ServiceLevel, ServiceLevelAddon } from './ServiceLevelAndAddonsInterfaces.js';

export interface AttachRingOwnAttrs {
  onSubmitOrder: (
    orderItems?: ConfiguredOffer[],
    deliveryAddress?: DeliveryAddress,
    isCompanyNameRequired?: boolean,
    deliveryAddressValidationErrors?: CommonError[],
    personAccountValidationErrors?: CommonError[],
    billingAccountId?: string
  ) => void;
  pbxServiceLevels?: ServiceLevel[];
  pbxServiceLevelAddons?: ServiceLevelAddon[];
  subscriptionActions?: SubscriptionAction[];
  associations?: AssociationRecord[];
  dependencies?: DependencyRecord[];
  onlineModels?: OnlineModel[];
  isContentWrapped?: boolean;
  hideSaving?: boolean;
  isExistingSubscription?: boolean;
  ringSettingsConfiguration?: RingSettingsConfiguration;
  blockedCorporateNumbers?: string[];
  blockedExtensionNumbers?: string[];
}

export type AttachRingAttrs = AttachRingOwnAttrs & AttachRingPbxContentAttrs;

export type AttachRingProps = AttachRingAttrs & AttachRingPbxContentRoutes & AttachRingPbxContentDispatchers;

const getErrorElement = (props: AttachRingProps) => {
  const { onClickSubscription, subscription, companyInfo, isExistingSubscription } = props;
  const pbxSolutions = companyInfo!.pbxSolutions;
  const vakioNotFoundError = (
    <EmptyOrError
      critical={true}
      id="vakio-not-found"
      text={t.EWI0('Your company does not have an active Ring solution.')}
      onButtonClick={() => {
        onClickSubscription(getSubscriptionUrlId(subscription!));
      }}
      buttonText={t.VH59(returnMsg)}
    />
  );

  if (!pbxSolutions || pbxSolutions.length < 1) {
    return vakioNotFoundError;
  }

  const [defaultPbxSolution] = pbxSolutions;
  if (
    !defaultPbxSolution.details ||
    !defaultPbxSolution.details.mobilePbx ||
    defaultPbxSolution.details.mobilePbx.numberRanges.find(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
    ) === undefined ||
    defaultPbxSolution.details.mobilePbx.numberRanges.find(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.EXTENSION
    ) === undefined
  ) {
    return vakioNotFoundError;
  }

  if (
    isExistingSubscription &&
    (!subscription!.compatiblePbxTypes || !subscription!.compatiblePbxTypes.includes(PbxType.MOBILE_PBX_LITE))
  ) {
    return (
      <EmptyOrError
        id="not-vakio-compatible"
        text={t.CCR4(subscriptionNotCompatibleWithVakioMsg)}
        onButtonClick={() => {
          onClickSubscription(getSubscriptionUrlId(subscription!));
        }}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }

  if (isExistingSubscription && subscription!.details!.mobile!.pbxConfiguration!.pbxSolutionId) {
    return (
      <EmptyOrError
        id="already-vakio-connected"
        text={t.RWP3('Subscription already connected to Ring solution.')}
        onButtonClick={() => {
          onClickSubscription(getSubscriptionUrlId(subscription!));
        }}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }
  return undefined;
};

export const AttachRing = (props: AttachRingProps) => {
  const {
    category,
    companyInfo,
    subscription,
    numberRanges,
    onClickSubscription,
    onSubmitOrder,
    associations,
    dependencies,
    onlineModels,
    isContentWrapped = true,
    onChangeConfig,
    hideSaving,
    isExistingSubscription = true,
    ringSettingsConfiguration,
    blockedCorporateNumbers,
    blockedExtensionNumbers,
  } = props;
  const { search } = useLocation();

  if ((isExistingSubscription && !subscription) || props.companyInfo?.companyName === undefined) {
    return <Loading />;
  }

  const errorElement = getErrorElement(props);
  if (errorElement) {
    return errorElement;
  }

  const ringServicesModel = onlineModels?.find(model => model.onlineModelCode === ModelType.Ring);

  const getPbxServiceLevelsAndAddons = () => {
    const ringSolution = companyInfo?.pbxSolutions?.find(
      pbxSolution => pbxSolution.subscriptionType === SubscriptionType.MOBILE_PBX
    );

    if (!ringSolution) {
      return {
        pbxServiceLevelAddons: [],
        pbxServiceLevels: [],
      };
    }

    const mobilePbxServiceLevelProduct = ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct;
    const productCodes = mobilePbxServiceLevelProduct?.userServiceLevelCommercialProductCodes || [];
    const productAddons = mobilePbxServiceLevelProduct?.addOnServiceLevelSelections || [];

    const serviceLevelsAndAddons = getRingServiceLevelsFromOnlineModel(productCodes, productAddons, ringServicesModel);
    return {
      pbxServiceLevels: serviceLevelsAndAddons.pbxServiceLevels,
      pbxServiceLevelAddons: serviceLevelsAndAddons.pbxServiceLevelAddons,
    };
  };

  const { pbxServiceLevels, pbxServiceLevelAddons } =
    props.pbxServiceLevels && props.pbxServiceLevelAddons
      ? { pbxServiceLevels: props.pbxServiceLevels, pbxServiceLevelAddons: props.pbxServiceLevelAddons }
      : getPbxServiceLevelsAndAddons();

  const content: JSX.Element = (
    <AttachRingPbxContent
      category={category}
      companyInfo={companyInfo}
      subscription={subscription}
      numberRanges={numberRanges}
      onClickSubscription={onClickSubscription}
      pbxServiceLevels={pbxServiceLevels}
      pbxServiceLevelAddons={pbxServiceLevelAddons}
      onSubmitOrder={onSubmitOrder}
      associations={associations}
      dependencies={dependencies}
      onChangeConfig={onChangeConfig}
      ringModels={ringServicesModel ? [ringServicesModel] : []}
      hideSaving={hideSaving}
      ringSettingsConfiguration={ringSettingsConfiguration}
      blockedCorporateNumbers={blockedCorporateNumbers}
      blockedExtensionNumbers={blockedExtensionNumbers}
    />
  );

  if (isContentWrapped && isExistingSubscription && subscription) {
    const breadCrumbs: JSX.Element = (
      <BreadCrumbsWithTitle
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: CATEGORY_TEXTS()[props.category], path: paths.PS_MOBILE_SUBSCRIPTIONS },
          {
            name: getSubscriptionBreadcrumbName(subscription),
            path:
              generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) +
              search,
          },
          { name: 'Ring' },
        ]}
      />
    );

    return (
      <DetailsWrapper
        classes={['of-attach-ring']}
        detailsTop={breadCrumbs}
        id="of-attach-ring"
        heading={t.NENG('Add subscription to Ring')}
        heroPicto="sim"
      >
        {content}
      </DetailsWrapper>
    );
  } else {
    return content;
  }
};
