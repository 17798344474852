import { getUserAccounts } from '../../components/Header/dynamic/headerFunctions.js';
import type { Address } from '../../generated/api/models.js';
import type { AuthenticatedUserState } from '../types/states.js';

export const formatAddress = (address?: Address): string | undefined => {
  if (address === undefined) {
    return undefined;
  }
  return (
    address.line1 + (address.line2 ? ' ' + address.line2 : '') + ', ' + address.postalCode + ', ' + address.postOffice
  );
};

export const getCompanyName = (authenticatedUser?: AuthenticatedUserState, accountMasterId?: string) => {
  if (!accountMasterId) {
    return '';
  }
  const userAccounts = getUserAccounts(authenticatedUser);
  return userAccounts.find(account => account.accountMasterId === accountMasterId)?.name ?? '';
};

export const isMultiBiz = (authenticatedUser?: AuthenticatedUserState): boolean =>
  !!(authenticatedUser && getUserAccounts(authenticatedUser).length > 1);

export const getPrimaryMdmId = (authenticatedUser?: AuthenticatedUserState) =>
  authenticatedUser?.contact?.accountContactRelationships?.find(acr => acr.isPrimaryAccount)?.accountMasterId;
