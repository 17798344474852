import * as CL from '@design-system/component-library';
import * as React from 'react';
import {
  AddOnVisibilityOperationType,
  DialogType,
  ModelType,
  PhoneNumberType,
  SimCardSelection,
} from '../../common/enums.js';
import { AdditionalServicesCheckoutContent } from './AdditionalServicesCheckoutContent.js';
import { AttachRing } from '../AttachRing/AttachRing.js';
import { CommercialProductType, SimType, SubscriptionType } from '../../generated/api/models.js';
import { NumberPrivacy } from '../NumberPrivacy/NumberPrivacy.js';
import { PurposeOfUse } from '../PurposeOfUse/PurposeOfUse.js';
import { SelectPhoneNumber } from '../SelectPhoneNumber/SelectPhoneNumber.js';
import {
  SimCardDuringNewSubscriptionOrder,
  SimOption,
} from '../SimCardDuringNewSubscriptionOrder/SimCardDuringNewSubscriptionOrder.js';
import { addDays, addHours, endOfDay, isEqual, isWeekend, startOfDay } from 'date-fns';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isDefined } from '../../common/utils/objectUtils.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useWatch } from 'react-hook-form';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AttachRingProps } from '../AttachRing/AttachRing.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { Contact, ContactHeader, SubscriptionPbxConfiguration } from '../../generated/api/models.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { OrderConfigurationItem } from './FormWrapper.js';
import type { RingSettingsConfiguration } from '../AttachPbxContent/AttachRingPbxContent';
import type { ValidatedPhoneNumber } from '../../common/utils/phoneNumberUtils.js';

export const isAllowedTransferDay = (date: Date, blockedDays: Date[]) => {
  const startOfTheDay = startOfDay(date);
  return (
    startOfTheDay.getTime() > addHours(endOfDay(new Date()), -1).getTime() &&
    !isWeekend(startOfTheDay) &&
    !blockedDays.some(blockedDay => isEqual(startOfDay(blockedDay), startOfTheDay))
  );
};

export const getNextAllowedDay = (blockedDays: Date[]) => {
  let currentDate = startOfDay(new Date());
  let i = 0;
  while (i <= 5) {
    currentDate = addDays(currentDate, 1);
    if (isAllowedTransferDay(currentDate, blockedDays)) {
      i++;
    }
  }

  return currentDate;
};

export enum Field {
  PURPOSE_OF_USE = 'PURPOSE_OF_USE',
  SELECT_PHONE_NUMBER = 'SELECT_PHONE_NUMBER',
  NUMBER_PRIVACY = 'NUMBER_PRIVACY',
  SIMCARD = 'SIMCARD',
  M2M_SIMCARD = 'M2M_SIMCARD',
  VAKIO = 'VAKIO',
  ADDONS = 'ADDONS',
}

interface OrderSubscriptionConfigurationFieldsProps {
  fieldsToShow: Field[];
  holidays: Date[];
  selectedCommercialProducts: ConfiguredCommercialProduct[];
  selectedCommercialProduct: ConfiguredCommercialProduct;
  phoneNumbers: string[];
  phoneNumbersLoading: boolean;
  cartItemInstanceId: string;
  companyName: string;
  contacts: Contact[] | ContactHeader[] | undefined;
  onSelectUseExistingPhoneNumber: (cartItemInstanceId: string, existingPhoneNumber: string) => void;
  validatedPhoneNumbers: ValidatedPhoneNumber[];
  onRingConfigurationChange: (
    cartItemInstanceId: string,
    configuration?: ConfiguredOffer,
    pbxConfiguration?: Partial<SubscriptionPbxConfiguration>
  ) => void;
  orderModelType: ModelType;
  ringSettingsConfiguration?: RingSettingsConfiguration;
  isRingSelected: boolean;
  onRingSelectionChange: (isRingSelected: boolean) => void;
  onAddOnSelectionChange: (cartItemInstanceId: string, addedAddons: AddedAddon[]) => void;
}

export const OrderSubscriptionConfigurationFields = (
  props: OrderSubscriptionConfigurationFieldsProps & Partial<AttachRingProps>
) => {
  const {
    fieldsToShow,
    cartItemInstanceId,
    companyName,
    holidays,
    numberRanges,
    onlineModels,
    companyInfo,
    subscription,
    contacts,
    onRingConfigurationChange,
    onSelectUseExistingPhoneNumber,
    phoneNumbers,
    phoneNumbersLoading,
    selectedCommercialProduct,
    selectedCommercialProducts,
    validatedPhoneNumbers,
    ringSettingsConfiguration,
    orderModelType,
    onAddOnSelectionChange,
    isRingSelected,
    onRingSelectionChange,
  } = props;

  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const toggleRingSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRingSelectionChange(event.target.checked);
    if (!event.target.checked) {
      onRingConfigurationChange(cartItemInstanceId, undefined, undefined);
    }
  };
  const isRingEnabledForCompany = companyInfo?.pbxSolutions?.some(
    sub => sub.subscriptionType === SubscriptionType.MOBILE_PBX
  );
  const onSelectUseExistingPhoneNumberWithIndex = useCallback(
    (existingPhoneNumber: string) => {
      onSelectUseExistingPhoneNumber(cartItemInstanceId, existingPhoneNumber);
    },
    [cartItemInstanceId, onSelectUseExistingPhoneNumber]
  );

  const showRingSettings =
    (orderModelType === ModelType.Voice || orderModelType === ModelType.VoiceSME) && isRingEnabledForCompany;

  const currentConfiguration: Record<string, OrderConfigurationItem> = useWatch({ name: `configuration` });
  const disabledPhoneNumbers: string[] = selectedCommercialProducts
    .map((_, itemIndex) => currentConfiguration?.[itemIndex])
    .filter(configuration => configuration?.selectPhoneNumber?.type === PhoneNumberType.NEW)
    .map(configuration => configuration.selectPhoneNumber?.newPhoneNumber)
    .filter(isDefined);

  const simCardNumbers: string[] = selectedCommercialProducts
    .map((_, itemIndex) => currentConfiguration?.[itemIndex])
    .filter(configuration => configuration?.simCardConfiguration?.simCardNumber !== undefined)
    .map(configuration => configuration?.simCardConfiguration?.simCardNumber)
    .filter(isDefined);

  return (
    <>
      {fieldsToShow.includes(Field.PURPOSE_OF_USE) && (
        <PurposeOfUse contacts={contacts} fieldPrefix={`configuration.${cartItemInstanceId}.purposeOfUseOrContact.`} />
      )}
      {fieldsToShow.includes(Field.SELECT_PHONE_NUMBER) && (
        <SelectPhoneNumber
          holidays={holidays}
          companyName={companyName}
          disabledPhoneNumbers={disabledPhoneNumbers} // TODO provide disabled numbers
          phoneNumbers={phoneNumbers || []}
          phoneNumbersLoading={phoneNumbersLoading || false}
          cartItemInstanceId={cartItemInstanceId}
          isAllowedTransferDay={isAllowedTransferDay}
          getNextAllowedDay={getNextAllowedDay}
          selectedPhoneNumber={selectedCommercialProduct.selectedPhoneNumber}
          selectedCommercialProduct={selectedCommercialProduct}
          onSelectUseExistingPhoneNumber={onSelectUseExistingPhoneNumberWithIndex}
          validatedPhoneNumbers={validatedPhoneNumbers}
        />
      )}
      {fieldsToShow.includes(Field.NUMBER_PRIVACY) && (
        <NumberPrivacy
          {...{
            cartItemInstanceId: cartItemInstanceId,
            selectedCommercialProduct,
          }}
        />
      )}
      {fieldsToShow.includes(Field.SIMCARD) && (
        <SimCardDuringNewSubscriptionOrder
          {...{
            cartItemInstanceId: cartItemInstanceId,
            onClickSimCardNumberHelp: () => {
              onShowDialog({
                type: DialogType.ORDER_SIM_CARD_NUMBER_HELP,
              });
            },
            simCardConfiguration: selectedCommercialProduct.simCardConfiguration || {
              simSelection: SimCardSelection.ORDER_NEW,
              simType: SimType.PHYSICAL,
            },
            simOptions: [SimOption.ACTIVATE_EXISTING_PHYSICAL, SimOption.ORDER_NEW_ESIM, SimOption.ORDER_NEW_PHYSICAL],
            simCardNumbers,
          }}
        />
      )}
      {fieldsToShow.includes(Field.M2M_SIMCARD) && (
        <SimCardDuringNewSubscriptionOrder
          {...{
            cartItemInstanceId: cartItemInstanceId,
            onClickSimCardNumberHelp: () => {
              onShowDialog({
                type: DialogType.ORDER_SIM_CARD_NUMBER_HELP,
              });
            },
            simCardConfiguration: selectedCommercialProduct.simCardConfiguration || {
              simSelection: SimCardSelection.ORDER_NEW,
              simType: SimType.PHYSICAL_MINI_MICRO,
            },
            showSimSizeTooltip: true,
            simOptions: [
              SimOption.ACTIVATE_EXISTING_PHYSICAL,
              SimOption.ORDER_NEW_PHYSICAL_MINI_MICRO,
              SimOption.ORDER_NEW_PHYSICAL_NANO,
            ],
            simCardNumbers,
          }}
        />
      )}

      {showRingSettings && (
        <div className="of-select-ring">
          <h4 className={dsClass.H4}>{t.OUCH('Selected ring solution')}</h4>
          <CL.Checkbox onChange={toggleRingSelection} checked={isRingSelected} key={String(isRingSelected)}>
            {t.ZWK3('Attach your number as part of ring solution')}
          </CL.Checkbox>
        </div>
      )}
      {isRingSelected && (
        <div className="of-attach-ring">
          <AttachRing
            category="voice"
            onClickSubscription={() => {}}
            companyInfo={companyInfo}
            subscription={subscription}
            onSubmitOrder={() => {}}
            onlineModels={onlineModels}
            isContentWrapped={false}
            numberRanges={numberRanges}
            onChangeConfig={(configuredOffer, pbxConfiguration) => {
              onRingConfigurationChange(cartItemInstanceId, configuredOffer, pbxConfiguration);
            }}
            hideSaving={true}
            isExistingSubscription={false}
            ringSettingsConfiguration={ringSettingsConfiguration}
            pbxServiceLevelAddons={ringSettingsConfiguration?.pbxServiceLevelAddons}
            pbxServiceLevels={ringSettingsConfiguration?.pbxServiceLevels}
            blockedCorporateNumbers={
              selectedCommercialProducts
                .map(product => product?.ringSettingsConfiguration?.corporateNumber)
                .filter(Boolean) as string[]
            }
            blockedExtensionNumbers={
              selectedCommercialProducts
                .map(product => product?.ringSettingsConfiguration?.selectedExtensionNumber)
                .filter(Boolean) as string[]
            }
          />
        </div>
      )}

      {fieldsToShow.includes(Field.ADDONS) && (
        <AdditionalServicesCheckoutContent
          commercialProduct={selectedCommercialProduct.commercialProduct}
          isRingSelected={isRingSelected}
          cartItemInstanceId={cartItemInstanceId}
          productAddOns={selectedCommercialProduct.addedAddOns}
          onAddOnSelectionChange={onAddOnSelectionChange}
          showSeparator={true}
          addOnVisibilityOperationType={AddOnVisibilityOperationType.NEW_SALES}
          loadNettiRules={Boolean(
            selectedCommercialProduct.commercialProduct.productType === CommercialProductType.FIXED_BROADBAND
          )}
        />
      )}
    </>
  );
};
