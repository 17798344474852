import type { AiChatResponse, SupportCase } from '../generated/api/models.js';

/**
 * Generic types & functions related to Google analytics
 */

export enum AnalyticsItemCategory {
  // Used in /netin-saatavuus (although it probabaly shouldn't)
  FIXED_BROADBAND = 'FIXED_BROADBAND',
  // Used in /yritysliittymat (although it shouldn't, it should match NOE).
  SALES_PRODUCT = 'Sales Product',
  YRITYS_LIITTYMA = 'Yritysliittymä',
  YRITYS_DATA_LIITTYMA = 'Yritysdata',
  LAITE_NETTI = 'Laitenetti',
  // Fallback value if there is some unexpected error
  UNKNOWN_CATEGORY = 'Unknown category',
}

export const CURRENCY_CODE_EUR = 'EUR';

// V4 item
export type EcommerceItem = {
  affiliation?: string;
  coupon?: string;
  index?: number;
  item_brand?: string;
  item_bundle_id?: string;
  item_category: string;
  item_id: string;
  item_list_name?: string;
  item_name: string;
  item_variant?: string;
  // Price of _single_ item
  price?: number;
  quantity?: number;
};

// Use for Google Analytics v4 events
export enum EcommerceEventTypeV4 {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_TO_CART = 'add_to_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  REMOVE_ADDITIONAL_SERVICES = 'remove_additional_services',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_ITEM = 'select_item',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
}

export type EcommerceBeginCheckoutEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.BEGIN_CHECKOUT;
};

export type EcommerceAddShippingInfoEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
    // We don't send shipping_tier here because we want to send this event when
    //  the user _enters_ the shipping info step.
  };
  event: EcommerceEventTypeV4.ADD_SHIPPING_INFO;
};

export type EcommerceAddPaymentInfoEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
    // We don't send payment_type here because we want to send this event when
    //  the user _enters_ the payment info step.
  };
  event: EcommerceEventTypeV4.ADD_PAYMENT_INFO;
};

export type EcommerceAddToCartEventV4 = {
  ecommerce: {
    currency: 'EUR';
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.ADD_TO_CART;
};

export type EcommerceRemoveFromCartEventV4 = {
  ecommerce: {
    currency: 'EUR';
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.REMOVE_FROM_CART;
};

export type EcommercePurchaseEventV4 = {
  ecommerce: {
    coupon?: string;
    currency: 'EUR';
    items: EcommerceItem[];
    shipping?: number;
    tax: number;
    transaction_id: string;
    value: number;
  };
  event: EcommerceEventTypeV4.PURCHASE;
  // This is _not_ part of Google's purchase event (i.e. it's our custom stuff),
  //  so it's not written inside ecommerce.
  payment_type: string;
};

export type EcommerceRemoveAdditionalServicesEventV4 = {
  ecommerce: {
    currency: 'EUR';
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.REMOVE_ADDITIONAL_SERVICES;
};

export type EcommerceViewItemEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.VIEW_ITEM;
};

export type EcommerceViewItemListEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.VIEW_ITEM_LIST;
};

export type EcommerceSelectItemEventV4 = {
  ecommerce: {
    items: EcommerceItem[];
  };
  event: EcommerceEventTypeV4.SELECT_ITEM;
};

type EcommerceEventV4 =
  | EcommerceBeginCheckoutEventV4
  | EcommerceAddShippingInfoEventV4
  | EcommerceAddPaymentInfoEventV4
  | EcommerceAddToCartEventV4
  | EcommerceRemoveFromCartEventV4
  | EcommercePurchaseEventV4
  | EcommerceRemoveAdditionalServicesEventV4
  | EcommerceViewItemEventV4
  | EcommerceViewItemListEventV4
  | EcommerceSelectItemEventV4;

const isEcommerceEventV4 = (event: EcommerceEventV4 | Record<string, unknown>): event is EcommerceEventV4 =>
  event.ecommerce != null;

declare global {
  // Resides in typescript/lib/lib.dom.d.ts, but can't be imported
  interface Window {
    dataLayer?: object[];
  }
}

export const pushToDataLayer = (obj: EcommerceEventV4 | Record<string, unknown>) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  // See https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
  if (isEcommerceEventV4(obj)) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
  }
  window.dataLayer.push(obj);
};

const createReduxAnalyticsEvent = (eventAction: string) => {
  return {
    event: 'online-ui',
    eventCategory: 'redux',
    eventAction: eventAction,
    eventNonInteraction: 0,
    eventLabel: '',
  };
};

export const pushGAEventToDataLayer = (eventAction: string) => {
  pushToDataLayer(createReduxAnalyticsEvent(eventAction));
};

export const pushSupportCaseGAEventToDataLayer = (
  eventName: string,
  isAuthenticated: boolean,
  feature: SupportCase.FeatureEnum | string
) => {
  const event = {
    event: eventName,
    form_type: 'Support Case',
    support_case_category: feature,
    support_case_type: `${isAuthenticated ? 'authenticated' : 'anonymous'}`,
  };
  pushToDataLayer(event);
};

export const pushGlobalSearchGAEventToDataLayer = (
  eventName: string,
  multiBiz: boolean,
  selectedFilters: string[],
  resultCategories: string[],
  searchResultsTotal?: number
) => {
  pushToDataLayer({
    event: eventName,
    include_all_companies: `${multiBiz}`,
    search_result_category: resultCategories.length > 0 ? resultCategories.join(', ') : null,
    search_result_category_filter: selectedFilters.length > 0 ? selectedFilters.join(', ') : null, // null if none applied
    search_results: searchResultsTotal || 0,
  });
};

export type DomainRecordEvent = 'add' | 'remove' | 'modify';
export const pushDomainRecordEventToDataLayer = (eventType: DomainRecordEvent) => {
  pushToDataLayer({
    event: 'oe_settings_update',
    setting_category: 'dns',
    update_type: eventType,
    updated_setting: 'dns record',
  });
};

export type BillingAccountEvent = 'add' | 'modify';
export const pushBillingAccountEventToDataLayer = (eventType: BillingAccountEvent) => {
  pushToDataLayer({
    event: 'oe_settings_update',
    setting_category: 'billing_account',
    update_type: eventType,
    updated_setting: 'billing account details',
  });
};

export const pushChatStartEventToDataLayer = ({ sessionId }: AiChatResponse) => {
  pushToDataLayer({
    event: 'chat_started',
    chat_platform: 'GPT',
    chat_session_id: sessionId,
  });
};

export const pushChatMessageEventToDataLayer = ({ sessionId, messages }: AiChatResponse) => {
  const [message, answer] = messages.slice(-2).map(i => i.content);
  pushToDataLayer({
    event: 'chat_message',
    chat_platform: 'GPT',
    chat_session_id: sessionId,
    message,
    answer,
  });
};

export const pushLicenseManagementGAEventToDataLayer = (eventName: string) => {
  const event = {
    event: eventName,
    eventCategory: 'license_management',
  };
  pushToDataLayer(event);
};
