import { EmployeeDeviceStoreHomeScene } from '../EmployeeDeviceStoreHomeScene/EmployeeDeviceStoreHomeScene.js';
import { Loading } from '../Loading/index.js';
import { PunchoutDialog } from './partials/PunchoutDialog.js';
import { UserDetails } from '../UserDetails/UserDetails.js';
import { clearCartItems, setSelectedVirtualCatalog } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getPublishedCatalogs } from '../../common/utils/catalogUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

const CatalogNotDefined = () => (
  <div className="of-empty-or-error__top of-empty-or-error__top--emptystage">
    <h4 className={dsClass.H4}>{t.D1RH(`No catalogs defined. Contact your employer's administrator.`)}</h4>
  </div>
);

export const PunchoutStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const catalogs = useSelector(
    (state: State) => getPublishedCatalogs(state.selfservice?.virtualCatalogs?.items),
    deepEqual
  );
  const selectedCatalogCode = useSelector(
    (state: State) => state.selfservice?.virtualCatalogs?.selectedCatalogCode,
    deepEqual
  );

  const { authenticatedUser } = useAuth();

  if (catalogs === undefined) {
    return <Loading big={true} topPadding={0} />;
  } else if (catalogs.length === 0) {
    return <CatalogNotDefined />;
  } else {
    return (
      <>
        {!authenticatedUser?.mobile ? (
          <>
            <UserDetails />
            <PunchoutDialog />
          </>
        ) : (
          <EmployeeDeviceStoreHomeScene
            catalogs={catalogs}
            isPunchoutStore={true}
            onClickCatalog={(category: string = '') => {
              navigate(generatePath(paths.PUNCHOUT_STORE_PRODUCTS, { category }));
            }}
            onSelectCatalog={(chosenCatalogCode: string) => dispatch(setSelectedVirtualCatalog(chosenCatalogCode))}
            selectedCatalogCode={selectedCatalogCode}
            clearCartItems={() => dispatch(clearCartItems())}
            hasCartItems={false}
          />
        )}
      </>
    );
  }
};
