import { getValueByPath, isNotDefined, isNumber, isString } from './objectUtils.js';
import { validateOrderParameter } from './searchUtils.js';
import type { NestedKeyOf } from '../types/utility';

export const sortArray = <T>(array: T[], sort: string, order?: string): T[] => {
  const multiplier = order === 'asc' ? 1 : -1;
  return array.sort((a: T, b: T) => {
    const aValue = getValueByPath(a, sort);
    const bValue = getValueByPath(b, sort);

    return (aValue || 0) >= (bValue || 0) ? multiplier : -multiplier;
  });
};

const compareValues = (a: unknown, b: unknown): number => {
  if (isNumber(a) && isNumber(b)) {
    return a - b;
  } else if (isString(a) && isString(b)) {
    return a.localeCompare(b);
  }

  if (isNotDefined(a) && isNotDefined(b)) {
    return 0;
  } else if (isNotDefined(a)) {
    return -1;
  } else if (isNotDefined(b)) {
    return 1;
  }

  return (a || 0) >= (b || 0) ? 1 : -1;
};

export const getArrayPropertySorter =
  (sort?: string, order?: string) =>
  <T>(a: T, b: T) => {
    const validatedOrder = validateOrderParameter(order);

    if (!sort || !validatedOrder) {
      return 0;
    }

    const comparisonValue = compareValues(a[sort as keyof T], b[sort as keyof T]);
    return validatedOrder === 'asc' ? comparisonValue : -comparisonValue;
  };

export const sortArrayByProperty = <T>(array: T[], sort: string, order?: string): T[] => {
  return array.sort(getArrayPropertySorter(sort, order));
};

export const groupBy = <T, U extends string>(array: T[], getGroupId: (item: T) => U): { [groupId in U]: T[] } => {
  return array.reduce(
    (result, currentItem) => {
      const groupId = getGroupId(currentItem);
      // @ts-ignore
      return { ...result, [groupId]: (result[groupId] || []).concat(currentItem) };
    },
    {} as Record<U, T[]>
  );
};

export const haveSameValues = (arr1: string[], arr2: string[]): boolean => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  if (set1.size !== set2.size) {
    return false;
  }
  for (const value of set1) {
    if (!set2.has(value)) {
      return false;
    }
  }
  return true;
};

export const getUniqueArrayBy = <T, K>(arrays: T[][], keySelector: (item: T) => K): T[] => {
  const uniqueMap = new Map<K, T>();

  for (const array of arrays) {
    for (const item of array) {
      const key = keySelector(item);
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
      }
    }
  }

  return Array.from(uniqueMap.values());
};

export const sum = <T>(arr: T[], propKey: NestedKeyOf<T>): number => {
  return arr.reduce((acc, item) => acc + (getValueByPath(item, propKey) as number) || 0, 0);
};
