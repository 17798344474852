import * as CL from '@design-system/component-library';
import { CommercialProductType } from '../../generated/api/commercialProductType';
import { continueShoppingMsg, quantityMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils';
import {
  findBasketItemForId,
  getAddonsDisplayData,
  getAnalyticsEventForAddOrRemoveItem,
  getDisclaimerFields,
  getOffer,
  getOnetimePaymentText,
  getOnlineModelForBasketItem,
  getPriceForSalesProduct,
  getPriceFromBasketOfferItem,
  getPriceToDisplay,
  getTotalAmount,
} from '../ShoppingBasket/shoppingBasketUtils';
import { getProductImage } from '../ShoppingBasket/shoppingBasketUiUtils';
import { loadOnlineModelWithId } from '../../selfservice/actions';
import { parseBasketJson, useShoppingBasket } from '../../common/hooks/useShoppingBasket';
import { pushToDataLayer } from '../../common/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import type { BasketItem, ShoppingBasketType } from '../../common/types/shoppingBasket';
import type { OnlineModel } from '../../generated/api/onlineModel';
import type { PageResponse } from '../../generated/api/pageResponse';
import type { PriceData } from '../ShoppingBasket/types';
import type { State } from '../../selfservice/common/store';

import './HeaderNewShoppingBasket.scss';

export interface HeaderNewShoppingBasketProps {
  toShoppingCart: string;
}

interface BasketItemUpdate {
  id: string;
  quantity: number;
  guid: string;
  model?: OnlineModel;
}

export const HeaderNewShoppingBasket = (props: HeaderNewShoppingBasketProps) => {
  const { toShoppingCart } = props;
  const navigate = useNavigate();
  const { shoppingBasket } = useShoppingBasket();
  const dispatch = useDispatch();
  const pageLoaderData = useRouteLoaderData('public-path-root') as PageResponse | undefined;
  const stateOnlineModels = useSelector((state: State) => state.selfservice?.onlineModels?.items || [], deepEqual);
  const onlineModels = [...stateOnlineModels, ...(pageLoaderData ? pageLoaderData.onlineModels : [])];

  const [lastItemChanged, setLastItemChanged] = useState({} as BasketItemUpdate);
  const lastModified = useRef<number>(new Date().getTime());

  const getBasketItemUpdates = (items: BasketItem[]) =>
    items.map(
      basketItem =>
        ({
          id: basketItem.id,
          quantity: basketItem.quantity,
          guid: basketItem.guid,
          model: getOnlineModelForBasketItem(onlineModels, basketItem),
        }) as BasketItemUpdate
    );

  const shoppingBasketType: ShoppingBasketType = parseBasketJson(shoppingBasket);
  const basketItems = shoppingBasketType.items;
  const basketFromStorage = getBasketItemUpdates(basketItems || []);

  const previousBasketFromStorage = useRef<BasketItemUpdate[]>(basketFromStorage);

  useEffect(() => {
    const changedIndices = basketFromStorage
      .map((item, index) =>
        previousBasketFromStorage.current[index] === undefined ||
        item.id !== previousBasketFromStorage.current[index].id ||
        item.quantity !== previousBasketFromStorage.current[index].quantity
          ? index
          : -1
      )
      .filter(index => index !== -1);

    const getQuantity = (items: BasketItemUpdate[], findId: string) => {
      return items.find(item => item.id === findId)?.quantity || 0;
    };

    if (changedIndices.length > 0) {
      const lastChangedId = basketFromStorage[changedIndices[0]].id;
      const lastChangedGuid = basketFromStorage[changedIndices[0]].guid;
      const lastChangedModel = basketFromStorage[changedIndices[0]].model;

      const addedQuantity =
        getQuantity(basketFromStorage, lastChangedId) - getQuantity(previousBasketFromStorage.current, lastChangedId);

      if (lastChangedModel) {
        setLastItemChanged({
          id: lastChangedId,
          quantity: addedQuantity,
          guid: lastChangedGuid,
          model: lastChangedModel,
        } as BasketItemUpdate);
        lastModified.current = new Date().getTime();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const changedBasketItem = findBasketItemForId(basketItems, lastChangedId);
        const newQuantity = (changedBasketItem?.quantity || 0) + addedQuantity;
        const analyticsEvent = getAnalyticsEventForAddOrRemoveItem(
          changedBasketItem,
          newQuantity,
          pageLoaderData?.onlineModels || []
        );
        if (analyticsEvent) {
          pushToDataLayer(analyticsEvent);
        }

        previousBasketFromStorage.current = basketFromStorage;
      } else {
        // This is mainly for Frosmo, load the missing onlineModel if not present to show basket popup
        dispatch(loadOnlineModelWithId(lastChangedGuid));
      }
    }
  }, [basketFromStorage, shoppingBasket, basketItems, pageLoaderData?.onlineModels, dispatch]);

  const resolveAriaSummary = (addedAmount: number, totalAmount: number) => {
    if (addedAmount) {
      const ariaSummaryPartOne =
        addedAmount === 1
          ? t.WDX0('One item as added to shopping cart')
          : t.IMA5('{} items added to shopping cart', String(addedAmount));

      const ariaSummaryPartTwo =
        totalAmount === 1
          ? t.HTQL('There is now one item in shopping cart')
          : t.UE2G('There are now {} items in shopping cart', String(totalAmount));

      return `${ariaSummaryPartOne}. ${ariaSummaryPartTwo}.`;
    } else {
      return '';
    }
  };

  const resolveI18nSummary = (totalAmount: number) => {
    if (totalAmount) {
      if (totalAmount > 1) {
        return t.ZE9V('There are {} items in your cart', String(totalAmount));
      } else {
        return t.CYZV('There is 1 item in your cart');
      }
    } else {
      return '';
    }
  };

  const getProducts = (basketState: BasketItemUpdate, models: OnlineModel[]) => {
    const basketItem = findBasketItemForId(basketItems, basketState.id);
    if (!basketItem) {
      return [];
    }
    const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
    if (!onlineModelForItem) {
      return [];
    }
    const isSalesProduct = onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT;
    const addedQuantity = basketState.quantity;
    const price: PriceData = isSalesProduct
      ? getPriceForSalesProduct(getOffer(basketItem.offer.guid, onlineModelForItem))
      : { price: getPriceFromBasketOfferItem(basketItem.offer) };
    const shoppingCartPrice = getPriceToDisplay(price, addedQuantity);
    const offer = getOffer(basketItem.offer.guid, onlineModelForItem);
    return [
      {
        addons: getAddonsDisplayData(basketItem.offer.addOns || [], onlineModelForItem, addedQuantity),
        disclaimer: [
          ...getDisclaimerFields(
            getOnetimePaymentText(basketItem.offer.oneTimePriceInCents, addedQuantity, isSalesProduct),
            price
          ),
        ],
        id: basketItem.id,
        name: basketItem.name,
        quantity: addedQuantity,
        price: shoppingCartPrice,
        image: getProductImage(basketItem.imageUrl, basketItem.name, offer, onlineModelForItem),
      },
    ];
  };

  const totalProductQuantity = getTotalAmount(basketItems);
  return (
    <div className="of-header-new-shopping-basket">
      <CL.HeaderShoppingCart
        ariaSummary={resolveAriaSummary(lastItemChanged.quantity, totalProductQuantity)}
        i18nClose={t.VLZR(continueShoppingMsg)}
        i18nQuantity={t.M0W7(quantityMsg)}
        i18nSubmit={t.VVQP('To shopping cart')}
        i18nSummary={resolveI18nSummary(totalProductQuantity)}
        i18nTitle={t.VJK6('Added to shopping cart')}
        i18nButtonLabel={t.YXF1('Shopping cart')}
        lastChange={lastModified.current}
        products={lastItemChanged ? getProducts(lastItemChanged, onlineModels) : []}
        onSubmit={() => navigate(toShoppingCart)}
        totalProductQuantity={totalProductQuantity}
      />
    </div>
  );
};
