import * as CL from '@design-system/component-library';
import {
  BARRING_CODE_ORDERS,
  barrings,
  callBarringCodes,
  dataRoamingBarringCodes,
  getAddOnBarringNameByAddOnGuid,
  roamingBarringCodes,
  smsBarringCodes,
} from '../../common/utils/barringUtils.js';
import { BarringGroup } from './BarringGroup.js';
import { CheckedList } from '../CheckedList/index.js';
import {
  callBarringsMsg,
  dataBarringMsg,
  editMsg,
  noRestrictionsMsg,
  phoneTrackingMsg,
  smsBarringsMsg,
  t,
  useAbroadMsg,
} from '../../common/i18n/index.js';
import { getChainedDisplayBarringNames } from './barringAccordionUtil.js';
import type { BarringGroupAvailability } from './barringAccordionUtil.js';
import type { SubscriptionDetails } from '../../generated/api/subscriptionDetails.js';

export interface BarringsViewProps {
  barringGroupAvailabilities: BarringGroupAvailability;
  details: SubscriptionDetails;
  disableEditing: boolean;
  showEditButton: boolean;
  onEditWanted: () => void;
}

export const BarringsView = ({
  barringGroupAvailabilities,
  details,
  disableEditing,
  onEditWanted,
  showEditButton,
}: BarringsViewProps) => (
  <div className="of-barrings-content">
    {barringGroupAvailabilities.areVoiceBarringsAvailable && (
      <BarringGroup id="barring-services-voice">
        <h5>{t.QLJY(callBarringsMsg)}</h5>
        {details.selectedAddOns && (
          <CheckedList
            items={getChainedDisplayBarringNames(
              details.selectedAddOns,
              [...callBarringCodes, barrings.CALLS_ALLOWED_FINLAND.code],
              BARRING_CODE_ORDERS.callBarring
            )}
            showIfEmpty={<span>{t.UPE3(noRestrictionsMsg)}</span>}
          />
        )}
      </BarringGroup>
    )}
    {barringGroupAvailabilities.areSmsBarringsAvailable && (
      <BarringGroup id="barring-services-ssm-mms">
        <h5>{t.D2PS(smsBarringsMsg)}</h5>
        {details.selectedAddOns && (
          <CheckedList
            items={getChainedDisplayBarringNames(
              details.selectedAddOns,
              smsBarringCodes,
              BARRING_CODE_ORDERS.smsBarring
            )}
            showIfEmpty={<span>{t.UPE3(noRestrictionsMsg)}</span>}
          />
        )}
      </BarringGroup>
    )}
    {barringGroupAvailabilities.areDataBarringsAvailable && (
      <BarringGroup id="barring-services-data">
        <h5>{t.CU9Z(dataBarringMsg)}</h5>
        {details.selectedAddOns && (
          <CheckedList
            items={getChainedDisplayBarringNames(
              details.selectedAddOns,
              [...dataRoamingBarringCodes, barrings.DATAVARTIJA.code],
              BARRING_CODE_ORDERS.dataRoamingBarring,
              barrings.DATA_ALLOWED_EVERYWHERE.code
            )}
            showIfEmpty={<span>{t.UPE3(noRestrictionsMsg)}</span>}
          />
        )}
      </BarringGroup>
    )}
    {barringGroupAvailabilities.areRoamingBarringsAvailable && (
      <BarringGroup id="barring-services-roaming">
        <h5>{t.R805(useAbroadMsg)}</h5>
        {details.selectedAddOns && (
          <CheckedList
            items={getChainedDisplayBarringNames(
              details.selectedAddOns,
              roamingBarringCodes,
              BARRING_CODE_ORDERS.roamingBarring,
              barrings.ALLOWED_EVERYWHERE.code
            )}
            showIfEmpty={<span>{t.UPE3(noRestrictionsMsg)}</span>}
          />
        )}
      </BarringGroup>
    )}
    {barringGroupAvailabilities.areLocatingBarringsAvailable && (
      <BarringGroup id="barring-services-locating">
        <h5>{t.EGQX(phoneTrackingMsg)}</h5>
        {details.selectedAddOns && (
          <CheckedList
            items={details.selectedAddOns
              .filter(selectedAddon => selectedAddon.addOnCode === barrings.DENY_LOCATING.code)
              .map(selectedAddon => getAddOnBarringNameByAddOnGuid(selectedAddon.addOnCode))}
            showIfEmpty={<span>{t.UPE3(noRestrictionsMsg)}</span>}
          />
        )}
      </BarringGroup>
    )}
    {showEditButton && (
      <div className="of-barrings-content__actions">
        <CL.Button color="light" onClick={onEditWanted} disabled={disableEditing}>
          {t.NVPK(editMsg)}
        </CL.Button>
      </div>
    )}
  </div>
);
