import { useEffect } from 'react';

export const useEventListener = <K extends keyof WindowEventMap, T extends WindowEventMap[K]>(
  type: K,
  listener: (event: T) => void,
  opts: AddEventListenerOptions = {},
  target: EventTarget = window.self
) =>
  useEffect(() => {
    const ctrl = new AbortController();
    target.addEventListener(
      type,
      listener,
      (opts.signal = opts.signal ? AbortSignal.any([opts.signal, ctrl.signal]) : ctrl.signal) && opts
    );
    return () => ctrl.abort();
  }, [type, listener, opts, target]);
