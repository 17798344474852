import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import type { ReactNode } from 'react';

export interface CheckboxProps {
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  name: string;
  onChange?: (value: boolean) => void;
  required?: boolean;
  value?: string;
  preventDefault?: boolean;
}

const createArrayValue = (isChecked: boolean, fieldValue: string[], value: string) => {
  const set = new Set(fieldValue || []);
  isChecked ? set.add(value) : set.delete(value);
  return Array.from(set);
};

/**
 * Checkbox works in two ways:
 * when value is not given the output will be { keyName: boolean }
 * when value is given the output will be { keyName: Array<value, value, ...> }
 */
export const Checkbox = ({
  checked,
  children,
  className,
  disabled,
  error,
  label,
  name,
  onChange,
  required = false,
  value,
  preventDefault = false,
}: CheckboxProps) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: checked,
    rules: { required: { value: required, message: error || t.VPVR(fieldCantBeEmptyMsg) } },
  });

  return (
    <>
      <CL.Checkbox
        checked={value ? (field.value || []).includes(value) : field.value}
        className={[className, fieldState.error ? dsClass.COLOR_RED_600 : ''].filter(i => i).join(' ')}
        disabled={disabled}
        inputRef={field.ref}
        label={label}
        onChange={e => {
          const isChecked = e.currentTarget.checked;
          if (!preventDefault) {
            field.onChange(value ? createArrayValue(isChecked, field.value, value) : isChecked);
          }
          if (onChange) {
            onChange(isChecked);
          }
        }}
      >
        {children}
      </CL.Checkbox>
      {fieldState.error && <CL.InputError>{fieldState.error?.message}</CL.InputError>}
    </>
  );
};
