import { DialogWrapper } from '../DialogWrapper';
import { t } from '../../common/i18n';

interface PendingActionsForContactDialogProps {
  onCloseDialog: () => void;
}

export const PendingActionsForContactDialog = ({ onCloseDialog }: PendingActionsForContactDialogProps) => (
  <DialogWrapper closeable header={t.I9KT('Change order in progress')} onCloseDialog={onCloseDialog}>
    {t.I9DB(`You can't make new changes until the previous change request has been processed`)}
  </DialogWrapper>
);
