import { AddonDetails } from '../../../../../components/AddonDetails/AddonDetails.js';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { generatePath, useLoaderData, useLocation } from 'react-router-dom';
import { getSubscriptionBreadcrumbName, getSubscriptionUrlId } from '../../../../../common/utils/subscriptionUtils.js';
import { omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables.js';
import type { SubscriptionAddOnLoaderResponse } from '../../../../../common/loaders';

export const MobileSubAddOnPath = () => {
  const { search } = useLocation();
  const { addOn, pendingSubscriptionActions, subscription } = useLoaderData() as SubscriptionAddOnLoaderResponse;

  return (
    <AddonDetails
      addOn={addOn}
      breadCrumbs={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
            {
              name: getSubscriptionBreadcrumbName(subscription),
              path:
                generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) +
                search,
            },
            { name: addOn?.addOnProductName || '...' },
          ]}
        />
      }
      subscription={subscription}
      subscriptionActions={pendingSubscriptionActions}
      subscriptionPath={paths.PS_MOBILE_SUBSCRIPTION}
    />
  );
};
