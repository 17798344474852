import { EMAIL_REGEX } from '../../../../common/utils/emailUtils.js';
import { type GridColProps } from '@design-system/component-library';
import { HOURS_AND_MINUTES_REGEX } from '../../../../common/utils/validationUtils.js';
import {
  InputComponent,
  type InputComponentProps,
} from '../../../../common/react-hook-form/components/InputComponent.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { PHONE_NUMBER_REGEX } from '../../../../common/utils/phoneNumberUtils.js';
import { TextAreaComponent } from '../../../../common/react-hook-form/components/TextAreaComponent.js';
import {
  invalidIntegerMsg,
  invalidPhoneNumberMsg,
  invalidTimeMsg,
  invalidValueMsg,
  pleaseCheckEmailMsg,
  t,
} from '../../../../common/i18n/index.js';
import { setValueOptions } from '../../OpenFormHooks/useOpenFormMethods.js';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

const configuration = {
  email: {
    colWidth: 6,
    type: 'text',
    validate: (required: boolean) => (value: string) =>
      required || value ? (EMAIL_REGEX.test(value) ? undefined : t.ROMH(pleaseCheckEmailMsg)) : undefined,
  },
  field: {
    colWidth: 6,
    type: 'text',
    validate: (required: boolean) => (value: string) =>
      required || value ? (value.length ? undefined : t.DUPA(invalidValueMsg)) : undefined,
  },
  int: {
    colWidth: 6,
    type: 'number',
    validate: (required: boolean) => (value: string) =>
      required || value ? (/^(0|-?[1-9]\d*)$/.test(value) ? undefined : t.EL7N(invalidIntegerMsg)) : undefined,
  },
  tel: {
    colWidth: 6,
    type: 'text',
    validate: (required: boolean) => (value: string) =>
      required || value ? (PHONE_NUMBER_REGEX.test(value) ? undefined : t.YLCX(invalidPhoneNumberMsg)) : undefined,
  },
  text: {
    colWidth: 6,
    type: 'text',
    validate: (required: boolean) => (value: string) =>
      required || value ? (value.length ? undefined : t.DUPA(invalidValueMsg)) : undefined,
  },
  textarea: {
    colWidth: 9,
    type: 'text',
    validate: (required: boolean) => (value: string) =>
      required || value ? (value.length ? undefined : t.DUPA(invalidValueMsg)) : undefined,
  },
  time: {
    colWidth: 6,
    type: 'time',
    validate: (required: boolean) => (value: string) =>
      required || value ? (HOURS_AND_MINUTES_REGEX.test(value) ? undefined : t.SZBW(invalidTimeMsg)) : undefined,
  },
} as const;

export type OpenFormQuestionInputProps = Omit<InputComponentProps, 'type' | 'validate'> & {
  colWidth?: GridColProps['colWidth'];
  rows?: number;
  type: 'email' | 'field' | 'int' | 'tel' | 'text' | 'textarea' | 'time';
  validate?: InputComponentProps['validate'];
  wrapGridRow?: boolean;
};

export const OpenFormQuestionInput = ({
  error = field => field.error?.message,
  hint,
  name,
  wrapGridRow = true,
  ...props
}: OpenFormQuestionInputProps) => {
  const { clearErrors, getValues, setValue } = useFormContext();

  const defaults = configuration[props.type];
  const colWidth = props.colWidth ?? defaults.colWidth;
  const validate = props.validate ?? defaults.validate(props.required);

  useEffect(() => {
    const value = getValues(name);
    if (!value) {
      setValue(name, value, setValueOptions);
    }
    return () => clearErrors(name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const column = (
    <OpenFormGridCol
      colWidth={colWidth}
      className={classNames(`of-openform__view__input__${props.type}`, { ['label--mandatory']: props.required })}
    >
      {props.type === 'textarea' ? (
        <TextAreaComponent {...props} name={name} rows={props.rows ?? 6} validate={validate} />
      ) : (
        <InputComponent {...props} name={name} error={error} hint={hint} type={defaults.type} validate={validate} />
      )}
    </OpenFormGridCol>
  );
  return !wrapGridRow ? column : <OpenFormGridRow>{column}</OpenFormGridRow>;
};
