import * as CL from '@design-system/component-library';
import { closeNotification } from '../../selfservice/actions/notificationsActions.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

import './NotificationContainer.scss';

export const NotificationContainer = () => {
  const notifications = useSelector((state: State) => state.notifications, deepEqual);
  const dispatch = useDispatch();
  return (
    <div className="of-notification-container">
      {notifications &&
        notifications.items.map(notification => (
          <div className="of-notification" key={notification.id}>
            <CL.Notification
              hasCloseButton={notification.hasCloseButton}
              id={notification.id}
              onNotificationHide={() => {
                if (notification.id !== undefined) {
                  dispatch(closeNotification(notification.id));
                }
              }}
              text={notification.text}
              timer={10}
              type={notification.type}
              visible={true}
            />
          </div>
        ))}
    </div>
  );
};
