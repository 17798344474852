/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Lead { 
    /**
     * Request Id generated by the originating system.
     */
    onlineRequestId?: string;
    /**
     * Lead type. SFDC: Convertible to Lead.Source, Lead.RecordType, Lead.Description
     */
    leadType: Lead.LeadTypeEnum;
    /**
     * Form parameter object with free key value pairs
     */
    formParameters?: { [key: string]: string; };
    /**
     * Email acknowledgement is enough if the lead can be handled with supplied information
     */
    emailAcknowledgementOnly?: boolean;
    /**
     * Web URL where the lead has been generated
     */
    sourceUrl?: string;
    /**
     * Customer-given description field for the lead creation. Editable. SFDC: Lead.Description.
     */
    description?: string;
    /**
     * Unix timestamp of last modification. SFDC: Lead.LastModifiedDate.
     */
    lastModified?: number;
    /**
     * The Lead\'s area of interest within Elisa, captured with the created lead
     */
    interestArea?: Lead.InterestAreaEnum;
    /**
     * The Salesforce campaign which is associated within the lead creation
     */
    campaignId?: string;
    /**
     * Consent given for contacting. This field is need to be able to contact the lead by sales user. For contact forms, this should always be set to true
     */
    consentToContact?: boolean;
    /**
     * Consent given for marketing. This field is needed to be able to market to the lead
     */
    consentForMarketing?: boolean;
    /**
     * Traffic source Google Analytics (GA) which led to lead creation
     */
    gaSource?: string;
    /**
     * Marketing channel from GA, which led to lead creation
     */
    gaMedium?: Lead.GaMediumEnum;
    /**
     * Marketing campaign name from GA, which led to lead creation
     */
    gaCampaign?: string;
    /**
     * Content identifiers from GA, which led to lead creation
     */
    gaContent?: string;
    /**
     * Segment and term identifiers from GA, which led to lead creation
     */
    gaTerm?: string;
    /**
     * Client Id from GA, which was captured in the lead creation
     */
    gaClientId?: string;
    /**
     * Session Id from GA, which was captured in the lead creation
     */
    gaSessionId?: string;
}
export namespace Lead {
    export type LeadTypeEnum = 'DEFAULT' | 'ONLINE_CONTACT' | 'ONLINE_ORDER' | 'ONLINE_TRAINING';
    export const LeadTypeEnum = {
        DEFAULT: 'DEFAULT' as LeadTypeEnum,
        ONLINE_CONTACT: 'ONLINE_CONTACT' as LeadTypeEnum,
        ONLINE_ORDER: 'ONLINE_ORDER' as LeadTypeEnum,
        ONLINE_TRAINING: 'ONLINE_TRAINING' as LeadTypeEnum
    };
    export type InterestAreaEnum = 'CAMPAIGN_DEFINED_AREA' | 'CUSTOMER_SERVICE_SOLUTIONS' | 'DEVICES' | 'INTERNET_SUBSCRIPTIONS' | 'IT_SERVICES' | 'MOBILE_SUBSCRIPTIONS' | 'NETWORK_AND_INFORMATION_SECURITY_SERVICES' | 'OTHER' | 'PROFESSIONAL_SERVICES_TRAINING';
    export const InterestAreaEnum = {
        CAMPAIGN_DEFINED_AREA: 'CAMPAIGN_DEFINED_AREA' as InterestAreaEnum,
        CUSTOMER_SERVICE_SOLUTIONS: 'CUSTOMER_SERVICE_SOLUTIONS' as InterestAreaEnum,
        DEVICES: 'DEVICES' as InterestAreaEnum,
        INTERNET_SUBSCRIPTIONS: 'INTERNET_SUBSCRIPTIONS' as InterestAreaEnum,
        IT_SERVICES: 'IT_SERVICES' as InterestAreaEnum,
        MOBILE_SUBSCRIPTIONS: 'MOBILE_SUBSCRIPTIONS' as InterestAreaEnum,
        NETWORK_AND_INFORMATION_SECURITY_SERVICES: 'NETWORK_AND_INFORMATION_SECURITY_SERVICES' as InterestAreaEnum,
        OTHER: 'OTHER' as InterestAreaEnum,
        PROFESSIONAL_SERVICES_TRAINING: 'PROFESSIONAL_SERVICES_TRAINING' as InterestAreaEnum
    };
    export type GaMediumEnum = 'CPC,' | 'ORGANIC,' | 'REFERRER,' | 'EMAIL';
    export const GaMediumEnum = {
        CPC: 'CPC,' as GaMediumEnum,
        ORGANIC: 'ORGANIC,' as GaMediumEnum,
        REFERRER: 'REFERRER,' as GaMediumEnum,
        EMAIL: 'EMAIL' as GaMediumEnum
    };
}


