import { AuthorizationError } from '../../../../components/AuthorizationScene/AuthorizationError.js';
import { CommonErrorType } from '../../../../common/enums.js';
import { Spinner } from '../../../../public/site/siteUtils.js';
import {
  externalAuthenticationFailed,
  getAuthorizationRequest,
  identifyStrongly,
  processAuthorizationRequest,
  validateUserAuthority,
} from '../../../../selfservice/actions/index.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../common/constants/pathVariables.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const AuthenticationReturnPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const errors = useAuth().authorizationRequest?.errors;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const codeQueryParam: string | null = queryParams.get('code');
    const stateQueryParam: string | null = queryParams.get('state');
    const errorQueryParam: string | null = queryParams.get('error');

    if (stateQueryParam?.startsWith('businessId') && stateQueryParam?.includes(':')) {
      if (codeQueryParam) {
        // Redirect to /rekisteroidy?redirectPath=/yhteystiedot
        // with /tunnistaudu/paluu?code=authCode&state=businessId:2698181-2:redirectPath:/yhteystiedot
        const queryState: string[] = stateQueryParam.split(':');
        dispatch(validateUserAuthority(codeQueryParam, queryState[1], queryState[3]));
      } else {
        // Redirect to /rekisteroidy with /tunnistaudu/paluu?state=businessId:2698181-2
        navigate(paths.REGISTER_PAGE, { replace: true });
        dispatch(externalAuthenticationFailed({ message: '', type: CommonErrorType.BAD_REQUEST }));
      }
    } else if (stateQueryParam?.startsWith('punchout')) {
      if (codeQueryParam) {
        // Redirect to /punchout/kassa with /tunnistaudu/paluu?code=idServiceCode&state=punchout
        dispatch(identifyStrongly({ idServiceCode: codeQueryParam }, stateQueryParam));
      } else {
        // Redirect to /punchout/kassa with /tunnistaudu/paluu?state=punchout
        navigate(paths.PUNCHOUT_CHECKOUT, { replace: true });
        dispatch(externalAuthenticationFailed({ message: '', type: CommonErrorType.BAD_REQUEST }));
      }
    } else if ((stateQueryParam && !codeQueryParam && !errorQueryParam) || (errorQueryParam && !stateQueryParam)) {
      // Redirect to /404 with /tunnistaudu/paluu?state=foobar or /tunnistaudu/paluu?error=foobar
      navigate(paths.NOT_FOUND_404);
    } else if (stateQueryParam) {
      if (errorQueryParam) {
        // Redirect to /tunnistaudu/vahvista-numeronsiirto/c49b856b-d1f8-7bda-ab7f-24e408104a83
        // with /tunnistaudu/paluu?state=c49b856b-d1f8-7bda-ab7f-24e408104a83&error=error
        // or /tunnistaudu/paluu?state=c49b856b-d1f8-7bda-ab7f-24e408104a83&error=error&error_description=cancelled
        const errorDescriptionQueryParam: string | null = queryParams.get('error_description');
        dispatch(getAuthorizationRequest(stateQueryParam, errorDescriptionQueryParam !== 'cancelled'));
        navigate(generatePath(paths.AUTHORIZE_NUMBER_PORTING, { token: stateQueryParam }), { replace: true });
      }
      if (codeQueryParam) {
        // Redirect to /tunnistaudu/vahvistettu-numeronsiirto/c49b856b-d1f8-7bda-ab7f-24e408104a83
        // with /tunnistaudu/paluu?code=foobar&state=c49b856b-d1f8-7bda-ab7f-24e408104a83
        dispatch(processAuthorizationRequest(stateQueryParam, codeQueryParam));
      }
    }
  }, [dispatch, navigate, search]);

  return errors ? <AuthorizationError errors={errors} /> : <Spinner />;
};
