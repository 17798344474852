import { AutoComplete } from '../../../../components/AutoComplete/AutoComplete.js';
import { Field, useFormikContext } from 'formik';
import { Loading } from '../../../../components/Loading/index.js';
import { SourceSystem } from '../../../../generated/api/sourceSystem.js';
import { billingAccountMsg, loadingMsg, noSearchResults, noSelectionMsg, t } from '../../../i18n/index.js';
import { fetchBillingAccounts } from '../../../fetch.js';
import { getBillingAccountListItems } from '../../../../components/AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { useEffect, useState } from 'react';
import type { BasicAutoCompleteItem } from '../../../../components/AutoComplete/AutoComplete.js';
import type { BillingAccountSearchResponse } from '../../../../generated/api/billingAccountSearchResponse.js';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { CustomerOrderDetailsFormValues } from '../../../../components/CustomerOrderDetails/CustomerOrderDetails.js';
import type { FieldInputProps, FormikComputedProps, FormikHelpers, FormikState, FormikValues } from 'formik';

interface BillingAccountAutoCompleteProps {
  defaultOption: BasicAutoCompleteItem;
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>;
  onInputChange: (e: ChangeEvent | KeyboardEvent | MouseEvent, item: BasicAutoCompleteItem) => void;
  options: BasicAutoCompleteItem[];
  fetchFn: (search: string) => Promise<BasicAutoCompleteItem[]>;
}

interface BillingAccountDropDownParams {
  approverMustSelectBillingAccount?: boolean;
}

const BillingAccountAutoComplete = ({
  defaultOption,
  fetchFn,
  field,
  options,
  ...autoCompleteProps
}: BillingAccountAutoCompleteProps) => {
  const value = options.find(o => o.value === field.value) ?? defaultOption;
  return (
    <div>
      <AutoComplete<BasicAutoCompleteItem>
        {...autoCompleteProps}
        async={{
          fetchFn: fetchFn,
          loadingMsg: t.KW12(loadingMsg),
          noResultsMsg: t.L7CZ(noSearchResults),
        }}
        config={{
          isSortable: false,
          isClearable: false,
        }}
        defaultOption={value}
        getDisplayHtml={(i: BasicAutoCompleteItem) => i.html}
        getDisplayValue={(i: BasicAutoCompleteItem) => i.label}
        getUniqueId={(i: BasicAutoCompleteItem) => i.value}
        id="billingAccountId"
        placeholder={t.QRYV('Choose')}
        label={t.IFT9(billingAccountMsg)}
        noOptionsMsg={t.ZW5W(noSelectionMsg)}
        onInputBlur={field.onBlur}
        options={options}
      />
    </div>
  );
};

export const BillingAccountDropDown = ({ approverMustSelectBillingAccount }: BillingAccountDropDownParams) => {
  const { setFieldValue } = useFormikContext<CustomerOrderDetailsFormValues>();

  const [currentBillingAccounts, setCurrentBillingAccounts] = useState<BillingAccountSearchResponse[]>();

  const searchBillingAccounts = async (search: string) => {
    const bas = (await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC, search }))
      .searchResults;
    return getBillingAccountListItems(false, bas, search);
  };

  useEffect(() => {
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }).then(bs => {
      const searchResults = bs.searchResults || [];
      setCurrentBillingAccounts(searchResults);
    });
  }, []);

  const onChangeBillingAccount = (_e: ChangeEvent | KeyboardEvent | MouseEvent, item: BasicAutoCompleteItem) => {
    const selected = item.value;
    if (selected) {
      setFieldValue('billingAccountId', selected);
    }
  };

  const billingAccountItems = getBillingAccountListItems(false, currentBillingAccounts);

  return !currentBillingAccounts ? (
    <Loading />
  ) : (
    <Field
      component={BillingAccountAutoComplete}
      defaultOption={approverMustSelectBillingAccount ? undefined : billingAccountItems[0]}
      fetchFn={searchBillingAccounts}
      name="billingAccountId"
      onInputChange={onChangeBillingAccount}
      options={billingAccountItems}
    />
  );
};
