import { ReissunettiAndReissaajaInfo } from './ReissunettiAndReissaajaInfo';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getListName } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import { isFiveGPlusOffer } from '../../common/utils/subscriptionUtils.js';
import { t } from '../../common/i18n/index.js';
import type { ModelType as OnlineModelType } from '../../common/enums.js';
import type {
  SubCardWithAnalyticsV4Props,
  SubCardWithImpressionProps,
} from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

interface VoiceSubscriptionCardsProps {
  subscriptionCards: (SubscriptionCardProps | SubCardWithAnalyticsV4Props | SubCardWithImpressionProps)[];
  onlineModelType: OnlineModelType;
  handleSubscriptionCardSelection: (subscriptionCard: SubCardWithImpressionProps) => void;
}

export const VoiceSubscriptionCards = ({
  subscriptionCards,
  onlineModelType,
  handleSubscriptionCardSelection,
}: VoiceSubscriptionCardsProps) => {
  const fiveGPlusCards = subscriptionCards.filter(sub => isFiveGPlusOffer(sub.selectedOffer));
  const fiveGCards = subscriptionCards.filter(sub => !isFiveGPlusOffer(sub.selectedOffer));
  return (
    <>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <SubscriptionCards
          buttonText={fiveGPlusCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={fiveGPlusCards}
        />
      </div>
      <div>
        <ReissunettiAndReissaajaInfo />
        <div className={`of-order-subscription-selection--cards ${dsClass.MARGIN_TOP_2} ${dsClass.TEXT_S}`}>
          ** {t.U7MJ('With optimised voice, you will only pay for what you use. Limits in voice billing: ')}
          {/* Zero-width spaces so this can be broken into multiple lines */}
          <span>100/&#8203;500/&#8203;1000/&#8203;3500</span>
          {t.U7MK(' minutes.')}
        </div>
      </div>
      <h3 className={`${dsClass.PADDING_TOP_3} of-order-subscription-selection__choose-subscription-header`}>
        {t.P6NV('Select a 5G subscription')}
      </h3>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <SubscriptionCards
          buttonText={fiveGCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={fiveGCards}
        />
      </div>
    </>
  );
};
