import { PopoverContent } from './CommercialProductContent.js';
import { t } from '../../../common/i18n/index.js';

export const YritysWifiPopoverContent = () => (
  <PopoverContent>
    <div>
      {t.E9LB(
        'Enjoy an even better connection at work with the Elisa YritysWifi Lite service! The included WiFi boosters ensure that the internet works across all rooms and floors.'
      )}
    </div>
  </PopoverContent>
);
