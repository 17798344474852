import { MobileBroadbandSubscriptionCards } from './MobileBroadbandSubscriptionCards';
import { ModelType } from '../../common/enums.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { VoiceSubscriptionCards } from './VoiceSubscriptionCards';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getListName } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import type { ModelType as OnlineModelType } from '../../common/enums.js';
import type {
  SubCardWithAnalyticsV4Props,
  SubCardWithImpressionProps,
} from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

export interface SubscriptionCardsByModelTypeProps {
  subscriptionCards: (SubscriptionCardProps | SubCardWithAnalyticsV4Props | SubCardWithImpressionProps)[];
  onlineModelType: OnlineModelType;
  handleSubscriptionCardSelection: (subscriptionCard: SubCardWithImpressionProps) => void;
  isChangeOffer?: boolean;
}

export const SubscriptionCardsByModelType = ({
  subscriptionCards,
  onlineModelType,
  handleSubscriptionCardSelection,
  isChangeOffer = false,
}: SubscriptionCardsByModelTypeProps) => {
  switch (onlineModelType) {
    case ModelType.VoiceSME:
      return (
        <VoiceSubscriptionCards
          subscriptionCards={subscriptionCards}
          onlineModelType={onlineModelType}
          handleSubscriptionCardSelection={handleSubscriptionCardSelection}
        />
      );
    case ModelType.MobileBroadband:
      return (
        <MobileBroadbandSubscriptionCards
          subscriptionCards={subscriptionCards}
          onlineModelType={onlineModelType}
          handleSubscriptionCardSelection={handleSubscriptionCardSelection}
          isChangeOffer={isChangeOffer}
        />
      );

    default:
      return (
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
          <SubscriptionCards
            buttonText={subscriptionCards[0]?.buttonText} // Button text is same in all cards
            listName={getListName(onlineModelType)}
            onButtonClick={subscriptionCard =>
              'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
            }
            subscriptions={subscriptionCards}
          />
        </div>
      );
  }
};
