import {
  SupportedModelCategories,
  SupportedModelCategory,
  toDisplayableProduct,
} from '../../common/utils/catalogUtils.js';
import {
  accessoriesMsg,
  computersMsg,
  networkingHardwareMsg,
  phonesMsg,
  t,
  tabletsMsg,
} from '../../common/i18n/index.js';
import { filterFn } from '../ProductGrid/ProductGridFilter.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getPeriodicPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { isDefined } from '../../common/utils/objectUtils.js';
import { searchFn } from '../ProductGrid/ProductGridSearch.js';
import { sortFn } from '../ProductGrid/ProductGridSort.js';
import type { Catalog } from '../../generated/api/catalog.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CatalogProducts } from '../../common/loaders.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { Filters } from '../ProductGrid/ProductGridFilter.js';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';
import type { SortType } from '../ProductGrid/ProductGridSort.js';

const getDisplayProducts = (headers: OnlineModelHeader[], discountedPrices: DiscountedPrices[], catalog: Catalog) =>
  headers.map(model => toDisplayableProduct(model, catalog, discountedPrices || [], false, false)).filter(isDefined);

export const mapLoaderDataToCatalogProducts = (
  loaderData: CatalogProducts,
  catalog: Catalog,
  discountedPrices: DiscountedPrices[]
) => {
  const products: Record<string, CatalogProduct[]> = {};

  products[SupportedModelCategory.COMPUTERS] = getDisplayProducts(
    loaderData.computers.headers || [],
    discountedPrices,
    catalog
  );

  products[SupportedModelCategory.ACCESSORIES] = getDisplayProducts(
    loaderData.accessories.headers || [],
    discountedPrices,
    catalog
  );

  products[SupportedModelCategory.TABLET] = getDisplayProducts(
    loaderData.tablets.headers || [],
    discountedPrices,
    catalog
  );

  products[SupportedModelCategory.PHONE] = getDisplayProducts(
    loaderData.phones.headers || [],
    discountedPrices,
    catalog
  );

  products[SupportedModelCategory.NETWORK_EQUIPMENT] = getDisplayProducts(
    loaderData.networkEquipment.headers || [],
    discountedPrices,
    catalog
  );

  return products;
};

export const getProductCategoryDetails = (category: SupportedModelCategory) => {
  switch (category) {
    case SupportedModelCategory.PHONE:
      return {
        displayText: t.E9IF(phonesMsg),
        showAdditionalInfo: true,
        iconClass: 'phone',
      };
    case SupportedModelCategory.ACCESSORIES:
      return {
        displayText: t.J954(accessoriesMsg),
        showAdditionalInfo: false,
        iconClass: 'headphones',
      };
    case SupportedModelCategory.COMPUTERS:
      return {
        displayText: t.MCW3(computersMsg),
        showAdditionalInfo: false,
        iconClass: 'laptop',
      };
    case SupportedModelCategory.NETWORK_EQUIPMENT:
      return {
        displayText: t.DMM5(networkingHardwareMsg),
        showAdditionalInfo: false,
        iconClass: 'network',
      };
    case SupportedModelCategory.TABLET:
      return {
        displayText: t.XS42(tabletsMsg),
        showAdditionalInfo: false,
        iconClass: 'tablet',
      };
  }
};

// prices can be either oneTime or monthlyRecurring at a time
export const getStartingPrice = (product: CatalogProduct): string =>
  formatSum(product.oneTimeCharge) || `Alk. ${getPeriodicPriceAsText(product.monthlyRecurringCharge)}`;

export const sortAndFilterProducts = (
  searchString: string,
  catalogProducts: Record<string, CatalogProduct[]>,
  sortType: SortType,
  selectedFilters: Record<string, Filters>
) => {
  const newProducts: Record<string, CatalogProduct[]> = {};
  SupportedModelCategories.forEach(cat => {
    const filteredItems = catalogProducts[cat]
      .filter(searchFn(searchString))
      .filter(filterFn(selectedFilters[cat]))
      .sort(sortFn(sortType));
    newProducts[cat] = [...filteredItems];
  });
  return newProducts;
};

export const getSelectedCatalogProducts = (
  catalogProducts: Record<string, CatalogProduct[]>,
  selectedProductCodes: string[]
) => {
  const selectedProducts: Record<string, CatalogProduct[]> = {};
  SupportedModelCategories.forEach(cat => {
    const productsForCatalog = catalogProducts[cat]
      .filter(product => selectedProductCodes.includes(product.code))
      .map(i => i);
    if (productsForCatalog.length > 0) {
      selectedProducts[cat] = productsForCatalog;
    }
  });
  return selectedProducts;
};
