import type { ItemsQuery } from './store.js';
import type {
  LoadCustomerOrdersAction,
  LoadEmployeeCustomerOrdersAction,
  LoadEmployeeSubscriptionActions,
  LoadSubscriptionActions,
  LoadSubscriptionsAction,
} from '../actions/index.js';

export const loadCustomerOrdersUseSearchService = (action: LoadCustomerOrdersAction): boolean => !action.displayId;

export const loadSubscriptionsUseSearchService = (action: LoadSubscriptionsAction): boolean =>
  (Boolean(action.listView) || Boolean(action.reporting)) && !action.displayId;

export const loadSubscriptionActionsUseSearchService = (
  action: LoadSubscriptionActions | LoadEmployeeSubscriptionActions
) => !action.displayId;

export const updateCustomerOrdersSearchQuery = (query: ItemsQuery, status?: string): ItemsQuery => {
  const currentStatus = status ?? query.status;

  return {
    ...query,
    status: currentStatus === 'ALL' ? undefined : currentStatus,
  };
};

export const loadEmployeeCustomerOrdersUseSearchService = (action: LoadEmployeeCustomerOrdersAction): boolean =>
  !action.displayId;
