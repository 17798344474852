import { ModelType } from '../../common/enums';
import { t } from '../../common/i18n';

import './SubscriptionSelectionHeader.scss';

const VoiceHeader = () => (
  <>
    <h3>{t.GG1D('Select a 5G+ voice subscription')}</h3>
    <h4>{t.D61P('More consistent connections. Up to 20% better battery life of the mobile device.')}</h4>
  </>
);

export const SubscriptionSelectionHeader = ({
  modelType,
  groupHeader,
}: {
  modelType: ModelType;
  groupHeader?: string;
}) => {
  switch (modelType) {
    case ModelType.VoiceSME: {
      return (
        <div className="subscription-selection-header">
          <VoiceHeader />
        </div>
      );
    }
    case ModelType.MobileM2M: {
      return (
        <div className="subscription-selection-header">
          <h3>{groupHeader}</h3>
        </div>
      );
    }
    default:
      return null;
  }
};
