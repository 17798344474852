import { analyticsEpic } from './analyticsEpic.js';
import { anonymousUserEpic } from './anonymousUserEpic.js';
import { authenticatedUserEpic } from './authenticatedUserEpic.js';
import { authorizationRequestEpic } from './authorizationRequestEpic.js';
import { auxiliaryEsimOrderEpic } from './auxiliaryEsimOrderEpic.js';
import { billChannelEpic } from './billChannelEpic.js';
import { billingAccountEpic } from './billingAccountEpic.js';
import { broadbandFixerEpic } from './broadbandFixerEpic.js';
import { catalogsEpic } from './catalogsEpic.js';
import { combineEpics } from 'redux-observable';
import { companyInfoEpic } from './companyInfoEpic.js';
import { contactEpic } from './contactEpic.js';
import { customerOrderEpic } from './customerOrderEpic.js';
import { deviceCheckoutEpic } from './deviceCheckoutEpic.js';
import { elisaIdEpic } from './elisaIdEpic.js';
import { employeeOnboardingEpic } from './employeeOnboardingEpic.js';
import { employeeSubscriptionPairingEpic } from './employeeSubscriptionPairingEpic.js';
import { employeeSubscriptionUsageEpic } from './employeeSubscriptionUsageEpic.js';
import { externalAuthenticationEpic } from './externalAuthenticationEpic.js';
import { fixedBroadbandOrderEpic } from './fixedBroadbandOrderEpic.js';
import { invoiceEpic } from './invoiceEpic.js';
import { leadEpic } from './leadEpic.js';
import { mobileFixerEpic } from './mobileFixerEpic.js';
import { myselfChangesEpic } from './myselfChangesEpic.js';
import { onlineModelsEpic } from './onlineModelsEpic.js';
import { onlineOrdersCardPaymentEpic } from './onlineOrdersCardPaymentEpic.js';
import { onlineOrdersEpic } from './onlineOrdersEpic.js';
import { paymentsEpic } from './paymentsEpic.js';
import { pickupPointSearchEpic } from './pickupPointSearchEpic.js';
import { punchoutEpic } from './punchoutEpic.js';
import { resourcesEpic } from './resourcesEpic.js';
import { selectUsernameEpic } from './selectUsernameEpic.js';
import { shoppingCartEpic } from './shoppingCartEpic.js';
import { subscriptionActionEpic } from './subscriptionActionEpic.js';
import { subscriptionAddOnRulesEpic } from './subscriptionAddOnRulesEpic.js';
import { subscriptionEpic } from './subscriptionEpic.js';
import { supportCaseEpic } from './supportCaseEpic.js';
import type { Action } from 'redux';
import type { Epic } from 'redux-observable';
import type { EpicDependencies } from './epicUtils.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

export const selfserviceEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  analyticsEpic,
  auxiliaryEsimOrderEpic,
  billChannelEpic,
  billingAccountEpic,
  broadbandFixerEpic,
  mobileFixerEpic,
  catalogsEpic,
  companyInfoEpic,
  contactEpic,
  customerOrderEpic,
  employeeOnboardingEpic,
  fixedBroadbandOrderEpic,
  invoiceEpic,
  leadEpic,
  myselfChangesEpic,
  onlineModelsEpic,
  onlineOrdersEpic,
  onlineOrdersCardPaymentEpic,
  paymentsEpic,
  pickupPointSearchEpic,
  punchoutEpic,
  selectUsernameEpic,
  shoppingCartEpic,
  subscriptionEpic,
  subscriptionActionEpic,
  subscriptionAddOnRulesEpic,
  supportCaseEpic
);

export const userEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  anonymousUserEpic,
  authenticatedUserEpic
);

export const employeeEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  employeeSubscriptionPairingEpic,
  elisaIdEpic,
  employeeSubscriptionUsageEpic
);

export const deviceCheckoutEpics: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(deviceCheckoutEpic);

export const authenticationEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  externalAuthenticationEpic,
  authorizationRequestEpic
);

export { resourcesEpic };

export { paymentsEpic };

export { isAuxiliaryEsimOrderPage, isNewDeviceCheckout } from './epicUtils.js';
