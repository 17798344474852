import { AutoComplete } from '../AutoComplete/AutoComplete.js';
import { companySelectMsg, noSelectionMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type * as CLT from '@design-system/component-library';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { ConfigurationObject, ExternalInputRef } from '../AutoComplete/config.js';

export interface CompanySelectorProps {
  id?: string;
  autoCompleteConfig?: ConfigurationObject;
  userAccounts: CLT.HeaderUserAccount[];
  onInputChange: (event: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<CLT.HeaderUserAccount>) => void;
  inputRef?: ExternalInputRef;
  labelText?: string;
  className?: string;
  initialMdmId?: string;
  onOpen?: () => void;
  disabled?: boolean;
}

export const CompanySelector = (props: CompanySelectorProps) => {
  const {
    autoCompleteConfig,
    id,
    inputRef,
    userAccounts,
    onInputChange,
    labelText,
    className,
    initialMdmId,
    onOpen,
    disabled,
  } = props;

  const ReadOnlyContent = ({ name }: { name: string }) => {
    return <p className={dsClass.COLOR_BLUE_800}>{name}</p>;
  };

  return (
    <div className="auto-complete-container">
      <AutoComplete<CLT.HeaderUserAccount>
        id={id || 'companySelector'}
        className={className}
        config={{ ...autoCompleteConfig, isClearable: false }}
        disabled={disabled}
        getDisplayValue={({ name }) => name}
        getUniqueId={({ accountMasterId }) => accountMasterId || ''}
        inputRef={inputRef}
        label={labelText}
        noOptionsMsg={t.ZW5W(noSelectionMsg)}
        placeholder={t.P3C8(companySelectMsg)}
        onInputChange={onInputChange}
        options={userAccounts}
        defaultOption={
          (initialMdmId && userAccounts.find(a => a.accountMasterId === initialMdmId)) ||
          userAccounts.find(({ active }) => active)
        }
        readOnlyContent={userAccounts.length === 1 ? ReadOnlyContent : undefined}
        onOpen={onOpen}
      />
    </div>
  );
};
