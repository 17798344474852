import * as CL from '@design-system/component-library';
import { Modal } from '../Modal/Modal';
import { ModalButtons } from '../Modal/ModalButtons';
import {
  cancelMsg,
  confirmRemoveMsg,
  removePalvelunumerotInDetailMsg,
  removePalvelunumerotMsg,
  t,
} from '../../common/i18n';
import { cancelOmalasku } from '../../common/fetch';
import { omalaskuOrderFailedToSubmit, omalaskuOrderSubmitted } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import { useOmalasku } from './Omalasku';
import { useState } from 'react';

interface OmalaskuCancellationModalProps {
  msisdn: string;
  onClose: () => void;
}

export const OmalaskuCancellationModal = ({ msisdn, onClose }: OmalaskuCancellationModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { resetOmalaskuStatus } = useOmalasku();
  // For showing the notification on top of page which still  uses redux, should be removed once notification logic is out of redux
  const dispatch = useDispatch();

  const removeOmalasku = () => {
    setSubmitting(true);
    cancelOmalasku(msisdn)
      .then(() => {
        onClose();
        resetOmalaskuStatus();
        dispatch(omalaskuOrderSubmitted());
      })
      .catch(() => {
        dispatch(omalaskuOrderFailedToSubmit());
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal closeable onClose={onClose} size="s" heading={t.IVHU(removePalvelunumerotMsg)}>
      {t.U3B8(removePalvelunumerotInDetailMsg)}
      <ModalButtons>
        <CL.Button onClick={onClose} size="m" color="link">
          {t.B2V1(cancelMsg)}
        </CL.Button>
        <CL.Button onClick={removeOmalasku} size="m" loading={submitting}>
          {t.GMNB(confirmRemoveMsg)}
        </CL.Button>
      </ModalButtons>
    </Modal>
  );
};
