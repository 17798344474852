import * as CL from '@design-system/component-library';
import { type Context } from '../../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { Table } from '../../OpenFormHooks/useTable.js';
import { getValue } from '../../OpenFormUtils.js';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';

export const OpenFormQuestionSelectGroup = ({
  dispatcher,
  choices,
  context,
  available,
  disabled,
  required,
  multiselect,
  label,
  name,
}: {
  dispatcher: OpenFormDispatcher;
  choices: OpenFormChoice[];
  context: Context | undefined;
  available: (guid: string) => boolean;
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  label: string;
  name: string;
}) => {
  const inactive = useCallback((guid: string) => disabled || !available(guid), [available, disabled]);
  const selectedValue = getValue(context?.choices);
  const sorted = useMemo(
    () =>
      choices
        .map(({ guid, values }) => ({ availability: available(guid), guid, values }))
        .sort(Table.compare('availability', 'desc')),
    [choices, available]
  );

  return !sorted.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <CL.SelectGroup
          key={name + selectedValue}
          label={label}
          multiselect={multiselect}
          onChange={v => dispatcher.setChoices(multiselect && Array.isArray(v) && v.includes('[]') ? undefined : v)}
          onClick={e =>
            !multiselect &&
            !inactive(e.target.defaultValue) &&
            e.target.defaultValue === selectedValue &&
            dispatcher.setChoices(undefined)
          }
          products={sorted.map(({ availability, guid, values }) => ({
            active: !!context?.choices?.includes(guid),
            availability: availability,
            disabled: inactive(guid),
            id: guid,
            name: getValue(values),
          }))}
          type="column"
          value={multiselect ? context?.choices : selectedValue}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
