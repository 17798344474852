import { ContactDetails } from '../../../../../components/ContactDetails/ContactDetails.js';
import { useLoaderData, useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { ContactLoaderData } from '../../../../../common/loaders';
import type { ContactMasterId } from '../../../../../common/constants/pathInterfaces';

export const CompanyInfoContactPath = () => {
  const {
    billingAccounts,
    broadbandSubscriptions,
    contact,
    pendingActionsResponse,
    deviceSubscriptions,
    serviceSubscriptions,
    voiceSubscriptions,
  } = useLoaderData() as ContactLoaderData;
  const { contactMasterId } = useParams<ContactMasterId>();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();

  return (
    <ContactDetails
      billingAccounts={billingAccounts!}
      broadbandSubscriptions={broadbandSubscriptions}
      deviceSubscriptions={deviceSubscriptions}
      contact={contact}
      contactMasterId={contactMasterId!}
      mdmId={mdmId!}
      pendingActionsResponse={pendingActionsResponse}
      serviceSubscriptions={serviceSubscriptions}
      voiceSubscriptions={voiceSubscriptions}
    />
  );
};
