import { loadingBillingChannelsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillChannel } from '../../../generated/api/billChannel.js';

export const useOpenFormBillChannels = () => {
  const [data, setData] = useState<BillChannel[] | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = async
      .fetchBillChannels()
      .settled(setData)
      .failed(text => async.actions.notification({ text, type: 'warning' }))
      .cache()
      .execute(t.F514(loadingBillingChannelsMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
