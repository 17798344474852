import { type AuthProps } from '../../../public/site/AuthProvider.js';
import { AuthenticatedUserRole } from '../../../generated/api/authenticatedUserRole.js';
import { IdentityProvider } from '../../../generated/api/identityProvider.js';
import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormLayoutOutlet } from './OpenFormLayoutOutlet.js';
import { authenticatingMsg, authenticationFailedMsg, t } from '../../../common/i18n/index.js';
import { useMemo, useState } from 'react';
import { useOpenFormSearchParams } from '../OpenFormProvider.js';

export const OpenFormLayoutCanvas = ({ auth }: { auth: AuthProps }) => {
  const search = useOpenFormSearchParams();
  const [fail] = useState(search.has('retry'));
  const authenticated = useMemo(
    () =>
      IdentityProvider.SALESFORCE === auth.authenticatedUser?.identityProvider &&
      [AuthenticatedUserRole.ADMIN, AuthenticatedUserRole.KEY_USER].includes(auth.authenticatedUser.userRole!),
    [auth]
  );
  switch (true) {
    case auth.isLoading:
      return <OpenFormBar indicator={t.TA3J(authenticatingMsg)} />;
    case authenticated:
      return <OpenFormLayoutOutlet />;
    case fail:
      return <OpenFormBar error={t.J9TT(authenticationFailedMsg)} />;
    default:
      search.set('retry', String(Date.now()));
      window.location.assign('?' + search.toString());
      return null;
  }
};
