import * as CL from '@design-system/component-library';
import { Catalog } from '../../generated/api/models.js';
import { DialogType } from '../../common/enums.js';
import { LOCATION_TYPE_REPLACE } from '../VirtualCatalogReplace/VirtualCatalogReplaceUtils.js';
import { addOrRemoveDevicesMsg, editDataMsg, removeDeviceListMsg, t } from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getCopyVersionOfCatalog } from '../../common/utils/catalogUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { VirtualCatalog } from '../../generated/api/models.js';

import './VirtualCatalogManageButtonGroup.scss';

export interface VirtualCatalogManageButtonGroupProps {
  catalog: Catalog;
  virtualCatalog: VirtualCatalog;
  onShowDialog: (params: DialogParams) => void;
  onAddMoreProducts: () => void;
  replaceCatalogs: boolean;
  accountMasterId?: string;
}

export const VirtualCatalogManageButtonGroup = ({
  catalog,
  virtualCatalog,
  onShowDialog,
  onAddMoreProducts,
  replaceCatalogs,
  accountMasterId,
}: VirtualCatalogManageButtonGroupProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="of-details-wrapper__action">
      <CL.Button
        color="link"
        onClick={() => {
          onShowDialog({
            accountMasterId: accountMasterId,
            catalogName: catalog.name,
            type: DialogType.DELETE_CATALOG_DIALOG,
            virtualCatalogCode: virtualCatalog.virtualCatalogCode,
          });
        }}
        size="l"
      >
        <CL.Icon icon="delete" />
        {t.XRLY(removeDeviceListMsg)}
      </CL.Button>
      <CL.Button
        color="link"
        onClick={() => {
          navigate(`${paths.COMPANY_INFO_CATALOG_LIST_EDIT}?mdmId=${accountMasterId}`, {
            state: { virtualCatalog, mdmId: accountMasterId },
          });
        }}
        size="l"
      >
        <CL.Icon icon="edit" />
        {t.KU6Y(editDataMsg)}
      </CL.Button>
      <CL.Button color="link" onClick={onAddMoreProducts} size="l">
        <CL.Icon icon="phone" />
        {t.W5JG(addOrRemoveDevicesMsg)}
      </CL.Button>
      <CL.Button
        color="link"
        onClick={() => {
          const catalogToCopy = getCopyVersionOfCatalog(catalog);
          onShowDialog({
            catalog: catalogToCopy,
            type: DialogType.COPY_CATALOG_MULTI_BUSINESS,
          });
        }}
        size="l"
      >
        <CL.Icon icon="draft" />
        {t.OIG7('Copy device list')}
      </CL.Button>

      {replaceCatalogs && (
        <div className="of-details-wrapper__action__replace">
          <CL.Button
            color="link"
            onClick={() => {
              navigate(
                `${generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: virtualCatalog.virtualCatalogCode })}?mdmId=${accountMasterId}`,
                {
                  state: {
                    type: LOCATION_TYPE_REPLACE,
                  },
                }
              );
            }}
            disabled={catalog.productType === Catalog.ProductTypeEnum.RECURRING}
            size="l"
          >
            <CL.Icon icon="update" />
            {t.EMZV('Copy the product selection of the device list')}
          </CL.Button>

          {catalog.productType === Catalog.ProductTypeEnum.RECURRING && (
            <CL.Tooltip
              triggerElement={<CL.Icon className="custom-icon" color="neutral-500--light" icon="information" />}
              placement="top"
              i18n_tooltip_contentText={t.WWEG(
                'Copying the product selection is not possible for monthly fee device lists. Update the device lists separately or use the Copy device list -option.'
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};
