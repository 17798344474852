import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses';

interface LinkProps {
  rel?: string;
  target?: string;
}

interface I18nTextLinkEmbeddedProps {
  translation: string;
  linkHref?: string;
  linkTo?: string;
  className?: string;
  linkProps?: LinkProps;
}

interface EmbeddedLinkProps {
  linkText: string;
  linkHref?: string;
  linkTo?: string;
  className?: string;
  linkProps?: LinkProps;
}

// linkHref uses Desing System link and linkTo uses RRD Link
const EmbeddedLink = ({ linkText, linkHref, linkTo, className = dsClass.MARGIN_0, linkProps }: EmbeddedLinkProps) => {
  if (!linkHref && !linkTo) {
    throw Error('either linkHref or linkTo should be defined');
  }

  if (linkHref) {
    const target = linkProps?.target as '_blank' | '_self' | '_parent' | '_top';
    return (
      <CL.Link linkHref={linkHref} className={className} linkTarget={target} {...linkProps}>
        {linkText}
      </CL.Link>
    );
  }

  if (linkTo) {
    return (
      <Link to={linkTo} className={className} {...linkProps}>
        {linkText}
      </Link>
    );
  }

  return null;
};

// supports a single link embedded in a translation text e.g. "this is a translation text [and this is a link] inside the text."
export const I18nTextLinkEmbedded = ({
  translation,
  linkHref,
  linkTo,
  className,
  linkProps,
}: I18nTextLinkEmbeddedProps): JSX.Element => {
  const rawLinkText = translation.match(/\[.*\]/)?.join(); // get [Content] from translation
  const body = translation.split(rawLinkText!); // linkText should not be undefined
  const linkText = rawLinkText!.replace(/\[|\]/g, ''); // remove [ ] letters from the string

  return (
    <>
      {body?.[0]}
      <EmbeddedLink
        className={className}
        linkText={linkText}
        linkHref={linkHref}
        linkTo={linkTo}
        linkProps={linkProps}
      />
      {body?.[1]}
    </>
  );
};
