import * as CL from '@design-system/component-library';
import { Icon } from '../Icon/Icon.js';
import { Link, generatePath } from 'react-router-dom';
import {
  customerMsg,
  monthlyChargesMsg,
  oneTimePaymentMsg,
  orderDateMsg,
  orderMsg,
  recurringChargesMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import {
  getDeliveryOrderStatusInfo,
  orderContainsBillingPeriod,
} from '../CustomerOrderDetails/CustomerOrderDetailsUtil.js';
import { getEmployeePeriodicPriceAsText, getPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { CustomerOrder, DeliveryOrder, DeliveryOrderDetails } from '../../generated/api/models.js';

interface DeliveryOrderDetailsGeneralProps {
  customerOrder: CustomerOrder;
  deliveryOrder: DeliveryOrder;
  details: DeliveryOrderDetails;
  onClickCustomerOrder: () => void;
  isEmployee: boolean;
  mdmId?: string;
}

export const DeliveryOrderDetailsGeneral = ({
  customerOrder,
  deliveryOrder,
  details,
  onClickCustomerOrder,
  isEmployee,
  mdmId,
}: DeliveryOrderDetailsGeneralProps) => {
  const { customerOrderDisplayId } = customerOrder;
  const {
    deliveryOrderCreated,
    monthlyRecurringCharge,
    oneTimeCharge,
    status,
    eppOrder,
    employeeMonthlyRecurringCharge,
    monthlyRecurringListCharge,
    oneTimeListCharge,
  } = deliveryOrder;
  const statusInfo = getDeliveryOrderStatusInfo(status.toString());
  const trackingCodeUrl = `https://www.posti.fi/henkiloasiakkaat/seuranta/#/lahetys/${details?.trackingCode}`;

  const isBillingPeriodOrder = orderContainsBillingPeriod(customerOrder);
  const recurringChargesTitle = isBillingPeriodOrder ? t.J4WW(recurringChargesMsg) : t.P6BC(monthlyChargesMsg);

  return (
    <div className="of-delivery-order-details--section" key="of-delivery-order--section-general">
      <CL.Description
        items={[
          {
            title: t.ASQT(statusMsg),
            description: (
              <div>
                <div className="of-status-icon">
                  <Icon icon="record" type="filled" size="s" color={statusInfo.color} /> {statusInfo.text}
                </div>
              </div>
            ),
          },
          {
            title: t.VXHX('Postal shipping code'),
            description:
              details && details.trackingCode ? (
                <a href={trackingCodeUrl} target="_blank" rel="noreferrer">
                  {details.trackingCode}
                </a>
              ) : undefined,
          },
          {
            title: t.ZC7D(customerMsg),
            description: deliveryOrder.deliveryOrderContact,
          },
          {
            title: t.C001(orderMsg),
            description: (
              <Link
                to={`${generatePath(paths.CUSTOMER_ORDER, { orderId: customerOrderDisplayId! })}${mdmId ? '?mdmId=' + mdmId : ''}`}
                id="customer-order-display"
                onClick={e => {
                  e.preventDefault();
                  onClickCustomerOrder();
                }}
              >
                {customerOrderDisplayId}
              </Link>
            ),
          },
          {
            title: t.F1NC(orderDateMsg),
            description: deliveryOrderCreated ? formatTimestampToUTCDDMMYYYY(deliveryOrderCreated) : undefined,
          },
          {
            title: eppOrder ? t.HMZZ('Contractual price for company, VAT 0%') : recurringChargesTitle,
            description: isEmployee
              ? undefined
              : getPriceAsText(
                  monthlyRecurringCharge,
                  monthlyRecurringListCharge,
                  true,
                  '',
                  deliveryOrder.billingPeriod
                ),
          },
          {
            title: t.GOBY(oneTimePaymentMsg),
            description: isEmployee || eppOrder ? undefined : getPriceAsText(oneTimeCharge, oneTimeListCharge),
          },
          {
            title: t.CVAH('Price for user'),
            description: isEmployee
              ? getEmployeePeriodicPriceAsText(employeeMonthlyRecurringCharge)
              : getPriceAsText(employeeMonthlyRecurringCharge, undefined, true),
          },
          {
            title: t.K8DS('Order type'),
            description: eppOrder ? 'EPP' : undefined,
          },
        ].filter(item => !!item && item.description)}
      />
    </div>
  );
};
