import * as CL from '@design-system/component-library';
import { Modal } from '../Modal/Modal';
import { ModalButtons } from '../Modal/ModalButtons';
import { cancelMsg, changeRequestInProgressInDetailMsg, changeRequestInProgressMsg, t } from '../../common/i18n';

export interface InProgressModalProps {
  onclose: () => void;
}

export const InProgressModal = ({ onclose }: InProgressModalProps) => {
  return (
    <Modal closeable onClose={onclose} size="s" heading={t.V09R(changeRequestInProgressMsg)}>
      {t.KJP5(changeRequestInProgressInDetailMsg)}
      <ModalButtons>
        <CL.Button onClick={onclose} size="m">
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </ModalButtons>
    </Modal>
  );
};
