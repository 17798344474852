import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormPageAvailability } from './OpenFormPageAvailability.js';
import { OpenFormPageInformative } from './OpenFormPageInformative.js';
import { OpenFormPageQuestion } from './OpenFormPageQuestion.js';
import { useOpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';

export const OpenFormPageType = ({
  answers,
  section,
  disabled,
}: {
  answers: OpenFormAnswers;
  section: OpenFormSection;
  disabled: boolean;
}) => {
  const dispatcher = useOpenFormDispatcher(section);
  switch (section?.pageType) {
    case 'AVAILABILITY_CHECK':
      return (
        <OpenFormPageAvailability
          dispatcher={dispatcher(section)}
          answers={answers}
          section={section}
          disabled={disabled}
        />
      );
    case 'INFORMATIVE':
      return <OpenFormPageInformative section={section} />;
    case 'ORDER_ENRICHMENT':
    case 'QUESTION_ADDITIONAL_SERVICES':
    case 'QUESTION_MAIN_PRODUCTS':
    case 'QUESTION_SUBSCRIPTION_TYPE':
      return <OpenFormPageQuestion dispatcher={dispatcher} answers={answers} section={section} disabled={disabled} />;
  }
};
