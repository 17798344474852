import { NumberRange } from '../../generated/api/numberRange';
import { NumberRangeResponse } from '../../generated/api/numberRangeResponse';
import type { NumberRangeData } from '../../common/loaders';
import type { Subscription } from '../../generated/api/subscription';
import type { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration';

export const getCurrentPbxSolutionNumberRanges = (pbxSolution?: Subscription) =>
  (pbxSolution?.details?.mobilePbx?.numberRanges ?? []).filter(
    numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
  );

export const sortNumberRangesInPbxSolutions = (pbxSolutions: Subscription[]): Subscription[] =>
  pbxSolutions.map(pbxSolution => {
    if (pbxSolution.details && pbxSolution.details.mobilePbx) {
      return {
        ...pbxSolution,
        details: {
          ...pbxSolution.details,
          mobilePbx: {
            ...pbxSolution.details.mobilePbx,
            numberRanges: pbxSolution.details.mobilePbx.numberRanges.sort((a, b) =>
              a.startNumber < b.startNumber ? -1 : 1
            ),
          },
        },
      };
    }
    return pbxSolution;
  });

export const isPbxConfigComplete = (pbxConfig: Partial<SubscriptionPbxConfiguration>) =>
  Boolean(
    pbxConfig.corporateNumberRangeId &&
      pbxConfig.corporateNumber &&
      pbxConfig.pbxSolutionId &&
      pbxConfig.extensionRangeId &&
      pbxConfig.extensionNumber
  );

export const getRelatedExtensionNumberRangeId = (pbxSolution: Subscription, corporateNumber?: string) => {
  if (!corporateNumber) {
    return undefined;
  }
  const numberRanges = pbxSolution.details!.mobilePbx!.numberRanges;
  const relatedExtensionRange = numberRanges.find(numberRange => {
    if (numberRange.rangeType !== NumberRange.RangeTypeEnum.EXTENSION) {
      return false;
    }
    const wantedExtension = parseInt(corporateNumber.substring(corporateNumber.length - 3, corporateNumber.length), 10);
    return (
      parseInt(numberRange.startNumber, 10) <= wantedExtension && parseInt(numberRange.endNumber, 10) >= wantedExtension
    );
  });
  return relatedExtensionRange ? relatedExtensionRange.rangeId : undefined;
};

export const getSelectedPbxSolution = (pbxSolutions: Subscription[], selectedPbxSolutionId?: string) =>
  selectedPbxSolutionId ? pbxSolutions.find(sub => sub.subscriptionId === selectedPbxSolutionId)! : pbxSolutions[0]!;

export const getNumberRangeData = (response: NumberRangeResponse, id: string): NumberRangeData => ({
  rangeId: id,
  allNumbers: response.numbers,
  displayedNumbers:
    response.numberCategory === NumberRangeResponse.NumberCategoryEnum.CORPORATE ||
    response.numberCategory === NumberRangeResponse.NumberCategoryEnum.REGIONAL
      ? response.numbers.slice(0, 4)
      : response.numbers,
  numberCategory: response.numberCategory,
});

export const getExtensionRangeId = (pbxSolution: Subscription, corporateNumber?: string) => {
  const relatedExtensionNumberRangeId = getRelatedExtensionNumberRangeId(pbxSolution, corporateNumber);
  const defaultExtensionRange = pbxSolution.details!.mobilePbx!.numberRanges.find(
    numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.EXTENSION
  );
  return relatedExtensionNumberRangeId || defaultExtensionRange?.rangeId;
};

// This function gets a possible "related" extension number which is the last 3 digits of corporate number. This extension
// number is returned in case it is found in the number range
export const getRelatedExtensionNumber = (selectedCorporateNumber: string, currentNumberRange?: NumberRangeData) => {
  if (!currentNumberRange?.displayedNumbers) {
    return undefined;
  }
  const availableExtensionNumbers = currentNumberRange?.displayedNumbers;
  const relatedExtensionNumber = selectedCorporateNumber.substring(
    selectedCorporateNumber.length - 3,
    selectedCorporateNumber.length
  );
  if (!availableExtensionNumbers?.includes(relatedExtensionNumber)) {
    return undefined;
  }
  if (Number(relatedExtensionNumber) < 700 || Number(relatedExtensionNumber) >= 900) {
    return relatedExtensionNumber;
  }
  return undefined;
};

const getDefaultExtensionNumber = (corporateNumber?: string, currentNumberRange?: NumberRangeData) => {
  if (!corporateNumber) {
    return undefined;
  }
  const defaultExtensionNumber = getRelatedExtensionNumber(corporateNumber, currentNumberRange);
  if (defaultExtensionNumber !== undefined) {
    return defaultExtensionNumber;
  }
  const displayedNumbers = currentNumberRange?.displayedNumbers;
  if (!displayedNumbers) {
    return undefined;
  }
  return displayedNumbers[0];
};

export const getExtensionNumber = (
  extensionRangeId: string,
  corporateNumber: string,
  numberRanges: NumberRangeData[]
) => {
  const currentExtensionNumberRange = numberRanges.find(i => i.rangeId === extensionRangeId);
  return getDefaultExtensionNumber(corporateNumber, currentExtensionNumberRange);
};
