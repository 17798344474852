import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface FeatureFlagWrapperProps {
  children: ReactNode;
}

// #TODO: shoppingBasket / feature flag - remove after taken into use
export const FeatureFlagWrapper = ({ children }: FeatureFlagWrapperProps) => {
  const { authenticatedUser } = useAuth();
  const userFF = authenticatedUser?.enabledFeatureFlags?.includes('shoppingBasketHeader');
  const useShoppingBasket = useSelector((s: State) => isStrictFeatureEnabled(s.config.featureFlags?.shoppingBasket));

  return useShoppingBasket || userFF ? <>{children}</> : <></>;
};
