import { ChangeBillingAccountDialog } from '../../../../../components/ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CopyCatalogMultiBusinessDialog } from '../../../../../components/CopyCatalogMultiBusinessDialog/CopyCatalogMultiBusinessDialog';
import { DeleteCatalogDialog } from '../../../../../components/Dialogs/DeleteCatalogDialog';
import { DialogType, WizardType } from '../../../../../common/enums.js';
import { LOCATION_TYPE_REPLACE } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplaceUtils.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { VirtualCatalogDetails } from '../../../../../components/VirtualCatalogDetails/VirtualCatalogDetails.js';
import { VirtualCatalogReplace } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplace.js';
import { copyVirtualCatalog } from '../../../../../selfservice/actions';
import { generatePath, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCompanyName } from '../../../../../common/utils/accountUtils.js';
import { getDefaultBillingContactId, getDefaultDeliveryMethod } from '../../../../../common/utils/billingAccountUtils';
import { isDefined } from '../../../../../common/utils/objectUtils.js';
import { isFeatureEnabledForUser } from '../../../../../common/utils/featureFlagUtils.js';
import { onUpsertVirtualCatalog } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { t } from '../../../../../common/i18n/index.js';
import { toCatalogProductsPerCategory } from '../../../../../common/utils/catalogUtils.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { AuthenticatedUserState } from '../../../../../common/types/states.js';
import type { BillingAccountHeader } from '../../../../../generated/api/billingAccountHeader';
import type { CatalogCode } from '../../../../../common/constants/pathInterfaces.js';
import type { CatalogSearchResponse } from '../../../../../generated/api/catalogSearchResponse.js';
import type { CompanyVirtualCatalogs } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplaceUtils.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { VirtualCatalogDetailsLoaderData } from '../../../../../common/loaders';

export interface CatalogBillingAccountDialogProps {
  mdmId: string;
  billingAccounts: BillingAccountHeader[];
}

const CatalogBillingAccountDialog = ({ mdmId, billingAccounts }: CatalogBillingAccountDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useSelector((state: State) => state.dialog);
  const catalog = location.state.catalog;
  const billingAccountId = catalog.billingAccountId;
  const catalogCode = location.state.virtualCatalog.virtualCatalogCode;
  const defaultBillingContactId = getDefaultBillingContactId(billingAccounts);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(billingAccounts);

  return (
    <ChangeBillingAccountDialog
      billingAccountId={billingAccountId}
      changeRequestInProgress={dialog?.submitInProgress}
      headerText={t.H41V('Change billing account details')}
      description={t.AIUJ(
        'Please note that after the change, invoicing will still be based on two invoices for the next payment. Any supplementary services will be transferred with the main product.'
      )}
      detailedView={true}
      searchable={true}
      onBeginCreateBillingAccount={() => {
        const path = generatePath(paths.BILLING_ACCOUNTS_CREATE_NEW_BA);
        const options = {
          state: {
            ...location.state,
            redirectPath: `${location.pathname}?mdmId=${mdmId}`,
            mdmId,
            hideDisclaimerText: true,
            defaultBillingContactId,
            defaultDeliveryMethod,
          },
        };
        navigate(path, options);
      }}
      onCloseDialog={() => {
        navigate(`${generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode })}?mdmId=${mdmId}`);
      }}
      onSubmit={(bid: string) => {
        if (bid !== billingAccountId) {
          onUpsertVirtualCatalog(dispatch)({ ...catalog, billingAccountId: bid }, false, location.state.virtualCatalog);
        }
      }}
    />
  );
};

const getCompanyVirtualCatalogs = (
  authenticatedUser?: AuthenticatedUserState,
  virtualCatalogsForAllCompanies?: CatalogSearchResponse[],
  currentCatalogCode?: string
): CompanyVirtualCatalogs[] => {
  const companyNames = [
    ...(authenticatedUser?.secondaryAccounts?.map(acc => getCompanyName(authenticatedUser, acc.accountMasterId)) || []),
    authenticatedUser?.companyName,
  ].filter(isDefined);

  const filteredCatalogs = virtualCatalogsForAllCompanies?.filter(c => c.result.catalogCode !== currentCatalogCode);

  return companyNames.map(companyName => ({
    companyName,
    virtualCatalogs:
      filteredCatalogs
        ?.filter(catalog => getCompanyName(authenticatedUser, catalog.result.accountMasterId) === companyName)
        .map(catalog => catalog.result) || [],
  }));
};

export const CompanyInfoCatalogPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { catalogCode } = useParams<CatalogCode>();
  const { authenticatedUser } = useAuth();
  const {
    billingAccounts,
    catalog: currentCatalog,
    catalogHeaders: virtualCatalogsForAllCompanies,
    discountedPrices,
    onlineModelHeaders,
    secondaryAccounts,
  } = useLoaderData() as VirtualCatalogDetailsLoaderData;
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const baHeaders = (billingAccounts.searchResults ?? []).map(e => e.result);
  const draftOrPublishedCatalog = currentCatalog?.draft || currentCatalog?.published;

  const replaceCatalogsFeatureFlag = 'replaceCatalogs';
  const replaceCatalogs = useSelector((state: State) =>
    isFeatureEnabledForUser(
      replaceCatalogsFeatureFlag,
      state.config.featureFlags,
      authenticatedUser?.enabledFeatureFlags
    )
  );

  return !draftOrPublishedCatalog || !discountedPrices?.discountedPrices ? (
    <Loading />
  ) : (
    <>
      {location.state?.type === WizardType.CHANGE_CATALOG_BILLING_ACCOUNT.valueOf() && (
        <CatalogBillingAccountDialog mdmId={mdmId!} billingAccounts={baHeaders} />
      )}
      {location.state?.type === DialogType.COPY_CATALOG_MULTI_BUSINESS && (
        <CopyCatalogMultiBusinessDialog
          onSubmit={(selectedAccountMasterIds: string[]) => {
            dispatch(copyVirtualCatalog(location.state.catalog, selectedAccountMasterIds));
          }}
          catalogProductType={location.state.catalog.productType}
          onCloseDialog={() => navigate(location.search)}
          secondaryAccountsInfo={secondaryAccounts}
          accounts={[
            ...(secondaryAccounts || []).map(account => {
              return {
                accountMasterId: account.accountMasterId,
                accountName: getCompanyName(authenticatedUser, account.accountMasterId),
              };
            }),
            {
              accountMasterId: mdmId!,
              accountName: getCompanyName(authenticatedUser, mdmId),
            },
          ]}
        />
      )}
      {location.state?.type === DialogType.DELETE_CATALOG_DIALOG && (
        <DeleteCatalogDialog
          accountMasterId={location.state!.accountMasterId}
          catalogName={location.state!.catalogName}
          virtualCatalogCode={location.state!.virtualCatalogCode}
        />
      )}
      <CompanyInfoContainer tab={CompanyInfoSceneTab.CATALOGS} isSingular={true}>
        {location.state?.type === LOCATION_TYPE_REPLACE ? (
          <VirtualCatalogReplace
            virtualCatalog={currentCatalog}
            catalog={draftOrPublishedCatalog}
            companyName={authenticatedUser?.companyName || ''}
            companyVirtualCatalogs={getCompanyVirtualCatalogs(
              authenticatedUser,
              virtualCatalogsForAllCompanies,
              catalogCode
            )}
          />
        ) : (
          <VirtualCatalogDetails
            catalog={draftOrPublishedCatalog}
            virtualCatalog={currentCatalog}
            products={toCatalogProductsPerCategory(
              draftOrPublishedCatalog,
              true,
              discountedPrices?.discountedPrices,
              onlineModelHeaders
            )}
            productsPublished={
              currentCatalog.published
                ? toCatalogProductsPerCategory(
                    currentCatalog.published,
                    true,
                    discountedPrices?.discountedPrices,
                    onlineModelHeaders
                  )
                : undefined
            }
            companyName={getCompanyName(authenticatedUser, mdmId)}
            onAddMoreProducts={() => {
              if (discountedPrices?.discountedPrices) {
                navigate(`${paths.COMPANY_INFO_CATALOG_LIST_PRODUCTS}?mdmId=${mdmId}`, {
                  state: {
                    catalog: draftOrPublishedCatalog,
                    virtualCatalog: currentCatalog,
                    products: toCatalogProductsPerCategory(
                      draftOrPublishedCatalog,
                      true,
                      discountedPrices?.discountedPrices,
                      onlineModelHeaders
                    ),
                    mdmId,
                  },
                });
              }
            }}
            billingAccounts={baHeaders}
            replaceCatalogs={replaceCatalogs}
            accountMasterId={mdmId!}
          />
        )}
      </CompanyInfoContainer>
    </>
  );
};
