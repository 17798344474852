import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { OrderSim } from '../../../../../components/OrderSim/OrderSim.js';
import { SimType } from '../../../../../generated/api/models.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import {
  getSubscriptionBreadcrumbName,
  getSubscriptionStatus,
  getSubscriptionUrlId,
} from '../../../../../common/utils/subscriptionUtils.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, orderSimMsg, t } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useSelector } from 'react-redux';
import type { BroadbandSubPostChangeRequestLoaderResponse } from '../../../../../common/loaders';
import type { State } from '../../../../../selfservice/common/store.js';

export const BroadbandSubOrderSimPath = () => {
  const { authenticatedUser } = useAuth();
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as BroadbandSubPostChangeRequestLoaderResponse;
  const serviceFees = useSelector((state: State) => state?.selfservice?.subscriptions?.voice?.serviceFees, deepEqual);
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <OrderSim
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
            {
              name: getSubscriptionBreadcrumbName(subscription),
              path:
                generatePath(paths.PS_BROADBAND_SUBSCRIPTION, {
                  subscriptionId: getSubscriptionUrlId(subscription),
                }) + search,
            },
            { name: t.CVON(orderSimMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.BROADBAND}
      companyInfo={companyInfo}
      disallowBillableSimChanges={false} // NOE is used by admins, always allow billable SIM changes.
      onClickSubscription={() => {
        navigate(
          generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: getSubscriptionUrlId(subscription) }) + search
        );
      }}
      pendingSubscriptionChanges={subscriptionStatus.pendingActions}
      simTypeInitialSelection={SimType.ESIM}
      subscription={subscription!}
      user={authenticatedUser}
      simChangeServiceFee={
        serviceFees?.find(serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId)?.price
      }
    />
  );
};
