import { InputComponent } from '../components/InputComponent';
import { purposeOfUseMsg, t } from '../../i18n';
import type { OptionalInputComponentProps } from '../components/InputComponent';

export const PurposeOfUse = ({
  name = 'purpose-of-use',
  label = t.Y8KX(purposeOfUseMsg),
  placeholder = t.Y8KX(purposeOfUseMsg),
  required = false,
  disabled = false,
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (!value) {
      return t.MBUX('Fill in the purpose of use');
    }
    return undefined;
  };

  return (
    <InputComponent
      type="text"
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      validate={validate}
    />
  );
};
