import { BreadCrumbs } from '../../../../../components/BreadCrumbs/index.js';
import { CatalogConfiguration } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper/index.js';
import { Spinner } from '../../../siteUtils.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import {
  deviceListsMsg,
  listOfDevicesMsg,
  newDeviceListMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { isCompanyInfoLoading } from '../../../../../common/utils/stateUtils.js';
import { isFeatureEnabledForUser } from '../../../../../common/utils/featureFlagUtils.js';
import { isMultiBiz } from '../../../../../common/utils/accountUtils';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { CatalogConfigurationLoaderData } from '../../../../../components/CatalogConfiguration/CatalogConfigurationForm';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoCatalogListNewPath = () => {
  const { companyInfo } = useLoaderData() as CatalogConfigurationLoaderData;
  const featureFlags = useSelector((state: State) => state.config.featureFlags, deepEqual);

  const { authenticatedUser } = useAuth();
  const isConsolidatedViewsEnabled = isFeatureEnabledForUser(
    'consolidatedViews',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );

  if (isCompanyInfoLoading(companyInfo)) {
    return <Spinner />;
  }
  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: t.MPFC(newDeviceListMsg) },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-catalog-configuration']}
      detailsTop={breadCrumbs}
      id="catalog-configuration"
      heading={t.Z0HF(listOfDevicesMsg)}
      headingBottom={isConsolidatedViewsEnabled && isMultiBiz(authenticatedUser) ? undefined : companyInfo?.companyName}
      heroPicto="catalog"
    >
      <CatalogConfiguration companyInfo={companyInfo} user={authenticatedUser} featureFlags={featureFlags} />
    </DetailsWrapper>
  );
};
