/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EppCategory } from './eppCategory.js';
import { OnlineModelCategory } from './onlineModelCategory.js';


/**
 * Catalog of products created by employers, saved in SFDC. These properties live in json file in sfdc, not mapped to any fields.
 */
export interface Catalog { 
    /**
     * unique identifier (guid) of a catalog
     */
    catalogCode?: string;
    /**
     * Name of the catalog.
     */
    name: string;
    /**
     * Type of the product in a catalog. There can be one type possible in a catalog.
     */
    productType: Catalog.ProductTypeEnum;
    /**
     * Consent for the enrollment program e.g. DEP, KME.
     */
    enrollmentProgramConsent: boolean;
    /**
     * Customer\'s Device Enrollment Alias, It\'s free text provided by user for KME enrollment.
     */
    enrollmentProgramAlias?: string;
    /**
     * Contract Period of products, only which should be included in the catalog.
     */
    contractPeriod?: number;
    /**
     * Selected damage insurance will be mandatory to purchase, if the product belongs to one of EppCategory.
     */
    damageInsurance?: Array<EppCategory>;
    /**
     * Price share which employer is going to pay when employee places an order. If not set, corporate pays in full.
     */
    corporateShare?: number;
    /**
     * Model guids of the products included in the catalog.
     */
    products: Array<string>;
    /**
     * Default billing account for the catalog. SFDC: Customer_Catalog__c.Default_Billing_Account__c
     */
    billingAccountId?: string;
    /**
     * Is the default billing account field mandatory by the approver?
     */
    approverMustSelectBillingAccount?: boolean;
    /**
     * Unix timestamp in milliseconds of creation.
     */
    created?: number;
    /**
     * Unix timestamp in milliseconds of last modification.
     */
    lastModified?: number;
    /**
     * Categories of the products included in the catalog.
     */
    productCategories: Array<OnlineModelCategory>;
    /**
     * Text message to employee while ordering device from a catalog added by respective admin. SFDC Mapping : Customer_catalog__c.Corporate_Message__c
     */
    corporateMessage?: string;
    /**
     * Used to filter non-published versions out in SFDC UI, so customer service would not use non-published catalogs.
     */
    publishedStatus?: Catalog.PublishedStatusEnum;
    /**
     * Rolling version number of catalogs inside a virtualCatalog. When a new catalog draft is created inside a virtual catalog, the version number is an increment of the current published catalog inside the virtual catalog.
     */
    versionNumber?: number;
}
export namespace Catalog {
    export type ProductTypeEnum = 'RECURRING' | 'ONETIME' | 'EPP_RECURRING';
    export const ProductTypeEnum = {
        RECURRING: 'RECURRING' as ProductTypeEnum,
        ONETIME: 'ONETIME' as ProductTypeEnum,
        EPP_RECURRING: 'EPP_RECURRING' as ProductTypeEnum
    };
    export type PublishedStatusEnum = 'PUBLISHED' | 'DRAFT' | 'DEPRECATED';
    export const PublishedStatusEnum = {
        PUBLISHED: 'PUBLISHED' as PublishedStatusEnum,
        DRAFT: 'DRAFT' as PublishedStatusEnum,
        DEPRECATED: 'DEPRECATED' as PublishedStatusEnum
    };
}


