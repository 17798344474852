import { ActionPhase } from '../common/storeUtils.js';
import { FormStateEnum } from '../../common/formik/index.js';
import { NumberRangeResponse, ValidatePortableResponse, ValidateSimFreeResponse } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { combineArrays, reduceCrudAction } from './reducerUtils.js';
import { localizeValidationErrorMessage } from '../../common/utils/simCardUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionState, ActionsHistory } from '../common/store.js';
import type { LoadNumberRangeAction, SelfServiceActionTypes } from '../actions/index.js';
import type { ResourcesState } from '../../common/types/states.js';

const isInitialLoadNumbersPrereserveAllowed = (state: ResourcesState | null, initialLoad?: boolean): boolean =>
  initialLoad === true && (state === null || !state.numbers || state.numbers.length === 0);

const isPrereserveAllowed = (state: ResourcesState | null, totalAmount?: number): boolean =>
  (!totalAmount && (state === null || !state.numbers || state.numbers.length < 30)) || // until max amount of numbers 30 is reached
  (totalAmount !== undefined &&
    state !== null &&
    state.numbers !== undefined &&
    totalAmount % 6 === 1 &&
    state.numbers.length > 1 &&
    totalAmount > state.numbers.length &&
    state.numbers.length < 30); // until max amount of numbers 30 is reached by every 7th product

export function resourcesReducer(
  state: (ResourcesState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (ResourcesState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.DISPLAY_MORE_NUMBERS_FROM_RANGE: {
      if (
        state.numberRanges &&
        state.numberRanges[action.rangeId] &&
        state.numberRanges[action.rangeId].allNumbers &&
        state.numberRanges[action.rangeId].displayedNumbers
      ) {
        const currentNumbers = state.numberRanges[action.rangeId];
        return {
          ...state,
          numberRanges: {
            ...state.numberRanges,
            [action.rangeId]: {
              ...currentNumbers,
              displayedNumbers: currentNumbers.allNumbers!.slice(0, currentNumbers.displayedNumbers!.length + 4),
            },
          },
        };
      } else {
        return state;
      }
    }

    case TypeKeys.LOAD_NUMBER_RANGE: {
      // Since there can be multiple ranges this is a bit more complex than ...reduceCrudAction can't handle
      if (state && state.actions) {
        const inProgressAction: LoadNumberRangeAction | undefined = state.actions.find(
          (actionState: ActionState) =>
            actionState.value.type === TypeKeys.LOAD_NUMBER_RANGE &&
            actionState.phase === ActionPhase.IN_PROGRESS &&
            (actionState.value as LoadNumberRangeAction).rangeId === action.rangeId
        ) as LoadNumberRangeAction | undefined;

        if (inProgressAction !== undefined) {
          return state;
        }
      }

      return {
        ...state,
        actions: [
          ...(state && state.actions ? state.actions : []),
          {
            phase: ActionPhase.IN_PROGRESS,
            value: {
              ...action,
              id: action.id,
            },
          },
        ],
        numberRanges: {
          ...(state && state.numberRanges ? state.numberRanges : undefined),
          [action.rangeId]: {
            ...(state && state.numberRanges ? state.numberRanges[action.rangeId] : undefined),
            loading: true,
          },
        },
      };
    }

    case TypeKeys.LOAD_NUMBER_RANGE_FAILED: {
      if (state && state.actions) {
        const updatedActions = state.actions.map((actionState: ActionState) => {
          if (
            actionState.value.type === TypeKeys.LOAD_NUMBER_RANGE &&
            (actionState.value as LoadNumberRangeAction).rangeId === action.rangeId
          ) {
            return {
              ...actionState,
              phase: ActionPhase.FAILED,
            };
          }
          return actionState;
        });

        return {
          ...state,
          actions: updatedActions,
          numberRanges: {
            ...state.numberRanges,
            [action.rangeId]: {
              ...(state.numberRanges ? state.numberRanges[action.rangeId] : undefined),
              errors: action.errors,
              loading: false,
            },
          },
        };
      }
      return state;
    }

    case TypeKeys.LOAD_NUMBER_RANGE_FULFILLED: {
      if (state && state.actions) {
        const updatedActions = state.actions.map((actionState: ActionState) => {
          if (
            actionState.value.type === TypeKeys.LOAD_NUMBER_RANGE &&
            (actionState.value as LoadNumberRangeAction).rangeId === action.rangeId
          ) {
            return {
              ...actionState,
              phase: ActionPhase.SUCCESS,
            };
          }
          return actionState;
        });

        const numbers = action.numbers.sort();

        return {
          ...state,
          actions: updatedActions,
          numberRanges: {
            ...state.numberRanges,
            [action.rangeId]: {
              allNumbers: numbers,
              displayedNumbers:
                action.numberCategory === NumberRangeResponse.NumberCategoryEnum.CORPORATE ||
                action.numberCategory === NumberRangeResponse.NumberCategoryEnum.REGIONAL
                  ? numbers.slice(0, 4)
                  : numbers,
              loading: false,
              numberCategory: action.numberCategory,
            },
          },
        };
      }
      return state;
    }

    case TypeKeys.PRERESERVE_NUMBERS: {
      const { totalAmount, initialLoad } = action;
      if (
        isInitialLoadNumbersPrereserveAllowed(state, initialLoad) ||
        (isPrereserveAllowed(state, totalAmount) && !initialLoad)
      ) {
        return {
          ...state,
          ...reduceCrudAction(action, state),
          numbersLoading: true,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case TypeKeys.PRERESERVE_NUMBERS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PRERESERVE_NUMBERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        numbers: state.numbers ? [...state.numbers, ...action.numbers] : action.numbers,
        numbersLoading: false,
      };
    }

    case TypeKeys.PRERESERVE_NUMBERS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PRERESERVE_NUMBERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        numbersLoading: false,
      };
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED: {
      // when order is fulfilled clear the number's being used so far
      return {
        ...state,
        numbers: [],
      };
    }

    case TypeKeys.RESET_COMPANY_SEARCH_RESULT: {
      return {
        ...state,
        companySearchResult: undefined,
      };
    }

    case TypeKeys.RESET_ADDRESS_SEARCH_RESULT: {
      return {
        ...state,
        addressSearchResult: undefined,
      };
    }

    case TypeKeys.SEARCH_PUBLIC_PAGES: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        pageSearchInProgress: true,
      };
    }

    case TypeKeys.SEARCH_ADDRESS:
    case TypeKeys.SEARCH_COMPANY: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        companySearchState: FormStateEnum.LOADING,
      };
    }

    case TypeKeys.SEARCH_COMPANY_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_COMPANY, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        companySearchState: FormStateEnum.FAILED,
      };
    }

    case TypeKeys.SEARCH_COMPANY_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_COMPANY, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        companySearchResult: action.result,
        companySearchState: FormStateEnum.FULFILLED,
      };
    }

    case TypeKeys.SEARCH_DOMAIN: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        domainSearchState: FormStateEnum.LOADING,
      };
    }

    case TypeKeys.SEARCH_DOMAIN_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_DOMAIN, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        domainSearchState: FormStateEnum.FAILED,
      };
    }

    case TypeKeys.SEARCH_DOMAIN_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_DOMAIN, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        domainSearchState: FormStateEnum.FULFILLED,
        domainSearchResult: action.result,
      };
    }

    case TypeKeys.SEARCH_ADDRESS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_ADDRESS, state, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
      };
    }

    case TypeKeys.SEARCH_ADDRESS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SEARCH_ADDRESS, state, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        addressSearchResult: action.result,
      };
    }

    case TypeKeys.GET_COMMERCIAL_AVAILABILITY: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        commercialAvailabilityResult: undefined,
        commercialAvailabilityState: FormStateEnum.LOADING,
      };
    }

    case TypeKeys.GET_COMMERCIAL_AVAILABILITY_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_COMMERCIAL_AVAILABILITY,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        commercialAvailabilityState: FormStateEnum.FAILED,
      };
    }

    case TypeKeys.GET_COMMERCIAL_AVAILABILITY_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_COMMERCIAL_AVAILABILITY,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        commercialAvailabilityResult: action.result,
        commercialAvailabilityState: FormStateEnum.FULFILLED,
      };
    }

    case TypeKeys.RESET_SEARCH_PUBLIC_PAGES_RESULT: {
      return {
        ...state,
        pageSearchResult: undefined,
      };
    }

    case TypeKeys.SEARCH_PUBLIC_PAGES_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SEARCH_PUBLIC_PAGES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        pageSearchInProgress: false,
      };
    }

    case TypeKeys.SEARCH_PUBLIC_PAGES_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SEARCH_PUBLIC_PAGES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        pageSearchInProgress: false,
        pageSearchResult: {
          total: action.result.total,
          pageHits:
            action.start === 1
              ? action.result.pageHits
              : [...(state.pageSearchResult?.pageHits || []), ...action.result.pageHits],
          spelling: action.result.spelling,
          promotions: action.result.promotions,
        },
      };
    }

    case TypeKeys.VALIDATE_PHONE_NUMBER_FULFILLED: {
      if (action.response.errorType === 'TECHNICAL_ERROR') {
        // We don't actually care about failures, so do nothing
        return state;
      }

      return {
        ...state,
        validatedPhoneNumbers: combineArrays('phoneNumber', state.validatedPhoneNumbers, [
          {
            phoneNumber: action.phoneNumber,
            valid: action.response.errorType !== ValidatePortableResponse.ErrorTypeEnum.INVALID_NUMBER_FORMAT,
            portable: action.validatePortIn ? action.response.portable : undefined,
            error: action.response.errorMessage,
          },
        ]),
      };
    }

    case TypeKeys.VALIDATE_PHONE_NUMBER_FAILED: {
      // We don't actually care about failures, so do nothing
      return state;
    }

    case TypeKeys.VALIDATE_SIM_FULFILLED: {
      const errorType = action.response?.errorType;

      if (errorType === ValidateSimFreeResponse.ErrorTypeEnum.TECHNICAL_ERROR) {
        // If there is a technical error, skip the validation. Otherwise, the user will be stuck on the
        // validation screen and not able to proceed with the order.
        return state;
      }

      return {
        ...state,
        validatedSims: combineArrays('simCardNumber', state.validatedSims || [], [
          {
            simCardNumber: action.iccId,
            valid: action.response.free,
            error: errorType ? localizeValidationErrorMessage(errorType) : undefined,
          },
        ]),
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.GET_PUBLIC_PAGE:
    case TypeKeys.GET_PUBLIC_PAGE_NOT_FOUND: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        cmsPage: undefined, // so that user don't see old product details when api is still loading for response.
      };
    }

    case TypeKeys.GET_PUBLIC_PAGE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.GET_PUBLIC_PAGE, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        cmsPage: undefined,
        headers: {},
        pageStatusCode: action.pageStatusCode,
        pagePath: action.pagePath,
      };
    }

    case TypeKeys.GET_PUBLIC_PAGE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.GET_PUBLIC_PAGE, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        cmsPage: action.page,
        headers: action.headers,
        pagePath: action.pagePath,
        pageStatusCode: action.pageStatusCode,
      };
    }

    default:
      return state;
  }
}
