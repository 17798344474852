import { EmployeeLoginPage } from '../../EmployeeLoginPage/EmployeeLoginPage';
import { LanguageSelector } from '../LanguageSelector.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import { appleWatchLoginScreenFooterMsg, backMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fetchElisaIdAuthCode } from '../../../public/site/async/fetchElisaIdAuthCode.js';
import { isFeatureEnabled } from '../../../common/utils/featureFlagUtils';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useElisaIdClient } from '../../../common/hooks/useElisaIdClient';
import { useSelector } from 'react-redux';
import LoginForm from '@onlinefirst/elisaid-react-login-form';
import type { State } from '../../../selfservice/common/store.js';

interface ProcessElisaIdLoginResponseProps {
  authenticationMethod: string;
  token: string;
  isVerified: boolean;
}

export interface AuxiliaryEsimOrderLoginProps {
  onElisaIdLoginSuccess: (token: string) => void;
  startElisaIdV2Login: (locale: string) => void;
}

const AuxiliaryEsimOrderLoginV1 = ({ onElisaIdLoginSuccess }: AuxiliaryEsimOrderLoginProps) => {
  const { siteLanguage, siteBaseUrl, saveUserPreferredLocale } = useContext(SiteContext);
  const client = useElisaIdClient();
  const elisaIdProps = useSelector((state: State) => state.config.elisaId, deepEqual);
  const { resetLoginForm } = useAuth();

  return (
    <>
      <LanguageSelector onChangeLocale={saveUserPreferredLocale} selectedLocale={siteLanguage} />
      <div id="elisaid-login-form-parent" className="ea-color-container ea-color-container--stretch of-login-form">
        <LoginForm
          client={client}
          // Configuration
          disableLinks={true}
          disableMobileLogin={true}
          showOtherSelfServiceChannels={false}
          footer={t.EDOH(appleWatchLoginScreenFooterMsg)}
          heading={t.QQJX('Add Elisa KelloSIM to your subscription by login to My OmaElisa')}
          locale={siteLanguage.substring(0, 2)}
          recoverPasswordHeaders={{
            headerTitle: t.SBDY('Add your email address'),
            subHeaderTitle: t.QD79(`We'll email you instructions how to reset your password`),
          }}
          resetLoginForm={resetLoginForm}
          returnToLoginButtonText={t.D1E9(backMsg)}
          // Links
          helpWithLoginLink={{
            title: t.FV8X('Help with login?'),
            url: `${siteBaseUrl}/info-omaelisa-tyontekijalle`,
          }}
          recoveryLink={{
            title: t.CSK0('I cannot remember my email address'),
            url: elisaIdProps?.recoveryUrl,
          }}
          registerLink={{
            title: t.XV9S(`I don't have Elisa ID yet or I can't remember it.`),
            url: elisaIdProps?.registrationUrl,
          }}
          // Functions
          getLoginAuthCode={fetchElisaIdAuthCode}
          getMobileLoginAuthCode={() => {}}
          loginSuccess={(loginResponse: ProcessElisaIdLoginResponseProps) => {
            onElisaIdLoginSuccess(loginResponse.token);
          }}
        />
      </div>
    </>
  );
};

const AuxiliaryEsimOrderLoginV2 = ({ startElisaIdV2Login }: AuxiliaryEsimOrderLoginProps) => {
  const { siteLanguage, saveUserPreferredLocale } = useContext(SiteContext);

  return (
    <>
      <LanguageSelector onChangeLocale={saveUserPreferredLocale} selectedLocale={siteLanguage} />
      <div id="elisaid-login-form-parent" className={`${dsClass.BACKGROUND_COLOR_WHITE} of-login-form`}>
        <EmployeeLoginPage
          initiateLogin={() => startElisaIdV2Login(siteLanguage.substring(0, 2))}
          isAuxiliarySimLogin={true}
        />
      </div>
    </>
  );
};

export const AuxiliaryEsimOrderLogin = (props: AuxiliaryEsimOrderLoginProps) => {
  const { auxiliarySimElisaIdV2, elisaIdV2 } = useSelector((state: State) => ({
    auxiliarySimElisaIdV2: state.config.featureFlags.auxiliarySimElisaIdV2,
    elisaIdV2: state.config.featureFlags.elisaIdV2,
  }));
  if (isFeatureEnabled(elisaIdV2) && isFeatureEnabled(auxiliarySimElisaIdV2)) {
    return <AuxiliaryEsimOrderLoginV2 {...props} />;
  } else {
    return <AuxiliaryEsimOrderLoginV1 {...props} />;
  }
};
