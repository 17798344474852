import { CustomerOrderSystemError } from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { DeliveryOrderDetails } from '../../../../../components/DeliveryOrderDetails/DeliveryOrderDetails.js';
import { generatePath, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { omaElisaForCompaniesMsg, orderMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { DeliveryOrder } from '../../../../../generated/api/models.js';
import type { DeliveryOrderLoaderData } from '../../../../../common/loaders';
import type { OrderId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const CustomerOrderProductPath = () => {
  const navigate = useNavigate();
  const { orderId, subscriptionId } = useParams<OrderId & SubscriptionId>();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const { customerOrder } = useLoaderData() as DeliveryOrderLoaderData;
  const deliveryOrder =
    subscriptionId && customerOrder.deliveryOrders
      ? customerOrder.deliveryOrders.find(
          ({ deliveryOrderDisplayId }: DeliveryOrder) => deliveryOrderDisplayId === subscriptionId
        )
      : undefined;

  if (customerOrder && deliveryOrder) {
    return (
      <CustomerOrderSystemError>
        <DeliveryOrderDetails
          customerOrder={customerOrder}
          deliveryOrder={deliveryOrder}
          onClickCustomerOrder={() => {
            navigate(`${generatePath(paths.CUSTOMER_ORDER, { orderId: orderId! })}?mdmId=${mdmId}`);
          }}
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.I9C0(orderMsg), path: paths.CUSTOMER_ORDERS },
            {
              name: customerOrder.customerOrderDisplayId,
              path: `${generatePath(paths.CUSTOMER_ORDER, { orderId: orderId! })}?mdmId=${mdmId}`,
            },
            { name: deliveryOrder.deliveryOrderName },
          ]}
          mdmId={mdmId}
        />
      </CustomerOrderSystemError>
    );
  }
  return null;
};
