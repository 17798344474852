import { MobileBroadbandService } from '../../common/constants/subscriptionConstants';
import { ReissunettiAndReissaajaInfo } from './ReissunettiAndReissaajaInfo';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getListName } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import { isFiveGPlusOffer, isLiikkuvaWifiOffer } from '../../common/utils/subscriptionUtils.js';
import { t } from '../../common/i18n/index.js';
import type { SubscriptionCardsByModelTypeProps } from './SubscriptionCardsByModelType';

export enum MbbSubscriptionSelectionSectionId {
  WIFI_SUBS_ID = 'wifi_subs',
  YRITYSDATA_SUBS_ID = 'yritysdata_subs',
}

const LiikkuvaWifiCards = ({
  onlineModelType,
  subscriptionCards,
  handleSubscriptionCardSelection,
}: SubscriptionCardsByModelTypeProps) => {
  const liikkuvaWifiCards = subscriptionCards.filter(sub => isLiikkuvaWifiOffer(sub.selectedOffer));

  return (
    <div id={MbbSubscriptionSelectionSectionId.WIFI_SUBS_ID}>
      <h3>{MobileBroadbandService.LIIKKUVA_WIFI}</h3>
      <p>
        {t.JA5V(
          `Using a mobile router can save your phone's battery life, as you won't need to share the internet from your phone. Thanks to 5G+ technology, the battery consumption of the mobile router has been minimized up to 20 % compared to the older 4G/5G network alternative.`
        )}
      </p>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <SubscriptionCards
          buttonText={liikkuvaWifiCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={liikkuvaWifiCards}
        />
      </div>
    </div>
  );
};

const YritysdataCards = ({
  onlineModelType,
  subscriptionCards,
  handleSubscriptionCardSelection,
}: SubscriptionCardsByModelTypeProps) => {
  const yritysdataCards = subscriptionCards.filter(
    sub => isFiveGPlusOffer(sub.selectedOffer) && !isLiikkuvaWifiOffer(sub.selectedOffer)
  );

  return (
    <div id={MbbSubscriptionSelectionSectionId.YRITYSDATA_SUBS_ID}>
      <h3 className={`${dsClass.PADDING_TOP_3} of-order-subscription-selection__choose-subscription-header`}>
        {MobileBroadbandService.YRITYSDATA}
      </h3>
      <p>{t.ADV9('Includes just the subscription, and the device is sold separately.')}</p>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <SubscriptionCards
          buttonText={yritysdataCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={yritysdataCards}
        />
      </div>
    </div>
  );
};

const FiveGCards = ({
  onlineModelType,
  subscriptionCards,
  handleSubscriptionCardSelection,
}: SubscriptionCardsByModelTypeProps) => {
  const fiveGCards = subscriptionCards.filter(sub => !isFiveGPlusOffer(sub.selectedOffer));

  return (
    <>
      <h3 className={`${dsClass.PADDING_TOP_3} of-order-subscription-selection__choose-subscription-header`}>
        {t.P6NV('Select a 5G subscription')}
      </h3>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
        <SubscriptionCards
          buttonText={fiveGCards[0]?.buttonText} // Button text is same in all cards
          listName={getListName(onlineModelType)}
          onButtonClick={subscriptionCard =>
            'offer' in subscriptionCard ? handleSubscriptionCardSelection(subscriptionCard) : undefined
          }
          subscriptions={fiveGCards}
        />
      </div>
    </>
  );
};

export const MobileBroadbandSubscriptionCards = ({
  subscriptionCards,
  onlineModelType,
  handleSubscriptionCardSelection,
  isChangeOffer,
}: SubscriptionCardsByModelTypeProps) => (
  <>
    {!isChangeOffer && (
      <LiikkuvaWifiCards
        handleSubscriptionCardSelection={handleSubscriptionCardSelection}
        subscriptionCards={subscriptionCards}
        onlineModelType={onlineModelType}
      />
    )}

    <YritysdataCards
      handleSubscriptionCardSelection={handleSubscriptionCardSelection}
      subscriptionCards={subscriptionCards}
      onlineModelType={onlineModelType}
    />
    <ReissunettiAndReissaajaInfo />
    <FiveGCards
      handleSubscriptionCardSelection={handleSubscriptionCardSelection}
      subscriptionCards={subscriptionCards}
      onlineModelType={onlineModelType}
    />
  </>
);
