import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid';
import { HeroHeading, HeroHeadingType } from '../HeroHeading';
import { OmalaskuBarringsDetails } from './OmalaskuBarringsDetails';
import { OmalaskuOrderForm } from './OmalaskuOrderForm';
import { OmalaskuStatusResponse } from '../../generated/api/omalaskuStatusResponse';
import { dsClass } from '../../common/constants/dsClasses';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils';
import { palvelunumerotMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables';
import { useNavigate, useParams } from 'react-router-dom';
import type { Msisdn } from '../../common/constants/pathInterfaces';
import OmaLaskuStatusEnum = OmalaskuStatusResponse.OmaLaskuStatusEnum;
import { useOmalasku } from './Omalasku';

export const OmalaskuOrderBarrings = () => {
  const { omalaskuSubsStatuses } = useOmalasku();
  const { msisdn } = useParams<Msisdn>();
  const navigate = useNavigate();

  const status = (omalaskuSubsStatuses || []).find(
    subStatus => subStatus.msisdn === msisdn && subStatus.omaLaskuStatus === OmaLaskuStatusEnum.Inactive
  );
  if (!status) {
    // Handle incorrect subscription id in the path param, if mistyped or incorrect bookmarked
    navigate(paths.EMPLOYEE_OMALASKU);
  }

  const address = (omalaskuSubsStatuses || [])
    .filter(subStatus => subStatus.omaLaskuStatus === OmalaskuStatusResponse.OmaLaskuStatusEnum.Active)
    .map(subStatus => subStatus.address)[0];

  const Content = () => {
    if (!omalaskuSubsStatuses) {
      return (
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.PADDING_8} ${dsClass.JUSTIFY_CONTENT_CENTER}`}>
          <CL.LoadingSpinner size="xl" />
        </div>
      );
    }
    return (
      <div>
        <OmalaskuBarringsDetails status={status!} />
        <OmalaskuOrderForm address={address} />
      </div>
    );
  };

  return (
    <div>
      <HeroHeading fullScreen={true} heroHeadingType={HeroHeadingType.BLANK} title={t.CH87(palvelunumerotMsg)} />
      <div className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_HORIZONTAL_3}`}>
        <Grid>
          <div className="of-employee-omalasku__ordering-header-text">
            <CL.Icon icon="sim" size="l" aria-hidden="true" />
            <h3 className={`${dsClass.H3}`}>{formatPhoneNumber(msisdn!)}</h3>
          </div>
          <hr className="of-employee-omalasku__ordering-separator" />
          <Content />
        </Grid>
      </div>
    </div>
  );
};
