import * as CL from '@design-system/component-library';
import { type BillingAccountData, type Context } from '../../OpenFormAnswers.js';
import { BillingAccountDeliveryMethod } from '../../../../generated/api/billingAccountDeliveryMethod.js';
import { CompanySearch } from '../../../../common/react-hook-form/fields/index.js';
import { ELECTRONIC_ADDRESS_REGEX } from '../../../../common/utils/validationUtils.js';
import { NEW_BILLING_ACCOUNT } from '../../OpenFormHooks/useOpenFormSummary.js';
import { type OpenFormAccount } from '../../OpenFormHooks/useOpenFormAccount.js';
import {
  OpenFormBillingInvoiceElectronic,
  OpenFormBillingInvoiceEmail,
  OpenFormBillingInvoicePaper,
} from '../../OpenFormComponents/OpenFormFormatted.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormDropdown } from '../../OpenFormComponents/OpenFormDropdown.js';
import { OpenFormGridBlock, OpenFormGridCol } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormQuestionContact } from './OpenFormQuestionContact.js';
import { OpenFormQuestionInput } from './OpenFormQuestionInput.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  cityMsg,
  contactInfoMsg,
  deliveryMethodMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailInvoiceMsg,
  invalidEInvoiceAddressMsg,
  invoiceLanguageMsg,
  newBillingAgreementMsg,
  payerDetailsMsg,
  payerNameAndBusinessIdMsg,
  postalCodeMsg,
  referenceMsg,
  searchWithCompanyNameOrBusinessIdMsg,
  selectMsg,
  streetAddressMsg,
  t,
} from '../../../../common/i18n/index.js';
import { concat, getValue } from '../../OpenFormUtils.js';
import {
  getDeliveryMethodDropDownOptions,
  getElectronicInvoiceOperatorDropDownOptions,
  getEmptyBillingAccount,
  getLanguageOptions,
} from '../../../../common/utils/billingAccountUtils.js';
import { setValueOptions } from '../../OpenFormHooks/useOpenFormMethods.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenFormBillChannels } from '../../OpenFormHooks/useOpenFormBillChannels.js';
import { useOpenFormBillingAccounts } from '../../OpenFormHooks/useOpenFormBillingAccounts.js';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';
import type { BillingAccount } from '../../../../generated/api/billingAccount.js';
import type { BillingAccountHeader } from '../../../../generated/api/billingAccountHeader.js';

const NewCombobox = () => ({
  id: NEW_BILLING_ACCOUNT as string,
  value: NEW_BILLING_ACCOUNT as string,
  label: t.NCIJ(newBillingAgreementMsg),
  html: <>{t.NCIJ(newBillingAgreementMsg)}</>,
});

const OldCombobox = (ba: BillingAccountHeader) => {
  const label = concat(ba.billingAccountDisplayId, ba.payerNameExtension ?? ba.payerName).join(' ');
  return {
    id: ba.billingAccountId!,
    value: ba.billingAccountId!,
    label: label,
    html: (
      <div>
        <div className="billing-account__label-container">
          <h5 className="billing-account__label-h5">{label}</h5>
        </div>
        {!ba.billingAccountName ? null : (
          <div>
            {t.RH6T(billingAccountNameMsg)}: {ba.billingAccountName}
          </div>
        )}
        {!ba.billingAccountExtensionName ? null : (
          <div>
            {t.KUTS(billingAccountExtensionNameMsg)}: {ba.billingAccountExtensionName}
          </div>
        )}
      </div>
    ),
  };
};

const NewBillingAccount = ({ disabled, guid, data }: { disabled: boolean; guid: string; data: BillingAccountData }) => {
  const { getValues, setValue } = useFormContext();
  const channels = useOpenFormBillChannels();
  const path = useCallback((key: string) => concat(guid, 'billingAccountData', key).join('.'), [guid]);

  useEffect(() => {
    for (const key of Object.keys(data)) {
      const field = path(key);
      const value = data[key as keyof typeof data];
      if (getValues(field) !== value) {
        setValue(field, value, setValueOptions);
      }
    }
  }, [data, getValues, setValue, path]);

  return (
    <>
      <OpenFormGridBlock>
        <OpenFormGridCol>
          <CompanySearch
            label={t.FDE2(payerNameAndBusinessIdMsg)}
            disabled={disabled}
            fieldNames={{
              businessId: path('payerBusinessId'),
              businessName: path('payerName'),
              masterId: path('accountMasterId'),
              postalAddress: path('payerAddress.line1'),
              postCode: path('payerAddress.postalCode'),
              postOffice: path('payerAddress.postOffice'),
            }}
            helpText="&nbsp;"
            noResultsText={t.VRG9(searchWithCompanyNameOrBusinessIdMsg)}
            placeholder={data.payerName ? concat(data.payerName, data.payerBusinessId).join(', ') : undefined}
          />
        </OpenFormGridCol>
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={t.YLAI(payerDetailsMsg)}
          name={path('payerNameExtension')}
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.DD38(streetAddressMsg)}
          name={path('payerAddress.line1')}
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.RUAW(postalCodeMsg)}
          name={path('payerAddress.postalCode')}
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.J0YE(cityMsg)}
          name={path('payerAddress.postOffice')}
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionContact
          colWidth={12}
          disabled={disabled}
          required={true}
          label={t.VYZS(contactInfoMsg)}
          name={path('billingContactId')}
          choices={data.billingContactId}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          onSelect={({ AccountId, Email, FirstName, LastName, MobilePhone } = {}) => {
            setValue(path('billingContactAccountId'), AccountId, setValueOptions);
            setValue(path('billingContactEmail'), Email, setValueOptions);
            setValue(path('billingContactName'), concat(FirstName, LastName).join(' ') || undefined, setValueOptions);
            setValue(path('billingContactPhone'), MobilePhone, setValueOptions);
          }}
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={t.RH6T(billingAccountNameMsg)}
          name={path('billingAccountName')}
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.KUTS(billingAccountExtensionNameMsg)}
          name={path('billingAccountExtensionName')}
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={`${t.ZLAU(referenceMsg)} 1`}
          name={path('customerReference1')}
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={`${t.ZLAU(referenceMsg)} 2`}
          name={path('customerReference2')}
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormDropdown
          label={t.G0QN(deliveryMethodMsg)}
          name={path('deliveryMethod')}
          disabled={disabled}
          items={getDeliveryMethodDropDownOptions(getEmptyBillingAccount(data?.payerName ?? ''), channels).filter(
            c => c.value !== BillingAccountDeliveryMethod.ELECTRONIC || channels?.length
          )}
          onValueChange={() => {
            setValue(path('billElectronicAddress'), undefined);
            setValue(path('billReceiverEmail'), undefined);
          }}
        />
        <OpenFormDropdown
          label={t.A7DR(invoiceLanguageMsg)}
          name={path('billLanguage')}
          disabled={disabled}
          items={getLanguageOptions()}
        />
        {data.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC && channels?.length ? (
          <>
            <OpenFormDropdown
              label={t.WVLB(eInvoicingOperatorMsg)}
              name={path('billElectronicOperator')}
              disabled={disabled}
              items={getElectronicInvoiceOperatorDropDownOptions(channels)}
            />
            <OpenFormQuestionInput
              label={t.OL7B(eInvoicingAddressMsg)}
              name={path('billElectronicAddress')}
              required={true}
              disabled={disabled}
              wrapGridRow={false}
              type="field"
              validate={value => (ELECTRONIC_ADDRESS_REGEX.test(value) ? undefined : t.NMXU(invalidEInvoiceAddressMsg))}
            />
          </>
        ) : data.deliveryMethod === BillingAccountDeliveryMethod.EMAIL ? (
          <OpenFormQuestionInput
            label={t.W1PP(emailInvoiceMsg)}
            name={path('billReceiverEmail')}
            required={true}
            disabled={disabled}
            wrapGridRow={false}
            type="email"
          />
        ) : null}
      </OpenFormGridBlock>
    </>
  );
};

const OldBillingAccount = ({ ba }: { ba: BillingAccount }) => (
  <OpenFormGridBlock>
    <OpenFormGridCol>
      <div>{ba.billingAccountName}</div>
      {ba.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC ? (
        <OpenFormBillingInvoiceElectronic ba={ba} />
      ) : ba.deliveryMethod === BillingAccountDeliveryMethod.EMAIL ? (
        <OpenFormBillingInvoiceEmail ba={ba} />
      ) : ba.deliveryMethod === BillingAccountDeliveryMethod.PAPER ? (
        <OpenFormBillingInvoicePaper addr={ba.payerAddress} />
      ) : null}
    </OpenFormGridCol>
  </OpenFormGridBlock>
);

export const OpenFormQuestionBillingAccount = ({
  dispatcher,
  disabled,
  required,
  label,
  guid,
  context,
}: {
  dispatcher: OpenFormDispatcher;
  disabled: boolean;
  required: boolean;
  label: string;
  guid: string;
  context: Context | undefined;
}) => {
  const { account } = useOutletContext<{ account: OpenFormAccount }>();
  const { resetField } = useFormContext();
  const accounts = useOpenFormBillingAccounts();
  const selectedValue = useMemo(() => getValue(context?.choices), [context?.choices]);
  return (
    <OpenFormGridBlock colWidth={6} className="of-openform__view__choices__billing" justifyCenterOuter={true}>
      <OpenFormGridCol className={classNames({ ['label--mandatory']: required })}>
        <CL.Combobox
          disabled={disabled}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          items={useMemo(() => concat(NewCombobox(), ...accounts.map(OldCombobox)), [accounts])}
          label={label}
          selectedItemFormat="label"
          selectedValue={selectedValue}
          onValueSelect={useCallback<(el?: HTMLLIElement) => void>(
            el => {
              switch (el?.dataset.value) {
                case undefined:
                  resetField(concat(guid, 'billingAccount').join('.'));
                  resetField(concat(guid, 'billingAccountData').join('.'));
                  return dispatcher.setAnswer(undefined);
                case NEW_BILLING_ACCOUNT:
                  return dispatcher.setAnswer(
                    NEW_BILLING_ACCOUNT,
                    'billingAccountData',
                    context?.billingAccountData ?? account.billingAccountData
                  );
                default:
                  return dispatcher.setAnswer(
                    el?.dataset.value,
                    'billingAccount',
                    accounts?.find(ba => ba.billingAccountId === el?.dataset.value) as BillingAccount
                  );
              }
            },
            [dispatcher, guid, context, account, resetField, accounts]
          )}
        />
      </OpenFormGridCol>
      {selectedValue === NEW_BILLING_ACCOUNT && context?.billingAccountData ? (
        <NewBillingAccount disabled={disabled} guid={guid} data={context.billingAccountData} />
      ) : selectedValue !== undefined && context?.billingAccount ? (
        <OldBillingAccount ba={context.billingAccount} />
      ) : null}
    </OpenFormGridBlock>
  );
};
