import * as CL from '@design-system/component-library';
import {
  BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH,
  BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH,
} from '../../../common/utils/validationUtils.js';
import { BillingAccountName } from '../../../common/formik/Fields/BillingAccountName.js';
import { BillingAccountReference } from '../../../common/formik/Fields/BillingAccountReference.js';
import { BillingFormatDropdown } from '../../../common/formik/Fields/Dropdown/BillingFormatDropdown.js';
import { ContactDropdown } from '../../../common/formik/Fields/Dropdown/ContactDropdown.js';
import { Field } from 'formik';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { InputComponent } from '../../../common/formik/FieldComponents/index.js';
import { NAME_WITH_SPECIAL_CHARACTERS_FORMAT_REGEX } from '../../../common/utils/nameUtils';
import { PostOffice } from '../../../common/formik/Fields/PostOffice.js';
import { PostalCode } from '../../../common/formik/Fields/index.js';
import { SpecialInput } from '../../../common/formik/Fields/SpecialInput.js';
import { StreetAddress } from '../../../common/formik/Fields/StreetAddress.js';
import {
  billingAccountExtensionNameMsg,
  fieldValueTooLong,
  invalidValueMsg,
  payerDetailsMsg,
  payerMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { Contact, ContactHeader } from '../../../generated/api/models.js';

interface RefCallBackProps {
  elementKey: string;
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
}

/**
 * Adds a ref for scroll to error feature
 * Currently this component is part of others that use this approach for scroll to error
 * to be refactored when upper components change
 */
export const RefCallBack = (props: RefCallBackProps) => {
  const { setRefCallback, elementKey } = props;
  if (setRefCallback) {
    return <div ref={(element: HTMLElement | null) => setRefCallback(elementKey, element)} />;
  } else {
    return null;
  }
};

interface BillingAccountFormProps {
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
  contacts?: Contact[] | ContactHeader[];
}

const validateBillingAccountExtensionName = (name: string) => {
  if (!NAME_WITH_SPECIAL_CHARACTERS_FORMAT_REGEX.test(name)) {
    return t.DUPA(invalidValueMsg);
  } else if (name?.length > BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH) {
    return t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH}`);
  }
  return undefined;
};

const validatePayerNameExtension = (name: string) => {
  if (!NAME_WITH_SPECIAL_CHARACTERS_FORMAT_REGEX.test(name)) {
    return t.DUPA(invalidValueMsg);
  } else if (name?.length > BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH) {
    return t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH}`);
  }
  return undefined;
};

export const BillingAccountForm = ({ setRefCallback, contacts }: BillingAccountFormProps) => {
  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <SpecialInput label={t.PB6S(payerMsg)} name="payerName" disabled={true} required={true} />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <Field
            label={t.YLAI(payerDetailsMsg)}
            name="payerNameExtension"
            maxLength={BILLING_ACCOUNT_PAYER_NAME_EXTENSION_MAX_LENGTH}
            component={InputComponent}
            validate={validatePayerNameExtension}
          />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <RefCallBack elementKey="payerAddress.line1" setRefCallback={setRefCallback} />
          <StreetAddress name="payerAddress.line1" />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <RefCallBack elementKey="payerAddress.postalCode" setRefCallback={setRefCallback} />
          <PostalCode name="payerAddress.postalCode" allowPoBox={true} />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <RefCallBack elementKey="payerAddress.postOffice" setRefCallback={setRefCallback} />
          <PostOffice name="payerAddress.postOffice" />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field" />
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field no-max-height">
          <RefCallBack elementKey="billingContactId" setRefCallback={setRefCallback} />
          <ContactDropdown canAddNewContacts={true} contacts={contacts || []} name="billingContactId" />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <RefCallBack elementKey="billFormatType" setRefCallback={setRefCallback} />
          <BillingFormatDropdown />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field" />
        <GridColumn className="of-billing-account-details form-column edit-field">
          <RefCallBack elementKey="billingAccountName" setRefCallback={setRefCallback} />
          <BillingAccountName />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow className={dsClass.PADDING_BOTTOM_8}>
        <GridColumn className="of-billing-account-details form-column edit-field" />
        <GridColumn className="of-billing-account-details form-column edit-field">
          <Field
            label={t.KUTS(billingAccountExtensionNameMsg)}
            name="billingAccountExtensionName"
            maxLength={BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH}
            component={InputComponent}
            validate={validateBillingAccountExtensionName}
          />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <BillingAccountReference number={1} />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <BillingAccountReference number={2} />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow className={dsClass.PADDING_BOTTOM_8}>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <BillingAccountReference number={3} />
        </GridColumn>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <BillingAccountReference number={4} />
        </GridColumn>
      </CL.GridRow>
    </>
  );
};
