import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isDefined } from '../../common/utils/objectUtils.js';
import { t } from '../../common/i18n';
import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import './CheckboxTree.scss';

export interface CheckboxTreeProps {
  data: CheckboxTreeItem[];
  onChange: (selectedValues: string[]) => void;
  initialValues?: string[];
}

export interface CheckboxTreeItem {
  label: ReactNode;
  value: string;
  disabled?: boolean;
  children?: CheckboxTreeItem[];
}

interface TreeItemProps extends CheckboxTreeItem {
  selectedValues: Set<string>;
  onChange: (item: TreeItemProps, checked: boolean) => void;
  level: number;
  sibling: number;
}

export type TreeItemState = 'UNCHECKED' | 'CHECKED' | 'INDETERMINATE';

export const TreeItemStateEnum = {
  UNCHECKED: 'UNCHECKED' as TreeItemState,
  CHECKED: 'CHECKED' as TreeItemState,
  INDETERMINATE: 'INDETERMINATE' as TreeItemState,
};

export const getItemStatus = (
  selectedValues: Set<string>,
  value?: string,
  children?: CheckboxTreeItem[]
): TreeItemState => {
  // Node with children
  if (children) {
    const allChildrenDisabled = children.every(child => child.disabled);
    const unselectedChildren = children.filter(child => {
      const itemStatus = getItemStatus(selectedValues, child.value, child.children);
      return allChildrenDisabled
        ? itemStatus !== TreeItemStateEnum.CHECKED
        : !child.disabled && itemStatus !== TreeItemStateEnum.CHECKED;
    });

    const indeterminateChildren = children.filter(child => {
      const itemStatus = getItemStatus(selectedValues, child.value, child.children);
      return allChildrenDisabled
        ? itemStatus === TreeItemStateEnum.INDETERMINATE
        : !child.disabled && itemStatus === TreeItemStateEnum.INDETERMINATE;
    });

    if (unselectedChildren.length === 0) {
      return TreeItemStateEnum.CHECKED;
    } else if (
      unselectedChildren.length !==
        (allChildrenDisabled ? children.length : children.filter(child => !child.disabled).length) ||
      indeterminateChildren.length > 0
    ) {
      return TreeItemStateEnum.INDETERMINATE;
    }

    return TreeItemStateEnum.UNCHECKED;
  }

  // Node without children
  if (value) {
    return selectedValues.has(value) ? TreeItemStateEnum.CHECKED : TreeItemStateEnum.UNCHECKED;
  }

  return TreeItemStateEnum.UNCHECKED;
};

const TreeItemCheckbox = ({
  label,
  value,
  item,
  itemStatus,
  onChange,
  disabled,
}: {
  label: ReactNode;
  value?: string;
  item: TreeItemProps;
  itemStatus: TreeItemState;
  onChange: (item: TreeItemProps, checked: boolean) => void;
  disabled?: boolean;
}) => {
  const checkRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.checked = itemStatus === TreeItemStateEnum.CHECKED;
      checkRef.current.indeterminate = itemStatus === TreeItemStateEnum.INDETERMINATE;
    }
  }, [checkRef, itemStatus]);

  return (
    <label className={`${dsClass.CHECKBOX} ${disabled ? dsClass.CHECKBOX_DISABLED : ''}`}>
      <input
        type="checkbox"
        ref={checkRef}
        value={value}
        disabled={disabled}
        onChange={() => onChange(item, !(itemStatus === TreeItemStateEnum.CHECKED))}
      />
      <label className={dsClass.CHECKBOX_LABEL}>
        {itemStatus === TreeItemStateEnum.CHECKED ? (
          <CL.Icon key="check" icon="check" type="filled" className={dsClass.CHECKBOX_ICON} />
        ) : (
          <CL.Icon key="remove" icon="remove" type="filled" className={dsClass.CHECKBOX_ICON} />
        )}
        {label}
      </label>
    </label>
  );
};

const TreeItem = (item: TreeItemProps) => {
  const { label, value, children, onChange, selectedValues, level } = item;
  const itemStatus = getItemStatus(selectedValues, value, children);
  const marginClass = level === 0 ? '' : dsClass.MARGIN_HORIZONTAL_4;
  return (
    <div className={marginClass}>
      <TreeItemCheckbox
        item={item}
        disabled={item.disabled}
        value={value}
        onChange={onChange}
        label={label}
        itemStatus={itemStatus}
      />
      {children?.map((child, index) => (
        <TreeItem
          disabled={child.disabled}
          level={level + 1}
          sibling={index}
          key={`${level}_${child.value}`}
          selectedValues={selectedValues}
          label={child.label}
          value={child.value}
          onChange={onChange}
        >
          {child.children}
        </TreeItem>
      ))}
    </div>
  );
};

const getAllChildValues = (children: CheckboxTreeItem[]): string[] =>
  children
    .filter(child => !child.disabled)
    .map(child => {
      if (child.children) {
        return getAllChildValues(child.children);
      }
      return child.value;
    })
    .flat(1)
    .filter(isDefined);

export const processDisabledStatuses = (data: CheckboxTreeItem[]): CheckboxTreeItem[] =>
  data.map(item => {
    if (item.disabled) {
      if (item.children) {
        return {
          ...item,
          children: processDisabledStatuses(item.children.map(child => ({ ...child, disabled: true }))),
        };
      }
      return item;
    }

    if (item.children && item.children.length > 0) {
      const processedChildren = processDisabledStatuses(item.children);
      const allChildrenDisabled = processedChildren.every(child => child.disabled);
      return {
        ...item,
        disabled: allChildrenDisabled,
        children: processedChildren,
      };
    }

    return item;
  });

export const CheckboxTree = ({ data, onChange, initialValues }: CheckboxTreeProps) => {
  const [selectedValues, setSelectedValues] = useState(new Set<string>(initialValues));
  const processedData = processDisabledStatuses(data);
  const handleChange = (item: TreeItemProps, checked: boolean) => {
    const updatedSelectedValues = new Set(selectedValues);

    if (item.children) {
      getAllChildValues(item.children).map(value =>
        checked ? updatedSelectedValues.add(value) : updatedSelectedValues.delete(value)
      );
    } else if (item.value) {
      if (checked) {
        updatedSelectedValues.add(item.value);
      } else {
        updatedSelectedValues.delete(item.value);
      }
    }
    onChange(Array.from(Array.from(updatedSelectedValues)));
    setSelectedValues(updatedSelectedValues);
  };

  return (
    <div>
      <TreeItem
        disabled={processedData?.every(child => child.disabled)}
        label={t.A8VA('Select all')}
        selectedValues={selectedValues}
        onChange={handleChange}
        level={0}
        sibling={0}
        value="select_all"
      >
        {processedData}
      </TreeItem>
    </div>
  );
};
