import * as CL from '@design-system/component-library';
import { BillingAccountDetailsForm } from './forms/BillingAccountDetailsForm.js';
import { BillingAccountStatus, ChangeRequestStatus } from '../../generated/api/models.js';
import { BillingAccountSubscriptionList } from '../SubscriptionLists/BillingAccountSubscriptionList.js';
import { BillingAccountVersion, EditingSection } from './billingAccountDetailsEnums.js';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Disclaimer } from './components/Disclaimer.js';
import { GutterStyle, SelectOneList } from '../SelectOneList/index.js';
import { Loading } from '../Loading/index.js';
import { ScheduleChangeType } from './forms/details/BillingAccountDetailsEditForm.js';
import {
  billingAccountMsg,
  billingAccountsMsg,
  cancelMsg,
  changesFailedToSave,
  changesWereSuccessfullySavedMsg,
  confirmMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../common/i18n/index.js';
import {
  cancelChangeRequest,
  fetchBillingAccountScheduledChange,
  reScheduleBillingAccountChange,
  scheduleBillingAccountChange,
  updateBillingAccount,
} from '../../common/fetch.js';
import { deepEqual, deepMerge } from '../../common/utils/objectUtils.js';
import { formatDefinedTimestampToDDMMYYYY, getNormalizedDateFromDateString } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { isBillingAccountInSfdc, prepareBillingAccountSave } from '../../common/utils/billingAccountUtils.js';
import {
  loadBillingAccountSubscriptions,
  startNotification,
  upsertBillingAccountScheduledChangeFailed,
  upsertBillingAccountScheduledChangeFulfilled,
} from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pushBillingAccountEventToDataLayer } from '../../common/analytics';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountScheduledChange,
  Contact,
} from '../../generated/api/models.js';
import type { ModalOperations } from '@design-system/component-library';
import type { ReceiverType } from '../../common/utils/billingAccountUtils.js';
import type { ScheduledChangeValues } from './forms/details/BillingAccountDetailsEditForm.js';
import type { State } from '../../selfservice/common/store.js';

import './BillingAccountDetails.scss';

export interface BillingAccountDetailsProps {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  contacts?: Contact[];
}

const getScheduledBillingAccount = (
  billingAccount: BillingAccount,
  scheduledBillingAccount: Partial<BillingAccount>
): BillingAccount => {
  return {
    ...deepMerge(billingAccount, scheduledBillingAccount),
    billingAccountStatus: BillingAccountStatus.SCHEDULED,
  };
};

export const BillingAccountDetails = ({ billingAccount, billChannels, contacts }: BillingAccountDetailsProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const modalPreventDialogRef = useRef<ModalOperations>(null);
  const modalDeleteDialogRef = useRef<ModalOperations>(null);
  const [billingAccountState, setBillingAccountState] = useState<BillingAccount>(billingAccount);
  const [editingSection, setEditingSection] = useState(EditingSection.NONE);
  const [billingAccountScheduledChange, setBillingAccountScheduledChange] = useState<
    BillingAccountScheduledChange | undefined
  >();
  const [showPreventDialog, setShowPreventDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingAccountVersion, setBillingAccountVersion] = useState<BillingAccountVersion>(
    BillingAccountVersion.ACTIVE
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const allSubs = useSelector((state: State) => state.selfservice?.subscriptions, deepEqual);
  const baCountFromState = useSelector(
    (state: State) =>
      state.selfservice?.billingAccounts?.billingAccountSubscriptions?.find(
        ba => ba.billingAccountId === billingAccount?.billingAccountId
      ),
    deepEqual
  );
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const companyName = getCompanyName(authenticatedUser, mdmId);

  useEffect(() => {
    if (showPreventDialog) {
      modalPreventDialogRef?.current?.openModal();
    } else {
      modalPreventDialogRef?.current?.closeModal();
    }
  }, [showPreventDialog]);

  useEffect(() => {
    if (showDeleteConfirmDialog) {
      modalDeleteDialogRef?.current?.openModal();
    } else {
      modalDeleteDialogRef?.current?.closeModal();
    }
  }, [showDeleteConfirmDialog]);

  useEffect(() => {
    if (billingAccount?.billingAccountId) {
      setIsLoading(true);
      fetchBillingAccountScheduledChange(billingAccount?.billingAccountId)
        .then(scheduledChange => {
          if (scheduledChange.scheduledChange) {
            setBillingAccountScheduledChange({
              ...scheduledChange.scheduledChange,
              billingAccount: getScheduledBillingAccount(
                billingAccount,
                scheduledChange.scheduledChange.billingAccount
              ),
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [billingAccount]);

  useEffect(() => {
    setEditingSection(EditingSection.NONE);
    if (billingAccount?.billingAccountId && baCountFromState?.totalSubscriptions === undefined) {
      dispatch(loadBillingAccountSubscriptions({ billingAccountId: billingAccount.billingAccountId, limit: 30 }));
    }
  }, [dispatch, baCountFromState, billingAccount]);

  let content: JSX.Element;
  if (billingAccount && !isLoading) {
    const editableInOmaElisa = isBillingAccountInSfdc(billingAccount);

    const rescheduleBillingAccount = async (
      billingAccountId: string,
      scheduledChange: BillingAccountScheduledChange,
      scheduledChangeTimestamp: number
    ) => {
      const reScheduleResult = await reScheduleBillingAccountChange(billingAccountId, {
        billingAccountId,
        changeRequestId: scheduledChange.changeRequestId ?? '',
        scheduledChangeTimestamp,
      });
      setEditingSection(EditingSection.NONE);
      setBillingAccountScheduledChange({
        ...scheduledChange,
        scheduledChangeTimestamp: reScheduleResult.scheduledChangeTimestamp ?? 0,
      });
    };

    const scheduleBillingAccount = async (
      billingAccountId: string,
      scheduledChangeTimestamp: number,
      scheduledBillingAccount: BillingAccount
    ) => {
      try {
        const scheduleResult = await scheduleBillingAccountChange(
          billingAccountId,
          scheduledChangeTimestamp,
          scheduledBillingAccount
        );
        dispatch(upsertBillingAccountScheduledChangeFulfilled());
        setBillingAccountScheduledChange({
          scheduledChangeTimestamp: scheduleResult.scheduledChangeTimestamp ?? 0,
          billingAccount: getScheduledBillingAccount(billingAccount, scheduledBillingAccount),
          changeRequestId: scheduleResult.changeRequestId,
        });
      } catch (error) {
        dispatch(upsertBillingAccountScheduledChangeFailed());
      } finally {
        setEditingSection(EditingSection.NONE);
      }
    };

    const saveBillingAccountChanges = async (
      billingAccountId: string,
      values: BillingAccount,
      receiverType: ReceiverType
    ) => {
      const updatedBillingAccount = prepareBillingAccountSave(billingAccountState, values, receiverType);
      try {
        setIsSaving(true);
        await updateBillingAccount(billingAccountId, updatedBillingAccount);
        setBillingAccountState(updatedBillingAccount);
        dispatch(startNotification(t.FFJ2(changesWereSuccessfullySavedMsg), 'success'));
      } catch (error) {
        dispatch(startNotification(t.GZ8L(changesFailedToSave), 'error'));
      } finally {
        setEditingSection(EditingSection.NONE);
        setIsSaving(false);
        if (location.state?.redirectToPath) {
          navigate(location.state.redirectToPath, { replace: true });
        }
      }
    };

    const onSaveBillingAccount = async (
      values: BillingAccount,
      schedule: ScheduledChangeValues,
      receiverType: ReceiverType
    ) => {
      if (isSaving || !billingAccount.billingAccountId) {
        return;
      }

      if (schedule.change === ScheduleChangeType.FUTURE) {
        setIsLoading(true);
        const scheduledChangeTimestamp = getNormalizedDateFromDateString(schedule.date ?? '').getTime();

        if (billingAccountScheduledChange) {
          await rescheduleBillingAccount(
            billingAccount.billingAccountId,
            billingAccountScheduledChange,
            scheduledChangeTimestamp
          );
        } else {
          const updatedBillingAccount = prepareBillingAccountSave(billingAccountState, values, receiverType);
          await scheduleBillingAccount(
            billingAccount.billingAccountId,
            scheduledChangeTimestamp,
            updatedBillingAccount
          );
        }
        setIsLoading(false);
      } else {
        await saveBillingAccountChanges(billingAccount.billingAccountId, values, receiverType);
      }

      pushBillingAccountEventToDataLayer('modify');
    };

    const BillingAccountVersionOption = (label: string, version: BillingAccountVersion) => ({
      element: <div>{label}</div>,
      isSelected: billingAccountVersion === version,
      onClick: () => {
        if (editingSection === EditingSection.NONE) {
          setBillingAccountVersion(version);
        }
      },
    });

    const subscriptions = Object.values(allSubs ?? {})
      .flatMap(subs => (subs?.items !== undefined ? subs.items : []))
      .filter(sub => sub && sub?.billingAccountId === billingAccount?.billingAccountId);

    const PreventEditDialog = () => (
      <CL.Modal
        onModalClose={() => setShowPreventDialog(false)}
        size="s"
        ref={modalPreventDialogRef}
        heading={t.LSQ3('This billing account has scheduled changes')}
        content={t.MJ63(
          'This billing account has scheduled change request so editing is not possible at the moment. You can delete the change request or reschedule it.'
        )}
      />
    );

    const onDeleteScheduledChange = async () => {
      if (billingAccount.billingAccountId && billingAccountScheduledChange?.changeRequestId) {
        setIsLoading(true);
        const deleteResult = await cancelChangeRequest(
          billingAccount.billingAccountId,
          billingAccountScheduledChange.changeRequestId
        );
        if (deleteResult.changeRequestStatus === ChangeRequestStatus.CANCELLED) {
          setShowDeleteConfirmDialog(false);
          setBillingAccountScheduledChange(undefined);
          setBillingAccountVersion(BillingAccountVersion.ACTIVE);
        }
        setIsLoading(false);
      }
    };

    const DeleteConfirmDialog = () => (
      <CL.Modal
        onModalClose={() => setShowDeleteConfirmDialog(false)}
        size="s"
        ref={modalDeleteDialogRef}
        heading={t.G9UR('Do you really want to delete scheduled change?')}
        content={t.O21N('You are about to delete a scheduled change request. The removal can not be undone.')}
        buttons={[
          <CL.Button color="link" key="btnCancel" onClick={() => setShowDeleteConfirmDialog(false)}>
            {t.B2V1(cancelMsg)}
          </CL.Button>,
          <CL.Button color="primary" key="btnConfirm" onClick={onDeleteScheduledChange}>
            {t.QVYK(confirmMsg)}
          </CL.Button>,
        ]}
      />
    );

    const onEditClick = (newEditingSection: EditingSection) => {
      if (billingAccountScheduledChange && newEditingSection === EditingSection.DETAILS) {
        setShowPreventDialog(true);
      } else {
        setEditingSection(newEditingSection);
      }
    };

    content = (
      <div className="of-billing-account-details__content">
        <CL.TabNavigation
          key={`ba_details_${editingSection}_${baCountFromState?.totalSubscriptions}_${billingAccountVersion}_${isLoading}`}
        >
          <CL.TabNavigation.Tab title={t.KJ47('Billing account details')} selected>
            {billingAccountScheduledChange && (
              <div className="of-billing-account-scheduled-change">
                <SelectOneList
                  disableIcon={true}
                  key={billingAccountVersion}
                  gutterStyle={GutterStyle.Minimal}
                  maxItemsAsideDesktop={2}
                  maxItemsAsideLaptop={2}
                  maxItemsAsideTablet={2}
                  fullWidth={false}
                  options={[
                    BillingAccountVersionOption(t.F6OD('Active version'), BillingAccountVersion.ACTIVE),
                    BillingAccountVersionOption(
                      `${t.Y91N('Scheduled changes')} ${formatDefinedTimestampToDDMMYYYY(
                        billingAccountScheduledChange.scheduledChangeTimestamp
                      )}`,
                      BillingAccountVersion.SCHEDULED
                    ),
                  ]}
                />
                <Disclaimer
                  billingAccountScheduledChange={billingAccountScheduledChange}
                  billingAccountVersion={billingAccountVersion}
                />
              </div>
            )}
            <PreventEditDialog />
            <DeleteConfirmDialog />
            <BillingAccountDetailsForm
              billingAccount={billingAccountState}
              billChannels={billChannels}
              onSaveBillingAccount={onSaveBillingAccount}
              contacts={contacts || []}
              setEditingSection={onEditClick}
              editingSection={editingSection}
              editableInClassic={!editableInOmaElisa}
              showScheduledChange={
                billingAccountScheduledChange && billingAccountVersion === BillingAccountVersion.SCHEDULED
              }
              billingAccountScheduledChange={billingAccountScheduledChange}
              billingAccountVersion={billingAccountVersion}
              onDeleteClick={() => setShowDeleteConfirmDialog(true)}
              isSaving={isSaving}
            />
          </CL.TabNavigation.Tab>
          <CL.TabNavigation.Tab title={t.W691('Products in account')}>
            {billingAccount.billingAccountId && (
              <BillingAccountSubscriptionList
                billingAccountId={billingAccount.billingAccountId}
                items={subscriptions}
                total={baCountFromState?.totalSubscriptions}
              />
            )}
          </CL.TabNavigation.Tab>
        </CL.TabNavigation>
      </div>
    );
  } else {
    content = <Loading />;
  }

  const billingAccountDisplayId = billingAccount?.billingAccountDisplayId || '…';
  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.ZVMK(billingAccountsMsg), path: paths.BILLING_ACCOUNTS },
        { name: billingAccountDisplayId },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-billing-account-details']}
      detailsTop={breadCrumbs}
      id={`billing-account-details-${billingAccountDisplayId}`}
      heading={billingAccountDisplayId}
      headingBottom={companyName}
      headingTop={t.IFT9(billingAccountMsg)}
      heroPicto="billing-account"
    >
      {content}
    </DetailsWrapper>
  );
};
