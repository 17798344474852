import { ConfirmationDialog } from '../ConfirmationDialog';
import { confirmMsg, t } from '../../common/i18n';
import { deprecateVirtualCatalog } from '../../common/fetch';
import { paths } from '../../common/constants/pathVariables';
import { setDeletedCatalogCode } from '../../selfservice/common/localStorageUtils';
import { useNavigate } from 'react-router-dom';

interface DeleteDeviceListDialogProps {
  accountMasterId?: string;
  catalogName: string;
  virtualCatalogCode: string;
}

export const DeleteCatalogDialog = ({
  accountMasterId,
  catalogName,
  virtualCatalogCode,
}: DeleteDeviceListDialogProps) => {
  const navigate = useNavigate();
  return (
    <ConfirmationDialog
      body={<div>{catalogName}</div>}
      confirmButtonText={t.QVYK(confirmMsg)}
      header={t.WGM5('Confirm deletion of device list')}
      hideClosingCross={false}
      onCloseDialog={() => navigate(location.search)}
      onConfirm={async () => {
        await deprecateVirtualCatalog(virtualCatalogCode, accountMasterId);
        setDeletedCatalogCode(virtualCatalogCode);
        navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
      }}
    />
  );
};
