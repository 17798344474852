import * as CL from '@design-system/component-library';
import { Catalog, OnlineModelCategory } from '../../generated/api/models.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { Loading } from '../Loading/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { ProductGrid } from '../ProductGrid/ProductGrid.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { categories } from '../../common/utils/categoryUtils.js';
import { corporateCustomersMsg, monthMsg, shopMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath } from 'react-router-dom';
import { getPublishedCatalogs, getSelectedCatalog, toDisplayableProduct } from '../../common/utils/catalogUtils.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { Category } from '../../common/types/category.js';
import type { ProductGridItem } from '../ProductGrid/ProductGrid.js';
import type { State } from '../../selfservice/common/store.js';

import './PunchoutCategoryList.scss';

interface PunchoutProductsProps {
  category: Category;
}

const getCategoryLinks = (productCategories: OnlineModelCategory[]) =>
  Object.values(categories)
    .filter(category => productCategories.includes(category.code))
    .sort((c1, c2) => c1.order - c2.order)
    .map(({ displayText, iconClass, urlPath }) => (
      <NavigationMenuItem
        key={urlPath}
        id={urlPath}
        to={generatePath(paths.PUNCHOUT_STORE_PRODUCTS, { category: urlPath })}
        icon={<CL.Icon icon={`${iconClass === 'phone' ? 'smart' : ''}${iconClass}`} size="l" />}
        label={displayText()}
      />
    ));

const getHeroSubtitle = (catalog: Catalog, category: Category) => {
  if (category.code === OnlineModelCategory.ACCESSORIES || catalog.productType === Catalog.ProductTypeEnum.ONETIME) {
    return undefined;
  }
  let subtitle = `${t.V3UP('Device contract period')} ${catalog.contractPeriod} ${t.XXVX(monthMsg)}.`;
  if (catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING) {
    subtitle = subtitle.concat(` ${t.KD2N('Quoted prices VAT')} ` + getVatAsString() + '%');
  }
  return subtitle;
};

export const PunchoutProducts = ({ category }: PunchoutProductsProps) => {
  const discountedPrices = useSelector((state: State) => state.selfservice?.companyInfo?.discountedPrices, deepEqual);
  const onlineModelHeaders = useSelector((state: State) => state.selfservice?.onlineModelHeaders, deepEqual);
  const selectedCatalogCode = useSelector(
    (state: State) => state.selfservice?.virtualCatalogs?.selectedCatalogCode,
    deepEqual
  );
  const catalogs = useSelector(
    (state: State) => getPublishedCatalogs(state.selfservice?.virtualCatalogs?.items),
    deepEqual
  );
  const catalog = getSelectedCatalog(catalogs, selectedCatalogCode)!;

  if (!selectedCatalogCode) {
    return <Loading big={true} topPadding={0} />;
  }
  if (hasSystemError(onlineModelHeaders?.errors)) {
    return <SystemError errors={onlineModelHeaders?.errors} />;
  }
  const models = onlineModelHeaders?.items && onlineModelHeaders.items[category.code];
  return (
    <div className="of-employee-device-store-products">
      <HeroHeading
        center={true}
        breadCrumbPaths={[
          { name: t.KBLX(corporateCustomersMsg), path: paths.PUNCHOUT_STORE },
          { name: t.BZGP(shopMsg), path: paths.PUNCHOUT_STORE },
          { name: category.displayText() },
        ]}
        heroHeadingType={HeroHeadingType.BLANK}
        title={category.displayText()}
        subtitle={getHeroSubtitle(catalog, category)}
      />
      {Boolean(catalog.productCategories.length > 1) && (
        <div className="product-grid-border-bottom product-grid-smoke">
          <NavigationMenu>{getCategoryLinks(catalog.productCategories)}</NavigationMenu>
        </div>
      )}
      {models && discountedPrices ? (
        <ProductGrid
          items={models
            .map(model => toDisplayableProduct(model, catalog, discountedPrices, true, true))
            .filter(product => product)
            .map((product: ProductGridItem) => ({
              ...product,
              pagePath: generatePath(paths.PUNCHOUT_STORE_PRODUCT, {
                category: category.urlPath,
                product: product?.pagePath?.substring(1) || '',
              }),
            }))}
          category={category.code}
          itemLimit={48}
          inEOE={true}
        />
      ) : (
        <Loading big={true} />
      )}
    </div>
  );
};
