import { BreadCrumbs } from '../../../../../components/BreadCrumbs/index.js';
import { CatalogConfigurationEdit } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper/index.js';
import { Spinner } from '../../../siteUtils.js';
import {
  deviceListsMsg,
  listOfDevicesMsg,
  newDeviceListMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { isCompanyInfoLoading } from '../../../../../common/utils/stateUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import type { CompanyInfoState } from '../../../../../common/types/states';

export const CompanyInfoCatalogListEditPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyInfo } = useLoaderData() as { companyInfo: CompanyInfoState };
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    if (!location.state) {
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    }
  }, [location.state, navigate]);

  if (!location.state) {
    return null;
  }

  if (isCompanyInfoLoading(companyInfo)) {
    return <Spinner />;
  }

  return (
    <DetailsWrapper
      classes={['of-catalog-configuration']}
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
            { name: t.MPFC(newDeviceListMsg) },
          ]}
        />
      }
      id="catalog-configuration"
      heading={t.Z0HF(listOfDevicesMsg)}
      headingBottom={companyInfo?.companyName}
      heroPicto="catalog"
    >
      <CatalogConfigurationEdit companyInfo={companyInfo} user={authenticatedUser} mdmId={location.state.mdmId} />
    </DetailsWrapper>
  );
};
