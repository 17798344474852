import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import { concat } from '../../OpenFormUtils.js';
import { deliveryDetailsMsg, t } from '../../../../common/i18n/index.js';

export const OpenFormSummaryDeliveryDateTime = ({ summary }: { summary?: OpenFormSummary }) => {
  const { deliveryDate, deliveryTime } = summary ?? {};
  return !deliveryDate && !deliveryTime ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.GLO4(deliveryDetailsMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <OpenFormItem>{concat(deliveryDate, deliveryTime).join(' ')}</OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
