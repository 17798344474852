import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack';
import { CompanySelector } from '../CompanySelector/CompanySelector';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { Loading } from '../Loading';
import { MoveContactFieldset } from '../../common/react-hook-form/fieldsets/MoveContactFieldset';
import { MoveContactValidationError } from '../../generated/api/moveContactValidationError';
import {
  billingAccountMsg,
  companySelectMsg,
  confirmMsg,
  costCenterMsg,
  elisaDevicesServiceMsg,
  orderMsg,
  t,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses';
import { fetchBillingAccounts, moveContact, validateMoveContact } from '../../common/fetch';
import { getUserAccounts } from '../Header/dynamic/headerFunctions';
import { paths } from '../../common/constants/pathVariables';
import { useAuth } from '../../public/site/AuthProvider';
import { useEffect, useState } from 'react';
import type { MoveContactProps } from './MoveContact';
import type { MoveContactValidateResponse } from '../../generated/api/moveContactValidateResponse';
import type { SubscriptionHeader } from '../../generated/api/subscriptionHeader';
import ErrorTypeEnum = MoveContactValidationError.ErrorTypeEnum;
import { BillingAccount } from '../../common/react-hook-form/fields/BillingAccount';
import { CostCenter } from '../../common/react-hook-form/fields/CostCenter';
import { FormGridFieldset } from '../../common/react-hook-form/FormGridFieldset/FormGridFieldset';
import { SourceSystem } from '../../generated/api/sourceSystem';
import { SubscriptionCategory } from '../../common/enums';
import { getSubscriptionTypes } from '../../public/common/util/category';
import { startNotification } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import type { BillingAccountSearchResponse } from '../../generated/api/billingAccountSearchResponse';
import type { MoveContactSubscriptionParams } from '../../generated/api/moveContactSubscriptionParams';

import './MoveContactForm.scss';

interface ValidationErrorsProps {
  sourceAccountMdmId: string;
  targetAccountMdmId: string;
  validationErrors: MoveContactValidationError[];
}

interface SubscriptionsListProps {
  billingAccounts: BillingAccountSearchResponse[];
  mdmId: string;
  subscriptions: SubscriptionHeader[];
}

interface FormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  costCenter?: string;
  employeeNumber?: string;
  subscriptionParams: MoveContactSubscriptionParams[];
}

const getErrorTitleTranslation = (errorType: MoveContactValidationError.ErrorTypeEnum) => {
  switch (errorType) {
    case ErrorTypeEnum.BILLING_CONTACT:
      return t.JR9F('The user is the billing contact and/or recipient');
    case ErrorTypeEnum.DUPLICATE_CONTACT:
      return t.ZE2K('We found a user with similar contact details in the target company');
    case ErrorTypeEnum.INVALID_EPP_AGREEMENT:
      return t.A145(
        'The target company does not have a corresponding Elisa Palvelupäätelaite (EPP) service agreement, so the device cannot be transferred'
      );
    case ErrorTypeEnum.YTT_PRESENT:
      return t.X1RF('The user is a contact person on the subscription');
    case ErrorTypeEnum.SUBSCRIPTION_IN_ACTIVATION:
      return t.DC0T('The user has products that have not been processed yet');
    default:
      return '';
  }
};

const getErrorSpecificationTranslation = (errorType: MoveContactValidationError.ErrorTypeEnum) => {
  switch (errorType) {
    case ErrorTypeEnum.BILLING_CONTACT:
      return `${t.I4ME('In billing account(s)')}: `;
    case ErrorTypeEnum.INVALID_EPP_AGREEMENT:
      return `${t.TRE5(elisaDevicesServiceMsg)}: `;
    case ErrorTypeEnum.YTT_PRESENT:
      return `${t.AYSW('Elisa Yritystietoturva')}: `;
    case ErrorTypeEnum.SUBSCRIPTION_IN_ACTIVATION:
      return `${t.C001(orderMsg)}: `;
    default:
      return '';
  }
};

const mapRecordIdsToLinks = (recordIds: string[], mdmId: string, path: string, idParam: string, linkText?: string) =>
  recordIds.map((recordId, index) => (
    <span key={recordId}>
      <Link
        to={`${generatePath(path, { [idParam]: recordId })}?mdmId=${mdmId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText || recordId}
      </Link>
      {index < recordIds.length - 1 && ', '}
    </span>
  ));

const getRecordIdLinks = (
  sourceAccountMdmId: string,
  targetAccountMdmId: string,
  validationError: MoveContactValidationError
) => {
  switch (validationError.errorType) {
    case ErrorTypeEnum.BILLING_CONTACT:
      return mapRecordIdsToLinks(
        validationError.recordIds,
        sourceAccountMdmId,
        paths.BILLING_ACCOUNT,
        'billingAccountId'
      );
    case ErrorTypeEnum.DUPLICATE_CONTACT:
      return mapRecordIdsToLinks(
        validationError.recordIds,
        targetAccountMdmId,
        paths.COMPANY_INFO_CONTACT,
        'contactMasterId',
        t.X1TL('View contact details')
      );
    case ErrorTypeEnum.INVALID_EPP_AGREEMENT:
      return mapRecordIdsToLinks(validationError.recordIds, sourceAccountMdmId, paths.PS_DEVICE, 'subscriptionId');
    case ErrorTypeEnum.YTT_PRESENT:
      return mapRecordIdsToLinks(validationError.recordIds, sourceAccountMdmId, paths.PS_SERVICE, 'subscriptionId');
    case ErrorTypeEnum.SUBSCRIPTION_IN_ACTIVATION:
      return mapRecordIdsToLinks(validationError.recordIds, sourceAccountMdmId, paths.CUSTOMER_ORDER, 'orderId');
    default:
      return [];
  }
};

const getSubscriptionLink = (subscription: SubscriptionHeader, mdmId: string) => {
  if (
    subscription.subscriptionType &&
    getSubscriptionTypes(SubscriptionCategory.VOICE).includes(subscription.subscriptionType)
  ) {
    return `${generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${mdmId}`;
  } else if (
    subscription.subscriptionType &&
    getSubscriptionTypes(SubscriptionCategory.BROADBAND).includes(subscription.subscriptionType)
  ) {
    return `${generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${mdmId}`;
  } else if (
    subscription.subscriptionType &&
    getSubscriptionTypes(SubscriptionCategory.DEVICE).includes(subscription.subscriptionType)
  ) {
    return `${generatePath(paths.PS_DEVICE, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${mdmId}`;
  } else if (
    subscription.subscriptionType &&
    getSubscriptionTypes(SubscriptionCategory.SERVICE).includes(subscription.subscriptionType)
  ) {
    return `${generatePath(paths.PS_SERVICE, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${mdmId}`;
  } else {
    throw new Error('Invalid subscription type');
  }
};

const ValidationErrors = ({ sourceAccountMdmId, targetAccountMdmId, validationErrors }: ValidationErrorsProps) => (
  <div className="of-move-contact-form__validation-errors-container">
    {validationErrors.map((validationError, index) => (
      <div key={index} role="alert">
        <h4>{getErrorTitleTranslation(validationError.errorType)}</h4>
        <p
          className={`${index === validationErrors.length - 1 ? dsClass.MARGIN_BOTTOM_0 : ''} ${dsClass.MARGIN_TOP_1}`}
        >
          {getErrorSpecificationTranslation(validationError.errorType)}
          {getRecordIdLinks(sourceAccountMdmId, targetAccountMdmId, validationError)}
        </p>
      </div>
    ))}
  </div>
);

const SubscriptionsList = ({ billingAccounts, mdmId, subscriptions }: SubscriptionsListProps) => (
  <CL.Grid className={`${dsClass.MARGIN_TOP_5} of-move-contact-form__subscriptions-list`} role="table">
    <div role="rowgroup">
      <CL.GridRow
        role="row"
        className={`${dsClass.PADDING_BOTTOM_2} of-move-contact-form__subscriptions-list-header-row`}
      >
        <CL.GridCol role="columnheader" colWidthXL={3} colWidthL={3}>
          <span className="of-move-contact-form__column-header">{t.C84H('Product')}</span>
        </CL.GridCol>
        <CL.GridCol role="columnheader" colWidthXL={2} colWidthL={3}>
          <span className="of-move-contact-form__column-header">{t.IFT9(billingAccountMsg)}</span>
        </CL.GridCol>
        <CL.GridCol role="columnheader" colWidthXL={4} colWidthL={3}>
          <span className="of-move-contact-form__column-header">{t.MZS3('New billing agreement')}</span>
        </CL.GridCol>
        <CL.GridCol role="columnheader" colWidthXL={3} colWidthL={3}>
          <span className="of-move-contact-form__column-header">{t.QJUW(costCenterMsg)}</span>
        </CL.GridCol>
      </CL.GridRow>
    </div>
    {subscriptions.map((sub, index) => (
      <div key={sub.subscriptionId} role="rowgroup">
        <CL.GridRow
          role="row"
          className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} ${dsClass.PADDING_TOP_3} ${dsClass.PADDING_BOTTOM_3} of-move-contact-form__border-divider`}
        >
          <CL.GridCol role="cell" colWidthXL={3} colWidthL={3} colWidthM={3} colWidthS={6} colWidthXS={4}>
            <div className="of-move-contact-form__column-header--mobile">{t.C84H('Product')}</div>
            <Link to={getSubscriptionLink(sub, mdmId)} target="_blank" rel="noopener noreferrer">
              {sub.subscriptionName}
            </Link>
          </CL.GridCol>
          <CL.GridCol role="cell" colWidthXL={2} colWidthL={3} colWidthM={3} colWidthS={6} colWidthXS={4}>
            <div className="of-move-contact-form__column-header--mobile">{t.IFT9(billingAccountMsg)}</div>
            <Link
              to={`${generatePath(paths.BILLING_ACCOUNT, { billingAccountId: sub.billingAccountDisplayId })}?mdmId=${mdmId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {sub.billingAccountDisplayId}
            </Link>
          </CL.GridCol>
          <CL.GridCol role="cell" colWidthXL={4} colWidthL={3} colWidthM={3} colWidthS={6} colWidthXS={4}>
            <div className="of-move-contact-form__column-header--mobile">{t.MZS3('New billing agreement')}</div>
            <BillingAccount
              enableAddNewBa={false}
              billingAccounts={billingAccounts}
              hideLabel={true}
              name={`subscriptionParams.${index}.targetBillingAccountId`}
            />
          </CL.GridCol>
          <CL.GridCol role="cell" colWidthXL={3} colWidthL={3} colWidthM={3} colWidthS={6} colWidthXS={4}>
            <div className="of-move-contact-form__column-header--mobile">{t.QJUW(costCenterMsg)}</div>
            <CostCenter name={`subscriptionParams.${index}.costCenter`} hideLabel={true} />
          </CL.GridCol>
        </CL.GridRow>
      </div>
    ))}
  </CL.Grid>
);

const Disclaimer = () => (
  <CL.Disclaimer
    className={`${dsClass.MARGIN_TOP_8} ${dsClass.MARGIN_BOTTOM_6}`}
    disclaimerType="info"
    icon={<CL.Icon icon="information" />}
    title={t.BEKE('Note')}
    text={t.M1RK(
      'Note that if the user has open approval requests, they will be cancelled. In addition, eventual OmaElisa for Companies, Elisa Ring, and Vakio access rights will be removed. Roles and services can be activated again after the transfer.'
    )}
  />
);

export const MoveContactForm = ({
  broadbandSubscriptions,
  contact,
  contactMasterId,
  deviceSubscriptions,
  mdmId,
  voiceSubscriptions,
}: MoveContactProps) => {
  const { authenticatedUser } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetAccounts = getUserAccounts(authenticatedUser).filter(acc => acc.accountMasterId !== mdmId);
  const [targetAccountMdmId, setTargetAccountMdmId] = useState<string | undefined>(
    targetAccounts.length === 1 ? targetAccounts[0].accountMasterId : undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [validateResponse, setValidateResponse] = useState<MoveContactValidateResponse | undefined>(undefined);
  const [targetAccountBillingAccounts, setTargetAccountBillingAccounts] = useState<BillingAccountSearchResponse[]>([]);
  const allSubscriptions = [...voiceSubscriptions, ...broadbandSubscriptions, ...deviceSubscriptions].map(
    sub => sub.result
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: contact.person?.firstName,
      lastName: contact.person?.lastName,
      email: contact.person?.email,
      phoneNumber: contact.person?.phoneNumber,
      costCenter: contact.person?.costCenter,
      employeeNumber: contact.person?.employeeNumber,
      subscriptionParams: allSubscriptions.map(sub => ({
        subscriptionId: sub.subscriptionId,
        costCenter: sub.costCenter,
        subscriptionReference: sub.subscriptionReference,
      })),
    },
  });

  const onSubmit = async (values: FormValues) => {
    const subscriptionParams = values.employeeNumber
      ? values.subscriptionParams.map(subParam => ({
          ...subParam,
          subscriptionReference: values.employeeNumber,
        }))
      : values.subscriptionParams;
    setSubmitInProgress(true);
    try {
      const res = await moveContact(
        {
          sourceContactId: contact.contactId!,
          targetAccountMasterId: targetAccountMdmId!,
          subscriptionParams: subscriptionParams,
          targetContactParams: {
            email: values.email!,
            phoneNumber: values.phoneNumber!,
            costCenter: values.costCenter,
            referenceOrAdditionalInformation: values.employeeNumber,
          },
        },
        mdmId
      );
      dispatch(startNotification(t.RM4V('The contact was successfully transferred'), 'success'));
      navigate(
        `${generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId: res.contactMasterId })}?mdmId=${targetAccountMdmId}`
      );
    } catch {
      dispatch(startNotification(t.LYDG('Transferring contact failed'), 'error'));
    } finally {
      setSubmitInProgress(false);
    }
  };

  useEffect(() => {
    const fetchBillingAccountsForTargetAccount = async () =>
      await fetchBillingAccounts(
        { useSearchService: true, sourceSystem: SourceSystem.SFDC },
        { mdmId: targetAccountMdmId }
      );

    const validate = async () => {
      const moveContactValidateResponse = await validateMoveContact(
        {
          contactId: contact.contactId!,
          targetAccountMasterId: targetAccountMdmId!,
        },
        mdmId
      );
      setValidateResponse(moveContactValidateResponse);

      if (moveContactValidateResponse.valid) {
        const billingAccountsResponse = await fetchBillingAccountsForTargetAccount();
        setTargetAccountBillingAccounts(billingAccountsResponse.searchResults || []);
      }
    };

    if (contact.contactId && targetAccountMdmId) {
      setIsLoading(true);
      validate().finally(() => setIsLoading(false));
    }
  }, [contact.contactId, mdmId, targetAccountMdmId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className={dsClass.MARGIN_BOTTOM_7}>
          {targetAccounts.length === 1 && (
            <section>
              <h3>{t.LXFL('The company to which the user will be transferred')}</h3>
              <p>{targetAccounts[0].name}</p>
            </section>
          )}
          {targetAccounts.length > 1 && (
            <div className={dsClass.MARGIN_BOTTOM_4}>
              <h3>{t.BR9A(companySelectMsg)}</h3>
              <div className={dsClass.MARGIN_BOTTOM_3}>
                <span className={dsClass.MARGIN_RIGHT_1}>
                  {t.YLXB('Select the company to which the user will be transferred.')}
                </span>
                <CL.Tooltip
                  triggerElement={<CL.Icon className="custom-icon" size="s" color="neutral-700" icon="information" />}
                  placement="top"
                  i18n_tooltip_contentText={t.HHCQ(
                    `You can only transfer a user between companies you have access to. The user's agreements will also be transferred. If needed, request access from the admin of the other company.`
                  )}
                />
              </div>
              <FormGridFieldset>
                <CompanySelector
                  className={dsClass.MARGIN_BOTTOM_4}
                  userAccounts={targetAccounts.map(acc => ({ ...acc, active: targetAccounts.length === 1 }))}
                  onInputChange={(_, option) => {
                    setTargetAccountMdmId(option.accountMasterId!);
                  }}
                />
              </FormGridFieldset>
            </div>
          )}
        </div>
        {isLoading && <Loading />}
        {!isLoading && !validateResponse?.valid && validateResponse?.validationErrors && (
          <div className={dsClass.PADDING_BOTTOM_3}>
            <h3>{t.YE85('User cannot be moved')}</h3>
            <p>{t.R0EY('User cannot be transferred to another company because')}</p>
            <ValidationErrors
              sourceAccountMdmId={mdmId}
              targetAccountMdmId={targetAccountMdmId!}
              validationErrors={validateResponse.validationErrors}
            />
            <p className={dsClass.MARGIN_TOP_4}>
              {t.I9NH('Modify the information and try transferring the user again. You can also create a')}&nbsp;
              <Link to={`${paths.SUPPORT_CASE_NEW}?mdmId=${mdmId}`}>{`${t.BTJ9('support case')}.`}</Link>
            </p>
          </div>
        )}
        {!isLoading && validateResponse?.valid && (
          <>
            <div className={dsClass.MARGIN_BOTTOM_6}>
              <h3>{t.P8B2('User to be transferred')}</h3>
              <p className={dsClass.MARGIN_BOTTOM_4}>
                {t.RTLQ('Check and fill in user details. Remember to use details related to the new company.')}
              </p>
              <MoveContactFieldset />
            </div>
            {allSubscriptions.length > 0 && (
              <div className={`${dsClass.MARGIN_TOP_6} ${dsClass.MARGIN_BOTTOM_6}`}>
                <h3>{t.VX6E('Agreements to be transferred')}</h3>
                <p>
                  {t.PR0A(
                    'Check the agreements that will be transferred with the user. Select a new billing account in the new company, and if necessary, define new cost centers for products.'
                  )}
                </p>
                <SubscriptionsList
                  billingAccounts={targetAccountBillingAccounts}
                  mdmId={mdmId}
                  subscriptions={allSubscriptions}
                />
              </div>
            )}
            <Disclaimer />
            <div className={`${dsClass.MARGIN_BOTTOM_4} of-move-contact-form__border-divider`} />
            <div className="of-move-contact-form__button-group">
              <ButtonGroupForSubmitAndBack
                cancelDisabled={submitInProgress}
                onCancel={() =>
                  navigate(`${generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId })}?mdmId=${mdmId}`)
                }
                onSubmit={() => {}}
                submitButtonText={t.QVYK(confirmMsg)}
                submitOnLeft={true}
                submitting={submitInProgress}
              />
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};
