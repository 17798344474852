import * as CL from '@design-system/component-library';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGrid } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { type OpenFormList } from '../OpenFormHooks/useOpenFormList.js';
import { allMsg, categoryMsg, nameMsg, subscriptionMsg, t, typeMsg } from '../../../common/i18n/index.js';
import { getPage, parsePath } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import { useTable } from '../OpenFormHooks/useTable.js';
import classNames from 'classnames';
import type { FormId, Page } from '../../../common/constants/pathInterfaces.js';

export const OpenFormListTable = ({
  list,
  pages,
  disabled,
  sizes,
}: {
  list: OpenFormList;
  pages: Map<string, number>;
  disabled: boolean;
  sizes: readonly number[];
}) => {
  const async = useOpenFormAsync();
  const navigate = useNavigate();
  const [cols, rows, view, refresh, toggle, update] = useTable({
    columns: [
      { sortable: true, visible: true, key: 'label', label: t.VGFI(nameMsg), sorted: 'asc' },
      { sortable: true, visible: true, key: 'type', label: t.VGFF(typeMsg) },
      { sortable: true, visible: true, key: 'productCategory', label: t.CV5H(categoryMsg) },
      { sortable: true, visible: true, key: 'subscriptionType', label: t.P674(subscriptionMsg) },
      { sortable: false, visible: true, key: 'icons', label: null, align: 'right' },
      { sortable: false, visible: false, key: 'name', label: null },
      { sortable: false, visible: false, key: 'formId', label: null },
    ],
    order: 'name',
    pagination: { page: 1, size: sizes[0], total: pages.size },
    rows: useCallback(
      () =>
        list.forms.map(({ id, name, productCategoryDesc, subscriptionType, typeDesc }) => ({
          label: <span className="label">{name}</span>,
          type: typeDesc ?? '',
          productCategory: productCategoryDesc ?? '',
          subscriptionType: subscriptionType ?? '',
          icons: (
            <OpenFormButton
              className={`of-openform__list__table__button--${async.storage.hasItem(id) ? 'active' : 'inactive'}`}
              icon={<OpenFormIcon icon="delete" type="regular" />}
              color="link"
              size="m"
              onClick={e => {
                e.stopPropagation();
                if (!disabled) {
                  async.storage.removeItem(id);
                  async.actions.list({ pages: pages.set(id, getPage()) });
                }
              }}
            />
          ),
          name: name ?? '',
          formId: id,
        })),
      [list, pages, disabled, async]
    ),
  });

  useEffect(() => refresh.current(), [pages, disabled, refresh]);

  return (
    <OpenFormGrid className="of-openform__list">
      <CL.Table
        className={classNames('of-openform__list__table', { disabled })}
        tableType="bordered"
        hover={true}
        columns={cols}
        onSortClick={value => toggle.current(cols.find(({ label }) => label === value))}
        rows={rows}
        rowClicks={rows.map(
          ({ formId }) =>
            () =>
              disabled ||
              navigate(parsePath<FormId & Page>(paths.OPENFORM_VIEW, { formId, page: getPage(pages.get(formId)) }))
        )}
      />
      <div className="of-openform__list__navigation">
        <div className="of-openform__list__navigation__dropdown" aria-disabled={disabled}>
          <CL.Dropdown
            integrated={true}
            onValueChange={el => update({ size: Number(el.attributes.getNamedItem('data-value')!.value) })}
            disabled={disabled}
            items={sizes
              .flatMap((size, i) => (sizes[i] < view.total ? { label: String(size), value: String(size) } : []))
              .concat({ label: t.EY6A(allMsg), value: String(view.total) })}
          />
          {view.start} - {view.end} / {view.total}
        </div>
        <div className="of-openform__list__navigation__buttons">
          <OpenFormButton
            className="of-openform__list__navigation__buttons__page"
            icon={<OpenFormIcon icon="arrow-left" />}
            color="link"
            size="m"
            disabled={disabled || view.page <= 1}
            onClick={() => update({ page: view.page - 1 })}
          />
          <OpenFormButton
            className="of-openform__list__navigation__buttons__index"
            text={view.page}
            color="link"
            size="m"
            disabled={disabled}
          />
          <OpenFormButton
            className="of-openform__list__navigation__buttons__page"
            icon={<OpenFormIcon icon="arrow-right" />}
            color="link"
            size="m"
            disabled={disabled || view.page >= view.pages}
            onClick={() => update({ page: view.page + 1 })}
          />
        </div>
      </div>
    </OpenFormGrid>
  );
};
