import * as CL from '@design-system/component-library';
import { NotificationText } from './NotificationText';
import { dsClass } from '../../common/constants/dsClasses';
import {
  getDisclaimerFields,
  getOnlineModelForBasketItem,
  getPrice,
  getPriceFromBasketOfferItem,
  getPriceToDisplay,
} from './shoppingBasketUtils';
import { getProductImage } from './shoppingBasketUiUtils';
import { oneTimePaymentMsg, removeMsg, t } from '../../common/i18n';
import type { BasketItem } from '../../common/types/shoppingBasket.js';
import type { OnlineModel } from '../../generated/api/onlineModel';

import './DiscontinuedProducts.scss';

interface DiscontinuedProductEntryProps {
  basketItem: BasketItem;
  onQuantityChange: (productId: string, quantity: number) => void;
  onlineModel?: OnlineModel | undefined;
}

const DiscontinuedProductEntry = ({ basketItem, onQuantityChange, onlineModel }: DiscontinuedProductEntryProps) => {
  const priceFromModel = getPrice(basketItem, onlineModel, []);
  const price = priceFromModel || { price: getPriceFromBasketOfferItem(basketItem.offer) };

  const priceToDisplay = getPriceToDisplay(price, 1); // Just display price for one item
  const disclaimerFields = getDisclaimerFields(t.ASEI(oneTimePaymentMsg), price);

  return (
    <li className={dsClass.SHOPPINGCART_PRODUCT}>
      <div className={dsClass.SHOPPINGCART_PRODUCT_INFO}>
        <div aria-hidden="true" className={dsClass.SHOPPINGCART_PRODUCT_IMAGE}>
          {getProductImage(basketItem.imageUrl, basketItem.name)}
        </div>
        <div className={dsClass.SHOPPINGCART_PRODUCT_DETAILS}>
          <div className={dsClass.SHOPPINGCART_PRODUCT_NAME_AND_DESCRIPTION}>
            <h3 className={dsClass.SHOPPINGCART_PRODUCT_NAME}>
              <span>{basketItem.name}</span>
            </h3>
            <div className={dsClass.SHOPPINGCART_PRODUCT_DESCRIPTION} />
          </div>
          <NotificationText className={dsClass.PADDING_2} icon="block" message={t.L9HF('Product not available')} />
          <div className={dsClass.SHOPPINGCART_PRODUCT_QUANTITY}>
            <CL.Button size="m" color="light" onClick={() => onQuantityChange(basketItem.id, 0)}>
              <CL.Icon icon="delete" />
              {t.R3VE(removeMsg)}
            </CL.Button>
          </div>
          <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_AND_DISCLAIMER}>
            <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE}>
              <output className={dsClass.SHOPPINGCART_PRICE_AMOUNT}>{priceToDisplay.amount}</output>
              <span className={dsClass.SHOPPINGCART_PRICE_UNIT}>{priceToDisplay.unit}</span>
            </div>
            <div className={dsClass.SHOPPINGCART_PRODUCT_DISCLAIMER}>
              {disclaimerFields.map((row, rowIndex) => (
                <div key={rowIndex}>{row}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export interface DiscontinuedProductsProps {
  basketItems: BasketItem[];
  onQuantityChange: (productId: string, quantity: number) => void;
  onlineModels?: OnlineModel[];
}

export const DiscontinuedProducts = ({ basketItems, onQuantityChange, onlineModels }: DiscontinuedProductsProps) => (
  <>
    <h2 className={`of-discontinued-prices-header ${dsClass.PADDING_BOTTOM_2} ${dsClass.PADDING_TOP_10}`}>
      {t.B9A9('Discontinued products')}
    </h2>
    <ul className={dsClass.SHOPPINGCART_PRODUCTS}>
      {basketItems.map(basketItem => {
        const onlineModelForItem = getOnlineModelForBasketItem(onlineModels || [], basketItem);
        return (
          <DiscontinuedProductEntry
            key={basketItem.id}
            basketItem={basketItem}
            onlineModel={onlineModelForItem}
            onQuantityChange={onQuantityChange}
          />
        );
      })}
    </ul>
  </>
);
