import * as CL from '@design-system/component-library';
import { BillingAccount } from '../../generated/api/billingAccount.js';
import { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import { BillingAccountFieldset } from '../BillingAccount/BillingAccountFieldset.js';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { ContactType } from '../../generated/api/contactType.js';
import { DeliveryMethodFieldset } from '../BillingAccount/DeliveryMethodFieldset.js';
import { DuplicateContactDialog } from '../Dialogs/DuplicateContactDialog.js';
import { FormGridFieldset } from '../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ReceiverTypes,
  createNewBillingAccount,
  getElectronicInvoiceOperatorDropDownOptions,
  isDuplicateContactError,
} from '../../common/utils/billingAccountUtils.js';
import {
  billingAccountChangeCompletedMsg,
  billingAccountChangeFailedMsg,
  cancelMsg,
  confirmMsg,
  deviceListSavedAsDraftMsg,
  selectCompanyHeaderMsg,
  t,
} from '../../common/i18n/index.js';
import { changeSubscriptionBillingAccount, putDraftCatalog } from '../../common/fetch.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToDDMMYYYY, getNormalizedAdjustedDate } from '../../common/utils/dateUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate, useRevalidator } from 'react-router-dom';
import { getPrimaryMdmId, isMultiBiz } from '../../common/utils/accountUtils.js';
import { getUserAccounts } from '../Header/dynamic/headerFunctions.js';
import { isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { startNotification } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useStore } from 'react-redux';
import { useState } from 'react';
import type { BaLoaderData } from '../../common/loaders.js';
import type { Catalog } from '../../generated/api/catalog';
import type {
  ContactPersonFormValues,
  DuplicateContactResponse,
  ReceiverType,
} from '../../common/utils/billingAccountUtils.js';
import type { State } from '../../selfservice/exports.js';
import type { VirtualCatalog } from '../../generated/api/virtualCatalog';

import './BillingAccountForm.scss';

export const BillingAccountForm = () => {
  const dispatch = useDispatch();
  const { search, state } = useLocation();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { featureFlags } = useStore<State>().getState().config;
  const searchParams = new URLSearchParams(search);
  const subscriptionId = searchParams.get('subscriptionId') || undefined;
  const { authenticatedUser } = useAuth();
  const { contacts, billChannels, companyInfo } = useLoaderData() as BaLoaderData;
  const [isSaving, setIsSaving] = useState(false);
  const isConsolidatedViewsEnabled = isFeatureEnabledForUser(
    'consolidatedViews',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );
  const defaultPayerAddress = companyInfo?.address;
  const defaultOperator =
    (billChannels && getElectronicInvoiceOperatorDropDownOptions(billChannels)[0].value) ?? undefined;

  const fourteenDaysFromNow = getNormalizedAdjustedDate(14, undefined, undefined, false);
  const expirationDate = formatTimestampToDDMMYYYY(fourteenDaysFromNow.getTime());
  const [duplicateContactErrorResponse, setDuplicateContactErrorResponse] = useState<DuplicateContactResponse>();
  const [duplicateContactFieldName, setDuplicateContactFieldName] = useState<
    'billingAccount.billingContactId' | 'billingAccount.billReceiverId'
  >('billingAccount.billingContactId');
  const [submittedBillingContactId, setSubmittedBillingContactId] = useState<string | undefined>(undefined);

  const defaultBillingAccount: BillingAccount = {
    payerBusinessId: companyInfo?.businessId,
    payerName: companyInfo?.companyName ?? '',
    additionalBillReceiverEmails: [],
    billLanguage: 'FI',
    billReceiverEmail: '',
    billReceiverId: state?.defaultBillingContactId,
    billReceiverName: '',
    billReceiverType: ContactType.PERSON,
    billingAccountName: companyInfo?.companyName ?? '',
    billingContactId: state?.defaultBillingContactId,
    billFormatType: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
    deliveryMethod: state?.defaultDeliveryMethod ?? BillingAccountDeliveryMethod.ELECTRONIC,
    billElectronicOperator: defaultOperator,
    payerAddress: {
      line1: defaultPayerAddress?.line1 ?? '',
      postalCode: defaultPayerAddress?.postalCode ?? '',
      postOffice: defaultPayerAddress?.postOffice ?? '',
      countryCode: 'FIN',
    },
  };

  const defaultContactFormValues: ContactPersonFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const methods = useForm({
    defaultValues: {
      billingAccount: defaultBillingAccount,
      billReceiverSelection: ReceiverTypes.SAME_AS_CONTACT,
      contact: { ...defaultContactFormValues },
      newBillReceiverContact: { ...defaultContactFormValues },
      key: 0,
    },
    values: {
      billingAccount: defaultBillingAccount,
    },
  });
  const { handleSubmit, resetField, setValue, watch } = methods;
  const { key } = watch();

  const onSubmit = async ({
    billingAccount,
    billReceiverSelection,
    contact,
    newBillReceiverContact,
  }: {
    billingAccount: BillingAccount;
    billReceiverSelection: ReceiverType;
    contact: ContactPersonFormValues;
    newBillReceiverContact: ContactPersonFormValues;
    key: number;
  }) => {
    setIsSaving(true);
    const newBillingAccount = { ...billingAccount };
    const selectedMdmId = searchParams.get('mdmId') || undefined;

    try {
      const createBillingAccountResponse = await createNewBillingAccount({
        newBillingAccount,
        billReceiverSelection,
        newContact: contact,
        newBillReceiverContact,
        contacts: contacts.contacts,
        setDuplicateContactFieldName,
        setSubmittedBillingContactId,
        submittedBillingContactId,
        mdmId: selectedMdmId,
      });

      if (isDuplicateContactError(createBillingAccountResponse)) {
        setDuplicateContactErrorResponse(createBillingAccountResponse);
        return;
      }

      dispatch(startNotification(t.OS99('Billing account was successfully created.')));

      if (subscriptionId) {
        try {
          await changeSubscriptionBillingAccount(
            subscriptionId,
            createBillingAccountResponse.billingAccountId,
            selectedMdmId
          );
          dispatch(startNotification(t.CTBU(billingAccountChangeCompletedMsg), 'success'));
        } catch {
          dispatch(startNotification(t.S3DX(billingAccountChangeFailedMsg), 'error'));
        }
      } else if (state.catalog) {
        const updatedCatalog: Catalog = {
          ...state.catalog,
          billingAccountId: createBillingAccountResponse.billingAccountId,
        };
        await putDraftCatalog(
          (state.virtualCatalog as VirtualCatalog).virtualCatalogCode,
          updatedCatalog,
          selectedMdmId
        );
        dispatch(startNotification(t.P7JV(deviceListSavedAsDraftMsg)));
      }

      if (state?.redirectPath) {
        navigate(generatePath(state.redirectPath), {
          replace: true,
        });
      } else {
        navigate(
          `${generatePath(paths.BILLING_ACCOUNT, {
            billingAccountId: createBillingAccountResponse.billingAccountDisplayId,
          })}?${searchParams}`,
          {
            state: {
              showExpirationWarning: true,
              expirationDate,
            },
          }
        );
      }
    } catch (err) {
      if (!(err instanceof TypeError) && err.message) {
        dispatch(startNotification(err.message, 'error'));
      } else {
        dispatch(startNotification(t.EBJW('Adding a new billing account failed. Please try again later.'), 'error'));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onCancel = () => {
    if (state?.redirectPath) {
      navigate(generatePath(state.redirectPath), {
        replace: true,
      });
    } else {
      navigate(paths.BILLING_ACCOUNTS);
    }
  };

  // If a user has created a new contact as both a billing contact and a bill receiver, and the latter request fails,
  // we should set the already submitted billing contact ID into the form state to mark it as selected in the dropdown.
  const setBillingContactAsSelectedIfSubmitted = (revalidate?: boolean) => {
    if (submittedBillingContactId) {
      resetField('contact.firstName');
      resetField('contact.lastName');
      resetField('contact.email');
      resetField('contact.phoneNumber');
      setValue('billingAccount.billingContactId', submittedBillingContactId);
      if (revalidate) {
        revalidator.revalidate();
      }
    }
  };

  const onCancelDuplicateContact = () => {
    setBillingContactAsSelectedIfSubmitted(true);
    setDuplicateContactErrorResponse(undefined);
  };

  const onSelectDuplicateContact = async (contactId: string) => {
    setBillingContactAsSelectedIfSubmitted();
    setValue(duplicateContactFieldName, contactId);
    setValue('key', (key || 0) + 1);
    await handleSubmit(onSubmit)();
  };

  return (
    <>
      {duplicateContactErrorResponse?.duplicateContact && (
        <DuplicateContactDialog
          duplicateContact={duplicateContactErrorResponse.duplicateContact}
          duplicateContactType={duplicateContactErrorResponse.type}
          onCloseDialog={onCancelDuplicateContact}
          onSelectDuplicateContact={onSelectDuplicateContact}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          {isMultiBiz(authenticatedUser) && isConsolidatedViewsEnabled && !subscriptionId && !state.catalog && (
            <>
              <h3 className={dsClass.MARGIN_TOP_7}>{t.BR9A(selectCompanyHeaderMsg)}</h3>
              <p>{t.OPKW('Select the company for which you want to create a new billing account.')}</p>
              <FormGridFieldset>
                <CompanySelector
                  className="billing-account-form-company-selector"
                  userAccounts={getUserAccounts(authenticatedUser)}
                  onInputChange={(_, option) => {
                    searchParams.set('mdmId', option.accountMasterId!);
                    navigate(`?${searchParams}`, { state: state });
                  }}
                  initialMdmId={getPrimaryMdmId(authenticatedUser)}
                />
              </FormGridFieldset>
            </>
          )}
          {!state?.hideDisclaimerText && (
            <CL.Disclaimer
              className={`${dsClass.MARGIN_TOP_6} ${dsClass.MARGIN_BOTTOM_6}`}
              disclaimerType="info"
              icon={<CL.Icon icon="information" />}
              text={t.FI29(
                'Please note that the billing account is valid for 14 days. If no products or services are ' +
                  'ordered for the billing account within this time period, it will be automatically cancelled.'
              )}
            />
          )}
          <h3>{t.AMRD('Billing account details')}</h3>
          <BillingAccountFieldset billChannels={billChannels} contacts={contacts} />
          <DeliveryMethodFieldset billChannels={billChannels} contacts={contacts.contacts ?? []} />
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_FLEX_END}`}>
            <CL.Button type="submit" className={dsClass.MARGIN_RIGHT_4} loading={isSaving}>
              {t.QVYK(confirmMsg)}
            </CL.Button>
            <CL.Button color="light" disabled={isSaving} onClick={onCancel}>
              {t.B2V1(cancelMsg)}
            </CL.Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
