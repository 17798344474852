import * as CL from '@design-system/component-library';
import { Await, Link, useAsyncValue, useLoaderData } from 'react-router-dom';
import { BillingAccount } from '../../common/react-hook-form/fields/BillingAccount.js';
import { BillingAccountFieldset } from '../BillingAccount/BillingAccountFieldset.js';
import { CREATED_CONTACT } from '../../common/react-hook-form/fields/Contact.js';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE } from '../../common/utils/billingAccountUtils.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/formik/Fields';
import { Catalog } from '../../generated/api/catalog.js';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { ContractPeriod } from '../../common/utils/catalogUtils.js';
import { DeliveryMethodFieldset } from '../BillingAccount/DeliveryMethodFieldset.js';
import { DialogType } from '../../common/enums.js';
import { EppCategory } from '../../generated/api/eppCategory.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Name, TextInput } from '../../common/react-hook-form/fields';
import { NewContact } from '../DuplicateContact/NewContact.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { Suspense, useEffect, useState } from 'react';
import { TextAreaComponent } from '../../common/react-hook-form/components/TextAreaComponent.js';
import {
  alvZeroMsg,
  cancelMsg,
  catalogCorporateMessageExampleLinkMsg,
  catalogCorporateMessageExampleMsg,
  catalogCorporateMessageHelpMsg,
  catalogCorporateMessageInstructionMsg,
  catalogCorporateMessageLabelMsg,
  catalogCorporateMessageSelectorMsg,
  companysShareOfMonthlyFeeMsg,
  connectDeviceToRegistrationProgramMsg,
  contractPeriodMsg,
  damageInsuranceCoverMsg,
  damageInsuranceMsg,
  deviceEnrollmentProgramAliasHelpMsg,
  deviceEnrollmentProgramAliasInfoMsg,
  deviceEnrollmentProgramMsg,
  deviceToEnrollmentProgramMsg,
  deviceToEnrollmentReadMoreMsg,
  elisaDevicesServiceMsg,
  enrollmentAliasPlaceHolderMsg,
  fieldCantBeEmptyMsg,
  monthMsg,
  nameOfCatalogMsg,
  t,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import type { BillChannel } from '../../generated/api/billChannel.js';
import type { BillingAccountCreateFormValues } from '../CreateBillingAccount/CreateBillingAccount.js';
import type { BillingAccountsResponse } from '../../generated/api/billingAccountsResponse.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CompanyInfoState } from '../../common/types/states';
import type { Contact } from '../../generated/api/contact.js';
import type { ContactsResponse } from '../../generated/api/contactsResponse.js';
import type { FieldValues } from 'react-hook-form';
import type { ReceiverType } from '../../common/utils/billingAccountUtils.js';
import type { SelectRadioItem } from '../../common/react-hook-form/components/SelectRadio.js';
import type { VirtualCatalog } from '../../generated/api/virtualCatalog';

import './CatalogConfigurationForm.scss';

interface ContactBaseValues {
  name: string;
  productType: 'EPP_RECURRING' | 'RECURRING' | 'ONETIME';
  damageInsurance: Array<Exclude<EppCategory, 'PLACEHOLDER'>>;
  corporateMessage?: string;
  enrollmentProgramConsent: boolean;
  enrollmentProgramAlias?: string;
  billingAccountId?: string;
  approverMustSelectBillingAccount?: boolean;
}

interface SupportValues {
  isCorporateMessageAdded?: boolean;
  billingAccount?: BillingAccountCreateFormValues;
  billReceiverSelection?: ReceiverType;
  contact?: Contact;
  receiver?: Contact;
}

export interface FormValues extends SupportValues {
  catalog: ContactBaseValues & {
    contractPeriod: string;
    corporateShare?: string;
  };
  key?: number;
}

export interface ReadyFormValues extends SupportValues {
  catalog: ContactBaseValues & {
    contractPeriod?: number;
    corporateShare?: number;
  };
  products?: Record<string, Array<CatalogProduct>>;
  virtualCatalog?: VirtualCatalog;
}

export interface CatalogConfigurationFormProps {
  defaultValues?: FormValues;
  isEppActive: boolean;
  isRecurringChargeAllowed: boolean;
  isOnetimeChargeAllowed: boolean;
  onSubmit: (values: FormValues) => void;
  cancelHref: string;
  submitButtonMsg?: string;
  mdmId?: string;
}

const contractPeriodList = [
  { label: `${ContractPeriod.MONTHS_12} kk`, value: `${ContractPeriod.MONTHS_12}` },
  { label: `${ContractPeriod.MONTHS_24} kk`, value: `${ContractPeriod.MONTHS_24}` },
  { label: `${ContractPeriod.MONTHS_36} kk`, value: `${ContractPeriod.MONTHS_36}` },
];

const BAFormWithDeferredData = () => {
  const { billChannels } = useLoaderData() as { billChannels: BillChannel[] };
  const deferredContacts: ContactsResponse = useAsyncValue() as ContactsResponse;
  return (
    <>
      <BillingAccountFieldset
        billChannels={billChannels}
        contacts={deferredContacts}
        isNewDuplicateContactHandling={true}
      />
      <DeliveryMethodFieldset
        billChannels={billChannels}
        contacts={deferredContacts.contacts ?? []}
        isNewDuplicateContactHandling={true}
      />
    </>
  );
};

export interface CatalogConfigurationLoaderData {
  billingAccounts: BillingAccountsResponse;
  billChannels: BillChannel[];
  contacts: Promise<ContactsResponse>;
  companyInfo: CompanyInfoState;
}

const calculateContractPeriod = (fv: FormValues) => {
  return fv.catalog.productType !== Catalog.ProductTypeEnum.ONETIME
    ? parseInt(fv.catalog.contractPeriod, 10)
    : undefined;
};

const calculateCorporateShare = (fv: FormValues) => {
  const number = parseFloat(fv.catalog.corporateShare || '');
  return fv.catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING && !isNaN(number)
    ? Math.round(number * 100)
    : undefined;
};

export const mapFormValuesToBeProcessed = (values: FormValues): ReadyFormValues => {
  return {
    catalog: {
      name: values.catalog.name,
      contractPeriod: calculateContractPeriod(values),
      productType: values.catalog.productType,
      corporateShare: calculateCorporateShare(values),
      damageInsurance: values.catalog.damageInsurance,
      corporateMessage: values.isCorporateMessageAdded ? values.catalog.corporateMessage : undefined,
      enrollmentProgramConsent: values.catalog.enrollmentProgramConsent,
      enrollmentProgramAlias: values.catalog.enrollmentProgramConsent
        ? values.catalog.enrollmentProgramAlias || ''
        : '',
      approverMustSelectBillingAccount: values.catalog.approverMustSelectBillingAccount || false,
      billingAccountId: values.catalog.billingAccountId,
    },

    billingAccount:
      values.catalog.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE ? values.billingAccount : undefined,
    contact: values.billingAccount?.billingContactId === CREATED_CONTACT ? values.contact : undefined,
    receiver: values.billingAccount?.billReceiverId === CREATED_CONTACT ? values.receiver : undefined,
    billReceiverSelection: values.billReceiverSelection,
  };
};

export const CatalogConfigurationForm = ({
  defaultValues,
  isEppActive,
  isRecurringChargeAllowed,
  isOnetimeChargeAllowed,
  onSubmit,
  cancelHref,
  submitButtonMsg,
  mdmId,
}: CatalogConfigurationFormProps) => {
  const loaderData = useLoaderData() as CatalogConfigurationLoaderData;
  const dispatch = useDispatch();
  const methods = useForm<FieldValues>({ mode: 'all', shouldUnregister: true, defaultValues });
  const runtimeValues = methods.watch() as FormValues;
  const [currentMdmId, setCurrentMdmId] = useState<string>();

  const productTypeList = [
    isEppActive && { label: t.TRE5(elisaDevicesServiceMsg), value: Catalog.ProductTypeEnum.EPP_RECURRING },
    isRecurringChargeAllowed && {
      label: t.OF9U('Devices subject to monthly fee'),
      value: Catalog.ProductTypeEnum.RECURRING,
    },
    isOnetimeChargeAllowed && {
      label: t.XED2('Device subject to one-time fee'),
      value: Catalog.ProductTypeEnum.ONETIME,
    },
  ].filter(i => i);

  useEffect(() => {
    if (mdmId !== currentMdmId) {
      methods.setValue('key', (runtimeValues.key || 0) + 1);
      setCurrentMdmId(mdmId);
    }
  }, [currentMdmId, mdmId, methods, runtimeValues]);

  return (
    <>
      <FormProvider {...methods}>
        <form className="catalog-configuration" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Name
            name="catalog.name"
            label={t.M6TP(nameOfCatalogMsg)}
            placeholder={t.M6TP(nameOfCatalogMsg)}
            maxLength={65}
            hint={t.WYPE('Max 65 characters')}
            allowSpecialCharacters={true}
            className="width-50"
          />

          <SelectRadio
            name="catalog.productType"
            label={t.LQ5X('Product types')}
            items={productTypeList as SelectRadioItem[]}
          />
          <SelectRadio
            name="catalog.contractPeriod"
            label={t.ULI0(contractPeriodMsg)}
            items={contractPeriodList}
            disabled={runtimeValues.catalog.productType === Catalog.ProductTypeEnum.ONETIME}
          />

          {isEppActive && (
            <div className="flex">
              <TextInput
                label={`${t.H8Q4(companysShareOfMonthlyFeeMsg)} (${t.S8TX(alvZeroMsg)})`}
                min={0}
                name="catalog.corporateShare"
                required={false}
                className="width-50"
                type="number"
                placeholder=""
                disabled={runtimeValues.catalog.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
              >
                <span className="extension">€/{t.XXVX(monthMsg)}</span>
              </TextInput>
            </div>
          )}

          <h4>{t.QSXP(damageInsuranceMsg)}</h4>
          <p>{t.FLLT(damageInsuranceCoverMsg)}</p>

          <Checkbox
            name="catalog.damageInsurance"
            label="Business Pro"
            value={EppCategory.BUSINESS_PRO}
            disabled={runtimeValues.catalog.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
          />
          <Checkbox
            name="catalog.damageInsurance"
            label="Business Premium"
            value={EppCategory.BUSINESS_PREMIUM}
            disabled={runtimeValues.catalog.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
          />

          <h4>{t.C8DA(catalogCorporateMessageLabelMsg)}</h4>
          <p>{t.Q57Q(catalogCorporateMessageInstructionMsg)}</p>
          <CL.Button
            className={dsClass.MARGIN_BOTTOM_3}
            color="light"
            onClick={() => {
              dispatch(
                showDialog({
                  body: <>{t.Y0HD(catalogCorporateMessageExampleMsg)}</>,
                  header: '',
                  type: DialogType.GENERIC_INFO_DIALOG,
                })
              );
            }}
            size="s"
          >
            {t.ZU62(catalogCorporateMessageExampleLinkMsg)}
          </CL.Button>

          <Checkbox
            className={dsClass.MARGIN_BOTTOM_3}
            name="isCorporateMessageAdded"
            label={t.FFQZ(catalogCorporateMessageSelectorMsg)}
          />

          {runtimeValues.isCorporateMessageAdded && (
            <TextAreaComponent
              rows={5}
              maxLength={500}
              disabled={!runtimeValues.isCorporateMessageAdded}
              hint={t.HQTV(catalogCorporateMessageHelpMsg)}
              name="catalog.corporateMessage"
              validate={(value: string, values: FormValues) => {
                if (!values.isCorporateMessageAdded) {
                  return;
                }
                return value ? undefined : t.VPVR(fieldCantBeEmptyMsg);
              }}
            />
          )}

          <h4>{t.S14B(deviceEnrollmentProgramMsg)}</h4>
          <p>
            {t.ZIH3(deviceToEnrollmentProgramMsg)}{' '}
            <Link to={paths.DEVICE_ENROLLMENT}>{t.X2UI(deviceToEnrollmentReadMoreMsg)}</Link>
          </p>
          <p>{t.CSOC(deviceEnrollmentProgramAliasHelpMsg)}</p>
          <Checkbox
            className={dsClass.MARGIN_BOTTOM_3}
            name="catalog.enrollmentProgramConsent"
            label={t.MNEQ(connectDeviceToRegistrationProgramMsg)}
          />

          {runtimeValues.catalog.enrollmentProgramConsent && (
            <TextInput
              label={t.IBVP(enrollmentAliasPlaceHolderMsg)}
              name="catalog.enrollmentProgramAlias"
              required={false}
              placeholder={t.IBVP(enrollmentAliasPlaceHolderMsg)}
              tooltip={t.BJRZ(deviceEnrollmentProgramAliasInfoMsg)}
            />
          )}

          <hr className={dsClass.MARGIN_BOTTOM_4} />

          <h3>{t.IXED('Device catalog billing information')}</h3>
          <h4>{t.Y93H('Select default invoicing agreement for device list')}</h4>
          <p>
            {t.SOJT(
              'The selected billing account will be used by default in all orders from this device list. If necessary, the order acceptor can select another billing account instead. Alternatively, you can have the order acceptor select the billing account each time that they approve an order.'
            )}
          </p>
          <Checkbox
            className={dsClass.MARGIN_BOTTOM_3}
            name="catalog.approverMustSelectBillingAccount"
            label={t.RXBO('The order acceptor needs to select the invoicing agreement when they approve an order')}
          />
          <div className={dsClass.MARGIN_BOTTOM_3}>
            <BillingAccount
              billingAccounts={loaderData.billingAccounts.searchResults || []}
              name="catalog.billingAccountId"
              key={'catalog.billingAccountId' + runtimeValues.key}
            />
          </div>
          {runtimeValues.catalog.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
            <Suspense
              fallback={
                <div className={dsClass.TEXT_ALIGN_CENTER}>
                  <CL.LoadingSpinner size="l" className={dsClass.MARGIN_BOTTOM_4} />
                </div>
              }
            >
              <Await resolve={loaderData.contacts}>
                <BAFormWithDeferredData />
              </Await>
            </Suspense>
          )}

          <CL.ButtonGroup>
            <Link
              to={cancelHref}
              className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_LINK} ${dsClass.LINK_BUTTON_SIZE_M}`}
            >
              {t.B2V1(cancelMsg)}
            </Link>
            <CL.Button type="submit" loading={methods.formState.isSubmitting}>
              {submitButtonMsg}
            </CL.Button>
          </CL.ButtonGroup>
        </form>
      </FormProvider>
      {/* These need to be put outside the form to avoid form inside form */}
      {runtimeValues.billingAccount?.billingContactId === CREATE_NEW_CONTACT_OPTION_VALUE && (
        <NewContact formContext={methods} id="billingAccount.billingContactId" name="contact" />
      )}
      {runtimeValues.billingAccount?.billReceiverId === CREATE_NEW_CONTACT_OPTION_VALUE && (
        <NewContact formContext={methods} id="billingAccount.billReceiverId" name="receiver" />
      )}
    </>
  );
};
