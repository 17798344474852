import * as CL from '@design-system/component-library';
import { Modal } from '../Modal/Modal';
import { ModalButtons } from '../Modal/ModalButtons';
import {
  cancelMsg,
  orderingOmaLaskuServiceNotPossibleBodyMsg,
  orderingOmaLaskuServiceNotPossibleTitleMsg,
  t,
} from '../../common/i18n';

export interface OrderForbiddenModalProps {
  onClose: () => void;
}

export const OrderForbiddenModal = ({ onClose }: OrderForbiddenModalProps) => {
  return (
    <Modal closeable onClose={onClose} size="s" heading={t.XNEL(orderingOmaLaskuServiceNotPossibleTitleMsg)}>
      {t.KJP5(orderingOmaLaskuServiceNotPossibleBodyMsg)}
      <ModalButtons>
        <CL.Button onClick={onClose} size="m">
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </ModalButtons>
    </Modal>
  );
};
